.why-us{
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0% 0% 0% 0%;
}

.WhyUs1 h1 {
    font-size: 10vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
    padding-top: 0%;
    padding-bottom: 0%;
}
.WhyUs1 .para1{
    font-size: 1.4vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.WhyUs1 .para2{
    font-size: 2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.WhyUs-Box{
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 5% 0% 8% 0%;
    gap: 2%;
    position: relative;
    overflow: hidden;
}

.animates::before {
    content: "EMPOWER";
    animation: animatee infinite 5s;
}
@keyframes animatee {
    0% {
        content: "EMPOWER";
    }
    50% {
        content: "EMPOWER, ENABLE";
    }
    75% {
        content: "EMPOWER, ENABLE OPTIMISE";
    }
}



.whyUs-Box22 :hover{
    cursor: pointer;
    
    .whyUs-Box2{
        background: #011639 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        border-radius: 20px;
        width: 30vw;
        height: 40vw;
        text-align: -webkit-left;
        padding: 2% 2% 0% 2%;
        overflow: hidden;
    }
   
    .headlines2 {
        font-size: 2.5vw;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: center;
        padding: 0% 0% 0% 0%;
    }
    .para2{
        font-size: 1.2vw;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        padding: 1% 2% 3% 2%;
        display:block;
    }
        // adding
        .image-containerr {
            position: relative;
            overflow: hidden;
            padding: 0%;
            border-radius: 20px;
            margin: 6% 4% 8% 4%;
            height: 16vw;

            transition-property: all;
            transition-duration: 1s;
            transition-delay: 0.15s;
            transition-timing-function: ease;
        }
        .image-containerr img {
            width: 100%;
            height: 16vw;
            transition: transform 0.3s, height 0.3s;
            transform: scale(1);
            background-size: cover;
            object-fit: cover;
            background-repeat: no-repeat;
            transition-property: all;
            transition-duration: 0.6s;
            transition-timing-function: ease;
        }
    

}

.whyUs-Box22 {
    .whyUs-Box2{
        background: #011639 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        border-radius: 20px;
        width: 30vw;
        height: 40vw;
        overflow: hidden;
        text-align: -webkit-left;
        padding: 2% 2% 0% 2%;
        overflow: hidden;
        
    }
    .para2{
        display: none;
    }
    .headlines2 {
        font-size: 2.5vw;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: center;
        padding: 0% 0% 0% 0%;
        
    }
        // adding
        .image-containerr img {
            transform: scale(1.2);
            height: 26vw;
            width: 100%;
            // border: 1px solid green;
            background-size: cover;
            object-fit: cover;
        }
        .image-containerr {
            position: relative;
            padding: 5% 2% 5% 2%;
            overflow: hidden;
            margin: 6% 4% 8% 4%;
            // border: 1px solid red;
            height: 30vw;
            border-radius: 20px;
        }

}


@media  only screen and (max-width: 480px) {

    .why-us{
        display: none;
    }
    .why-us-mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0% 0% 5% 0%;
    }
    
    .WhyUs1-mobile h1 {
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    .WhyUs1-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 10% 2% 10%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .WhyUs1-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .Why-Box-mobile{
        padding: 0% 0% 10% 0%;
        overflow: hidden;
        text-align: -webkit-center;
        gap: 3%;
        position: relative;
        display: grid;
        justify-items: center;
        margin: 0% 0% 20% 0%;
    }
    .Why-Box1-mobile{
        background: #011639 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 20px;
        width: 75vw;
        text-align: end;
        padding: 2% 2% 0% 2%;
        overflow: hidden;
    }

    .Why-img1{
        width: 100%;
        // height: 45vw;
        overflow: hidden;
        padding: 5%;
    }
    .Why-img1 img {
        max-width: 100%;
        width: 100%;
        // border: 1px solid green;
        // height: 100%;
    }


    .Why-content1{
        padding: 5% 0% 5% 0%;
        // border: 1px solid red;
    }
    .Why-content1 .para1{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: center;
    }
    .Why-content1 .para2 {
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        // margin: 5% 5% 30% 5%;
        padding: 2% 0% 0% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .margin-para{
        margin: 5% 2% 40% 2%;
    }
    .margin-para1{
        margin: 5% 2% 10% 2%;
    }

}

@media  only screen and (min-width: 481px) and (max-width: 767px){
    .why-us{
        display: none;
    }
    .why-us-mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0% 0% 5% 0%;
    }
    
    .WhyUs1-mobile h1 {
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    .WhyUs1-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 10% 2% 10%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .WhyUs1-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .Why-Box-mobile{
        padding: 0% 0% 10% 0%;
        overflow: hidden;
        text-align: -webkit-center;
        gap: 3%;
        position: relative;
        display: grid;
        justify-items: center;
        margin: 0% 0% 5% 0%;
    }
    .Why-Box1-mobile{
        background: #011639 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 20px;
        width: 75vw;
        text-align: end;
        padding: 2% 2% 0% 2%;
        overflow: hidden;
    }

    .Why-img1{
        width: 100%;
        // height: 45vw;
        overflow: hidden;
        padding: 5%;
    }
    .Why-img1 img {
        max-width: 100%;
        width: 100%;
        // border: 1px solid green;
        // height: 100%;
    }


    .Why-content1{
        padding: 5% 0% 5% 0%;
        // border: 1px solid red;
    }
    .Why-content1 .para1{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: center;
    }
    .Why-content1 .para2 {
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        // margin: 5% 5% 30% 5%;
        padding: 2% 0% 0% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .margin-para{
        margin: 5% 2% 40% 2%;
    }
    .margin-para1{
        margin: 5% 2% 10% 2%;
    }
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .why-us{
        display: none;
    }
    .why-us-mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0% 0% 5% 0%;
    }
    
    .WhyUs1-mobile h1 {
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    .WhyUs1-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 10% 2% 10%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .WhyUs1-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .Why-Box-mobile{
        padding: 0% 0% 10% 0%;
        justify-items: center;
        margin: 0% 0% 10% 0%;
        display: flex;
        justify-content: center;
        text-align: center;
        padding-top: 1%;
        padding-bottom: 2%;
        gap: 2%;
        position: relative;
        overflow: hidden;
    }
    .Why-Box1-mobile{
        background: #011639 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 20px;
        width: 30vw;
        text-align: end;
        padding: 2% 2% 0% 2%;
        overflow: hidden;
    }

    .Why-img1{
        width: 100%;
        // height: 45vw;
        overflow: hidden;
        padding: 5%;
    }
    .Why-img1 img {
        max-width: 100%;
        width: 100%;
        // border: 1px solid green;
        // height: 100%;
    }


    .Why-content1{
        padding: 5% 0% 0% 0%;
    }
    .Why-content1 .para1{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: center;
    }
    .Why-content1 .para2 {
        line-height: 200%;
        font-size: 1.5vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        padding: 10% 0% 5% 0%;
    }
    .margin-para{
        margin: 0% !important;
    }
    .margin-para1{
        margin: 0% !important;
    }
}



@media  only screen and (min-width: 1024px) {
    .why-us-mobile{
        display: none;
    }
}

@media  only screen and (min-width: 1024px) and (max-width: 1399px) {
    .WhyUs1 h1 {
        font-size: 10vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding: 0%;
    }
    .WhyUs1 .para1 {
        font-size: 1.4vw;
    }


    .whyUs-Box22 :hover{
        cursor: pointer;
        
        .whyUs-Box2{
            background: #011639 0% 0% no-repeat padding-box;
            border-radius: 20px;
            opacity: 1;
            border-radius: 20px;
            width: 30vw;
            height: 45vw;
            overflow: hidden;
            text-align: -webkit-left;
            padding: 2% 2% 0% 2%;

        }
       
        .headlines2 {
            font-size: 2.5vw;
            font-weight: bolder;
            letter-spacing: 0.96px;
            color: #FFFFFF;
            opacity: 1;
            font-family:  "space-Groteskr" !important;
            text-align: center;
            padding: 0% 0% 0% 0%;
        }
        .para2{
            text-align: center;
            padding: 1% 2% 3% 2%;
            color: white;
            font-family:  "space-Groteskr" !important;
            letter-spacing: 1.2px;
            font-size: 1.1vw;
            line-height: 25px;
            display: block;
        }
            // adding
            .image-containerr {
                position: relative;
                overflow: hidden;
                padding: 0%;
                border-radius: 20px;
                margin: 6% 4% 8% 4%;
                height: 16vw;

                transition-property: all;
                transition-duration: 1s;
                transition-delay: 0.15s;
                transition-timing-function: ease;
            }
            .image-containerr img {
                width: 100%;
                height: 16vw;
                // border: 1px solid green;
                transition: transform 0.3s, height 0.3s;
                transform: scale(1);
                background-size: cover;
                object-fit: cover;
                background-repeat: no-repeat;
                transition-property: all;
                transition-duration: 0.6s;
                transition-timing-function: ease;
            }
        
    
    }
    
    .whyUs-Box22 {
        .whyUs-Box2{
            background: #011639 0% 0% no-repeat padding-box;
            border-radius: 20px;
            opacity: 1;
            border-radius: 20px;
            width: 30vw;
            height: 45vw;
            overflow: hidden;
            text-align: -webkit-left;
            padding: 2% 2% 0% 2%;
            
        }
        .para2{
            display: none;
        }
        .headlines2 {
            font-size: 2.5vw;
            font-weight: bolder;
            letter-spacing: 0.96px;
            color: #FFFFFF;
            opacity: 1;
            font-family:  "space-Groteskr" !important;
            text-align: center;
            padding: 8% 0% 0% 0%;
            
        }
            // adding
            .image-containerr img {
                transform: scale(1.2);
                height: 26vw;
                width: 100%;
                // border: 1px solid green;
                background-size: cover;
                object-fit: cover;
            }
            .image-containerr {
                position: relative;
                padding: 5% 2% 5% 2%;
                overflow: hidden;
                margin: 6% 4% 8% 4%;
                // border: 1px solid red;
                height: 30vw;
                border-radius: 20px;
            }
    
    }


}


@media  only screen and (min-width: 1200px) and (max-width: 1399px) {
    
    .whyUs-Box22 :hover{
        cursor: pointer;
        
        .whyUs-Box2{
            background: #011639 0% 0% no-repeat padding-box;
            border-radius: 20px;
            opacity: 1;
            border-radius: 20px;
            width: 30vw;
            height: 40vw !important;
            overflow: hidden;
            text-align: -webkit-left;
            padding: 2% 2% 0% 2%;

        }
       
        .headlines2 {
            font-size: 2.5vw;
            font-weight: bolder;
            letter-spacing: 0.96px;
            color: #FFFFFF;
            opacity: 1;
            font-family:  "space-Groteskr" !important;
            text-align: center;
            padding: 0% 0% 0% 0%;
        }
        .para2{
            text-align: center;
            padding: 1% 2% 3% 2%;
            color: white;
            font-family:  "space-Groteskr" !important;
            letter-spacing: 1.2px;
            font-size: 1.1vw;
            line-height: 25px;
            display: block;
        }
            // adding
            .image-containerr {
                position: relative;
                overflow: hidden;
                padding: 0%;
                border-radius: 20px;
                margin: 6% 4% 8% 4%;
                height: 16vw;

                transition-property: all;
                transition-duration: 1s;
                transition-delay: 0.15s;
                transition-timing-function: ease;
            }
            .image-containerr img {
                width: 100%;
                height: 16vw;
                // border: 1px solid green;
                transition: transform 0.3s, height 0.3s;
                transform: scale(1);
                background-size: cover;
                object-fit: cover;
                background-repeat: no-repeat;
                transition-property: all;
                transition-duration: 0.6s;
                transition-timing-function: ease;
            }
        
    
    }
    
    .whyUs-Box22 {
        .whyUs-Box2{
            background: #011639 0% 0% no-repeat padding-box;
            border-radius: 20px;
            opacity: 1;
            border-radius: 20px;
            width: 30vw;
            height: 40vw !important;
            overflow: hidden;
            text-align: -webkit-left;
            padding: 2% 2% 0% 2%;
            
        }
        .para2{
            display: none;
        }
        .headlines2 {
            font-size: 2.5vw;
            font-weight: bolder;
            letter-spacing: 0.96px;
            color: #FFFFFF;
            opacity: 1;
            font-family:  "space-Groteskr" !important;
            text-align: center;
            padding: 0% 0% 0% 0% !important;
            
        }
            // adding
            .image-containerr img {
                transform: scale(1.2);
                height: 26vw;
                width: 100%;
                // border: 1px solid green;
                background-size: cover;
                object-fit: cover;
            }
            .image-containerr {
                position: relative;
                padding: 5% 2% 5% 2%;
                overflow: hidden;
                margin: 6% 4% 8% 4%;
                // border: 1px solid red;
                height: 30vw;
                border-radius: 20px;
            }
    
    }
}