.sticky-pagess {
  display: flex;
  transition: background-color 0.3s ease;
  // border-radius: 30px;
}

.sticky-sectionss {
  position: sticky;
  top: 0;
  width: 30vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  color: #011639;
}

.sticky-sectionss h1,
.sticky-sectionss img {
  margin: 0;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  // color: #011639 !important;
}

.sticky-sectionss h1.active,
.sticky-sectionss img.active {
  background-color: transparent;
  color: #011639 !important;
  font-size: 2.2vw !important;
  font-family:  "space-Grotesksb" !important;
}

.sticky-sectionss img {
  max-width: 100%;
  height: auto;
  $active: true;
  opacity: if($active, 1, 0);
  transition: opacity 0.5s ease;
  position: absolute;
  top: 10;
  left: 0;
  z-index: 10;
}

.scrollable-section {
  flex: 1;
  overflow-y: auto;
  width: 30vw;
  padding: 20px;
}


.scrollable-section .defaultClass {
  margin: 0;
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  padding: 0% 5% 0% 3%;
  align-items: flex-start;
}



.bg-whitee.active {
  .Scrollhead2.active {
    color: #fff !important;
  }
}

.bg-whitee:hover {
  color: #fff !important;
}

.scrollable-section h1,
.scrollable-section h2,
.scrollable-section p,
.scrollable-section a {
  margin: 0;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scrollable-section h1.active,
.scrollable-section h2.active,
.scrollable-section p.active,
.scrollable-section a.active {
  background-color: #ddd;
}



.numscroll {
  color: #FF6600;
  font-size: 1.2vw !important;
}

.numscroll.active {
  color: #FF6600;
  font-size: 2vw !important;
}

.headScroll {
  font-size: 2vw;
  text-align: left;
  font-family:  "space-Groteskr" !important;
  padding: 1% 0% 0% 3%;
}

.sticky-sectionss .para1 {
  font-size: 1.2vw;
  text-align: left;
  font-family: "space-Groteskr" !important;
  letter-spacing: 0.96px;
}

.sticky-sectionss .headScroll1 {
  font-size: 4.5vw;
  opacity: 1;
  font-family:  "space-Groteskb" !important;
}

.borderLine1 {
  border-left: 4px solid #FF6600;
  height: 33vh;
  left: 40%;
  position: relative;
  top: -18%;
}

.borderLine2 {
  border-left: 4px solid #FF6600;
  height: 35vh;
  right: 40%;
  position: relative;
  align-self: flex-end !important;
  bottom: -25%;
}

.padclass {
  padding: 0% 0% 0% 5%;
}




.Scrollhead1 {
  font-size: 2vw !important;
  text-align: left;
  font-family: "space-Grotesksb" !important;
  // font-weight: bold;
  // padding: 20% 0% 5% 0%;
}

.Scrollhead11 {
  font-size: 2vw !important;
  text-align: left;
  font-family:"space-Grotesksb" !important;
  // font-weight: bold;
  padding: 20% 0% 5% 0%;
}

.bg-blackk {
  color: #011639;
}

.colClass {
  color: #011639;
}

.colClass:hover {
  color: #011639 !important;
}







.bg-whitee {
  color: #fff;
}

.Scrollhead2 {
  font-size: 1.2vw !important;
  text-align: left;
  font-family:"space-Groteskr" !important;
  letter-spacing: 0.96px;
  padding: 5% 0% 5% 0%;
}

.Scrollhead22 {
  font-size: 1.2vw !important;
  text-align: left;
  font-family:"space-Groteskr" !important;
  letter-spacing: 0.96px;
  padding: 5% 0% 5% 0%;
}

.ScrollPara {
  font-size: 1.3vw !important;
  text-align: left;
  font-family:"space-Groteskr" !important;
  letter-spacing: 1.36px;
  padding: 0% 0% 0% 0%;
  font-weight: lighter;
}

.bg-blackk:hover {
  color: #011639 !important;
}

.classActive.active {
  .colClass {
    color: #ffff !important;
  }

  .addWhite.active {
    color: #fff !important;
  }

  .colClass:hover {
    color: #fff !important;
  }

  .sticky-sectionss h1 {
    color: #fff !important;
  }

}

.classActive.active {
  .addWhite.active {
    color: #fff !important;
  }
}



.paddingClass {
  padding: 5% 0% 5% 0%;
}

.learnMore {
  background-color: #FF6600;
  color: #FFFFFF;
  font-family:  "space-Groteskr" !important;
  font-size: 1.2vw;
  height: auto !important;
  padding: 5% 5% 5% 5% !important;
  border: 1px solid red;
  display: block !important;
  width: 15vw;
  border-radius: 10px;
  text-align: -webkit-center;
}

.LearnArrow {
  transform: rotateZ(135deg) scale(1.3);
  background-position: 0 0;
  color: #FFFFFF;
}

.learnMore.actice  {
  color: #FFFFFF !important;
}
.LearnArrow.actice  {
  color: #FFFFFF !important;
}
.learnMore:hover  {
  color: #FFFFFF !important;
}
.LearnArrow:hover  {
  color: #FFFFFF !important;
}













@media only screen and (max-width: 480px) {
  .sticky-pagess {
    display: none;
  }

  .MobileSticky {}

  .stickkyMain {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    color: #011639;
    background: transparent linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 15% 0% 0% 0%;
  }

  // .stickkyMain .headSticky{
  //   font-size: 40px;
  //   opacity: 1;
  //   font-family:  "space-Groteskb" !important;
  // }
  .MobileSticky .headSticky {
    font-size: 40px;
    opacity: 1;
    font-family:  "space-Groteskb" !important;
  }

  .Scrollable-Mobile {
    position: relative;
    text-align-last: center;
  }

  .Scrollable-Mobile h1 {
    font-size: 25px;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    font-weight: bold;
    padding: 5% 0% 2% 0%;
  }

  .Scrollable-Mobile h2 {
    font-size: 18px;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    padding: 0% 0% 5% 0%;
    color: #011639 !important;
  }

  .Scrollable-Mobile p {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 1.36px;
    padding: 0% 5% 0% 5%;
    font-weight: lighter;
  }

  .Scrollable-Mobile img {
    max-width: 100%;
    height: auto;
    width: 55%;
  }

  .stickkyMain .para1 {
    font-size: 20px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
  }

  .MobileSticky .para1 {
    font-size: 20px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
  }

  .borderLine {
    border-left: 4px solid #FF6600;
    height: 10vh;
    position: relative;
    top: 0%;
  }

  .paddingClass {
    padding: 5% 0% 5% 0%;
    text-align: -webkit-center !important;
  }

  .learnMore {
    background-color: #FF6600;
    color: #FFFFFF;
    font-family:  "space-Groteskr" !important;
    font-size: 16px;
    height: auto !important;
    padding: 5% 5% 5% 5% !important;
    border: 1px solid red;
    display: block !important;
    width: 40vw !important;
    border-radius: 10px;
    margin: 5% 0% !important;
  }










  .StickyService-Mobile {
    // border: 1px solid red;
    height: 100vh;
    height: 100svh;
    width: 100%;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    color: #011639;
    background: transparent linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    // padding: 15% 0% 0% 0%;
  }

  // .Serv-Div{
  //   height: 15vh;
  //   height: 25svh;
  //   // padding: 10%;
  // }
  .Serv-Div .headSticky {
    font-size: 40px;
    opacity: 1;
    font-family:  "space-Groteskb" !important;
    text-align: center;
  }

  .Serv-Div .para1 {
    font-size: 20px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    padding: 0%;
    margin: 0%;
  }

  .scrollable-divv {
    height: 75vh; // Set the desired height for the container
    overflow-y: auto; // Enable vertical scrolling for the container
    overflow-x: hidden; // Disable horizontal scrolling
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Serv-Scroll {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #011639;
    background: transparent linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    position: sticky;
    margin-bottom: 20px; // Add margin between each .Serv-Scroll
  }


  .Serv-Scroll .borderLine {
    border-left: 4px solid #FF6600;
    height: 8vh;
    position: relative;
    top: 0%;
  }

  .Serv-Scroll h1 {
    font-size: 25px;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    font-weight: bold;
    padding: 5% 0% 2% 0%;
  }

  .Serv-Scroll .bdLine {
    border-left: 4px solid #FF6600;
    height: 5vh;
    position: relative;
    top: 0%;
  }

  .Serv-Scroll .borderLine {
    border-left: 4px solid #FF6600;
    height: 5vh;
    position: relative;
    top: 0%;
  }

  .Serv-Scroll h2 {
    font-size: 18px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    padding: 0% 0% 5% 0%;
    color: #011639 !important;
  }

  .Serv-Scroll p {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 1.36px;
    padding: 0% 5% 0% 5%;
    font-weight: lighter;
  }

  .Serv-Scroll img {
    max-width: 100%;
    height: auto;
    width: 50%;
    padding: 2% 0% 0% 0%;
  }

  .paddCl {
    padding: 0%;
    text-align-last: center;
    text-align: -webkit-center;
  }

  .SerMore {
    background-color: #FF6600;
    color: #FFFFFF;
    font-family: "space-Groteskr" !important;
    font-size: 18px;
    height: auto !important;
    padding: 5% 5% 5% 5% !important;
    border: 1px solid red;
    display: flex;
    width: 50vw !important;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }

  .SerMoreArrow {
    transform: rotateZ(135deg) scale(1.3);
    background-position: 0 0;
    color: #FFFFFF;
  }


















  .StickyService-Mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    color: #011639;
    background: transparent linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    // Remove position: sticky; from here
  }






  .scrollable-divv {
    height: 100%; // Set to 100% to take the remaining height
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Serv-Scroll {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #011639;
    background: transparent linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin-bottom: 20px;
    position: sticky;
    top: 0;
  }


  .Serv-Scroll.digital-engineering {
    background-color: #FFA500; // Change to the desired background color for Digital Engineering
  }

  .Serv-Scroll.ai-data {
    background-color: #00FF00; // Change to the desired background color for AI & Data
  }

  .Serv-Scroll.experience-design {
    background-color: #FF00FF; // Change to the desired background color for Experience Design
  }

  .Serv-Scroll.operation-transformation {
    background-color: #FFFF00; // Change to the desired background color for Operation Transformation
  }








  .MobileSticky3 {
    text-align: -webkit-center;
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5% 0% 5% 0%;

  }

  .Service-Content {
    padding: 8% 0%;
  }

  .Service-Content h1 {
    font-size: 45px;
    opacity: 1;
    font-family:  "space-Groteskb" !important;
    color: #FF6600;
  }

  .Service-Content p {
    font-size: 20px;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #fff;
    padding: 0% 5%;
  }

  .card1 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #011639 !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;

  }

  .card2 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(180deg, #5149AC 0%, #241F57 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #fff !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;

  }

  .card3 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(119deg, #B7C3E9 0%, #7A80B0 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #011639 !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;

  }

  .card4 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(119deg, #72ADD6 0%, #5A87C2 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #fff !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;
  }

  .Service-Scroll .bdLine {
    border-left: 4px solid #FF6600;
    height: 8vh;
    position: relative;
    top: 0%;
    display: table;
    margin: 5% 0%;
  }

  .Service-Scroll img {
    max-width: 100%;
    height: auto;
    width: 60%;
  }

  .Service-Scroll h1 {
    font-size: 25px;
    text-align: center;
    font-family: "space-Grotesksb" !important;
    // font-weight: bold;
    padding: 5% 2% 2% 2%;
    ;

  }

  .Service-Scroll h2 {
    font-size: 18px;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 0.96px;
    padding: 0% 0% 5% 0%;

  }

  .Service-Scroll p {
    font-size: 16px;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 1.36px;
    padding: 0% 5% 0% 5%;
    font-weight: lighter;

  }

  .Cl-B {
    color: #011639 !important;
  }

  .img-rotate {
    transform: rotateZ(346deg);
  }
}





@media only screen and (min-width: 768px) {
  .MobileSticky {
    display: none;
  }

  .MobileSticky3 {
    display: none;
  }

  .StickyService-Mobile {
    display: none;
  }
}



@media only screen and (min-width: 481px) and (max-width: 767px) {
  .sticky-pagess {
    display: none;
  }

  .MobileSticky3 {
    text-align: -webkit-center;
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5% 0% 5% 0%;

  }

  .Service-Content {
    padding: 8% 0%;
  }

  .Service-Content h1 {
    font-size: 45px;
    opacity: 1;
    font-family:  "space-Groteskb" !important;
    color: #FF6600;
  }

  .Service-Content p {
    font-size: 20px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #fff;
    padding: 0% 5%;
  }

  .card1 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(118deg, #A2D1F6 0%, #989CFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #011639 !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;

  }

  .card2 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(180deg, #5149AC 0%, #241F57 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #fff !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;

  }

  .card3 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(119deg, #B7C3E9 0%, #7A80B0 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #011639 !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;

  }

  .card4 {
    width: 85vw;
    height: auto;
    text-align-last: center;
    background: transparent linear-gradient(119deg, #72ADD6 0%, #5A87C2 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 2% 0% 5% 0%;
    color: #fff !important;
    margin: 5% 0% 20% 0%;
    overflow: hidden;
  }

  .Service-Scroll .bdLine {
    border-left: 4px solid #FF6600;
    height: 8vh;
    position: relative;
    top: 0%;
    display: table;
    margin: 5% 0%;
  }

  .Service-Scroll img {
    max-width: 100%;
    height: auto;
    width: 60%;
  }

  .Service-Scroll h1 {
    font-size: 25px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    font-weight: bold;
    padding: 5% 2% 2% 2%;
    ;

  }

  .Service-Scroll h2 {
    font-size: 18px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    padding: 0% 0% 5% 0%;

  }

  .Service-Scroll p {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 1.36px;
    padding: 0% 5% 0% 5%;
    font-weight: lighter;

  }

  .Cl-B {
    color: #011639 !important;
  }

  .img-rotate {
    transform: rotateZ(346deg);
  }


  .paddCl {
    padding: 0%;
    text-align-last: center;
    text-align: -webkit-center;
  }

  .SerMore {
    background-color: #FF6600;
    color: #FFFFFF;
    font-family:  "space-Groteskr" !important;
    font-size: 18px;
    height: auto !important;
    padding: 5% 5% 5% 5% !important;
    border: 1px solid red;
    display: flex;
    width: 50vw !important;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }

  .SerMoreArrow {
    transform: rotateZ(135deg) scale(1.3);
    background-position: 0 0;
    color: #FFFFFF;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sticky-sectionss .headScroll1 {
    font-size: 45px !important;
  }

  .sticky-sectionss .para1 {
    font-size: 16px !important;
  }

  .headScroll {
    font-size: 22px !important;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding: 1% 0% 0% 3%;
  }

  .scrollable-section {
    padding: 0% !important;
  }

  .Scrollhead1 {
    font-size: 25px !important;
  }

  .Scrollhead2 {
    font-size: 16px !important;
  }

  .ScrollPara {
    font-size: 16px !important;
  }

  .learnMore {
    width: 30vw !important;
    font-size: 17px !important;
    height: auto !important;
    padding: 8% 5% 8% 5% !important;
  }

  .Scrollhead11 {
    font-size: 25px !important;
  }

  .Scrollhead22 {
    font-size: 16px !important;
  }
}