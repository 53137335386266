.needs-heading-center {
    padding: 0 6rem;
    text-align: center;
}
/* .center-box {
    display: flex !important;
    justify-content: center;
} */
.common-needs-box{
    height: 168px;
    color: #F9F9F9;
    box-shadow: 0px 0px 2px 2px lightgrey;
    margin: 10px 0px;
    width: 250px;
    border-radius: 20px;
}
.needs-box {
    height: 200px;
    color: #F9F9F9;
    /* background-color: #1A2238; */
    box-shadow: rgb(149 157 165 / 20%) 6px 6px 20px;
    margin: 10px 0px;
    /* border-radius:10px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
}
.needs-container-padding {
   padding: 0px 0px 32px 0px;
}
.needs-box-img{
    border-radius: 5px;
    /* background-color: #1A2238; */
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.needs-content-home {
    font-family: "Montserrat-Regular";
    font-size: 18px;
    font-weight: 800;
    background-color: #F9F9F9;
    border-radius: 0px 0px 10px 10px;
    height: 50px;
    padding: 0px 12px;
    color:  black;
}
.needs-content {
    background-color:white;
    border-radius: 0px 0px 10px 10px;
    height: 40px;
    padding: 0px 12px;
    color: black;
}
.section3-box-img{
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    height: 170px;
    border-radius: 20px;
}
.section3-box-title{
    text-align: center;
    padding: 20px 0px;
}
  
  .section3-box-para{
    font-family: "Montserrat-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #6A696B;
    padding: 1rem 0;
    text-align: center;
  }
  .section3-box-view{
    font-family: "Inter-Regular";
    color:#2B2A2C;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 1.5rem 0;
  }
  .common-needs-banner {
        /* background-color: #fff9eb; 
        background: linear-gradient(0deg,#48a7bc,#cce2fc 50%,#48a7bc);
        transform: skew(0deg, 1deg); */
        transform-origin: 100% 0;
        margin-top: 0px;
        /* background-image: url('../../fonts/images/proj_com_need.png'), linear-gradient(0deg, #48A7BC, #CCE2FC 50%, #48A7BC); */
        background-image: url('../../fonts/images/common_needs.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        padding-bottom: 3rem;
        margin-top: -1rem;
  }
  .proj-common-needs-heading-container{
    padding: 48px 80px 44px 80px;
    margin: 0;
  }


  .model_caption_proj{
    position: absolute;
    top: 17%;
    padding: 0;
    max-width: 75%;
    left: 14%;
  }
  
  .model_caption_proj_1 {
    position: absolute;
    top: 14%;
    padding: 0;
    max-width: 75%;
    left: 14%;
  }
  .model_caption_proj_2 {
    position: absolute;
    top: 13%;
    padding: 0;
    max-width: 75%;
    left: 11%;
  }

/* ipad Air */
@media (min-width: 820px) and (max-width: 920px) { 
    .proj-common-needs-heading-container {
      padding: 48px 24px 44px 24px;
    }

  
  }


@media only screen and (max-width: 768px)  {
.needs-container-padding .row{
    padding: 0;
    margin: 0;
}
.heading-right{
    text-align: right !important;
}
.proj-common-needs-heading-container{
    padding: 48px 24px 0;
}
.proj_common_box > img{
    width: 100%;
}
.model_caption_proj{
    position: absolute;
    top: 15%;
    padding: 0;
    max-width: 75%;
    left: 14%;
  }
  
  .model_caption_proj_1 {
    position: absolute;
    top: 15%;
    padding: 0;
    max-width: 75%;
    left: 14%;
  }
  .model_caption_proj_2 {
    position: absolute;
    top: 20%;
    padding: 0;
    max-width: 75%;
    left: 11%;
  }
  /* .digi_common_mob .engage_mob_box:nth-child(1){
    padding-bottom: 16px !important;
  } */

}


@media only screen and (max-width: 650px)  {
    .needs-box {
        margin: 38px 0px;
    }
    .needs-heading-center {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #1A2238;
        position: relative;
        text-align: center;
    }
    .center-box {
        display: inline-block !important;
        margin: 0px 18px;
    }
    .section3-box {
        margin: 10px 15px;
        width: auto;
        height: auto;
        /* width: 200px; */
    }
    
.model_caption_proj{
    position: absolute;
    top: 14%;
    padding: 0;
    max-width: 80%;
    left: 12%;
  }
  
  .model_caption_proj_1 {
    position: absolute;
    top: 8%;
    padding: 0;
    max-width: 80%;
    left: 12%;
  }
  .model_caption_proj_2 {
    position: absolute;
    top: 18%;
    padding: 0;
    max-width: 80%;
    left: 8%;
  }


}

@media only screen and (max-width: 580px)  {
    .proj_common_box > img {
        height: 520px;
    }
    .model_caption_proj_1 {
        position: absolute;
        top: 10%;
        padding: 0;
        max-width: 80%;
        left: 12%;
    }
    .model_caption_proj_1_mob{
        position: absolute;
        top: 14%;
        padding: 0;
        max-width: 80%;
        left: 12%;
    }

 }

 @media only screen and (max-width: 480px)  {
    .proj_common_box > img {
        height: 470px;
    }
    .proj_mob_box_3{
        padding-bottom: 24px;
    }
 }

 @media only screen and (max-width: 420px)  {
    .proj_common_box > img {
        height: 410px;
    }
    .proj_mob_box_3{
        padding-bottom: 24px;
    }
 }

 @media only screen and (max-width: 350px)  {
    .proj_common_box > img {
        height: 405px;
    }
    .proj_mob_box_3{
        padding-bottom: 24px;
    }
    .engage_logo{
        padding-bottom: 8px;
    }
    .engage_head{
        line-height: 21px;
    }
    .engage_logo_line{
        padding-bottom: 0;
    }
 }

 @media (min-width: 820px) and (max-width: 920px) { 
  .needs-container-padding{
    text-align: center;
  }
  .common-needs-banner{
    margin-top: -1rem;
  }

 }

 
 @media only screen and (min-width: 1900px)  { 
  .proj-common-needs-heading-container{
    padding: 48px 120px 44px 120px;
  }
  .common-needs-banner{
    padding-bottom: 96px;
  }

 }

 @media screen and (min-width:768px) and (max-width:819px)  { 
  .common-needs-banner {
    margin-top: -15px;
  }
  .model_caption_proj {
    position: absolute;
    top: 15%;
    padding: 0;
    max-width: 65%;
    left: 14%;
}

.proj-common-needs-heading-container {
  padding: 48px 24px 44px 24px;
}


 }