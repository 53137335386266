.career-main{ 
    padding: 0% 0% 10% 0%
}
.career-inside1{
    left: 10%;
    position: relative;
    width: 80%;
}
.career-inside1 h1{
    font-size: 10vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
    padding-top: 3%;
    padding-bottom: 2%;
}
.career-inside1 .career-p1{
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.career-inside1 .career-p2{
    font-size: 2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.modal_applying{
    color: #000;
    border-radius: 5px;
    text-align: left;
    padding: 0% 8%;
    font-family:  "space-Groteskr" !important;
    background: #fff !important;
  }


.career-inside2{
    width: 85%;
    position: relative;
    left: 7.5%;
} 
.career-inside2  .career-links1{
    display: flex;
    cursor: pointer;
    border: 1px #d3d1d1;
    // border-top-style: solid;
    border-bottom-style: solid;
}
.career-inside2 .career-links1 h1 {
    width: 50%;
    color: white;
    font-family:  "space-Groteskr" !important;
    padding: 3% 0% 2% 2%;
    font-size: 1.4vw;
}
.career-inside2  .career-links2 {
    width: 50%;
    display: flex;
    position: relative;
    align-self: center;
    justify-content: end;
    padding: 0% 5% 0% 0%;

}
.career-inside2  .career-links2 p{
    color: white;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 1.4vw;
}


.modell-heading{
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.model-content-main{

}
.btn-career{
    display: flex;
    justify-content: space-between;
}
.btn-career1{
    display: flex;
    padding: 0% 0% 5% 0%;
    justify-content: space-between;
}

.btn-career-head{
    font-family:  "space-Grotesksb" !important;
    font-size: 2.4vw;
    padding: 2% 0%; 

}
.para-evee{
    font-family:  "space-Groteskr" !important;
    font-size: 1.2vw;
    opacity: 0.9;
    letter-spacing: 0.44px;
    line-height: 160%;
}
.para-evee1{
    line-height: 200%;
    margin-top: 0;
    margin-bottom: 5%;
}



.btn-career .share-career{
    width: 25%;
}
.btn-career .share-career-link{
    width: 25%;
}
.btn-career1 .close-career{
    border: 1px solid #FF6600;
    color: #000 !important;
    background-color: #fff;
    box-shadow: none;
    border-radius: 10px;
    padding: 1% 3% 1% 2%;
    font-size: 1.2vw;
    font-family:  "space-Grotesksb" !important;
    cursor: pointer;
}
.btn-career .Appply-career{
    position: relative;
    text-align: end;
    display: inline-block;
    background: #FF6600;
    color: #fff;
    padding: 15px 50px 40px 55px;
    font-size: 1.4vw;
    cursor: pointer;
    clip-path: polygon(0% 0%, 20% 100%, 60% 80%, 100% 60%, 100% 0%);
    transition: all 0.5s;
    font-family:  "space-Grotesksb" !important;
}
.btn-career1 .Appply-career{
    position: relative;
    background: #FF6600;
    border-radius: 10px;
    color: #000;
    padding: 1% 3% 1% 2%;
    margin: 0% 0% 0% 64%;
    font-size: 1.2vw;
    font-family:  "space-Grotesksb" !important;
    cursor: pointer;
}

.modal_content_applying {
    .modal-content{
        background: white !important;
        color: rgba(0, 0, 0, 0);
        width: 90%;
        border-radius: 2%;
        left: 5%;
        top: 150px;
        position: relative;
        margin-bottom: 20%;
    }
}

.modal_applying2{
    color: #000000;
    border-radius: 5px;
    text-align: left;
    padding: 20px;
    font-family:  "space-Groteskr" !important;
    background: #fff !important;
}

.flex-input{
    display: flex;
    gap: 2%;
}
.form-career1 input {
    font-family:  "space-Groteskr" !important;
    font-weight: 500;
    font-size: 16px;
    color: #030303;
    text-align: left;
    height: 30px;
}
.text-box1 {
    width: 100%;
    font-size: 14px;
    font-family:  "space-Groteskr" !important;
    color: #000000;
    margin-bottom: 45px;
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
    background: transparent;
    background-color: transparent;
    font-weight: lighter;
}
.apply_btn_applying {
    border: 1px solid #F05F30;
    padding: 10px 12px 10px 12px;
    border-radius: 5px;
    background: #F05F30;
    color: #fff;
    margin-top: 4%;
    font-family:  "space-Groteskr" !important;
    background-image: linear-gradient(to left, transparent, transparent 50%, #F05F30 50%, #F05F30);
    background-position: 100% 0;
    background-size: 200% 100%;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .5s ease-in;
    position: relative;
}
.center-apply{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.file-upload-button1  {
    position: relative;
    font-family:  "space-Groteskr" !important;
    color: #000000;
    display: flex;
    justify-content: left;
    padding: 10px 0px 10px 20px;
    background: #fff;
    transition: 1s;
    width: 40%;
    border: 1px solid #FF6600;
    cursor: pointer;
    border-radius: 10px;
    font-size: 1.2vw;
}
.model_box_close_btn1 {
    color: #f05f30;
    cursor: pointer;
    float: right;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    top: -4px;
}

.modal_content_applying2 {
    .modal-content{
        background: #fff !important;
        width: 100%;
        border-radius: 2%;
    }
}

    .modal-open .modal {
        overflow-x: hidden;
        overflow-y: auto;
        background: #000;
    }


    .model-above{    
    top: -150px;
    position: relative;
    color: #fff;
    text-align: center;
}
.model-above h1{    
    font-family:  "space-Grotesksb" !important;
    font-size: 2.6vw;
    text-transform: uppercase;
}

.modell-heading p{
    font-family:  "space-Grotesksb" !important;
    font-size: 1.4vw;
}



@media  only screen and (max-width: 480px) {
    .career-inside2 .career-links1 h1 {
        width: 50%;
        color: white;
        font-family:  "space-Groteskr" !important;
        padding: 3% 0% 2% 2%;
        font-size: 16px;
    }
    .career-inside2 .career-links2 p {
        color: white;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
        font-size: 14px;
    }
    .btn-career-head {
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        padding: 2% 0%;
    }
    .para-evee {
        font-family:  "space-Groteskr" !important;
        font-size: 14px;
        opacity: 0.9;
        letter-spacing: 0.44px;
        line-height: 160%;
    }
    .modal_content_applying .modal-content {
        background: white !important;
        color: rgba(0, 0, 0, 0);
        width: 90%;
        border-radius: 2%;
        left: 5%;
        top: 125px;
        position: relative;
        margin-bottom: 70%;
    }
    .model-above {
        top: -85px;
        position: relative;
        color: #fff;
        text-align: center;
    }
    .model-above h1 {
        font-family:  "space-Grotesksb" !important;
        font-size: 20px;
        text-transform: uppercase;
    }
    .modell-heading p {
        font-family:  "space-Grotesksb" !important;
        font-size: 12px;
    }
    .btn-career1 .close-career {
        border: 1px solid #FF6600;
        color: #000 !important;
        background-color: #fff;
        box-shadow: none;
        border-radius: 10px;
        padding: 2% 3% 2% 2%;
        font-size: 14px;
        font-family:  "space-Grotesksb" !important;
        cursor: pointer;
    }
    .btn-career1 {
        display: flex;
        padding: 5% 0% 10% 0%;
        justify-content: space-between;
    }
    .btn-career1 .Appply-career {
        position: relative;
        background: #FF6600;
        border-radius: 10px;
        color: #000;
        padding: 2% 4% 2% 4%;
        font-size: 14px;
        font-family:  "space-Grotesksb" !important;
        cursor: pointer;
        margin: 0% 0% 0% 25%;
    }

    .btn-career .Appply-career {
        position: relative;
        text-align: end;
        display: inline-block;
        background: #FF6600;
        color: #fff;
        padding: 2% 10% 10% 15%;
        font-size: 16px;
        cursor: pointer;
        clip-path: polygon(0% 0%, 23% 86%, 19% 71%, 100% 50%, 100% 0%);
        transition: all 0.5s;
        font-family:  "space-Grotesksb" !important;
        margin: 0% 0% 0% 0%;
    }
    .career-main {
        padding: 0% 0% 15% 0%;
    }
}


@media  only screen and (min-width: 481px) and (max-width: 767px){
    
    .career-inside2 .career-links1 h1 {
        width: 50%;
        color: white;
        font-family:  "space-Groteskr" !important;
        padding: 3% 0% 2% 2%;
        font-size: 16px;
    }
    .career-inside2 .career-links2 p {
        color: white;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
        font-size: 14px;
    }
    .btn-career-head {
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        padding: 2% 0%;
    }
    .para-evee {
        font-family:  "space-Groteskr" !important;
        font-size: 14px;
        opacity: 0.9;
        letter-spacing: 0.44px;
        line-height: 160%;
    }
    .modal_content_applying .modal-content {
        background: white !important;
        color: rgba(0, 0, 0, 0);
        width: 90%;
        border-radius: 2%;
        left: 5%;
        top: 125px;
        position: relative;
        margin-bottom: 70%;
    }
    .model-above {
        top: -85px;
        position: relative;
        color: #fff;
        text-align: center;
    }
    .model-above h1 {
        font-family:  "space-Grotesksb" !important;
        font-size: 20px;
        text-transform: uppercase;
    }
    .modell-heading p {
        font-family:  "space-Grotesksb" !important;
        font-size: 12px;
    }
    .btn-career1 .close-career {
        border: 1px solid #FF6600;
        color: #000 !important;
        background-color: #fff;
        box-shadow: none;
        border-radius: 10px;
        padding: 2% 3% 2% 2%;
        font-size: 14px;
        font-family:  "space-Grotesksb" !important;
        cursor: pointer;
    }
    .btn-career1 {
        display: flex;
        padding: 5% 0% 10% 0%;
        justify-content: space-between;
    }
    .btn-career1 .Appply-career {
        position: relative;
        background: #FF6600;
        border-radius: 10px;
        color: #000;
        padding: 2% 4% 2% 4%;
        font-size: 14px;
        font-family:  "space-Grotesksb" !important;
        cursor: pointer;
        margin: 0% 0% 0% 25%;
    }

    .btn-career .Appply-career {
        position: relative;
        text-align: end;
        display: inline-block;
        background: #FF6600;
        color: #fff;
        padding: 2% 10% 10% 15%;
        font-size: 16px;
        cursor: pointer;
        clip-path: polygon(0% 0%, 23% 86%, 19% 71%, 100% 50%, 100% 0%);
        transition: all 0.5s;
        font-family:  "space-Grotesksb" !important;
        margin: 0% 0% 0% 0%;
    }
    .career-main {
        padding: 0% 0% 15% 0%;
    }
}



@media  only screen and (min-width: 768px) and (max-width: 1023px){
    .btn-career .Appply-career {
        position: relative;
        text-align: end;
        display: inline-block;
        background: #FF6600;
        color: #fff;
        padding: 14px 50px 40px 55px;
        font-size: 16px;
        cursor: pointer;
        clip-path: polygon(0% 0%, 25% 73%, 30% 71%, 100% 60%, 100% 0%);
        transition: all 0.5s;
        font-family:  "space-Grotesksb" !important;
    }
    .career-inside2 .career-links1 h1 {
        width: 50%;
        color: white;
        font-family:  "space-Groteskr" !important;
        padding: 3% 0% 2% 2%;
        font-size: 18px;
    }
    .career-inside2 .career-links2 p {
        color: white;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
        font-size: 18px;
    }
    .career-main {
        padding: 0% 0% 15% 0%;
    }

        }