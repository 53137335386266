*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0 !important;
    /* background-color: #FFF; */
}

.home-page-container {
    align-items: center;
    max-width: 100%;
    padding: 0px !important;
    margin: 0px !important;
    background: #000A1C;
    opacity: 10;
    position: relative;
    z-index: 99999 !important;
}

.home-page-container .logo {
    padding: 1.3rem 0rem;
    overflow: hidden;
    z-index: 9999;
}

.home-page-container .logo a {
    align-items: center;
    height: 60px;
}

.home-page-container .logo a img {
    max-width: 100%;
    height: auto;
}

.section-title {
    color: white;
}

.home-page-container .navbar {
    justify-content: flex-end;
    /* padding: .5rem 0rem; */
}

.home-page-container .navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    gap: 3%;
    margin-right: 5%;
}

/* .container .navbar ul li a {
    text-decoration: none;
    color: #0F326C;
    font-size: 18px;
    display: block;
    cursor: pointer;
} */
.container .navbar ul li a:hover {
    /* color: #FF6A3D; */
}

.container .navbar ul .close {
    display: none;
    text-align: right;
    padding: 10px;
}

.container .navbar ul .close span {
    font-size: 40px;
    display: inline-block;
    border: 1px solid #cccccc;
    padding: 0 10px;
    cursor: pointer;
}

.container .navbar .icon-bar {
    position: relative;
    /* padding: 12px 5px; */
    width: 53%;
    /* height: 10px; */
    /* display: none; */
    /* flex-direction: column; */
    /* justify-content: space-around; */
    cursor: pointer;
    margin-right: 10%;
}

.container .navbar .icon-bar i {
    /* background-color: #ffffff;
    height: 2px; */
    background-color: #F05F30;
    height: 2px;
}

video {
    width: 100%;
    height: 542px;
    object-fit: fill;
}

.video-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(15, 50, 108, 0.6);
    width: 100%;
    height: 99%;
}

.banner {
    position: relative;
    height: 542px;
}

.banner img {
    display: block;
    width: 100%;
    max-height: auto;
}

.banner>.home_page_caption {
    position: absolute;
    width: 100%;
    padding-left: 80px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.banner>.home_page_caption>h1 {
    font-family: "Poppins-SemiBold";
    font-size: 42px;
    font-weight: 900;
    color: #FF6600;
    padding-bottom: 2rem;
}

.banner>.home_page_caption>p {
    font-family: "Poppins-Medium";
    font-size: 16px;
    font-weight: normal;
    color: #FFF;
    margin: 0;
    line-height: 26px;
    padding-bottom: 1rem;
}

.banner>.home_page_caption>div {
    font-family: "Poppins-SemiBold";
    font-size: 36px;
    font-weight: 900;
    color: #FFF;
}

.banner>.home_page_caption>div>span {
    color: #FF6600;
}

.banner>.home_page_caption>h1,
.banner>.home_page_caption>h2 {
    text-align: left;
}

.custom-btn {
    padding: 10px 25px;
    margin: 2.5rem 0;
    font-family: "Poppins-Bold";
    font-size: 18px;
    font-weight: 900;
    color: #FF6600;
    border-radius: 6px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
}

.btn-15 {
    border: 2px solid #FF6600;
    z-index: 1;
}

.btn-15:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    background: #FF6600;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.btn-15:hover {
    color: #fff;
}

.btn-15:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.btn-15:active {
    top: 2px;
}

.client_adjustment {
    margin-top: -7px;
}

.section-body {
    background-color: #219ebc;
    padding: 2rem 1rem;
    display: flex;
    height: 450px;
}

.section-body .row {
    margin: 0;
    align-items: center;
}

.section-body-container-left {
    font-family: "Montserrat-Bold";
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
    text-align: left;
}

.section-body-container-left p {
    font-family: "Montserrat-Regular";
    font-weight: 300;
    font-size: 18px;
}

.section-body-container-right img {
    width: 100%;
    height: 400px;
}

span[data-color="sustain"] {
    color: #9DAAF2;
}

span[data-color="soft"] {
    color: #fff;
}

span[data-color="ware"] {
    color: #FF6A3D;
}

.section2 {
    display: flex;
    align-items: center;
    padding: 0 6rem;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    background-color: #fff9eb;
}

.section2-left {
    flex: 1;
}

.section2-left img {
    width: 500px;
}

.section2-right {
    flex: 1;
    align-self: flex-end;
    margin-top: 3rem;
}

.section2-title {
    font-size: 36px;
    font-weight: bold;
    color: #1A2238;
    padding-bottom: 3rem;
    position: relative;
    font-family: "Montserrat-Regular";
    font-weight: 400;
    line-height: 55px;
}

.para_bold {
    font-weight: bold;
    color: #002236;
}

.custom-shape-divider-bottom-1623154367 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1623154367 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-1623154367 .shape-fill {
    fill: #F9F9F9;
}

.right_arrow_icon {
    width: 21px;
    left: 4px;
    position: relative;
    transition: transform 0.5s ease;
    transform: translateX(0px);
}

.learmore__btn:hover {
    color: #ffb60a !important;
}

.learmore__btn:hover .right_arrow_icon {
    transform: translateX(10px);
}

.section3-box-view:hover .right_arrow_icon {
    transform: translateX(10px);
}

.all_projects_links:hover .right_arrow_icon {
    transform: translateX(10px);
}

.section2-para {
    font-family: "Montserrat-Regular";
    font-weight: 400;
    font-size: 18px;
    color: #707070;
    line-height: 30px;
}

.section2-learn {
    font-family: "Montserrat-Regular";
    font-weight: 600;
    font-size: 16px;
    color: #002236;
    text-align: right;
    cursor: pointer;
}

.learmore__btn {
    color: #002236 !important;
    text-decoration: none !important;
    background-color: #f9f9f9;
    border-radius: 30px;
    border: 1px solid #ecf0f3;
    box-shadow: 4px 4px 10px rgb(149 157 165 / 20%);
    line-height: 45px;
    height: 45px;
    margin-top: 8px;
    white-space: nowrap;
    padding: 12px 30px;
    font-weight: 600;
}

.isSticky {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    max-width: 100%;
    background-color: none;
    /* background-image: url('../../fonts/menubar.svg'); */
    /* box-shadow: 0 0 12px rgba(0, 0, 0, 0.4); */
}

.visible_mobile_content {
    display: none;
}

.visible_content {
    display: block;
}

.home_contactbtn {
    margin-top: 2rem !important;
    padding-bottom: 2rem !important;
    text-align: left !important;
}

@media only screen and (max-width: 650px) {
    .section2-left img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .section-body-container-right img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .section2,
    .section-body {
        display: block;
    }

    .section-body {
        height: auto;
    }

    .section-body-container-left {
        font-size: 40px;
        text-align: center;
        padding: 0px;
    }

    .section2-right,
    .section2-learn,
    .section2-left,
    .section6-content,
    .section6-title,
    .section6-para {
        text-align: center;
    }

    .section2-right {
        margin-top: 1rem;
    }

    .section2,
    .section3,
    .section6-content,
    .section6-content1,
    .main-footer {
        padding: 1rem 1.5rem;
    }

    .visible_mobile_content {
        display: block;
        text-align: center;
        line-height: 50px;
        padding-top: 1rem;
    }

    .visible_content {
        display: none;
    }

    .section3-center1,
    .section5-center1 {
        justify-content: space-around;
        align-items: center;
        gap: 16px;
    }

    .section6-content,
    .section6-content1 {
        padding-top: 0;
    }

    .section6-content-right,
    .section6-content1-right {
        padding-top: 30px;
    }

    .section4-center {
        padding: 0;
    }

    .section4-div {
        justify-content: space-around;
    }

    .section6-img-div,
    .section6-img-div1 {
        max-width: 450px;
        height: auto;
    }

    .tabs-turnkey {
        justify-content: space-around;
    }

    .section-get-left {
        display: none;
    }

    .textbox {
        max-width: 300px;
        margin: 0;
    }

    .section-title {
        font-size: 34px;
    }

    .isSticky {
        position: fixed;
        top: 0;
        z-index: 9999;
        width: 100%;
        max-width: 100%;
        background-color: none;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .section2 {
        display: block;
    }

    .visible_mobile_content {
        display: block;
        text-align: center;
        line-height: 50px;
        padding-top: 1rem;
    }

    .section2-right,
    .section2-learn,
    .section2-left,
    .section6-content,
    .section6-title,
    .section6-para {
        text-align: center;
    }

    .visible_content {
        display: none;
    }

    .section4-box-title {
        height: 55px;
    }

    .section4-box-title {
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 300px) {
    .section-title {
        font-size: 28px;
        margin-bottom: 1rem;
    }

    .visible_mobile_content {
        font-size: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .banner>.home_page_caption>h1 {
        font-size: 6vw;
    }

    .banner>.home_page_caption>div {
        font-size: 5vw;
        line-height: 38px;
    }

    .banner>.home_page_caption {
        width: 85%;
        height: 90%;
        left: 50%;
        top: 0%;
        padding-left: 0rem;
        transform: translate(-50%, 10%);
    }
}

.breadcrumb_container_new {
    /* padding: 0.2%; */
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 6%;
    padding-right: 2%;
    background: linear-gradient(90deg, #35D2D2 0%, #57C0AE 47.01%, rgba(255, 102, 0, 0.94) 100%);
    opacity: 1;
    transition: opacity 0.3s ease-out;
    z-index: 999;
    font-family:  "space-Groteskr" !important;
    position: absolute;
    position: absolute;
    border-radius: 0px 0px 3px 0px;
    height: 25px;
}

.breadcrumb_container_new .breadcrumb_text a {
    color: #0F326C !important;
    font-family:  "space-Grotesksb" !important;
    font-weight: 300;
    font-size: 16px;
}

.breadcrumb_text :hover {
    color: orange;
    text-decoration: underline;
}

.breadcrumb_content {
    background-color: #fff;
}

.breadcrumb_links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    background-color: #F05F30;
    margin-left: 80px;
}

.progress-bar {
    position: absolute;
    /* top:calc(innerHeight - 88%); */
    top: calc(100% - 2px);
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(90deg, #35D2D2 0%, #57C0AE 47.01%, rgba(255, 102, 0, 0.94) 100%);
    transform-origin: 0%;
    z-index: 1;
  }

@media all and (max-width: 700px) {
    .breadcrumb_links {
        margin-left: 18px;
        white-space: nowrap;
        height: 1.5rem;
    }

    .breadcrumb_content {
        /* background-color: #fff; */
        margin-top: -0.2rem;
    }

    .breadcrumb_links .link {
        margin-bottom: 4px;
    }

}

.breadcrumb_links .link {
    background-color: #FFF;
    transition: 0.3s, color 0.3s;
    transform: skewX(-35deg);
}

@media all and (max-width: 700px) {
    .breadcrumb_links .link a {
        font-size: 12px !important;
    }

    .breadcrumb_links .link a {
        line-height: 28px !important;
    }

    .breadcrumb_links .link span {
        font-size: 12px !important;
        line-height: 28px !important;
    }
}



.homepage_desktop {
    display: block;
}

.homepage_desktop_mob {
    display: none;
}

@media all and (max-width: 768px) {
    .breadcrumb_links .active_now {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .page_bread{
        font-size: 14px;
    }
    .breadcrumb_container_new .breadcrumb_text a {
        color: #0F326C !important;
        font-family:  "space-Grotesksb" !important;
        font-weight: 300;
        font-size: 14px;
    }
    .breadfont{
        font-size: 14px !important;
    }
    .banner>.home_page_caption>h1 {
        text-align: center;
    }

    .banner>.home_page_caption>h1 {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }

    .homepage_desktop {
        display: none;
    }

    .homepage_desktop_mob {
        display: block;
    }

    .mob_home_page_caption_inner {
        position: absolute;
        top: 5%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: 100%;
        padding: 0 1.5rem;
    }

    .home_page_caption_inner_1 {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: 100%;
        padding: 0 24px;
        text-align: center;
    }

    .home_page_caption_inner_1 p,
    .home_page_caption_inner_1 div {
        color: #fff;
        font-size: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        margin-bottom: 0;
    }

    .home_page_caption_inner_1 p {
        padding-bottom: 16px;
    }

    .home_page_caption_inner_1 .contactbtn {
        margin-top: 18px;
    }

    video {
        width: 100%;
        height: auto;
        object-fit: fill;
        margin-bottom: -6px;
    }
}

@media all and (min-width: 920px) {
    .banner>.home_page_caption>h1 {
        font-family: "Poppins-Medium";
        font-size: 32px;
        padding-bottom: 16px;
        margin-bottom: 0;
        color: #FF6600;
    }

    .banner>.home_page_caption>div {
        font-family: "Poppins-SemiBold";
        font-size: 24px;
        font-weight: 900;
        color: #FFF;
    }
}

@media all and (min-width: 1200px) {
    .banner>.home_page_caption>h1 {
        font-family: "Poppins-Medium";
        font-size: 40px;
        padding-bottom: 32px;
        margin-bottom: 0;
        color: #FF6600;
        line-height: 48px;
    }

    .banner>.home_page_caption>p {
        font-family: "Poppins-Medium";
        font-size: 16px;
        font-weight: normal;
        color: #FFF;
        margin: 0;
        line-height: 27px;
        padding-bottom: 16px;
    }

    .banner>.home_page_caption>div {
        font-family: "Poppins-Medium";
        font-size: 26px;
        font-weight: 500;
        line-height: 48px;
        color: #FFF;
    }



}

@media (min-width: 820px) and (max-width: 920px) {}

@media all and (min-width: 1900px) {
    .breadcrumb_links {
        margin-left: 120px;
    }

    .banner>.home_page_caption {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0px 120px;
    }

    .banner>.home_page_caption>h1 {
        font-family: "Poppins-Medium";
        font-size: 48px;
        padding-bottom: 32px;
        margin-bottom: 0;
        color: #FF6600;
        line-height: 72px;
    }

    .banner>.home_page_caption>p {
        font-family: "Poppins-Medium";
        font-size: 20px;
        font-weight: normal;
        color: #FFF;
        margin: 0;
        line-height: 36px;
        padding-bottom: 16px;
    }

    .banner>.home_page_caption>div {
        font-family: "Poppins-Medium";
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
        color: #FFF;
    }
}

@media screen and (min-width:768px) and (max-width:819px) {
    .homepage_desktop {
        display: none;
    }

    .homepage_desktop_mob {
        display: block;
    }

    .banner-inner-mob {
        display: block !important;
    }

    .mob_home_page_caption_inner {
        position: absolute;
        top: 5%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: 100%;
        padding: 0 1.5rem;
    }

    .home_page_caption_inner_1 {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: 100%;
        padding: 0 24px;
        text-align: center;
    }

    .home_page_caption_inner_1 p,
    .home_page_caption_inner_1 div {
        color: #fff;
        font-size: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        margin-bottom: 0;

    }

    .home_page_caption_inner_1 p {
        padding-bottom: 16px;
    }

    .home_page_caption_inner_1 .contactbtn {
        margin-top: 18px;

    }

    video {
        width: 100%;
        object-fit: fill;
        margin-bottom: -8px;
    }

    .breadcrumb_links {
        margin-left: 24px;
    }

}