
@media  only screen and (min-width: 1024px) {
    .Our-Toggle{
        display: none;
    }
}
@media  only screen and (max-width: 768px) {
    .toggling{
        display: none;
    }
}
@media  only screen and (min-width: 768px) {
    .Our-Toggle-tablet{
        display: none;
    }
}



@media  only screen and (min-width: 1024px) {
    .toggling{

        display: flex;
        padding-top: 10px;
        // border: 1px solid blue;
        align-items: center;
    }
    .roww1{
        position: relative;
        width: 10%;
        left: 4%;
        margin-top: -3%;
        // border: 1px solid red;
        // cursor: pointer;
    }
    .roww2{
        width: 50%;
        position: relative;
        // border: 1px solid red;
    }
    .roww3 {
        width: 40%;
        text-align: center;
        right: 8%;
        position: relative;
        margin-top: -2%;
        cursor: pointer;
        z-index: 2;
    }
    
    
    .image1{
    position: relative;
    top: 8vw;
    opacity: 0.5;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    }
    .image1 img{
    width: 50%;
    border-radius: 25px;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    }
    .image2 {
    position: relative;
    bottom: 11vw;
    left: 15%;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    }
    .image2 img{
    width: 60%;
    border-radius: 25px;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    }
    
    
    .image22 {
        position: relative;
        bottom:11vw;
        left: 0%;
        opacity: 0.5;
        transition-property: all;
        transition-duration: 0.6s;
        transition-timing-function: ease;
    }
    .image22 img {
        width: 50%;
        border-radius: 25px;
        transition-property: all;
        transition-duration: 0.6s;
        transition-timing-function: ease;
    }
    .image11 {
        position: relative;
        // top: 6%;
        // top: 170px;
        top: 8vw;
        left: 15%;
        z-index: 2;
        transition-property: all;
        transition-duration: 0.6s;
        transition-timing-function: ease;
    }
    .image11 img {
        width: 60%;
        border-radius: 25px;
        transition-property: all;
        transition-duration: 0.6s;
        transition-timing-function: ease;
    }
    
    
    
    
    .roww3-inside1{
        cursor: pointer;
        // border: 1px solid blue;
        z-index: 10 !important;
    }
    .roww3-inside2{
        cursor: pointer;
        // border: 1px solid blue;
        z-index: 10 !important;
    }
    
    .roww3-inside1-pa{
        font-size: 2vw;
        font-weight: bolder;
        letter-spacing: 0px;
        color: #FF6600;
        opacity: 1;
        font-family: "space-Groteskl" !important;
        text-align: left;
        cursor: pointer;
    
    }
    .roww3-inside2-pa{
        font-size: 2vw;
        font-weight: bolder;
        letter-spacing: 0px;
        color: #FF6600;
        opacity: 1;
        font-family: "space-Groteskl" !important;
        text-align: left;
        cursor: pointer;
    
    }
    
    .roww3-inside1-paa{
        font-size: 1.4vw;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
        cursor: pointer;
    }
    .roww3-inside2-paa{
        font-size: 1.4vw;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
        cursor: pointer;
    }

}


@media  only screen and (min-width: 768px) and (max-width: 1023px) {
.toggling{
    display: flex;
    padding-top: 10%;
    align-items: center;
}
.roww1{
    position: relative;
    width: 10%;
    left: 4%;
    margin-top: -3%;
    // cursor: pointer;
}
.roww2{
    width: 50%;
    position: relative;
}
.roww3 {
    width: 45%;
    text-align: center;
    right: 4%;
    position: relative;
    cursor: pointer;
}


.image1{
position: relative;
top: 4vw;
opacity: 0.5;
transition-property: all;
transition-duration: 0.6s;
transition-timing-function: ease;
}
.image1 img{
width: 50%;
border-radius: 10px;
transition-property: all;
transition-duration: 0.6s;
transition-timing-function: ease;
}
.image2 {
    position: relative;
    bottom: 9vw;
    left: 10%;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}
.image2 img{
width: 70%;
border-radius: 10px;
transition-property: all;
transition-duration: 0.6s;
transition-timing-function: ease;
}


.image22 {
    position: relative;
    bottom: 19vw;
    left: 0%;
    opacity: 0.5;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}
.image22 img {
    width: 50%;
    border-radius: 10px;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}
.image11 {
    position: relative;
    top: 8vw;
    left: 10%;
    z-index: 2;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}
.image11 img {
    width: 70%;
    border-radius: 10px;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}




.roww3-inside1{
    cursor: pointer;
}
.roww3-inside2{
    cursor: pointer;
}

.roww3-inside1-pa{
    font-size: 20px;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;
    margin-bottom: 1%;

}
.roww3-inside2-pa{
    font-size: 20px;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;
    margin-bottom: 1%;

}

.roww3-inside1-paa{
    font-size: 13px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;
}
.roww3-inside2-paa{
    font-size: 13px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;
}
.box-border {
    display: flex;
    gap: 10px !important;
    justify-content: center;
    position: relative;
    padding-left: 2%;
    padding-right: 2%;
}
.box-border-inside {
    border: 1px solid #FF6600   !important;
    border-radius: 30px   !important;
    width: 20vw;
    text-align: center   !important;
    padding-top: 15px   !important;
    color: white   !important;
}
.box-border-inside1 {
    border: 1px solid #FF6600 !important;
    border-radius: 30px;
    width: 20vw !important;
    text-align: center !important;
    padding-top: 20px !important;
    color: white !important;
}

.box-border-inside p {
    color: white;
    display: grid;
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 20px;
}

.box-border-inside1 p {
    color: white;
    display: grid;
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 10px;
}



}

