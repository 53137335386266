.product-container{
  max-width: 100%;
  padding: 48px 80px 96px 80px;
}
.product-container_stackerank{
  max-width: 100%;
  padding: 48px 80px 48px 80px;
}
.product-background-style {
background-image: url('../../fonts/images/problem_statement.svg');
background-repeat: no-repeat;
background-size: cover;
background-position: bottom;
}
.problem_head_container{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  gap: 24px;
}
.problem_head{
font-family: 'Poppins-Medium';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
color: #fff;
margin-top: -10px;
}
.product_img img{
  max-width: 80px;
  height: 70px;
}
.problem_line{
  line-height: 0;
  padding-top: 8px;
}
.hiring_head{
font-family: 'Poppins-Medium';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
color: #fff;
padding-top: 32px;
padding-bottom: 16px;
}
.product_para{
font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 400;
font-size: 24px;
color: #FFFFFF;
}
.product_para div {
  line-height: 64px;
}
.impact_top{
  margin-top: 56px;
}
.stackerank-background-style{
/* background-image: url('../../fonts/images/bg_product_1.png'); */
background-image: url('../../fonts/images/stackerank.png');
background-repeat: no-repeat;
background-size: cover;
background-position: top;
margin-top: -5rem;
}
.stackerank_container{
  padding-bottom: 44px;
}
.product_details_div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  padding-bottom: 64px;
}
.product_space_top{
  padding-top: 0px;
}
.product_details_div_right{
  justify-content: flex-end;
}
.details_head{
font-family: 'Poppins-Medium';
font-style: normal;
font-weight: 500;
font-size: 26px;
line-height: 48px;
color: #fff;
}
.details_para{
  font-family: 'Poppins-Medium';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 27px;
color: #fff;
}
.details_line{
  line-height: 0;
  padding-top: 8px;
  padding-bottom: 16px;
}
.caption_project_products {
  top: 14% !important;
}
.product_img_2 {
  justify-content: end;
}
.enage_mob_box_product2 {
  margin-top: -220px;
}
.details_para_desk{display: block;}
.details_para_mob{display: none;}
.box_statement{
  padding:60px 0px 0px 0px;
}
.heading_img_products{
  margin-left: -3.8rem;
  vertical-align: middle;
}
.problem_heading{
  margin: 0;
  padding: 48px 80px 0px 80px;
}
.product_details_container{
  max-width: 1100px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) { 
  .product-container{
    padding: 48px 24px 96px 24px;
  }
  .product_img img{
    max-width: 60px;
    height: 50px;
  }
  .problem_head_container{
    padding-top: 24px;
    align-items: center;
  }
  .problem_head{
    font-size: 18px;
    line-height: 27px;
  }
  .hiring_head{
    font-size: 18px;
    line-height: 27px;
    padding-top: 8px;
  }
  .product_para{
    font-size: 14px;
  }
  .product_para div{
    line-height: 32px;
  }
  .impact_top{
    margin-top: 24px;
  }
  .product-container_stackerank{
    padding: 48px 24px 48px 24px;
  }
  .details_head{
    font-size: 18px;
    line-height: 27px;
  }
  .details_para{
    font-size: 14px;
  }
  .stackerank_container{
    padding-top: 0;
  }
  .product_details_div{
    display: block;
    text-align: center;
    padding-bottom: 32px;
  }
  .details_img{
    text-align: center;
  }
  .details_line{
    padding-top: 0;
    padding-bottom: 8px;
  }
  .digi-needs-container-padding {
    padding: 0px;
  }
  .problem_box_container{
    padding-top: 48px;
  }
  .details_para_desk, .problem_heading{display: none;}
  .details_para_mob{display: block;}
}



@media only screen and (min-width: 1900px) { 
  .product_details_container{
    max-width: 1100px;
    margin: 0 auto;
  }
  .problem_heading {
    margin: 0;
    padding: 48px 120px 0px 120px;
}
.product-container_stackerank {
  max-width: 100%;
  padding: 96px 120px 48px 120px;
}
.product_banner_page .digital_banner_contact_caption{
  position: absolute;
  text-align: right;
  bottom: 15%;
  right: 9%;
  transform: translate(-50%, -50%);
}
.details_head {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #fff;
}
.details_para {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #fff;
}


}

@media screen and (min-width:768px) and (max-width:819px)  { 
  .problem_heading {
    margin: 0;
    padding: 48px 24px 0px 24px;
}
.product-container_stackerank {
  max-width: 100%;
  padding: 48px 24px 48px 24px;
}

}


.login-box {
  position: relative;
  width: 45vw;
  background: transparent
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #FF6600;
  text-align: center;
}

.login-box .user-box {
  position: relative;
  background: transparent;
  background-color: transparent;
}

.login-box .user-box input {
  width: 92%;
  padding: 0px 15px;
  font-size: 1.5vw;
  font-family:  "space-Groteskr" !important;
  color: #ffff;
  margin-bottom: 45px;
  border: none;
  border-bottom: 1px solid #FF6600;
  border-radius: 0px;
  outline: none;
  background: transparent;
  background-color: transparent;
  font-weight: lighter;
}
.login-box .user-boxx textarea {
  width: 92%;
  padding: 0px 15px;
  font-size: 1.5vw;
  font-family:  "space-Groteskr" !important;
  color: #ffff;
  margin-bottom: 4%;
  border: none;
  border-bottom: 1px solid #FF6600;
  border-radius: 0px;
  outline: none;
  background: transparent;
  background-color: transparent;
  font-weight: lighter;

}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #ffff;
  pointer-events: none;
  background: transparent;
  background-color: transparent;
  transition: .5s;
}

.login-box .user-box input:focus ~ label{
  top: -20px;
  left: 0;
  color: #FF6600;
  font-size: 12px;
  background: transparent;
  background-color: transparent;
}
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #FF6600;
  font-size: 12px;
}



/* position: relative;
    display: flex;
    flex-direction: column;
    width: 52vw;
    pointer-events: auto;
    background-color: #fcfdfd;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    outline: 0;
    

    .bg-color {
    line-height: 100%;
    font-size: 3vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
    margin-top: 3% !important;
}

.model_patra {
    font-size: 1.2vw;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
}

.Ok-btn-stacker {
    border: 2px solid #FF6600;
    border-radius: 10px;
    font-family:  "space-Groteskr" !important;
    color: #FF6600;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 1.2vw;
    background-position: right bottom;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 0.9s ease-in;
}
    
    
    */