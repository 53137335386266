.Main-Home {
  background: #001220 0% 0% no-repeat padding-box;
  opacity: 1;
}

.Main-Home-Banner {
  width: 100%;
  height: 100vh;
}

.Main-Home-Banner .video-height {
  width: 100%;
  height: 100vh !important;
  height: 100svh !important;
  position: relative;
  object-fit: fill;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay-image-Home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.overlayy{
  opacity: 0.75 !important;
}

.Main-Home-Banner .inside-Banner {
  position: absolute;
  top: 30%;
  // left: 20%;
  width: 100%;
  // transform: translate(-26%, -80%);
}

.centerClass {
  top: 50%;
  position: relative;
}

.contact-home {
  position: absolute;
  bottom: 0%;
  height: 175px;
  width: 350px;
  border-radius: 175px 175px 0 0;
  background-color: rgb(229, 124, 20);
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: -webkit-center;
}

.contact-home a {
  font-family: "space-Grotesksb" !important;
  font-size: 1.4vw;
  color: #ffffff;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}

.Main-Home-Banner h1 {
  line-height: 100%;
  font-size: 2.5vw;
  opacity: 1;
  color: white;
  font-family: "space-Groteskb" !important;
  margin-bottom: 3%;
  text-align-last: center;
}

.Main-Home-Banner .para1 {
  font-size: 1.4vw;
  color: white;
  text-align: center;
  font-family: "space-Groteskr" !important;
}

.Main-Home-Banner .para2 {
  font-family: "space-Grotesksb" !important;
  font-size: 2vw;
  text-shadow: 0px 9px 38px #000000;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}













.custom-div {
  position: absolute;
  bottom: 0%;
  height: 175px;
  width: 350px;
  border-radius: 175px 175px 0 0;
  background-color: #FF6600;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  overflow: hidden;
}

.heading-div {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
  font-family: "space-Groteskr" !important;
  ;
}

.heading-div1-div {
  transform: translateY(100%);
}

.heading-div2 {
  transform: translateY(500%);
}

.custom-div:hover .heading-div1 {
  transform: translateY(-500%);
}

.custom-div:hover .heading-div2 {
  transform: translateY(0);
}




@media only screen and (max-width: 480px) {

  .Main-Home-Banner {
    display: none;
  }

  .Mobile-Home-Banner {
    width: 100%;
    height: 100vh;
    height: 100svh;
  }

  .Mobile-Home-Banner .video-height {
    width: 100%;
    height: 100vh !important;
    height: 100svh !important;
    position: relative;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }




  .inside-Banner-mobile {
    position: absolute;
    top: 25%;
    width: 100%;

  }

  .centerClass {
    top: 50%;
    position: relative;
  }

  .contact-home {
    position: absolute;
    bottom: 0%;
    height: 175px;
    width: 350px;
    border-radius: 175px 175px 0 0;
    background-color: rgb(229, 124, 20);
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: -webkit-center;
  }

  .contact-home a {
    font-family: "space-Grotesksb" !important;
    font-size: 1.4vw;
    color: #ffffff;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }

  .Mobile-Home-Banner h1 {
    line-height: 40px;
    font-size: 26px;
    opacity: 1;
    color: white;
    font-family: "space-Groteskb" !important;
    margin-bottom: 8%;
    text-align-last: center;
    letter-spacing: 1.96px;
    text-align: -webkit-center;
  }

  .Mobile-Home-Banner .para1 {
    font-size: 18px;
    color: white;
    text-align: center;
    font-family: "space-Groteskr" !important;
    margin: 0% 0% 10% 0%;
    line-height: 35px;
  }

  .Mobile-Home-Banner .para2 {
    font-family: "space-Grotesksb" !important;
    font-size: 16px;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    line-height: 35px;
  }


  .custom-div-mobile {
    position: absolute;
    bottom: 0%;
    height: 125px;
    width: 250px;
    border-radius: 175px 175px 0 0;
    background-color: #FF6600;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    overflow: hidden;
  }

  .heading-div-mobile {
    position: absolute;
    width: 100%;
    top: 60%;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
    font-size: 25px;
    font-family: "space-Groteskr" !important;
    ;
  }

  .overlay-image-Home-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100svh !important;
    object-fit: cover;
    /* You can adjust the opacity as needed */
  }

}

@media only screen and (min-width: 768px) {
  .Mobile-Home-Banner {
    display: none;
  }
}


@media only screen and (min-width: 481px) and (max-width: 767px) {


  .Main-Home-Banner {
    display: none;
  }

  .Mobile-Home-Banner {
    width: 100%;
    height: 100vh;
    height: 100svh;
  }

  .Mobile-Home-Banner .video-height {
    width: 100%;
    height: 100vh !important;
    height: 100svh !important;
    position: relative;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }




  .inside-Banner-mobile {
    position: absolute;
    top: 25%;
    width: 100%;

  }

  .centerClass {
    top: 50%;
    position: relative;
  }

  .contact-home {
    position: absolute;
    bottom: 0%;
    height: 175px;
    width: 350px;
    border-radius: 175px 175px 0 0;
    background-color: rgb(229, 124, 20);
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: -webkit-center;
  }

  .contact-home a {
    font-family: "space-Grotesksb" !important;
    font-size: 1.4vw;
    color: #ffffff;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }

  .Mobile-Home-Banner h1 {
    line-height: 40px;
    font-size: 26px;
    opacity: 1;
    color: white;
    font-family: "space-Groteskb" !important;
    margin-bottom: 8%;
    text-align-last: center;
    letter-spacing: 1.96px;
  }

  .Mobile-Home-Banner .para1 {
    font-size: 18px;
    color: white;
    text-align: center;
    font-family: "space-Groteskr" !important;
    margin: 0% 0% 10% 0%;
    line-height: 35px;
  }

  .Mobile-Home-Banner .para2 {
    font-family: "space-Grotesksb" !important;
    font-size: 16px;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    line-height: 35px;
  }


  .custom-div-mobile {
    position: absolute;
    bottom: 0%;
    height: 125px;
    width: 250px;
    border-radius: 175px 175px 0 0;
    background-color: #FF6600;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    overflow: hidden;
  }

  .heading-div-mobile {
    position: absolute;
    width: 100%;
    top: 60%;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
    font-size: 25px;
    font-family: "space-Groteskr" !important;
  }

  .overlay-image-Home-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh !important;
    height: 100svh !important;
    object-fit: cover;
    /* You can adjust the opacity as needed */
  }
  
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .Main-Home-Banner {
    width: 100%;
    height: 100vh;
  }

  .Main-Home-Banner .video-height {
    width: 100%;
    height: 100vh !important;
    height: 100svh !important;
    position: relative;
    object-fit: fill;
    // z-index: 999999 !important;
    position: relative;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .overlay-image-Home {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh !important;
    height: 100svh !important;
    opacity: 1;
    object-fit: cover;
  }

  .Main-Home-Banner .inside-Banner {
    position: absolute;
    top: 30%;
    width: 100%;
  }

  .centerClass {
    top: 50%;
    position: relative;
  }

  .contact-home {
    position: absolute;
    bottom: 0%;
    height: 125px;
    width: 250px;
    border-radius: 175px 175px 0 0;
    background-color: rgb(229, 124, 20);
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: -webkit-center;
  }

  .contact-home a {
    font-family: "space-Grotesksb" !important;
    font-size: 1.4vw;
    color: #ffffff;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }

  .Main-Home-Banner h1 {
    line-height: 40px;
    font-size: 26px;
    opacity: 1;
    color: white;
    font-family: "space-Groteskb" !important;
    margin-bottom: 8%;
    text-align-last: center;
    letter-spacing: 1.96px;
  }

  .Main-Home-Banner .para1 {
    font-size: 18px;
    color: white;
    text-align: center;
    font-family: "space-Groteskr" !important;
    margin: 0% 0% 10% 0%;
    line-height: 35px;
  }

  .Main-Home-Banner .para2 {
    font-family: "space-Grotesksb" !important;
    font-size: 16px;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    line-height: 35px;
  }


  .custom-div {
    position: absolute;
    bottom: 0%;
    height: 125px;
    width: 250px;
    border-radius: 175px 175px 0 0;
    background-color: #FF6600;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    overflow: hidden;
  }

  .centerClass {
    top: 50%;
    position: relative;
    font-size: 25px !important;
  }
}