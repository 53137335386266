.section3 {
    padding: 0px 60px;
}
.clients-baclground-style {
    background-image: url("../../fonts/images/brand_bg_2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: -48px;
}
.client_heading_container {
    padding: 0px 80px 44px 80px;
}
.section3-center {
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #1a2238;
    padding-bottom: 50px;
    padding-top: 50px;
    position: relative;
    text-align: center;
}
.text-line {
    position: relative;
    border-bottom: 3px solid #ffb60a;
    left: 0%;
    bottom: 0;
    width: 8%;
    height: 0px;
}
.section5-center1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.section5-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f9f9f9;
    color: #f9f9f9;
    height: 130px;
    width: 170px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: rgb(149 157 165 / 20%) 4px 4px 20px;
    margin: 0 auto;
}
.react-multi-carousel-list {
    padding: 10px 10px;
}
.react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}
.react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
}
.models-heading-right {
    text-align: right;
    padding: 0 6rem;
}
.heading_color {
    color: #0f326c;
}
.client_underline {
    text-align: right;
    line-height: 0;
}
@media only screen and (max-width: 768px) {
    .offering-box-img {
        height: 140px;
        padding: 13px 10px;
    }
    .industries-container {
        padding: 0px 2rem !important;
    }
    .section5-box {
        width: 100%;
    }
    .mphasis {
        height: 70px;
    }
    .dd {
        height: 70px;
    }
}
.clients-mobile img {
    width: 100%;
}
.client-desktop img {
}
.clients-mobile .react-multi-carousel-item {
    align-self: center;
}
.heading_img {
    margin-left: -4.5rem;
    vertical-align: middle;
}
.image {
    vertical-align: top !important;
}
.client-desktop-container {
    max-width: 100%;
    height: 190px;
    padding: 0px;
    margin: 0;
    overflow: hidden;
}
.slick-slide {
    height: 120px;
    box-sizing: border-box;
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.25s ease-out 0s;
    flex-direction: column;
    -webkit-box-align: center;
    outline: none !important;
    padding-right: 56px;
}
.slick-slide img {
    max-width: 100%;
    box-sizing: border-box;
}
.slick-slide img:hover {
    transform: translate(0px, -1rem);
    transition: transform 0.7s ease-in-out 0s;
}
.slick-arrow {
    display: none;
}
@media (min-width: 820px) and (max-width: 920px) {
    .client_heading_container {
        padding: 0px 24px 44px 24px;
    }
}
@media only screen and (max-width: 768px) {
    .clients-baclground-style {
        padding: 32px 0;
    }
    .client_heading_container {
        padding: 0 24px;
    }
    .client-desktop-container {
        height: 150px;
    }
    .client_heading {
        text-align: right !important;
    }
    .section_sub_heading {
        font-size: 18px;
    }
    .section_sub_text {
        font-size: 24px;
    }
    .heading_rotate_img {
        max-width: 25%;
        height: auto;
    }
    .heading_img {
        margin-left: -3.8rem;
    }
}
@media only screen and (max-width: 520px) {
    .section_sub_heading {
        font-size: 14px !important;
    }
    .section_sub_text {
        font-size: 18px;
    }
}
@media only screen and (max-width: 360px) {
    .section_sub_heading {
        font-size: 14px !important;
    }
}
@media only screen and (min-width: 1900px) {
    .client_heading_container {
        padding: 0px 120px 44px 120px;
    }
    .heading_img {
        margin-left: -4.5rem;
        vertical-align: middle;
    }
}
@media screen and (min-width: 768px) and (max-width: 819px) {
    .client_heading_container {
        padding: 0px 24px 44px 24px;
    }
}
