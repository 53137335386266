
.logo_header{
  width: 100%;
  height: auto;
  padding: 0.2rem 3rem;
}
.home-page-container {
    align-items: center;
    max-width: 100%;
    padding: 0px 50px;
    background: #000A1C;
    opacity: 10;
    position: relative;
    z-index: 9999 !important;
}
.home-page-container .navbar ul li a {
    font-family: "space-Groteskr" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* line-height: 27px; */
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    /* font-size: 16px; */
    display: block;
    /* height: 60px;
    line-height: 60px; */
    cursor: pointer;
    /* padding: 0 22px; */
    white-space: nowrap;
  }
  
  .home-page-container .navbar ul li a:hover {
    color: #FF6600;
    background-color: none;
    /* background-color: rgba(23, 23, 23, 0.9); */
  }
  
  .home-page-container .navbar ul .close {
    display: none;
    text-align: right;
    padding: 10px;
  }
  
  .home-page-container .navbar ul .close span {
    font-size: 40px;
    display: inline-block;
    border: 1px solid #cccccc;
    padding: 0 10px;
    cursor: pointer;
  }
  .home-page-container .navbar .icon-bar {
    // padding: 18px 8px;
    width: 40px;
    height: 55px;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
  }
  .dropdown-item_modify {
    line-height: 40px;
    font-weight: 800;
    z-index: 99;}

  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
    color: #FF6600 !important;
    background-color: transparent !important;
  }
  
  .home-page-container .navbar .icon-bar i {
    background-color: #F05F30;
    height: 2px;
  }
  .dropdown {
      /* margin: 12px 0px; */
  }
  /* .dropdown .dropdown-item a:hover {
      color:  #F05F30;
      background: none !important;
  } */
  .dropdown-item:hover{
    background: none !important;
  }

  .dropdown-menu {
    border: 1px solid rgba(0,0,0,.05);
    background: #FBFBFB;
  }
  .dropdown .btn-secondary {
    color: #fff;
    background-color: #ffffff;
    border: none !important;
    font-weight: 600;
    padding: 0;
    font-family: "space-Groteskr" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
  }
  /* .dropdown .btn-secondary:hover{
    color: #F05F30;
  } */
  .btn-secondary.dropdown-toggle:focus {
      box-shadow: none !important;
      color: #fff;
  }
  .dropdown .dropdown-item a:hover{
    background-color: none !important;
  }
  .dropdown-toggle::after{
    display: inline-block;
    margin-left: 0.275em;
    vertical-align: .100em;
    content: "";
    border-top: .4em solid;
    border-right: .4em solid transparent;
    border-bottom: 0;
    border-left: .4em solid transparent;
  }
  .dropdown > .acitve_menu{
    color: #F05F30 !important;
  }
  .home-page-container .navbar ul li.contactbtn a{
    color: #fff;
    background-color: #F05F30;
    border-radius: 30px;
    border: 1px solid #F05F30;
    line-height: 45px;
    height: 45px;
    padding: 0 22px;
    white-space: nowrap;
  }
  .show .active_menu{
    color: #FF6600;
  }
.header-container{
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-flow: nowrap;
    position: relative;
    z-index: 99999 !important;
    /* padding: 0 3rem; */
}
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.flex-column{
  list-style-type: none;
  padding: 0 3rem;
}
.flex-column li{
  line-height: 3rem;
  font-family: "space-Groteskr" !important; 
  /* font-weight: 600; */
}
.flex-column li a{
  font-family: "space-Groteskr" !important; 
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  // line-height: 27px;
  color: #fff;
  text-decoration: none;
  /* color: #0F326C; */
  font-size: 16px;
  display: block;
  /* height: 60px;
  line-height: 60px; */
  cursor: pointer;
  /* padding: 0 22px; */
  white-space: nowrap;
  background-color: transparent;
}

.home-page-container .navbar ul li a:hover {
  color: #FF6600;
  background-color: none;
  /* background-color: rgba(23, 23, 23, 0.9); */
}

.home-page-container .navbar ul .close {
  display: none;
  text-align: right;
  padding: 10px;
}

.home-page-container .navbar ul .close span {
  font-size: 40px;
  display: inline-block;
  border: 1px solid #cccccc;
  padding: 0 10px;
  cursor: pointer;
}

.home-page-container .navbar .icon-bar {
     /* padding: 18px 8px; */
     width: 50px;
     height: 70px;
     display: none;
     /* flex-direction: column; */
     /* justify-content: space-between; */
     cursor: pointer;
     color: #FF6600;
     object-fit: contain;
}
.dropdown-item_modify{                         
  line-height: 40px;
  font-weight: 800;
  z-index: 99;
  margin-top: 5px;
  padding: 0.25rem 1rem !important;
  }

.dropdown-item_modify div{      
  display: block;
  background-image: linear-gradient(to left, transparent, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2));
  background-position: 100% 0;
  background-size: 201% 100%;
  cursor: pointer;
  color: #fff !important;
  box-sizing: border-box;
  transition: all .6s ease-in;
  border-radius: 5px;
  padding: 0.25rem 1rem !important;
}
.dropdown-item_modify div:hover {       
  background-position: 0 0; 
  box-shadow: 0px 8px 6px #00000047;
  color: #fff !important;
}
/* .dropdown-item_modify {
 
  display: block;
  background-image: linear-gradient(to left, transparent, transparent 50%, #F05F30 50%, #F05F30);
  background-position: 100% 0;
  background-size: 201% 100%;
  padding: 6px 0px;
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
  transition: all .6s ease-in;
}
  .dropdown-item_modify:hover {
    background-position: 0 0; 
  box-shadow: 0px 8px 6px #00000047;
  } */

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
  color: #FF6600 !important;
  background-color: transparent !important;
}

.home-page-container .navbar .icon-bar i {
  background-color: #F05F30;
  height: 2px;
}
.dropdown {
    /* margin: 12px 0px; */
}
/* .dropdown .dropdown-item a:hover {
    color:  #F05F30;
    background: none !important;
} */
.dropdown-item:hover{
  background: none !important;
  color: #fff !important;
}


.dropdown-menu {
  background: #000A1C;
  color: #fff;
  inset: 17.5px auto auto 0px !important;
  transform: translate3d(2px, 27.2px, 2px);
  border-top: 1px solid #FF6600;
}
.dropdown .btn-secondary {
  color: #fff;
  background-color: transparent;
  border: none !important;
  font-weight: 600;
  padding: 0;
  font-family: "space-Groteskr" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}
/* .dropdown .btn-secondary:hover{
  color: #F05F30;
} */
.btn-secondary.dropdown-toggle:focus {
    box-shadow: none !important;
    color: #F05F30;
}
.dropdown .dropdown-item a:hover{
  background-color: none !important;
}
.dropdown-toggle::after{
  display: none;
  margin-left: 0.275em;
  vertical-align: .100em;
  content: "";
  border-top: .4em solid;
  border-right: .4em solid transparent;
  border-bottom: 0;
  border-left: .4em solid transparent;
}
.dropdown > .acitve_menu{
  color: #F05F30 !important;
}
.home-page-container .navbar ul li.contactbtn a{
  color: #0F326C !important;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #F05F30;
    line-height: 45px;
    height: 45px;
    padding: 0 22px;
    white-space: nowrap;
}
.show .active_menu{
  color: #FF6600;
}
.header-container{
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-flow: nowrap;
  padding: 0 2rem;
  position: relative;
  z-index: 99999 !important;
}
.header_container-new{
  width: 100%;
    padding-right: 0px;
    padding-left: 0px;
}
.close {
position: absolute;
right: 32px;
top: 32px;
width: 32px;
height: 32px;
}
.close:before, .close:after {
position: absolute;
left: 15px;
content: ' ';
height: 33px;
width: 2px;
background-color: #333;
}
.close:before {
transform: rotate(45deg);
}
.close:after {
transform: rotate(-45deg);
}
.flex-column{
list-style-type: none;
padding: 0 1.5rem;
}
.flex-column li{
line-height: 3.2rem;
font-family: "space-Groteskr" !important;
/* font-weight: 600; */
}
.flex-column li a{
  font-family: "space-Groteskr" !important;
  font-style: initial;
  /* font-weight: 500; */
  font-size: 18px;
  // line-height: 45px;
  color: #fff;
}
.nav-lists-mobile li a.acitve {
  border-bottom-width: 0px;
  border-bottom-style: solid;
  box-sizing: border-box;
  
}
.nested__menu_items li a{
  font-size: 18px;
}
.dropdown_icon{
  width: 25px !important;
  height: 40px !important;
text-align: end;
}


.flex-column .dropdown{
  margin: 0;
}
.flex-column .dropdown .btn-secondary{
  padding: 4px 0px;
  color:#FFFFFF  ;
  background-color: transparent!important;
  font-family: "space-Groteskr" !important;
  box-shadow: none !important;
  width: 100%;
    text-align: left;
}
.flex-column .dropdown .show{
  background-color: #f9f9f9;
  border: none;
  display: block;
  position: relative !important;
  transform: translate3d(0px, 0px, 0px) !important;
}
.flex-column li.contactbtn a{
  background-color: #FF6600  ;
  border-radius: 30px;
  /* border: 1px solid #ecf0f3; */
  box-shadow: 4px 4px 10px rgb(149 157 165 / 20%);
  /* line-height: 45px;
  height: 45px; */
  /* line-height: 45px; */
  height: 45px;
  margin-top: 8px;
  white-space: nowrap;
  padding: 12px 30px;
}
.nested__menu ul li{
  list-style-type: none;
  text-indent: 2rem;
}
.nav-bar{
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
  height: 60%;
}
.sidebar-container{
  position: fixed;
  width: 100%;
  height:calc(100%); 
  z-index: 9999999;
  top:0;
  right:0;
  padding: 2%;
  /* background-color:#F9F9F9; */
  background: #000A1C;
  animation:slide-in 0.5s backwards ;
  -webkit-animation: slide-in 0.5s backwards;
  transition: opacity .9s cubic-bezier(.165,.84,.44,1) .3s,transform 1.4s cubic-bezier(.165,.84,.44,1) .3s,-webkit-transform 1.4s cubic-bezier(.165,.84,.44,1) .3s;
 }
 @keyframes slide-in {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}
@-webkit-keyframes slide-in {
  0% { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(0%); }
}
.sidebar-close{
  /* vertical-align: -webkit-baseline-middle; */
  animation: slide-out 1s forwards;
  -webkit-animation: slide-out 1s forwards;
  transition: all 2s ease-in-out;
}

@keyframes slide-out {
  0% { transform: translateX(0%);  }
  100% { transform: translateX(100%);  }
}

@-webkit-keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}
.sidebar-header{
  display: flex;
  justify-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  align-items: center;
}
.sidebar-logo{
  flex: 0 0 50%;
}

.divider-header{
  background: transparent linear-gradient(90deg, #FF6600 0%, #00CCC5 63%, #80808000 100%) 0% 0% no-repeat padding-box;
  height: 3px;
  width: 100%;
  border-radius: 6px;
}
.subscribe-header{
  color: #5E5E5E;
  font-family: "space-Groteskr" !important;
  font-size: 14px;
  margin: 0px;
  padding-top: 10%;
}
.socialIcon-header{
  width: 13%;
  margin-right: 10%;
  margin-top: 13%;
}
.sidebar__bottom{
  bottom: 0;
  position: absolute;
  width: 100%;
  display: none;
}
.sidebar__bottom_text{
  font-family: "Poppins-Regular";
  font-size: 16rem;
  color: red;
  text-transform: uppercase;
  text-align: justify;
  opacity: 0.1;
}
.nested__menu_items{
  padding-left: 2rem;
  list-style: none;
  padding-bottom: 5%;
}
.dropdown :hover{ color: #FF6600;}
.active_menu{
    /* border-bottom-width: 1px;
    border-bottom-style: solid; */
        /* box-sizing: border-box; */
        color: #FF6600 !important;
        font-family: "space-Groteskr" !important;
        font-size: 18px !important;
        font-weight: 500;
        width: 100%;
        text-align: left;
        background-color: transparent;
  }
.active_menu_btn{
  color: #fff !important;
    border: 1px solid #FF6600;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 13px;
    border-radius: 5px;
    background-color: #FF6600;
    font-family:  "space-Groteskr" !important;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10%;
    .arow-icon{
      transform: rotate(-45deg);
      font-size: 25px;
    }
  }

  .sidebar-close-img{
    width: 24px;
    height: 24px;
  }

/* @media only screen and (max-width: 1050px) {
  .home-page-container{
    padding:0px 24px;
  }
 } */

 @media only screen and (max-width: 1180px) and (min-width:1023px) {
  .logo_header{
    width: 100%;
    height: auto;
  }
  .home-page-container .navbar ul {
    gap: 1.5% !important;
    margin-right: -2% !important;
}
.dropdown .btn-secondary {
font-size: 15px;
 }
 .contact_btn{
  font-size: 15px;
}
}

 @media only screen and (max-width: 1023px) and (min-width:921px) {
  .logo_header{
    width: 100%;
    height: auto;
  }
  .home-page-container .navbar ul {
    gap: 1.5% !important;
    margin-right: -4% !important;
}
.home-page-container .navbar ul li a {
  font-size: 15px;
}
.contact_btn{
  font-size: 15px;
}
.dropdown .btn-secondary {
  font-size: 15px;
   }
 }
@media only screen and (max-width:480px) {
  .dropdown .btn-secondary{
    color: #fff;
    font-size: 18px;
  }  
  .container .navbar .icon-bar {
    position: relative;
    width: 50%;
    cursor: pointer;
    margin-right: 10% !important;
}
.logo_header{
  width: 90% !important;
  height: auto;
  padding: 0.2rem 1.5rem !important;
}
.breadcrumb_container_new {
  padding: 0.2%;
}
}
@media screen and (min-width:520px) and (max-width:820px) {
  .sidebar-container {
    width: 75%;
  }
}
 @media only screen and (max-width: 920px) and (min-width:819px) {
  .logo_header{
    width: 100% !important;
    height: auto !important;
  }
  .home-page-container .navbar ul {
    gap: 1.2% !important;
    margin-right: -6% !important;
}
.home-page-container .navbar ul li a {
  font-size: 14px;
}
.contact_btn{
  font-size: 14px;
}
.dropdown .btn-secondary {
  font-size: 14px;
   }
   .contact_btn div{
    width: 135px !important;
   }
 }
 @media only screen and (min-width:1367px) {
  .logo_header{
    width: 100% !important;
    height: auto !important;
  }
  .home-page-container .navbar ul {
    gap: 4% !important;
    margin-right: 13% !important;
  }
  .home-page-container .navbar ul li a {
    font-size: 18px;
  }
  .container-btn a{
    font-size: 18px !important;
  }
  .contact_btn{
    font-size: 18px;
  }
  .dropdown .btn-secondary {
    font-size: 18px;
     }
     .contact_btn div{
      width: 180px !important;
      height: 50px !important;
      padding: 11px 0px !important;
     }
 }
 .contact_btn{                         
  font-size: 16px;
  line-height: 36px;
  /* text-align: center; */
  border-radius: 5px;
  font-weight: 600;
  }

  .contact_btn div {       
    display: block;
    background-color: #FF6600;
    width: 140px;
    padding: 6px 0px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #FF6600;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    .rotatee{
      transform: rotate(135deg)
  }
  .contact_text{
    background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  }

.contact_text{
  /* font-size: 1.2vw; */
display: inline-block;
position: relative;
opacity: 1;
color: #0F326C;
background-image: transparent linear-gradient(91deg, #0F326C 0%, #0F326C 100%) 0% 0% no-repeat padding-box;
background-clip: border-box;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-family:  "space-Groteskr" !important;
border-radius: 5px;
top:0%;
margin:0px 30px;
transition:0.3s;
}
.contact_text:hover{
  display: inline-block;
  position: relative;
  opacity: 1;
  background-color: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important; 
  .rotatee{
    transform: rotate(135deg);
    color: #fff;
}
}
.contact_btn:hover .contact_text{
  top:-250%;
}
.arrow_header :hover{
  color: #0F326C;
  background-color: #002236;
}

  @media only screen and (max-width: 768px) {
    .header-container{
      justify-content: space-between;
      display: flex;
      align-items: center;
      flex-flow: nowrap;
      padding: 0 0px;
      position: relative;
      z-index: 99999 !important;
  }
    .home-page-container {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
    
      /* .home-page-container .logo {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
      } */
    
      /* .home-page-container .navbar {
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
      } */
    
      .home-page-container .navbar ul {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        position: fixed;
        left: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background: #ffffff;
        width: 100%;
        height: 100%;
        overflow: auto;
        -webkit-transition: left .3s;
        -o-transition: left .3s;
        transition: left .3s;
      }
    
      .home-page-container .navbar ul li a {
        padding: 10px;
        font-size: 16px;
        height: auto;
        line-height: normal;
        color: #555555;
      }
    
      .home-page-container .navbar ul .close {
        display: block;
      }
    
      .home-page-container .navbar .icon-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    
      .home-page-container .navbar ._Menus-show {
        left: 0;
      }
    
  }

/* Desktop menu */
@media all and (min-width: 960px) {
.flex-column .dropdown .show{
    background-color: #f9f9f9;
    border: none;
    display: inline-flex;
  }


}
.space{
  margin-right:12px
}
.text{
  text-align: left;
  margin-top:53px

}

@media all and (min-width: 1900px) { 
  .home-page-container{
    padding: 0px 120px;
  }

  
}

@media screen and (min-width:768px) and (max-width:819px)  { 
  .home-page-container {
    justify-content: space-between;
}
/* .header-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-flow: nowrap;
  padding: 0px;
} */
.home-page-container .navbar .icon-bar {
  display: flex;
}
.home-page-container .navbar ul {
  flex-wrap: nowrap;
  position: fixed;
  left: 100%;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
  transition: left .3s;
  z-index: 99999 !important;
}
.sub_container_blogs {
  padding: 0% 5%;
}
}


.container-btn {
  width:100%;
  text-align:center;
  /* margin:30px 0; */
  font-family:sans-serif;
  opacity: 2;
}

.button-btnn {
  display: inline-block;
  height: 45px;
  line-height: 60px;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-color: #FF6600;
  color: white;
  border-radius: 5px;
  transition: 0.4s ease-in-out;
  opacity: 2;
}

.container-btn a{
  font-family: "space-Groteskr" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
}
.row_contact{
  flex-wrap: initial !important;
  .rotateeicon{
    color: white;
    transition: all 0.4s ease-in-out;
    justify-content: center;
    display: static;
    position: relative;
    cursor: pointer;
  }
}
.row_contact:hover{
  .rotateeicon {
    transform: rotate(-45deg);
    transition: all 0.4s ease-in-out;
    background-position: 0 0; 
  }
 .label-down {
    top:0%;
  }
.label-down1 {
    top: -0%;
  }
 .label-down22 {
    top: 100%;
  }
 .label-down11 {
    top: 100%;
  }
}

/* .button-btnn:hover {background:#FF6600;} */

/* BUTTON UP */
.label-up {
  display:block;
  /* margin:0px 30px; */
  height:100%;
  position:relative;
  top:0%;
  transition:0.6s;
}

.button-btnn:hover .label-up {
  top:-100%;
}

/* BUTTON DOWN */
.label-down {
  display:block;
  margin:0px 5px;
  height:100%;
  position:relative;
  top:-99%;
  transition:0.6s;
  margin-top: -5%;
}
.label-down12 {
  display:block;
  margin:0px 5px;
  height:100%;
  position:relative;
  top:-99%;
  transition:0.6s;
  left: -9%;
}
.label-down22 {
  display:block;
  margin:0px 5px;
  height:100%;
  position:relative;
  top: -99%;
  transition:1s;
  left: -12%;
}

.label-down1 {
  display:block;
  margin:0px 5px;
  height:100%;
  position:relative;
  top:-100%;
  transition:0.5s;
}
.label-down11 {
  display:block;
  margin:0px 15px;
  height:100%;
  position:relative;
  top: 0%;
  transition:0.6s;
}

.flexx{
  display: flex;
}


.padd{
  padding-left: 2%;
}


.col_arrow{
  align-self: center;
  /* margin-left: -5%; */

}

