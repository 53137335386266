.how-it-works{
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    padding: 0% 0% 0% 0%;
}
.HowItWorks-box1{
    padding: 5% 5% 5% 5%;
    width: 50vw;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}
.HowItWorks-box1 h1{
    font-family:  "space-Groteskb" !important;
    color: #ffff;
    font-size: 5.5vw;
    padding: 10% 0% 0% 0%;
}
.HowItWorks-box1 .para1{
    font-size: 1.2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.HowItWorks-box1 .para2{
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.HowItWorks-box2{
    display: flex;

}
.Box-right{
    padding: 25% 5% 5% 10%;
    width: 50vw;
}
.HowItWork-img{

}

.inside-box-right1{
    padding:  5% 0% 5% 0%;
}
.headline{
    font-size: 3vw;
    font-weight: bolder;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
}
.para-right{
    font-size: 1.2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}

.inside-box-right2{
    padding:  15% 0% 5% 0%;
}

.inside-box-right3{
    padding:  15% 0% 5% 0%;
}
.rightt img {
    max-width: 100%;
    height: auto;
    width: 50%;
}
.Circle_No{
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: white;
    color: #FF6600;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 32px;
    font-family:  "space-Groteskb" !important;
    padding: 5%;
}
.mergin-set{
    margin: 0% 0% 0% -16.5%;
    display: flex;
    gap: 8%;
    padding: 0% 0% 0% 0%;
}
.flexx-set{
    display: flex;
    gap: 10%;
}
.flexx-set1{
    // display: flex;
}


@media  only screen and (min-width: 1024px) and (max-width: 1123px) {
    .mergin-set {
        margin: 0% 0% 0% -19%;
    }
    .Circle_No{
        height: 50px;
        width: 50px;
        font-size: 25px;
        padding: 15%;
    }
}
@media  only screen and (min-width: 1124px) and (max-width: 1223px) {
    .mergin-set {
        margin: 0% 0% 0% -18.5%;
    }
    .Circle_No{
        height: 50px;
        width: 50px;
        font-size: 25px;
        padding: 15%;
    }
    
}
@media  only screen and (min-width: 1224px) and (max-width: 1323px) {
    .mergin-set {
        margin: 0% 0% 0% -18.1%;
    }
    .Circle_No{
        height: 50px;
        width: 50px;
        font-size: 25px;
        padding: 15%;
    }
}
@media  only screen and (min-width: 1324px) and (max-width: 1423px) {
    .mergin-set {
        margin: 0% 0% 0% -17.5%;
    }
    .Circle_No{
        height: 50px;
        width: 50px;
        font-size: 25px;
        padding: 15%;
    }
}
@media  only screen and (min-width: 1424px) and (max-width: 1523px) {
    .mergin-set {
        margin: 0% 0% 0% -17.5%;
    }
    .Circle_No{
        height: 50px;
        width: 50px;
        font-size: 25px;
        padding: 15%;
    }
}
@media  only screen and (min-width: 1524px) and (max-width: 1623px) {
    .mergin-set {
        margin: 0% 0% 0% -17%;
    }
    .Circle_No{
        height: 50px;
        width: 50px;
        font-size: 25px;
        padding: 15%;
    }
}
@media  only screen and (min-width: 1624px) and (max-width: 1723px) {
    .mergin-set {
        margin: 0% 0% 0% -17%;
    }
    .Circle_No{
        height: 55px;
        width: 55px;
        font-size: 30px;
        padding: 10%;
    }
}

@media  only screen and (max-width: 480px) {
    .how-it-works {
        display: none;
    }
    .how-it-works-mobile {
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0% 0% 10% 0%;
    }
    
    .HowItWorks-box1-mobile{
        padding: 5% 5% 5% 5%;
    }
    .HowItWorks-box1-mobile h1{
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    .HowItWorks-box1-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .HowItWorks-box1-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .Box-right-mobile{
        padding: 10% 10% 20% 10%;
        display: grid;
        // border: 1px solid red;
    }
    .inside-box-right1-mobile{
        padding:  5% 0% 5% 0%;
    }
    .headline-mobile{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
    }
    .para-right-mobile{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }

    .inside-box-right2-mobile{
        padding:  15% 0% 5% 0%;
    }

    .inside-box-right3-mobile{
        padding:  15% 0% 5% 0%;
    }
    .rightt-mobile img {
        max-width: 100%;
        height: auto;
        width: 100%;
        padding: 0% 0% 10% 0%;
    }
    .Circle_No-mobile{
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background-color: #ffff;
        color: #FF6600;
        display: table-cell;
        vertical-align: middle; 
        text-align: center;
        text-decoration: none;
        font-size: 30px;
        font-family:  "space-Groteskb" !important;
    }
    .mergin-set-mobile{

    }
    .flexx-set-mobile{
        gap: 5%;
        display: grid;
    }
    .flexx-set1-mobile{
        // display: flex;
    }
}


@media  only screen and (min-width: 481px) and (max-width: 767px){
    .how-it-works {
        display: none;
    }
    .how-it-works-mobile {
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0% 0% 10% 0%;
    }
    
    .HowItWorks-box1-mobile{
        padding: 5% 5% 5% 5%;
    }
    .HowItWorks-box1-mobile h1{
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    .HowItWorks-box1-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .HowItWorks-box1-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .Box-right-mobile{
        padding: 10% 10% 20% 10%;
        display: grid;
        // border: 1px solid red;
    }
    .inside-box-right1-mobile{
        padding:  5% 0% 5% 0%;
    }
    .headline-mobile{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
    }
    .para-right-mobile{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }

    .inside-box-right2-mobile{
        padding:  15% 0% 5% 0%;
    }

    .inside-box-right3-mobile{
        padding:  15% 0% 5% 0%;
    }
    .rightt-mobile img {
        max-width: 100%;
        height: auto;
        width: 100%;
        padding: 0% 0% 10% 0%;
    }
    .Circle_No-mobile{
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background-color: #ffff;
        color: #FF6600;
        display: table-cell;
        vertical-align: middle; 
        text-align: center;
        text-decoration: none;
        font-size: 30px;
        font-family:  "space-Groteskb" !important;
    }
    .mergin-set-mobile{
        // margin: 0% 0% -7% -17%;
        // display: flex;
        // gap: 8%; 
        // padding: 0% 0% 8% 0%;
    }
    .flexx-set-mobile{
        gap: 5%;
        display: grid;
    }
    .flexx-set1-mobile{
        // display: flex;
    }

}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .how-it-works-outer{
        display: none;
    }
    .how-it-works {
        display: none;
    }
    .how-it-works-mobile {
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 0% 0% 10% 0%;
    }
    
    .HowItWorks-box1-mobile{
        padding: 5% 5% 5% 5%;
    }
    .HowItWorks-box1-mobile h1{
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    .HowItWorks-box1-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .HowItWorks-box1-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .Box-right-mobile{
        padding: 10% 10% 20% 10%;
        display: grid;
        // border: 1px solid red;
    }
    .inside-box-right1-mobile{
        padding:  5% 0% 5% 0%;
    }
    .headline-mobile{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
    }
    .para-right-mobile{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }

    .inside-box-right2-mobile{
        padding:  15% 0% 5% 0%;
    }

    .inside-box-right3-mobile{
        padding:  15% 0% 5% 0%;
    }
    .rightt-mobile img {
        max-width: 100%;
        height: auto;
        width: 60%;
        padding: 0% 0% 10% 0%;
    }
    .Circle_No-mobile{
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background-color: #ffff;
        color: #FF6600;
        display: table-cell;
        vertical-align: middle; 
        text-align: center;
        text-decoration: none;
        font-size: 30px;
        font-family:  "space-Groteskb" !important;
    }
    .mergin-set-mobile{
        // margin: 0% 0% -7% -17%;
        // display: flex;
        // gap: 8%; 
        // padding: 0% 0% 8% 0%;
    }
    .flexx-set-mobile{
        gap: 5%;
        display: grid;
    }
    .flexx-set1-mobile{
        // display: flex;
    }
}

@media  only screen and (min-width: 1024px) {
    .how-it-works-mobile{
        display: none;
    }
}


@media  only screen and (min-width: 1024px) and (max-width: 1399px) {
    .HowItWorks-box1 .para1 {
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
        letter-spacing: 1.2px;
        font-size: 1.1vw;
        line-height: 25px;
    }
    .HowItWork-img img{
        max-width: 100%;
        height: 160vw;
    }
    .para-right {
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding: 1% 0% 1% 0%;
        letter-spacing: 1.2px;
        font-size: 1.1vw;
        line-height: 25px;
    }
    .HowItWorks-box1 h1 {
        font-size: 6.5vw;
    }
    .headline {
        font-size: 3vw;
    }
    .rightt img {
        width: 70%;
    }
    

}


@media  only screen and (max-width: 767px){
    // .wave-bg{
    //     display: none;   
    // }
    // .wave-bg img{
    //      display: none;        
    // }
    .wave-bg{
        background-color: #001220;
        background: #001220;
    }
    .wave-bg img{
        max-width: 100%;
        width: 100%;
        filter: drop-shadow(0 18px 10px black);
    }
    .Our_offerings_mobile_mobile{
        background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
    }
    .how-it-works-outer{
        display: none;
    }

}
@media  only screen and (min-width: 768px){
    // .how-it-works-mobile{
    //     display: none;
    // }
    .wave-bg{
        background-color: #001220;
        background: #001220;

    }
    .wave-bg img{
        max-width: 100%;
        width: 100%;
        filter: drop-shadow(0 18px 10px black);

        
    }
}
