
//LeaderShip from about us-->
.LeaderShip-Container{
    // border: 1px solid red;
    padding-top: 11%;
    padding-bottom: 6%;
    margin-top: 3%;
    margin-bottom: 0%;
    overflow: hidden;
  }
  .LeaderShip-Container h1{
    // border: 1px solid blue;
    font-size: 11vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
    padding-top: 3%;
    padding-bottom: 2%;
  }
  
  .LeaderShip-Container-inside{
    // border: 1px solid violet;
    padding-top: 0%;
    padding-bottom: 5%;
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .Founder-Container{
    // border: 1px solid blue;
    background-color: #0E2D61;
    padding-top: 12%;
    padding-bottom: 14%;
    z-index: 999;
    border-radius: 15px;
    position: relative;
    display: inline-grid;
    left: 4%;
    margin-top: 5%;
    width: 95%;
  }
  .Founder-img-Container{
    left: 0%;
    top: 0%;
    width: 30%;
    position: absolute;
    cursor: pointer;
    display: block;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    .linked-inn {
    position: absolute;
    top: 84%;
    width: 20%;
    left: 84%;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    z-index: 3;
    }
  }
.Founder-Content-Container{
    left: 32%;
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
  }
  .Founder-Name{
    color: #FFFFFF;
    font-family:  "space-Grotesksb" !important;
    font-size: 1.2vw;
    text-align: left;
    position: relative;
    letter-spacing: 1.2px;
    top: 7%;
  }
  .Founder-Role{
    color: #F05F30;
    letter-spacing: 1.2px;
    text-transform: capitalize;
    position: relative;
    text-align: left;
    top: 6%;
    font-size: 1vw;
    font-family:  "space-Groteskr" !important;
  }
  .Founder-Stories{
    color: #FFFFFF;
    left: 0;
    width: 100%;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.72px;
    font-size: 1.2vw;
    overflow: hidden;
    top: 6%;
    height: 56%;
    position: relative;
    color: white;
    text-align: left;
  }
  .Founder-Container:hover{
    cursor: pointer;
    .Founder-img-Container{
    left: -3%;
    top: -10%;
    width: 12%;
    position: absolute;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    }
    .Founder-Content-Container{
      left: 12%;
      width: 80%;
      height: 100%;
      position: absolute;
      transition-duration: 0.6s; 
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease;
    }
    .linked-inn {
    position: absolute;
    bottom: 60%;
    width: 20%;
    left: 84%;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
  }
  }
  // Co-founder-->
  .CO-Founder-Container{
    // border: 1px solid blue;
    background-color: #0E2D61;
    padding-top: 12%;
    padding-bottom: 14%;
    z-index: 999;
    border-radius: 15px;
    position: relative;
    display: inline-grid;
    left: 4%;
    margin-top: 10%;
    width: 95%;
  }
  
  .CO-Founder-img-Container{
    left: 0%;
    top: 0%;
    width: 30%;
    position: absolute;
    cursor: pointer;
    display: block;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
  .linked-in-preeti {
    position: absolute;
    top: 84%;
    width: 20%;
    left: 84%;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    z-index: 3;
}
  }
  .CO-Founder-Content-Container{
    left: 32%;
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
  }
  .CO-Founder-Name{
    // border: 1px solid green;
    color: #FFFFFF;
    font-family:  "space-Grotesksb" !important;
    font-size: 1.2vw;
    text-align: left;
    position: relative;
    letter-spacing: 1.2px;
    top: 7%;
  }
  .CO-Founder-Role{
    // border: 1px solid rgb(42, 8, 34);
    color: #F05F30;
    letter-spacing: 1.2px;
    text-transform: capitalize;
    position: relative;
    text-align: left;
    top: 6%;
    font-size: 1vw;
    font-family:  "space-Groteskr" !important;
  }
  .CO-Founder-Stories{
    // border: 1px solid rgb(223, 155, 18);
    color: #FFFFFF;
    left: 0;
    width: 100%;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.72px;
    font-size: 1.2vw;
    overflow: hidden;
    top: 6%;
    height: 56%;
    position: relative;
    color: white;
    text-align: left;
  }
  .CO-Founder-Container:hover{
    cursor: pointer;
    .CO-Founder-img-Container{
      left: -3%;
      top: -10%;
      width: 12%;
      position: absolute;
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease;
    }
    .CO-Founder-Content-Container{
      left: 12%;
      width: 80%;
      height: 100%;
      position: absolute;
      transition-duration: 0.6s; 
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease;
    }
    .linked-in-preeti {
      position: absolute;
      bottom: 60%;
      width: 20%;
      left: 84%;
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease;
    }
  } 



  @media  only screen and (min-width: 1024px) and (max-width: 1400px) {
    .Founder-Container {
      padding-bottom: 17%;
  }
  .Founder-img-Container {
    width: 33%;
  }
  .Founder-Content-Container {
    left: 36%;
  }
  .Founder-Stories {
    height: 62%;
  }
}
  @media  only screen and (min-width: 1024px) and (max-width: 1400px) {
  .CO-Founder-Container {
      padding-bottom: 17%;
  }
  .CO-Founder-Stories {
    height: 62%;
  }
  .CO-Founder-img-Container {
    width: 33%;
  }
  .CO-Founder-Content-Container {
    left: 36%;
  }
  }
  @media  only screen and (min-width: 2000px) {
    .Founder-img-Container .linked-inn {
      position: absolute;
      top: 84%;
      width: 20%;
      left: 80%;
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: ease;
      z-index: 3;
    }
  }
  @media  only screen and (min-width: 2000px) {

   .Founder-img-Container img {
      max-width: 100%;
      height: auto;
      width: 26vw;
  }
   .CO-Founder-img-Container img {
      max-width: 100%;
      height: auto;
      width: 26vw;
  }
  }


