.Main-Types{
 padding: 5% 0%;
 background: #001220 0% 0% no-repeat padding-box;
 display: flex;
}
.right-TaasTypes{
    width: 50vw;
    padding: 10% 0%;
}
.left-TaasTypes{
    width: 50vw;
    padding: 10% 5%;
}
.left-TaasTypes  h1{
    padding: 0% 0% 0% 0%;
    font-size: 4.5vw;
    text-align: left;
    color: white;
    font-family:  "space-Groteskb" !important;
}
.left-TaasTypes  p{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 5% 0%;
    line-height: 200%;
    cursor: pointer;
    letter-spacing: 0.96px;
}

.left-TaasTypes  h2{
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}

.left-TaasTypes img {
    max-width: 100%;
    height: auto;
    width: 100%;
}




  

@media  only screen and (max-width: 480px) {
    .Main-Types{
        display: none;
    }
    .Main-Types-mobile{
        padding: 5% 0%;
        background: #001220 0% 0% no-repeat padding-box;
       }
       .right-TaasTypes-mobile{
           width: 100vw;
           padding: 10% 0%;
       }
       .left-TaasTypes-mobile{
        padding: 5% 2% 5% 2%;
        text-align: -webkit-center;
       }
       .left-TaasTypes-mobile  h1{
            font-size: 12.5vw;
            text-align: center;
            color: white;
            font-family:  "space-Groteskb" !important;
            padding-top: 8%;
            padding-bottom: 0%;
       }
       .left-TaasTypes-mobile  p{
            font-size: 16px;
            color: white;
            text-align: center;
            font-family:  "space-Groteskr" !important;
            margin: 2% 4% 2% 4%;
            padding: 2% 0% 2% 0%;
            letter-spacing: 0.64px;
            line-height: 200%;
        }
       
       .left-TaasTypes-mobile  h2{
            color: white;
            text-align: center;
            font-family:  "space-Groteskr" !important;
            font-size: 18px;
            letter-spacing: 1.44px;
            line-height: 170%;
            margin: 2% 0% 2% 0%;
            padding: 2% 0% 2% 0%;
        }
       
       .left-TaasTypes-mobile img {
           max-width: 100%;
           height: auto;
           width: 85%;
       }
  }
  
  
  
  @media  only screen and (min-width: 768px){
    .Main-Types-mobile{
        display: none;
    }
  }
  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .Main-Types{
        display: none;
    }
    .Main-Types-mobile{
        padding: 5% 0%;
        background: #001220 0% 0% no-repeat padding-box;
       }
       .right-TaasTypes-mobile{
           width: 100vw;
           padding: 10% 0%;
       }
       .left-TaasTypes-mobile{
        padding: 5% 2% 5% 2%;
        text-align: -webkit-center;
       }
       .left-TaasTypes-mobile  h1{
            font-size: 12.5vw;
            text-align: center;
            color: white;
            font-family:  "space-Groteskb" !important;
            padding-top: 8%;
            padding-bottom: 0%;
       }
       .left-TaasTypes-mobile  p{
            font-size: 16px;
            color: white;
            text-align: center;
            font-family:  "space-Groteskr" !important;
            margin: 2% 4% 2% 4%;
            padding: 2% 0% 2% 0%;
            letter-spacing: 0.64px;
            line-height: 200%;
        }
       
       .left-TaasTypes-mobile  h2{
            color: white;
            text-align: center;
            font-family:  "space-Groteskr" !important;
            font-size: 18px;
            letter-spacing: 1.44px;
            line-height: 170%;
            margin: 2% 0% 2% 0%;
            padding: 2% 0% 2% 0%;
        }
       
       .left-TaasTypes-mobile img {
           max-width: 100%;
           height: auto;
           width: 85%;
       }
  }

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .left-TaasTypes h1 {
        padding: 0% 0% 0% 0%;
        font-size: 5.5vw;
        text-align: left;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    .left-TaasTypes p {
        font-size: 1.6vw;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 5% 0%;
        line-height: 200%;
        cursor: pointer;
        letter-spacing: 0.96px;
    }
    .left-TaasTypes h2 {
        font-size: 2.2vw;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
    }  

}