@media  only screen and (min-width: 1024px){
    .login-box-mobile{
        display: none;
    }
    .send-btn-tablet{
        display: none;
    }
    .btn-stacke-tablet{
        display: none;
    }
    .btn-stacke-mobile{
        display: none;
    }
    .stacke-boxs-mobile{
        display: none;
    }
    .stacke-content-box-mobile{
        display: none;
    }
    .stacke-content-box-mobile2{
        display: none;
    }
    .StakeRank-ProblemCase .Stacke-Para2-desktop {
        letter-spacing: 0.99px;
        font-size: 2.2vw;
        padding-top: 2%;
        padding-bottom: 2%;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
    }
    .StakeRank-Content .Stack-content-para2-desktop {
        letter-spacing: 0.26px;
        font-size: 2.2vw;
        padding-top: 3%;
        padding-bottom: 2%;
        padding-left: 2%;
        padding-right: 2%;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
    }
    .StakeRank-ProblemCase .Stacke-Para2 {
    display: none;
    }
    .StakeRank-Content .Stack-content-para2 {
        display: none;
    }

.login-box {
  position: relative;
  width: 45vw;
  background: transparent
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #FF6600;
  text-align: center;
}

.login-box .user-box {
  position: relative;
  background: transparent;
  background-color: transparent;
}

.login-box .user-box input {
  width: 92%;
  padding: 10px 15px;
  font-size: 1.5vw;
  font-family:  "space-Groteskr" !important;
  color: #ffff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #FF6600;
  border-radius: 0px;
  outline: none;
  background: transparent;
  background-color: transparent;
  font-weight: lighter;
}
.login-box .user-boxx textarea {
  width: 92%;
  padding: 10px 15px;
  font-size: 1.5vw;
  font-family:  "space-Groteskr" !important;
  color: #ffff;
  margin-bottom: 30px;
  border: none;
  height: 10vw;
  border-bottom: 1px solid #FF6600;
  border-radius: 0px;
  outline: none;
  background: transparent;
  height: 8vw;
  background-color: transparent;
  font-weight: lighter;
  border-radius: 15px;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #ffff;
  pointer-events: none;
  background: transparent;
  background-color: transparent;
  transition: .5s;
}

.login-box .user-box input:focus ~ label{
  top: -20px;
  left: 0;
  color: #FF6600;
  font-size: 12px;
  background: transparent;
  background-color: transparent;
}
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #FF6600;
  font-size: 12px;
}
.bg-color{
    line-height: 100%;
    font-size: 2.2vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
    margin-top: 3% !important;
}
.model_patra {
    font-size: 1.1vw;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
}

.Ok-btn-stacker:hover{
    border: 2px solid #000000;
    border-radius: 10px;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 1.2vw;
    margin-bottom: 2%;
    color: #000000;
    left: 45%;
    position: relative;
    background-color: #FF6600;
        .rotate-arrow-product{
            transform: rotate(135deg)scale(1.4);
            color: #000000;
            background-position: 0 0;
        }
    }
    .Ok-btn-stacker{
        border: 2px solid #FF6600;
        border-radius: 10px;
        font-family:  "space-Groteskr" !important;
        color: #FF6600;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 3%;
        padding-right: 3%;
        font-size: 1.2vw;
        margin-bottom: 2%;
        left: 45%;
        position: relative;
        background-position: right bottom;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 0.4s ease-in;
        
    .rotate-arrow-product{
        transform: rotate(180deg) scale(1.4);
        color: #FF6600;
        background-position: right bottom;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all .9s ease-in;
    }
    }
    .gif-set img{
        width: 50%;
        object-fit: contain;
        // mix-blend-mode: color-burn;
    }

}





.main-Product2{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    opacity: 1;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    // border: 1px solid green;
}
.main-ProductInside{
    // border: 1px solid red;
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    // background: #000000 0% 0% no-repeat padding-box;
    // background: transparent linear-gradient(359deg, #00122E 0%, #000 14.2%, #013143 34.3%, #00072D 66.6%, #182878 79.2%, #010101 100%);
    background: transparent linear-gradient(360deg, #000000 0%, #0c0e65 79%, #000000 100%) 0% 0% no-repeat padding-box;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
}
.Main-Class1{
    background-color: #000;
}
.StackeRank-Main{
    // border: 1px solid red;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-image: url("../../fonts/Stakerank/bg_stackerank\ \(1\).svg");
}
.StackeRank-Main img{ 
    position: relative;
    width: 100%;
    // height: 60vh;
}
.StackeRank-inner{
    // border: 1px solid blue;
    // position: absolute;
    // top: 50%;
    // left: 20%;
    // transform: translate(-20%, -75%);
    position: absolute;
    top: 25%;
    left: 5%;
    width: 55%;
}
.StackeRank-inner h1{
    // border: 1px solid black; 
    line-height: 100%;
    font-size: 9.2vw;
    opacity: 1;
    background: transparent linear-gradient(95deg, #FF6600 0%, #35D2D2 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "space-Groteskb" !important;
    padding-bottom: 1%;
}
.StackeRank-inner .Stackpara1{
    // border: 1px solid green;
    font-family:  "space-Grotesksb" !important;
    font-size: 1.6vw;
    text-align: left;
    letter-spacing: 0px;
    color: #FF6600;
    opacity: 1;
}
.StackeRank-inner .Stackpara2{
    // border: 1px solid yellow; 
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 1.3vw;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    font-weight: lighter;
}

.btn-container-stacker{
    position: relative;
    overflow:hidden;
    padding-top: 1px;
}

.btn-lii-stacker{                         
//   font-size: 2vw;
    display: inline-block;
    position: relative;
    opacity: 1;
    background: white;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskr" !important;
    padding-bottom: 1px;
    background-image: linear-gradient(to left,
    transparent,
    transparent 50%,
    #FF6600 50%,
    #FF6600);
background-position: 100% 0;
background-size: 200% 100%;
transition: all .4s ease;     

}


.btn-lii-stacker a{      
    display: block;
    background-image: linear-gradient(to left,
                        transparent,
                        transparent 50%,
                        #FF6600 50%,
                        #FF6600);
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all .4s ease;        
    border-radius: 10px;
    border: 1px solid #FF6600;
    padding-left: 20px;
    padding-right: 30px;
    color: white;
.rotatee-stacker{
    color: white;
    transition: all 0.4s ease;
    justify-content: center;
    display: inline-table;
    display: static;
    position: relative;
    transform: scale(1.2)rotateZ(180deg);
}
}
.btn-lii-stacker a:hover {       
    background-position: 0 0; 
    box-shadow: 0px 8px 6px #00000047;
    border-radius: 10px;
    // border: 1px solid #FF6600;
    color: white;
    .rotatee-stacker{
        transform: scale(1.2)rotateZ(135deg);
        color: white;
        }
}

.btn-liil-stacker{
    font-size: 1.2vw;
    font-weight: bolder;
    display: inline-block;
    position: relative;
    opacity: 1;
    background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskr" !important;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: white;
.rotatee-stacker{
    color: white;
    transition: all 0.4s ease;
    justify-content: center;
    display: inline-table;
    display: static;
    position: relative;
    transform: scale(0.9);
}
}

.btn-liil-stacker:hover{
    color: white;
    font-size: 1.2vw;
    font-weight: bolder;
    display: inline-block;
    position: relative;
    opacity: 1;
    // background: transparent linear-gradient(91deg, #000 0%, #000 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskr" !important; 
    padding-top: 15px;
    padding-bottom: 15px;
    .rotatee-stacker{
        transform: scale(1.3)rotateZ(135deg);
        color: white;
        }
}
.StakeRank-ProblemCase{
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    // background: #000000 0% 0% no-repeat padding-box;  // back to make it sma as before
    opacity: 1;
    overflow: hidden;
    padding-bottom: 1%;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
}
.StakeRank-ProblemCase h1{
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    opacity: 1;
    line-height: 100%;
    font-size: 9vw;
    font-family:  "space-Groteskb" !important;
    padding-top: 5%;
    padding-bottom: 2%;
}
.StakeRank-ProblemCase .Stacke-Para1{
    font-size: 1.5vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}
.StakeRank-ProblemCase .Stacke-Para2{
    letter-spacing: 1.44px;
    font-size: 2.2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 2%;
    padding-bottom: 2%;
}
.StakeRank-ProblemCase .Stacke-Para2{
    letter-spacing: 1.44px;
    font-size: 2.2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 2%;
    padding-bottom: 2%;
}
.stacke-boxs{
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}
.stacke-inside-box1{
    // border: 2px solid #FF6600;
    width: 40vw;
    height: auto;
    // border-radius: 40px;
    padding-top: 1%;
    padding-bottom: 1%;
}
.gradient-border-mask-product {
    display: flow-root;
    position: relative;
    padding: 0% 1%;
    // width: 24vw;
    height: auto;
    overflow: hidden;
  }
  
  .gradient-border-mask-product::before {
    content: "";
    position: absolute;
    // width: 24vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 2px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
.stacke-inside-box1 img{
    max-width: 100%;
    height: auto;
    width: 30vw;
    padding: 8%;
}
.stacke-inside-box2{
    // border: 2px solid #FF6600;
    width: 40vw;
    height: auto;
    // border-radius: 40px;
    padding-top: 1%;
    padding-bottom: 2%;
}
.stacke-inside-box2 img{
    max-width: 100%;
    height: auto;
    width: 27vw;
    padding: 6%;
}
.Stacke-Problem-para1{
    opacity: 1;
    font-family:  "space-Grotesksb" !important;
    font-size: 2.5vw;
    color: var(--unnamed-color-ffffff);
    letter-spacing: 0.96px;
    color: #FFFFFF;
    letter-spacing: 0px;
    text-align: center;
}
.Stacke-Problem-para2{
    // border: 1px solid red;
    font-size: 1.3vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin-left: 25px;
    margin-right: 25px;
    line-height: 200%;
}
.Stacke-Problem-para22{
    // border: 1px solid red;
    font-size: 1.3vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin-left: 25px;
    margin-right: 25px;
    line-height: 195%;
}
.stackerank-box{
    // background: transparent linear-gradient(0deg, #003636 0%, #023331 28%, #0F5654 50%, #000404 100%) 0% 0% no-repeat padding-box; // back to make it sma as before
    // border: 1px solid red;
}
.StakeRank-Content{
// border: 1px solid green;
}
.StakeRank-Content h1{
    line-height: 100%;
    font-size: 10vw;
    text-align: center;
    opacity: 1;
    color: white;
    padding-top: 10%;
    font-family:  "space-Groteskb" !important;
    padding-bottom: 2%;
}
.StakeRank-Content .Stack-content-para1{
    font-size: 1.3vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 20%;
    margin-right: 20%;
    // border: 1px solid green;
}
.StakeRank-Content .Stack-content-para2{
    text-align: center;
    letter-spacing: 1.44px;
    font-size: 1.8vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 2%;
    padding-bottom: 2%;
}


.stacke-content-box{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 2%;
    gap: 2%;
    position: relative;
    overflow: hidden;
}
.stacke-Insidecontent-box{
    border: 2px solid #FF6600;
    border-radius: 20px;
    opacity: 1;    
    width: 27vw;
    height: auto;
}
.stacke-Insidecontent-box img{
    max-width: 100%;
    height: auto;
    width: 9vw;
    padding: 8% 0%;
}
.stacke-Insidecontent-box .Stacke-content-para1 {
    font-family:  "space-Groteskb" !important;
    font-size: 1.7vw;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
}

.stacke-Insidecontent-box .Stacke-content-para21{
    font-size: 1.3vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 22%;
    margin-right: 22%;
    line-height: 150%;
    padding-bottom: 1%;
    padding-top: 1%;    // border: 1px solid green;
}
.stacke-Insidecontent-box .Stacke-content-para22{
    font-size: 1.3vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 18%;
    margin-right: 18%;
    line-height: 150%;
    padding-bottom: 1%;
    padding-top: 1%;    // border: 1px solid green;
}
.stacke-Insidecontent-box .Stacke-content-para23{
    font-size: 1.3vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 5%;
    margin-right: 5%;
    line-height: 170%;
    padding-bottom: 1%;
}


@media  only screen and (min-width: 768px) {

    .stackeRank-Form {
        padding-top: 3%;
        padding-bottom: 0%;
        display: flex;
        margin-top: 3%;
    }
    .stackeRank-Form-left {
        width: 30vw;
        left: 7vw;
        position: relative;
        margin-top: 5%;
        padding-top: 5%;
    }
    .stackeRank-Form-left img{
        padding-bottom: 10%;
        padding-top: 7%;
        width: 80%;
    }
}



.stackeRank-H{
    font-family:  "space-Groteskr" !important;
    font-size: 5vw;
    color: #FFFFFF;
    // text-align: center;
    position: relative;
}
.stackeRank-p{
    font-size: 4.3vw;
    // text-align: center;
    line-height: 90%;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
}
// form css start 
.stackeRank-Form-right{
    left: 20vw;
    position: relative;
    margin-top: 5%;
    padding-top: 5%;
}
.input-color::placeholder {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font-size: 1.5vw !important;
  font-family:  "space-Groteskr" !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: transparent;
  opacity: 1;
  padding-bottom: 2%;
}
.input-color{
    background-color: transparent;
    background: transparent;
}



.gif-set {
    display: inline-block;
    /* text-align-last: center; */
    width: 50%;
}


@media  only screen and (min-width: 769px) {    
    .btn-stacke-mobile{
    display: none;
}
}
@media  only screen and (min-width: 768px) and (max-width: 1023px){
    .login-box{
        display: none;
    }
    .btn-container-stacker{
        display: none;
    }
    .stacke-boxs-mobile{
        display: none;
    }

    .stacke-content-box-mobile{
        display: none;
    }
    .stacke-content-box-mobile2{
        display: none;
    }
    .StakeRank-Main img{ 
        position: relative; 
        width: 100%;
    }
    .StackeRank-inner {
        position: absolute;
        top: 33%;
        left: 20%;
        transform: translate(-25%, -15%);
    }
    .StackeRank-inner h1 {
        font-size: 9.2vw;
    }
    .StackeRank-inner .Stackpara1 {
        font-size: 2vw;
        margin-bottom: 2% !important;
    
    }
    .StackeRank-inner .Stackpara2 {
        font-size: 1.7vw;
    }

    .btn-stacke-tablet{
        color: white;
        padding-top: 1%;
        padding-bottom: 2%;    
    }
    .btn-inner{
        color: white;
        border: 1px solid black;
        padding-top: 2%;
        padding-bottom: 2%;
        padding-left: 1%;
        padding-right: 2%;
        background: #FF6600;
        font-size: 1.6vw;
        border-radius: 4px;
        font-family:  "space-Groteskr" !important;
    }
    .stacke-arrow-tablet{
        color: white;
        display: static;
        transform: rotate(135deg) scale(1);
    }
    .StakeRank-ProblemCase h1 {
        font-size: 9.5vw;
        padding-top: 6%;
    }
    .StakeRank-ProblemCase .Stacke-Para1 {
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: lighter;
        padding-left: 3%;
        padding-right: 3%;
    }
    .StakeRank-ProblemCase .Stacke-Para2 {
        display: none;
    }
    .StakeRank-ProblemCase .Stacke-Para2-desktop {
        letter-spacing: 0.99px;
        font-size: 20px;
        padding-top: 2%;
        padding-bottom: 2%;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
    }
    .Stacke-Problem-para1 {
        font-size: 20px;
        letter-spacing: 0.96px;
        font-family:   "space-Grotesksb" !important;
    }
    .Stacke-Problem-para2 {
        font-size: 18px;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 170%;
    }
    .Stacke-Problem-para22 {
        font-size: 18px;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 170%;
    }
    .StakeRank-Content h1 {
        font-size: 14vw;
    }
    .StakeRank-Content .Stack-content-para1 {
        font-size: 20px;
        margin-left: 5%;
        margin-right: 5%;
        font-weight: lighter;
    }
    .StakeRank-Content .Stack-content-para2 {
        // letter-spacing: 1.5px;
        // font-size: 20px;
        // padding-top: 3%;    
        display: none;
    }
    .StakeRank-Content .Stack-content-para2-desktop {
        letter-spacing: 0.26px;
        font-size: 20px;
        padding-top: 3%;
        padding-bottom: 2%;
        padding-left: 2%;
        padding-right: 2%;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
    }
    .stacke-Insidecontent-box {
        width: 29vw;
    }
    .stacke-Insidecontent-box img {
        width: 10vw;
        padding-top: 6%;
    }
    .stacke-Insidecontent-box .Stacke-content-para1 {
        font-size: 17px;
        padding-left: 3%;
        padding-right: 3%;    
        padding-top: 2%;
    }
    .stacke-Insidecontent-box .Stacke-content-para21 {
        font-size: 16px;
        letter-spacing: 1px;
        margin-left: 5%;
        margin-right: 5%;
        line-height: 155%;
        padding-bottom: 1%;
        padding-top: 0%;
    }
    .stacke-Insidecontent-box .Stacke-content-para22 {
        font-size: 16px;
        margin-left: 5%;
        margin-right: 5%;
        line-height: 155%;
        padding-bottom: 1%;
        padding-top: 0%;
    }
    .stacke-Insidecontent-box .Stacke-content-para23 {
        font-size: 16px;
        letter-spacing: 0.96px;
        margin-left: 2%;
        margin-right: 2%;
        line-height: 125%;
        padding-bottom: 1%;
    }
    .stackeRank-H {
        font-size: 6vw;
    }
    .stackeRank-p {
        font-size: 4.2vw;
    }
    .stackeRank-Form-right {
        left: 20vw;
        position: relative;
        width: 47vw;
    }
    .login-box .user-box input {
        width: 100%;
        padding: 10px 15px;
        font-size: 1vw;
        font-family:  "space-Groteskr" !important;
        color: #ffff;
        margin-bottom: 10%;
        border: none;
        border-bottom: 1px solid #FF6600;
        border-radius: 0px;
        outline: none;
        background: transparent;
        background-color: transparent;
        font-weight: lighter;
    }
    .input-color::placeholder {
        font-size: 2.3vw !important;
        padding-bottom: 2% !important;
    }
    .send-btn{
        display: none;
    }
    .send-btn-tablet {
        border-radius: 10px;
        background-color: #FF6600;
        border: 1px solid black;
        color: rgb(255, 255, 255);
        padding-top: 3%;
        padding-bottom: 2%;
        padding-left: 2%;
        padding-right: 3%;
        font-size: 2.5vw;
        font-family: "space-Groteskr" !important;
        font-weight: lighter;
        .rotate-arrow-tablet{
            transform: rotate(140deg);
            color: rgb(255, 255, 255);
        }
    }
    .login-box-mobile .user-box-mobile input {
    width: 95%;
    padding: 10px 15px;
    font-size: 3.5vw;
    font-family:  "space-Groteskr" !important;
    color: #ffff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #FF6600;
    border-radius: 0px;
    outline: none;
    background: transparent;
    background-color: transparent;
    font-weight: lighter;
    }
    .login-box-mobile .user-boxx-mobile textarea {
    width: 95%;
    padding: 10px 15px;
    font-size: 3.5vw;
    font-family:  "space-Groteskr" !important;
    color: #ffff;
    margin-bottom: 30px;
    border: none;
    height: 14vw;
    border-bottom: 1px solid #FF6600 !important;
    border-radius: 0px !important;
    outline: none;
    background: transparent;
    background-color: transparent;
    font-weight: lighter;
}
.bg-color{
    line-height: 100%;
    font-size: 4vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
    margin-top: 3% !important;
}
.model_patra {
    font-size: 1.6vw;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
}
.Ok-btn-stacker {
    border-radius: 10px;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 3.5vw;
    margin-bottom: 2%;
    left: 40%;
    border: 2px solid #000000;
    color: #000000;
    background-color: #FF6600;
    position: relative;
    background-position: right bottom;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 0.4s ease-in;
}

}

@media screen and (max-width:820px) {
    .modal-contentPro .modal-content {
        margin-top: 22%;
    }
}


@media  only screen and (min-width: 481px) and (max-width: 767px){
    // .login-box-mobile{
    //     display: none;
    // }
    .stacke-boxs{
        display: none;
    }
    .stacke-content-box{
        display: none;
    }
    .stackeRank-p{
        display: none;
    }
    .StackeRank-Main{
        display: none;
    }
    .login-box{
        display: none;
    }
    .send-btn{
        display: none;
    }
    .btn-stacke-tablet{
        display: none;
    }
    // .stacke-boxs-mobile{
    //     display: none;
    // }
    .btn-container-stacker{
        display: none;
    }
    // .stacke-content-box-mobile{
    //     display: none;
    // }
    // .stacke-content-box-mobile2{
    //     display: none;
    // }
    .StackeRank-Main img{ 
        position: relative;
        width: 100%;
        height: auto;
    }
    .StackeRank-inner {
        position: absolute;
        top: 0%;
        left: 10%;
        transform: translate(-5%, 35%);
    }
    .StackeRank-inner h1 {
        font-size: 9vw;
    }
    .StackeRank-inner .Stackpara1 {
        font-family:  "space-Grotesksb" !important;
        font-size: 2vw;
        margin-bottom: 1% !important;
    }
    .StackeRank-inner .Stackpara2 {
        font-size: 2vw;
        letter-spacing: 1px;
    }
    
    .btn-stacke-mobile{
        color: white;
        padding-top: 1%;
        padding-bottom: 1%;    
    }
    .btn-inner-mobile {
        color: white;
        padding-top: 2.5%;
        padding-bottom: 2.5%;
        padding-left: 2%;
        padding-right: 2%;
        font-size: 18px;
        background: #FF6600;
        border-radius: 5px;
        font-family:  "space-Groteskr" !important;
    }
    .stacke-arrow-mobile{
        color: white;
        display: static;
        transform: rotate(135deg) scale(1);
    }
    .StakeRank-ProblemCase h1 {
        font-size: 12vw;
        padding-top: 8%;
    }
    .StakeRank-ProblemCase .Stacke-Para1 {
        font-size: 16px;
        letter-spacing: 0.56px;
        margin-left: 2%;
        margin-right: 2%;
    }
    .StakeRank-ProblemCase .Stacke-Para2 {
        font-size: 18px;
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 2%;
        letter-spacing: 1.44px;
        line-height: 170%;
    
    }
    .StakeRank-ProblemCase .Stacke-Para2-desktop {
        display: none;
    }
    .stacke-inside-box1 img {
        width: 31vw;
    }
    .stacke-inside-box2 img {
        width: 29vw;
    }
    .Stacke-Problem-para1 {
        font-size: 3.5vw;
        letter-spacing: 0.96px;
        margin-left: 1%;
        margin-right: 1%;
    }
    .Stacke-Problem-para2 {
        font-size: 3vw;
        font-family:  "space-Groteskr" !important;
        margin-left: 1%;
        margin-right: 1%;
        line-height: 145%;
        letter-spacing: 1px;
    }
    .Stacke-Problem-para22 {
        font-size: 3vw;
        font-family:  "space-Groteskr" !important;
        margin-left: 1%;
        margin-right: 1%;
        line-height: 175%;
        letter-spacing: 1px;
    }
    .StakeRank-Content h1 {
        font-size: 14vw;
    }
    .StakeRank-Content .Stack-content-para1 {
        font-size: 3.2vw;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        margin-left: 5%;
        margin-right: 5%;
    }
    .StakeRank-Content .Stack-content-para2 {
        letter-spacing: 0.99px;
        font-size: 3.8vw;
        padding-top: 3%;
    }
    .stacke-Insidecontent-box {
        width: 30vw;
    }
    .stacke-Insidecontent-box img {
        width: 12vw;
        padding-top: 6%;
    }
    .stacke-Insidecontent-box .Stacke-content-para1 {
        font-size: 2.8vw;
        padding-top: 1%;
    }
    .stacke-Insidecontent-box .Stacke-content-para23 {
        font-size: 2.5vw;
        letter-spacing: 1px;
        margin-left: 2%;
        margin-right: 2%;
        line-height: 150%;
        padding-bottom: 1%;
    }
    .stacke-Insidecontent-box .Stacke-content-para22 {
        font-size: 2.5vw;
        letter-spacing: 1px;
        margin-left: 1%;
        margin-right: 1%;
        line-height: 150%;
        padding-bottom: 1%;
        padding-top: 6%;
    }
    .stacke-Insidecontent-box .Stacke-content-para21 {
        font-size: 2.5vw;
        letter-spacing: 1px;
        margin-left: 1%;
        margin-right: 1%;
        line-height: 150%;
        padding-bottom: 1%;
        padding-top: 6%;
    }
    .stackeRank-H {
        font-size: 6vw;
    }
    .stackeRank-p {
        font-size: 4.4vw;
    }
    .input-color::placeholder {
        font-size: 3.5vw !important;
        padding-bottom: 2% !important;

    }
    .send-btn-tablet {
        border-radius: 10px;
        background-color: #FF6600;
        border: 1px solid black;
        color: white;
        padding-top: 3%;
        padding-bottom: 2%;
        padding-left: 2%;
        padding-right: 3%;
        font-size: 3.2vw;
        font-family: "space-Groteskr" !important;
        font-weight: lighter;
    .rotate-arrow-tablet{
            transform: rotate(140deg);
            color: white;
        }
    }
    .stackeRank-Form-right {
        left: 15vw !important;
        position: relative;
        width: 70vw;
    }
    .login-box-mobile .user-box-mobile input {
        width: 95%;
        padding: 3% 1% 1.5% 1%;
        font-size: 3.5vw;
        font-family:  "space-Groteskr" !important;
        color: white;
        margin-bottom: 30px;
        border: none;
        border-bottom: 1px solid #FF6600;
        border-radius: 0px;
        outline: none;
        background: transparent;
        background-color: transparent;
        font-weight: lighter;
    }
    .login-box-mobile .user-boxx-mobile textarea {
        width: 95%;
        padding: 1% 0% 1.5% 1%;        
        font-size: 3.5vw;
        font-family:  "space-Groteskr" !important;
        color: #ffff;
        margin-bottom: 30px;
        border: none;
        height: 16vw;
        border-bottom: 1px solid #FF6600 !important;
        border-radius: 0px !important;
        outline: none;
        background: transparent;
        background-color: transparent;
        font-weight: lighter;
        // border-radius: 15px;
    }
    .bg-color{
        line-height: 100%;
        font-size: 6vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskb" !important;
        margin-top: 3% !important;
    }
    .model_patra {
        font-size: 2.2vw;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
    }
    .Ok-btn-stacker {
        border: 2px solid #000000;
        color: #000000;
        background-color: #FF6600;
        border-radius: 10px;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 3%;
        font-size: 3.5vw;
        padding-right: 3%;
        margin-bottom: 2%;
        left: 41%;
        position: relative;
        background-position: right bottom;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 0.4s ease-in;
    }
    .gif-set img {
        width: 40%;
        object-fit: contain;
    }

    
.StackeRank-Main-Mobile{
    //  display: flex;
     position: relative;
    }
    
    .StackeRank-Main-Mobile img {
        // max-width: 100%;
        // height: auto;
        // width: 100vw;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
    }
    .Data-video-Stacker-Mobile{
        width: 100%;
        height: 45vw !important;
        object-fit: fill;
        padding-bottom: 1%;
    }
    .banner-up{
        position: absolute;
        top: 8%;
        right: 22%;
        width: 58%;
    }
    .Stackerank-Content-Main{
        // border: 1px solid red;
        padding-top: 3%;
        padding-bottom: 5%;
        margin-top: 3%;
        margin-bottom: 5%;
        position: absolute;
        top: 45%;
        left: 12%;
    }
    .Stackerank-Content-Main h1{
            line-height: 100%;
            font-size: 13.2vw;
            opacity: 1;
            background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
            background-clip: border-box;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family:  "space-Groteskb" !important;
            margin-bottom: 4%;
    }
    .Stackerank-Content-Main .Stackpara1{
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:   "space-Grotesksb" !important;
        font-size: 18px;
        margin-bottom: 2%;
    }
    .Stackerank-Content-Main .Stackpara2{
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }

    
    .stackeRank-Form {
        padding-top: 3% !important;
        margin-top: 3% !important;
    }
    .stackeRank-Form-left {
        width: 100vw !important;        ;
        position: relative !important;
        margin-top: 5% !important;
        padding-top: 5% !important;
        text-align: -webkit-center;
    }
    .stackeRank-Form-left img{
        padding-bottom: 5% !important;
        padding-top: 7% !important;
        width: 80% !important;
    }
.stackeRank-p-Mobile{
    font-size: 18px;
    line-height: 90%;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
}

.stacke-inside-box2 {
    border: 1px solid #FF6600 !important;
    width: 45vw;
}
.stacke-inside-box1 {
    border: 1px solid #FF6600 !important;
    width: 45vw;
}
.stacke-Insidecontent-box {
    border: 1px solid #FF6600 !important;
}
.StakeRank-Content h1 {
    font-size: 13.5vw;
    padding-top: 20%;
}
.StakeRank-Content .Stack-content-para1 {
    font-size: 16px;
    letter-spacing: 0.56px;
    margin-left: 2%;
    margin-right: 2%;
}
.StakeRank-Content .Stack-content-para2 {
    font-size: 18px;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    letter-spacing: 1.44px;
    line-height: 170%;
}
.StakeRank-Content .Stack-content-para2-desktop{
    display: none;
}

 
.stacke-boxs-mobile {
    position: relative;
    text-align: -webkit-center;
}
.stacke-inside-box1-mobile {
    // border: 1px solid #FF6600;
    width: 75vw;
    height: auto;
    // border-radius: 20px;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 5%;
}
.gradient-border-mask-product1 {
    display: flow-root;
    position: relative;
    padding: 0% 1%;
    // width: 24vw;
    height: auto;
    overflow: hidden;
  }
  
  .gradient-border-mask-product1::before {
    content: "";
    position: absolute;
    // width: 24vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 2px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
.stacke-inside-box1-mobile img {
    max-width: 100%;
    height: auto;
    width: 50vw;
    padding: 8%;
}
.stacke-inside-box2-mobile {
    width: 75vw;
    height: auto;
    // border-radius: 20px;
    padding-top: 1%;
    padding-bottom: 2%;
    overflow: hidden;
    // border: 1px solid #FF6600;
}
.stacke-inside-box2-mobile img {
    max-width: 100%;
    height: auto;
    width: 46vw;
    padding: 7%;
}
.Stacke-Problem-para1-mobile {
    font-family:   "space-Grotesksb" !important;
    font-size: 18px;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    text-align: center;
}
.Stacke-Problem-para2-mobile {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin-left: 3%;
    margin-right: 3%;
    line-height: 175%;
}
.Stacke-Problem-para22-mobile {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin-left: 3%;
    margin-right: 3%;
    line-height: 170%;
}




        
.stacke-content-box-mobile{
    // border: 1px solid red;
    padding-top: 1%;
    padding-bottom: 2%;
}
.stacke-content-box-mobile1{
    overflow: hidden;
    padding-top: 1%;
    padding-bottom: 0%;
    text-align: -webkit-center;
}

.stacke-Insidecontent-box-mobile {
    border: 1px solid #FF6600;
    border-radius: 20px;
    opacity: 1;
    width: 65vw;
    height: auto;
    margin-bottom: 10%;
}
.stacke-Insidecontent-box-mobile img {
    max-width: 100%;
    height: auto;
    width: 17vw;
    padding-top: 10%;
    padding-bottom: 5%;
}
.stacke-Insidecontent-box-mobile1 {
    border: 1px solid #FF6600;
    border-radius: 20px;
    opacity: 1;
    width: 65vw;
    height: auto;
    margin-bottom: 10%;
}
.stacke-Insidecontent-box-mobile1 img {
    max-width: 100%;
    height: auto;
    width: 17vw;
    padding-top: 10%;
    padding-bottom: 5%;
}
.stacke-Insidecontent-box-mobile .Stacke-content-para1-mobile {
    font-family:   "space-Grotesksb" !important;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    padding-top: 3%;
}
.stacke-Insidecontent-box-mobile1 .Stacke-content-para1-mobile1 {
    font-family:   "space-Grotesksb" !important;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    padding-top: 3%;
}

.stacke-Insidecontent-box-mobile .Stacke-content-para21-mobile {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 3%;
    margin-right: 3%;
    line-height: 160%;
    padding-bottom: 1%;
    padding-top: 1%;
    // border: 1px solid green;
}
.stacke-Insidecontent-box-mobile1 .Stacke-content-para24-mobile1 {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 3%;
    margin-right: 3%;
    line-height: 150%;
    padding-bottom: 1%;
    padding-top: 1%;
    // border: 1px solid green;
}
.stacke-Insidecontent-box-mobile .Stacke-content-para23-mobile {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 3%;
    margin-right: 3%;
    line-height: 150%;
    // border: 1px solid green;
    padding-bottom: 1%;
}

.stacke-content-box-mobile2{
    text-align: -webkit-center;
}

}


@media  only screen and (max-width: 480px){

    .Stacke-Para2-desktop{
        display: none;
    }
    .StackeRank-Main{
        display: none;
    }
    .login-box{
        display: none;
    }
    .btn-container-stacker{
        display: none;
    }
    .send-btn{
        display: none;
    }
    .stacke-content-box{
        display: none;
    }
    .btn-stacke-tablet{
        display: none;
    }
    .stacke-boxs{
        display: none;
    }
    .StackeRank-Main{
        // position: sticky;
        position: relative;
    }
    .StackeRank-inner {
        position: absolute;
        top: 60%;
        left: 20%;
        transform: translate(-20%, -55%);
    }
    .StackeRank-Main img {
        position: relative;
        width: 100%;
        height: 100vh;
    }
    .StackeRank-inner h1 {
        font-size: 12.5vw;
    }
    .StackeRank-inner .Stackpara1 {
        font-family:  "space-Grotesksb" !important;
        font-size: 3.2vw;
    }
    .StackeRank-inner .Stackpara2 {
        font-size:3vw;
    }
    .btn-stacke-mobile{
        color: white;
        padding-top: 1%;
        padding-bottom: 1%;    
    }
    .btn-inner-mobile {
        color: #ffffff;
        padding-top: 3.5%;
        padding-bottom: 3.5%;
        padding-left: 3%;
        padding-right: 2.5%;
        background: #FF6600;
        border-radius: 4px;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .stacke-arrow-mobile{
        color: #ffffff;
        display: static;
        transform: rotate(135deg) scale(0.9);
    }
    .StakeRank-ProblemCase {
        padding-top: 4%;
    }
    .StakeRank-ProblemCase h1 {
        font-size: 12.5vw;
        padding-top: 6%;
    }
    .StakeRank-ProblemCase .Stacke-Para1 {
        font-size: 16px;
        letter-spacing: 0.56px;
        margin-left: 2%;
        margin-right: 2%;
    }
    .StakeRank-ProblemCase .Stacke-Para2 {
        font-size: 18px;
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 2%;
        letter-spacing: 1.44px;
        line-height: 170%;
    
    }


    
    .stacke-boxs-mobile {
        position: relative;
        text-align: -webkit-center;
    }
    .stacke-inside-box1-mobile {
        // border: 1px solid #FF6600;
        width: 75vw;
        height: auto;
        // border-radius: 20px;
        padding-top: 1%;
        padding-bottom: 1%;
        margin-bottom: 5%;
    }
    .gradient-border-mask-product1 {
        display: flow-root;
        position: relative;
        padding: 0% 1%;
        // width: 24vw;
        height: auto;
        overflow: hidden;
      }
      
      .gradient-border-mask-product1::before {
        content: "";
        position: absolute;
        // width: 24vw;
        height: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 15px;
        border: 2px solid transparent;
        background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }
    .stacke-inside-box1-mobile img {
        max-width: 100%;
        height: auto;
        width: 50vw;
        padding: 8%;
    }
    .stacke-inside-box2-mobile {
        width: 75vw;
        height: auto;
        // border-radius: 20px;
        padding-top: 1%;
        padding-bottom: 2%;
        overflow: hidden;
        // border: 1px solid #FF6600;
    }
    .stacke-inside-box2-mobile img {
        max-width: 100%;
        height: auto;
        width: 46vw;
        padding: 7%;
    }
    .Stacke-Problem-para1-mobile {
        opacity: 1;
        font-family:   "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        text-align: center;
    }
    .Stacke-Problem-para2-mobile {
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    .Stacke-Problem-para22-mobile {
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 170%;
    }
    .StakeRank-Content{
        padding-top: 25%;
        margin-top: 12%;

    }
    .StakeRank-Content h1 {
        font-size: 13vw;
        // padding-top: 25%;
    }
    .StakeRank-Content .Stack-content-para1 {
        font-size: 16px;
        letter-spacing: 0.56px;
        margin-left: 2%;
        margin-right: 2%;
    }
    .StakeRank-Content .Stack-content-para2 {
        font-size: 18px;
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 2%;
        letter-spacing: 1.44px;
        line-height: 170%;
    
    }
    .StakeRank-Content .Stack-content-para2-desktop {
        display: none;
    }
        
    .stacke-content-box-mobile{
        // border: 1px solid red;
        padding-top: 1%;
        padding-bottom: 2%;
    }
    .stacke-content-box-mobile1{
        overflow: hidden;
        padding-top: 1%;
        padding-bottom: 0%;
        text-align: -webkit-center;
    }
    .stacke-Insidecontent-box-mobile {
        border: 1px solid #FF6600;
        border-radius: 20px;
        opacity: 1;
        width: 65vw;
        height: auto;
        margin-bottom: 10%;
    }
    .stacke-Insidecontent-box-mobile img {
        max-width: 100%;
        height: auto;
        width: 17vw;
        padding-top: 10%;
        padding-bottom: 5%;
    }
    .stacke-Insidecontent-box-mobile1 {
        border: 1px solid #FF6600;
        border-radius: 20px;
        opacity: 1;
        width: 65vw;
        height: auto;
        margin-bottom: 10%;
    }
    .stacke-Insidecontent-box-mobile1 img {
        max-width: 100%;
        height: auto;
        width: 17vw;
        padding-top: 10%;
        padding-bottom: 5%;
    }
    .stacke-Insidecontent-box-mobile .Stacke-content-para1-mobile {
        font-family:   "space-Grotesksb" !important;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        letter-spacing: 0px;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 3%;
    }
    .stacke-Insidecontent-box-mobile1 .Stacke-content-para1-mobile1 {
        font-family:   "space-Grotesksb" !important;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        letter-spacing: 0px;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 3%;
    }

    .stacke-Insidecontent-box-mobile .Stacke-content-para21-mobile {
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 160%;
        padding-bottom: 1%;
        padding-top: 1%;
        // border: 1px solid green;
    }
    .stacke-Insidecontent-box-mobile1 .Stacke-content-para24-mobile1 {
        font-size: 19px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 150%;
        padding-bottom: 1%;
        padding-top: 1%;
        // border: 1px solid green;
    }
    .stacke-Insidecontent-box-mobile .Stacke-content-para23-mobile {
        font-size: 18px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 150%;
        // border: 1px solid green;
        padding-bottom: 1%;
    }
    .stackeRank-Form-right {
        left: 15vw;
        position: relative;
        width: 75vw;
    }
    .stacke-content-box-mobile2{
        text-align: -webkit-center;
    }
    .stackeRank-H {
        font-size: 6vw;
    }
    .stackeRank-p {
        font-size: 4.5vw;
    }
    .input-color::placeholder {
        font-size: 3.5vw !important;
        padding-bottom: 2% !important;
        opacity: 0.5;
    }
    .send-btn-tablet {
        border-radius: 5px;
        background-color: #FF6600;
        color: white;
        padding-top: 3.5%;
        padding-bottom: 3%;
        padding-left: 3%;
        padding-right: 3%;
        font-size: 16px;
        font-family:  "space-Groteskr" !important;
        font-weight: lighter;
        .rotate-arrow-tablet{
            transform: rotate(140deg);
            color: white;
        }
    }


    
.login-box-mobile .user-box-mobile input {
    width: 95%;
    font-size: 3.5vw;
    font-family:  "space-Groteskr" !important;
    color: white;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #FF6600;
    border-radius: 0px;
    outline: none;
    background: transparent;
    background-color: transparent;
    font-weight: lighter;
    padding: 1% 0% 0.5% 1%;
  }
  .login-box-mobile .user-boxx-mobile textarea {
    width: 95%;
    padding: 1% 0% 0.5% 1%;
    font-size: 3.5vw;
    font-family:  "space-Groteskr" !important;
    color: #ffff;
    margin-bottom: 30px;
    border: none;
    height: 20vw;
    // border: 1px solid #FF6600;
    border-bottom: 1px solid #FF6600 !important;
    border-radius: 0px !important;
    outline: none;
    background: transparent;
    background-color: transparent;
    font-weight: lighter;
    border-radius: 15px;
}
.bg-color {
    font-size: 6vw;
    line-height: 100%;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
    margin-top: 3% !important;
}
.model_patra {
    font-size: 3.5vw;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
}
.Ok-btn-stacker{
    border: 2px solid #000000;
    border-radius: 10px;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 5vw;
    margin-bottom: 2%;
    color: #000000;
    left: 39%;
    position: relative;
    background-color: #FF6600;
}
.gif-set img {
    width: 40%;
    object-fit: contain;
}

.StackeRank-Main-Mobile{
//  display: flex;
 position: relative;
}

.StackeRank-Main-Mobile img {
    // max-width: 100%;
    // height: auto;
    // width: 100vw;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    object-fit: cover;
}
.Data-video-Stacker-Mobile{
    width: 100%;
    height: 65vw !important;
    object-fit: fill;
    padding-bottom: 1%;
}
.banner-up{
    position: absolute;
    top: 8%;
    right: 10%;
    width: 80%;
}
.Stackerank-Content-Main{
    padding-top: 1%;
    padding-bottom: 5%;
    margin-top: 1%;
    margin-bottom: 5%;
    position: absolute;
    top: 48%;
    left: 12%;
}
.Stackerank-Content-Main h1{
        line-height: 100%;
        font-size: 13.2vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskb" !important;
        margin-bottom: 4%;
}
.Stackerank-Content-Main .Stackpara1{
    color: #FF6600;
    opacity: 1;
    letter-spacing: 0px;
    font-family:   "space-Grotesksb" !important;
    font-size: 18px;
    margin-bottom: 2%;
}
.Stackerank-Content-Main .Stackpara2{
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 16px;
}
.stackeRank-Form {
    padding-top: 25% !important;
    margin-top: 8% !important;
}
.stackeRank-Form-left {
    width: 100vw !important;
    position: relative !important;
    margin-top: 15% !important;
    padding-top: 5% !important;
    text-align: -webkit-center;
}
.stackeRank-Form-left img{
    // padding-bottom: 5% !important;
    // padding-top: 7% !important;
    width: 80% !important;
}
.stackeRank-p-Mobile{
    font-size: 18px;
    line-height: 90%;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    padding: 5% 0% 0% 0%;
}










}

@media  only screen and (min-width: 768px){
.StackeRank-Main-Mobile{
    display: none;
}
.stackeRank-p-Mobile{
    display: none;
}
}


.add_svg{
    // padding-bottom: 1%;
    padding: 10% 0% 10% 0%;
}


.modal-contentPro {
    .modal-content {
    background-color: #fcfdfd;
    width: 100%;
}
}
.modal-contentPro {
    .modal-content{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fcfdfd;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 40px;
        outline: 0;
        text-align-last: center;
 }
 .modal-dialog {
    max-width: 742px !important;
    margin: 1.65rem auto;
}
}


// @media  only screen and (min-width: 576px) and (max-width: 1023px){
//         .modal-dialog {
//             max-width: 70%;
//             margin: 1.75rem auto;
//         }
// }
// @media  only screen and (max-width: 576px){
//         .modal-dialog {
//             max-width: 90%;
//             margin: 1.75rem auto;
//         }
// }
@media  only screen and (min-width: 1024px){
        .modal-dialog {
            max-width: 40%;
            margin: 1.75rem auto;
        }
}


// .gif-set img{
//     width: 35%;
//     object-fit: contain;
// }

// .model_patra{
//     font-size: 1.2vw;
//     opacity: 1;
//     font-family:  "space-Groteskr" !important;
// }



@media only screen and (min-width: 1366px) and (max-width: 1566px){
    .stacke-inside-box1 {
        width: 34vw;
    }
    .stacke-inside-box2 {
        width: 34vw;
    }
    .stacke-inside-box1 img {
        max-width: 100%;
        height: auto;
        width: 24vw;
        padding: 5% 8% 5% 8%;
    }
    .stacke-inside-box2 img {
        max-width: 100%;
        height: auto;
        width: 22vw;
        padding: 5% 8% 5% 8%;
    }
    .Stacke-Problem-para2 {
        font-size: 1.3vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 25px;
        margin-right: 25px;
        line-height: 175%;       
        margin-bottom: 0% !important;
    }
    .Stacke-Problem-para22 {
        font-size: 1.3vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 25px;
        margin-right: 25px;
        line-height: 170%;
        margin-bottom: 0% !important;
    }
    .Stacke-Problem-para1 {
        font-size: 2.2vw !important;
        margin-bottom: 8px !important
    }
    


}



.banner-content{
    // border: 1px solid green;
    display: flex;
}
.banner-right{
    // border: 1px solid rgb(7, 81, 130);
    position: absolute;
    top: 14%;
    right: 5%;
    width: 30%;
}

@media only screen and (min-width: 1024px) and (max-width: 1400px){
    .btn-lii-stacker a {
        display: block;
        background-image: linear-gradient(to left, transparent, transparent 50%, #FF6600 50%, #FF6600);
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 0.4s ease;
        border-radius: 10px;
        border: 1px solid #FF6600;
        padding-left: 15px;
        padding-right: 25px;
        color: white;
    }
    .btn-liil-stacker {
        font-size: 1.3vw;
        font-weight: bolder;
        display: inline-block;
        position: relative;
        opacity: 1;
        background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskr" !important;
        border-radius: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        color: white;
    }

    
.btn-lii-stacker a{      
    padding-left: 10px;
    padding-right: 20px;
    .rotatee-stacker{
    transform: scale(1.1)rotateZ(180deg);
    }
}

.btn-lii-stacker a:hover {       
    background-position: 0 0; 
    box-shadow: 0px 8px 6px #00000047;
    border-radius: 10px;
    // border: 1px solid #FF6600;
    color: white;
    .rotatee-stacker{
        transform: scale(1.1)rotateZ(135deg);
        color: white;
        }
}

.btn-liil-stacker:hover {
    color: white;
    font-size: 1.3vw;
    font-weight: bolder;
    display: inline-block;
    position: relative;
    opacity: 1;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskr" !important;
    padding-top: 15px;
    padding-bottom: 15px;
}
}


