.career-container{
    background: #000000;
  }
  
.career-banner {
    align-items: center !important;
}
.careerWhy-tabs {
    display: flex;
    justify-items: center;
    justify-content: space-around;
    color: white;
    gap: 25px;
    padding: 60px;
    background-color: #1a2238;
}
.careermain_image{
    width: 100% !important;
    height: max-content !important;
    // top:-180px !important
}
.framer-career-insideContent{
    position: absolute;
    top: 32%;
    left: 20%;
    transform: translate(-20%, -25%);
  }
  
  .framer-career-insideContent h1{
    // border: 1px solid black; 
    line-height: 100%;
    font-size: 9vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
    padding-bottom: 3%;
  }
  
  .framer-career-insideContent .f-para1{
      // border: 1px solid blue;
      color: #FF6600;
      opacity: 1;
      letter-spacing: 0px;
      font-family:  "space-Grotesksb" !important;
      font-size: 1.8vw;
    }
  .framer-career-insideContent .f-para2{
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 1.5vw;
    opacity: 0.9;
  }
.tabs {
    font-family: "Poppins SemiBold";
    font-size: 18px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
    border: none;
}
.culture_a1{
    width: auto;
    height: 20rem;
    padding: 7%;
    padding-left: 10%;
    margin: 6px;
    border-radius: 10px;
    flex-shrink: 0;
    background-image: linear-gradient(#182878, #000723);
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    /* font-weight: 400; */
    /* line-height: normal; */
    letter-spacing: 3px;
    padding-right: 6%;
}
.culture_a1-mobile{
    width: auto;
    height: auto;
    padding: 6%;
    margin: 5px;
    border-radius: 10px;
    flex-shrink: 0;
    background-image: linear-gradient(#182878, #000723);
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    .culture_img-mobile{
        margin-left: 4%;
        margin-bottom: 2%;
        width: 50px;
    }
    .culture_head{
        align-self: center;
        margin-left: 2%
    }
    .culture_img2-mobile{
        width: 55px;
        margin-left: 4%;
    }
    .culture_img4-mobile{
        margin-left: 4%;
        width: 58px;
    }
}
.border_culture{
    border: 1.4px solid transparent;
    padding: 15px;
    border-radius: 30%;
    margin: 40px;
    height: fit-content;
    border-image: linear-gradient(45deg,#00CCC5, #48A7BC, #FF6600) 1;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    opacity: 999;
}
.border_culture-mobile{
    box-sizing: border-box;
    border: 1.4px solid;
    padding: 15px;
    border-radius: 30%;
    margin: 40px;
    height: fit-content;
    border-radius: 50px; 
    border-image: linear-gradient(45deg,#00CCC5, #48A7BC, #FF6600) 1;
}
.culture_img{
    width: 66px;
}
.culture_img2{
    width: 77px;
}
.culture_img4{
    width: 97px;
}
.Contact-Button_career{
    font-size: 10vw;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    opacity: 1;
    background: transparent linear-gradient(91deg,#FF6600 0%, #00CCC5 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
    transition-property: all;
    transition-duration: 12s;
    transition-timing-function: ease;
    .Irotating{
      color: transparent;
      transition: all 1s ease-in-out;
      justify-content: center;
      display: inline-table;
      display: static;
      position: relative;
      transform: scale(0.2);
  }
  }
  .Contact-Button_career:hover{
    font-size: 10vw;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    opacity: 1;
    background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;  
    // transition-property: all;
    // transition-duration: 12s;
    // transition-timing-function: ease-in;
    transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .Contact-Container_Career{
    // border: 1px solid rebeccapurple;
    width: 100%;
    opacity: 1;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  .Contact-Container-inside_Career{
    font-size: 9vw;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    opacity: 1;
    background: transparent linear-gradient(91deg, #99CC00 0%,#00CCC5 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
    opacity: 1;
  }
  .Contact-Container-inside_Career a{      
    width:100%;
    height: 100%;
    display: block;
    background-image: linear-gradient(to left,
                      transparent,
                      transparent 50%,
                      #35D2D2 50%,
                      #99CC00 );
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all .9s ease-in;    
    opacity: 1; 
  }
  .Contact-Container-inside_Career a:hover {       
    background-position: 0 0; 
    box-shadow: 0px 8px 6px #00000047;
    .Irotating{
      transform: rotateZ(135deg) scale(1.7);
      color: white;
    }
  }
.main_container_career{
    top: 0px;
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    // border-top-left-radius: 150px;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    background:transparent linear-gradient(359deg, #013143 5.64%, #182878 12.20%, #000720 50.60%, #182878 75.20%, #010101 100%);
    opacity: 1;
}
.active-tabs {
    border-bottom: 1px solid transparent;
    color: #ff6a3d;
}
.active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 3px;
    background: #ff6a3d;
}
.tabs:hover {
    cursor: pointer;
    color: #ff6a3d;
}
button {
    border: none;
}
.content-tabs {
    flex-grow: 1;
}
.content {
    width: 100%;
    height: 100%;
    display: none;
}
.content h2 {
    padding: 0px 0 5px 0px;
}
.content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
}
.content p {
    width: 100%;
    height: 100%;
}
.active-content {
    display: block;
}
.align__items {
    text-align: center !important;
}
.static_container_content_career_page {
    border: none;
}
.professional__style .static_container_content:nth-child(4) {
    border: none;
}.careermain_image-tab{
    object-fit: cover;
    width: 50%;
}
@media all and (max-width: 768px) {
    .framer-career-insideContent-tablet{
        position: absolute;
    top: 32%;
    left: 23%;
    transform: translate(-35%, -30%);
    }
    .careerWhy-tabs {
        display: flex;
        justify-items: flex-start;
        justify-content: stretch;
        padding: 60px;
    }
    .framer-main-career{
        position: relative;
// top: -1px;
}
    .framer-main-career img{
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        object-position: 75% 60%;
      }
    .classabv768{
        display: none;
    }
    .careerWhy-tab1 {
        font-family: "Poppins Light";
        font-size: 18px;
        font-weight: 300;
        color: #ffffff;
        padding: 10px 0px;
    }
    .careerWhy-tab1:hover,
    .active {
        cursor: pointer;
        color: #ff6a3d;
    }
    .border_culture{
        opacity: 999;
    }
    .careerWhy-tab1-menu {
        display: flex;
        padding: 0 30px;
        flex-flow: column;
        order: 1;
        padding-top: 16em;
    }
    .careerWhy {
        background-color: #1a2238;
        margin-top: 0em;
    }
    .career_heading {
        line-height: 45px;
        padding: 0 2rem;
        font-size: 1.5rem !important;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .static_container_content_career_page {
        border: none;
    }
}
@media all and (min-width: 960px) {
    .careerWhy-tabs {
        display: flex;
        justify-items: flex-start;
        color: white;
        gap: 25px;
        padding: 60px;
        background-color: #1a2238;
    }
    .static_container_content_career_page {
        border-right: 2px solid #fff !important;
    }
}
.our_culture_section {
    background-image: url("../../fonts/images/evenion_culture.svg");
    transform-origin: 0% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    max-width: 100%;
    padding: 48px 80px 64px 80px;
}
.heading_rotate_img {
    transform: rotate(220deg);
}
.career_section {
    background-image: url("../../fonts/images/opportunities_new.png");
    transform-origin: 0% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    max-width: 100%;
    margin-top: -5rem;
    padding: 84px 80px 24px 80px;
}
.career-banner .home_page_caption_inner h1 {
    color: #ff3c11;
}
.career-banner .home_page_caption_inner p {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 48px;
    color: #153e75;
}
.career-banner .banner-inner > .home_page_caption_inner {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    padding: 0px 80px;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .culture_a1{
        height: 23rem;
        padding: 10%;
        font-size: 12px;
        letter-spacing: 1px;
    .culture_img{
        width: 62px;
    }
    .culture_img2{
        width: 73px;
    }
    .culture_img4{
        width: 85px;
    }
    .culture_head{
        font-weight: 200;
        font-size: 20px;
        line-height: 43px;
      } 
      .culture_para{
        font-size: 14px;
      }
    }
    .career-banner .banner-inner > .home_page_caption_inner {
        position: absolute;
        width: 50%;
        left: 11%;
        top: 50%;
        transform: translate(-12%, -50%);
       
    }
    .border_culture{
        opacity: 999;
    }
    .our_culture_section {
        padding: 48px 24px 64px 24px;
    }
    .career_section {
        padding: 84px 24px 24px 24px;
    }
}
@media all and (max-width: 768px) and (min-width:481px){
    .our_culture_section {
        padding: 36px 24px 84px 24px;
    }
    .framer-career-insideContent-mobile{
        margin-left: 14%;
        top: 50%;
        left: 20% !important;
        width: 80% !important;
    }
    .career_section {
        margin-top: -7rem;
        padding: 84px 24px 24px 24px;
    }
    .career-banner .project-banner-inner {
        position: absolute;
        top: 8%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .border_culture{
        opacity: 999;
    }
    .career-banner .project-banner-inner-1 {
        background: transparent;
        padding: 24px 24px;
        position: absolute;
        width: 100%;
        bottom: 2.5%;
    }
    .framer-career-main-section-tablet{
        position : absolute;

    }
    .btn-lii-mobile {
        padding-top: 1px;
        padding-bottom: 1px;
      }
      .btn-lii-mobile a{
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 9px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: black;
        background: #FF6600;
        border: 1px solid black;
      }
      .btn-liil-mobile{
        font-size: 3.3vw;
        font-family:  "space-Groteskr" !important;
        color: black;
        font-weight: bold;
        background: transparent linear-gradient(91deg, #0c0c0c 0%, #000000 100%) 0% 0% no-repeat padding-box;
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .btn-lii-mobile a .rotatee-mobile{
        color: black;
        display: static;
        transform: rotate(135deg);
      }
      
}
@media all and (min-width: 1400px) {
    .career-banner .banner-inner > .home_page_caption_inner {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
    }
}
@media all and (min-width: 1900px) {
    .career-banner .banner-inner > .home_page_caption_inner {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        padding: 0px 120px;
    }
    .our_culture_section {
        padding: 48px 120px 64px 120px;
    }
    .career_section {
        padding: 96px 120px 24px 120px;
    }
    .career_section .digital_banner_contact_caption {
        position: absolute;
        text-align: right;
        bottom: 14%;
        right: 9%;
        transform: translate(-50%, -50%);
    }
    .career-banner .home_page_caption_inner p {
        font-family: "Poppins-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        color: #153e75;
    }
}
.framer-career-main-section-tablet{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
@media screen and (max-width: 480px) {
    .framer-career-insideContent-mobile{
        position: absolute;
        width: 90%;
        top: 65%;
        left: 36%;
        transform: translate(-35%, -50%);
    }
    .open_oppor{
        font-size: 12vw !important;
    padding-top: 25% !important;
        line-height: 125%;
    }

    }
    .careermbile{
        width: 100%;
        margin: 10px;
        .f-para1-mobile{
            color: #FF6600;
      opacity: 1;
      letter-spacing: 0px;
      font-family:  "space-Grotesksb" !important;
      font-size: 14px;
        }
        .blur-head{
            font-size: 11vw;
        }
    }
    .culture_a1-mobile{
        padding: 6%;
        padding-left: 9%;
        padding-right: 11%;
        .culture_head{
            font-size: 22px;
        }
        .culture_img-mobile{
            width: 17%;
            object-fit: contain;
        }
        .culture_img2-mobile{
            width: 18%;
            object-fit: contain;
            margin-bottom: 3%;
        }
        .culture_img4-mobile{
            width: 19.2%;
            object-fit: contain;
            margin-left: 5%;
        }
    }
    .border_culture-mobile{
        margin:2%;
        padding:2%;
        margin-bottom: 15%;
    }
    .culture_para-mobile {
        font-size: 16px;
        text-align: justify;
        font-family:  "space-Groteskr" !important;
        line-height: 23px;
    }
    
    
