/* footer Section */
footer {
  // background-color: #FFF;
  background: transparent linear-gradient(180deg, #011639 0%, #001220 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 0%;
  box-shadow: 0px 0px 10px 0px grey;

}

.footer-desktop {
  display: none;
}



.footer-mobile {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
  .ColorClass {
    color: #FF6600 !important;
    padding: 0% 6% !important;
    max-width: 100% !important;
    font-size: 20px !important;

}
.p-cl {
  list-style-type: none;
  padding: 0% 10%;
}
.FooterPad{
  margin: 10% 0% 5% 0% !important;
  text-align: left !important;
  // border: 1px solid red;
  padding: 0%;
}
.text-center-mobile{
  width: 100%;
  text-align: center;
}
.nav-bar-mobile {
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
  height: 60%;
  width: 100vw;
  // border: 1px solid blue;
}
.HomeIcon-mobile img {
  max-width: 100%;
  height: auto;
  width: 50%;
  padding: 10% 0% 0% 0%;
}
.Footer-copy-mobile {
  width: 100%;
  padding: 5% 0% 10% 0%;
}
.site-map-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexClCl {
  display: flex;
  width: 100vw;
  padding: 0% 5%;
}

.widClass {
  width: 90vw !important;
  height: auto !important;
  margin: 2% 0% !important;
}

.footer-section .footer-mobile .footer-logo {
  width: 100%;
  text-align: center;
}

.footer-section .footer-mobile .footer-logo img {
  margin: 0 auto;
}

.footer-mobile .footer-social img {
  width: 37px;
  height: 36px;
  margin-right: 15px;
}

.footer-section .footer-mobile .contact-links {
  font-family: "Poppins-Medium";
  margin: 1rem 0rem 0rem;
  color: #0F326C;
  line-height: 36px;
}

.footer-section .footer-mobile .contact-links a {
  color: #0F326C;
}

.footer-section .footer-mobile .contact-links a:hover {
  color: #0F326C;
  text-decoration: none;
}

.footer-section .footer-mobile .contact-links div span {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.footer-section .footer-mobile .contact-links div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-section .footer-mobile .contact-links img {
  width: 37px;
  height: 36px;
  margin-right: 10px;
}

.footer-section .footer-mobile .contact-address {
  margin-top: 2rem;
  font-family: "Montserrat-Regular";
  flex-wrap: wrap;
  padding: 0px;
}

.footer-section .footer-mobile .contact-address div {
  flex-basis: 49%;
  margin: 10px 0px;
  text-align: left;
}

.footer-section .footer-mobile .contact-address h2 {
  color: #1A2238;
  letter-spacing: 0px;
  font-size: 18px;
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
}

.footer-section .footer-mobile .contact-address p {
  display: none;
  color: #606161;
  letter-spacing: 0px;
  text-align: center;
  /* font-size: 18px; */
}










.logofooter {
  text-align: center;
}
.footer-social a .fb:hover{
  border-radius: 50%;
  border: 2px solid #00aced;
  box-shadow: 0 0 6px #00aced;
  transition: all 0.3s ease;
}
.footer-social a .linked:hover{
  border-radius: 50%;
  border: 2px solid #007fb1;
  box-shadow: 0 0 6px #007fb1;
  transition: all 0.3s ease;
}
.footer-social a .twt:hover{
  border-radius: 50%;
  border: 2px solid #00aced;
  box-shadow: 0 0 6px #00aced;
  transition: all 0.3s ease;
}
.footer-social a .insta:hover{
  border-radius: 50%;
  border: 2px solid #bc2a8d;
  box-shadow: 0 0 6px #bc2a8d;
  transition: all 0.3s ease;
}


.social-img {
  display: flex;
  gap: 20px;
  padding: 30px 0px;
}

.mail-phone {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  color: #6A696B;
}

.mail-phone div {
  padding: 10px 0px;
}

.mail-phone div>img {
  vertical-align: middle;
}

.itemsrow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.itemsrow ul {
  list-style-type: none;
  line-height: 2.2em;
}

.itemsrow ul li {
  color: #6A696B;
}

li.list-heading {
  font-family: "Montserrat-Regular";
  font-size: 16px;
  font-weight: normal;
  color: #2B2A2C !important;
  padding-bottom: 5px;
}

.copyright {
  text-align: left;
  padding-top: 0px;
  font-size: 12px;
  color: #fff;
  background: #0D0D0D;
}

.copyright div {
  font-family: "Poppins-Regular";
  font-size: 12px;
  max-width: 100%;
  padding: 2px 60px;
  text-align: center;
}

.copy-line {
  height: 1px;
  background-color: #0f326c;
}
.industry_container{
  display: flex;
  align-items: flex-start;
  gap: 40px;
}
/* Footer Section */

/* Desktop menu */
@media all and (min-width: 960px) {

  /* footer section - desktop */
  footer {
    background-color: #FFF;
    padding: 25px 80px 10px 80px;
    /* transform: skew(0deg, 1deg);
  transform-origin: 0% 100%; */
    margin-top: 0px;
    box-shadow: 0px 0px 10px 0px grey;
    background: transparent linear-gradient(180deg, #011639 0%, #001220 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .footer-desktop {
    display: block;
    /* transform: skew(0deg, -1deg); */
  }

  .footer-mobile {
    display: none;
  }

  /* .footer-section{
  display: flex;
} */
  .footer-social img {
    width: 37px;
    height: 36px;
    margin-right: 15px;
  }

  .footer-section .contact-address {
    margin-top: 2rem;
    font-family: "Montserrat-Regular";
    color: #0F326C;
  }

  .footer_new_container div ul {
    list-style: none;
    line-height: 28px;
    margin-bottom: 0;
  }

  .footer_new_container h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    color: #EBF5F5;
    padding-bottom: 1rem;
    margin-bottom: 0rem;
    font-family:  "space-Grotesksb" !important;
    text-transform: uppercase;
    letter-spacing: 2.11px;
  }

  .footer_link li a,
  .footer_link li {
    font-family:  "space-Groteskr" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #EBF5F5;
  }

  .product_top_3 {
    padding-top: 1.2rem;
  }

  .digi_left {
    padding-left: 1.5rem;
  }

  .footerlogo {
    flex: 1;
  }

  .logofooter {
    text-align: left;
  }

  .footermenu {
    flex: 2;
  }

  .social-img {
    display: flex;
    gap: 20px;
    padding: 30px 0px;
  }

  .mail-phone {
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-weight: normal;
    color: #6A696B;
  }

  .mail-phone div {
    padding: 10px 0px;
  }

  .mail-phone div>img {
    vertical-align: middle;
  }

  .itemsrow {
    display: flex;
    justify-content: space-between;
  }

  .itemsrow ul {
    list-style-type: none;
    line-height: 2.2em;
  }

  .itemsrow ul li {
    font-family: "Montserrat-Regular";
    font-size: 16px;
    color: #6A696B;
  }

  li.list-heading {
    font-family: "Montserrat-Regular";
    font-weight: bold;
    font-size: 16px;
    color: #2B2A2C !important;
    padding-bottom: 30px;
  }

  .copyright {
    text-align: left;
    padding-top: 0px;
    font-size: 13px;
    color: #fff;
    background: #0D0D0D;
    transform: skew(0deg, 0deg);
  }

  .copyright div {
    max-width: 100%;
    padding: 2px 80px;
    text-align: left;
  }

  .copy-line {
    height: 1px;
    background-color: #0f326c;
  }

  /* footer -desktop End */
  /* Desktiop View end */
}

.footer-desktop .d-flex.justify-content-between.contact-address div {
  flex-basis: 25%;
}

.footer-section .footer-desktop .contact-address h2 img {
  width: 20px;
  height: 20px;
  /* margin-left: 10px; */
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.footer-section .footer-mobile .contact-address h2 img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

@media all and (max-width: 390px) {
  .footer-section .footer-mobile .contact-links a {
    font-size: 18px;

  }

}

@media all and (max-width: 370px) {
  .footer-section .footer-mobile .contact-links a {
    font-size: 18px;
  }
}

@media all and (max-width: 350px) {
  .footer-section .footer-mobile .contact-links a {
    font-size: 18px;
  }
}

@media all and (max-width: 330px) {
  .footer-section .footer-mobile .contact-links a {
    font-size: 12px;
  }
}


@media all and (min-width: 1900px) { 
footer {
  padding: 25px 120px 10px 120px;
}
.footer_new_container h2 {
  letter-spacing: 2.11px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  color: #EBF5F5;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
  font-family:  "space-Grotesksb" !important;
  text-transform: uppercase;
}
.footer_link li a,
.footer_link li {
  font-family:  "space-Groteskr" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #EBF5F5;
}


}










.clSiteMap{
  color: #EBF5F5;
}
.colorWhite{
  color: #FFFFFF;
}
.BackBtn{
  letter-spacing: 0px;
  color: #878A8A;
  margin: 0%;
  cursor: pointer;
  animation-duration: 2s;
  animation-iteration-count: 1;
  transform-origin: bottom;

}

.BackBtn:hover{
  animation-name: bounceArrow;
  animation-timing-function: ease;
  
}
@keyframes bounceArrow {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

.Footer-copy{
  display: flex;
  width: 100%;
  padding: 2% 0%;
}
.CopyRight{
  // border: 1px solid red;
  width: 70%;
  display: flex;
  align-items: center;
}
.site-map{
  // border: 1px solid red;
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.HomeIcon img {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding: 10% 0%;
}

.Footer-line{
  border-bottom: 1px solid #FF6600;
  width: 100%;
  padding: 10px 0px;
}

.iconGap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 15vw;
}

.flexClass{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.widCl{
  width: 20vw !important;
  height: auto !important;
  margin: 2% 0% !important;
}

.btnMail{
img {
  max-width: 100%;
  height: auto;
  width: 50%;
}
  background: transparent;
  .BeforeEmail{
    display: block;
  }
  .AfterEmail {
    display: none;
  }
}

.btnMail:hover{
  background: transparent;
  .AfterEmail{
    display: block;
  }
  .BeforeEmail{
    display: none;
  }
}
.ColorCl{
  color: #FF6600;
}


.Contaoner-main{
  position: relative;
  // border: 1px solid blue;
}
.Top-Container{
    border: 3px solid #FF6600;
    border-radius: 200px;
    height: 175px;
    width: 175px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: 84%;
    bottom: 60px;
}
.Top-Container:hover{
  border: 3px solid #FF6600;
  background: #FF6600 10% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 200px;
  height: 175px;
  width: 175px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: 84%;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease-in;
.Top-Icon{
    display: inline-block;
    top: 38%;
    position: absolute;
    left: 42%;
    color: #FFF;
    scale: 4;
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: ease;
  }
}
.Top-Icon {
  display: inline-block;
  top: 38%;
  position: absolute;
  left: 42%;
  color: #FF6600;
  scale: 4;
}
.rotateArrow{
transform: rotateZ(90deg);
}




.Bottom-Container{
  // border: 1px solid red;
}

.btn-container-bot{
  position: relative;
  overflow:hidden;
}
.btn-lii-bot {
  font-size: 2vw;
  display: inline-block;
  position: relative;
  opacity: 1;
  background: #fff;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important;
  display: inline-block;
  position: relative;
  left: 83%;
}

.btn-lii-bot a{      
display: block;
background-image: linear-gradient(to left,
                  transparent,
                  transparent 50%,
                  #FF6600 50%,
                  #FF6600);
background-position: 100% 0;
background-size: 200% 100%;
transition: all .9s ease-in;        
border-radius: 10px;
border: 2px solid #FF6600;
padding-left: 20px;
padding-right: 30px;
color: #1E0101;
.rotatee-bot{
  color:  #0F326C;;
  transition: all 0.9s ease-in-out;
  justify-content: center;
  display: inline-table;
  display: static;
  position: relative;
  transform: scale(0.9);
}
}
.btn-lii-bot a:hover{       
background-position: 0 0; 
box-shadow: 0px 8px 6px #00000047;
border-radius: 10px;
border: 2px solid #FF6600;
color: #1E0101;
.rotatee{
  transform: rotateZ(150deg);
  color:  #1E0101;
}
}

.btn-liil-bot{
font-size: 1.2vw;
display: inline-block;
position: relative;
opacity: 1;
background: transparent linear-gradient(91deg, #0F326C 0%, #0F326C 100%) 0% 0% no-repeat padding-box;
background-clip: border-box;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-family:  "space-Groteskr" !important;
border-radius: 10px;
padding-bottom: 12px;
// border: 1px solid orange;
.rotatee-bot{
  color: #0F326C;
  transition: all 1s ease-in-out;
  justify-content: center;
  display: inline-table;
  display: static;
  position: relative;
  transform: scale(0.9);
}
}

.btn-liil-bot:hover{
font-size: 1.2vw;
display: inline-block;
position: relative;
opacity: 1;
background: transparent linear-gradient(91deg, #1E0101 0%, #1E0101 100%) 0% 0% no-repeat padding-box;
background-clip: border-box;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-family:  "space-Groteskr" !important; 
  // box-shadow: 0px 8px 6px #00000047;
  .rotatee-bot{
    transform: rotateZ(150deg);
    color:  #1E0101;
  }
}






















@media  only screen and (max-width: 481px) {
.Top-Container{
  display: none;
}
.Top-Icon{
  display: none;
}
}


