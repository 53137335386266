.Main-AtEvenion{
    background-color: #FF6600;
}
.Main-AtEvenion-inside{
    display: flex;
}
.left-AtEvenion{
    width: 50vw;
    padding: 5% 5%;
}
.right-AtEvenion{
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inside-right-AtEvenion{
    background-image: url("../../fonts/taas/at_evenion.png");
    background-size: cover;
    background-position: center center;
    height: 28vw;
    width: 40vw;
    filter: drop-shadow(0 13px 20px black);
    animation: animateblob 5s ease-in-out infinite ;
}
@keyframes animateblob {
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50%{
        border-radius: 70% 30% 40% 60%/40% 70% 30% 60%;
    }
    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.left-AtEvenion p{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 0% 0%;
    line-height: 200%;
    cursor: pointer;
    letter-spacing: 0.96px;
}
.left-AtEvenion h2{
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.left-AtEvenion h1{
    padding: 0% 0% 0% 0%;
    font-size: 6vw;
    text-align: left;
    color: white;
    font-family:  "space-Groteskb" !important;
}
.right-AtEvenion img {
    max-width: 100%;
    height: auto;
    width: 85%;
    top: 10%;
    position: relative;
}


.TaaS-BtnContainer{
    padding: 0% 0% 5% 0%;
}
.TaaS-BtnContainer a{
    font-size: 6vw;
    color: white;
    font-family:  "space-Groteskb" !important;
    width: 100%;
    height: 100%;
    display: block;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 0.9s ease-in;
    opacity: 1;
    padding: 0% 5%;
    .Irotating-TaaS{
        transform: rotateZ(0deg);
        transition: all 0.3s ease-in;
        color: white;
        text-shadow: 0px 7px 6px #0000004D;
      }
}
.TaaS-BtnContainer a:hover {       
    background-position: 0 0; 
    .Irotating-TaaS{
      transform: rotateZ(-45deg);
      color: white;
    }
  }

.TaaS-Btn{
    filter: drop-shadow(1px 3px 3px black);
}









@media  only screen and (max-width: 480px) {
    .Main-AtEvenion{
        display: none;    
    }
    .Main-AtEvenion-mobile{
        background-color: #FF6600;
    }
    .Main-AtEvenion-inside-mobile{
        text-align: -webkit-center;
    }
    .left-AtEvenion-mobile{
        width: 90vw;
        padding: 5% 5%;
    }
    .right-AtEvenion-mobile{
        width: 70vw;
    }
    
    .left-AtEvenion-mobile p{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 0% 0%;
        line-height: 150%;
        cursor: pointer;
        letter-spacing: 0.96px;
    }
    .left-AtEvenion-mobile h2{
        font-size: 18px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
    }
    .left-AtEvenion-mobile h1{
        padding: 0% 0% 0% 0%;
        font-size: 12vw;
        text-align: left;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    .right-AtEvenion-mobile img {
        max-width: 100%;
        height: auto;
        width: 100%;
        top: 0%;
        position: relative;
    }
    
    .TaaS-BtnContainer-mobile{
        padding: 10% 0% 5% 0%;
    }
    .TaaS-BtnContainer-mobile a{
        font-size: 12vw;
        color: white;
        font-family:  "space-Groteskb" !important;
        width: 100%;
        height: 100%;
        display: block;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 0.9s ease-in;
        opacity: 1;
        padding: 0% 5%;
        text-align: -webkit-center;
        .Irotating-TaaS{
            transform: rotateZ(-45deg);
            transition: all 0.3s ease-in;
            color: white;
            text-shadow: 0px 7px 6px #0000004D;
          }
    }
      
.right-AtEvenion-mobile1{
    // width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inside-right-AtEvenion-mobile1{
    background-image: url("../../fonts/taas/at_evenion.png");
    background-size: cover;
    background-position: center center;
    height: 55vw;
    width: 70vw;
    filter: drop-shadow(0 13px 20px black);
    animation: animateblob 5s ease-in-out infinite ;
}
@keyframes animateblob {
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50%{
        border-radius: 70% 30% 40% 60%/40% 70% 30% 60%;
    }
    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}
    
    .TaaS-Btn{
        filter: drop-shadow(1px 3px 3px black);
    }
    
  }
  
  
  
  @media  only screen and (min-width: 768px){
    .Main-AtEvenion-mobile{
        display: none;
    }
  }
  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .Main-AtEvenion{
        display: none;    
    }
    .Main-AtEvenion-mobile{
        background-color: #FF6600;
    }
    .Main-AtEvenion-inside-mobile{
        text-align: -webkit-center;
    }
    .left-AtEvenion-mobile{
        width: 90vw;
        padding: 5% 5%;
    }
    .right-AtEvenion-mobile{
        width: 70vw;
    }
    
    .left-AtEvenion-mobile p{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 0% 0%;
        line-height: 150%;
        cursor: pointer;
        letter-spacing: 0.96px;
    }
    .left-AtEvenion-mobile h2{
        font-size: 18px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
    }
    .left-AtEvenion-mobile h1{
        padding: 0% 0% 0% 0%;
        font-size: 12vw;
        text-align: left;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    .right-AtEvenion-mobile img {
        max-width: 100%;
        height: auto;
        width: 100%;
        top: 0%;
        position: relative;
    }
    
    .TaaS-BtnContainer-mobile{
        padding: 10% 0% 5% 0%;
    }
    .TaaS-BtnContainer-mobile a{
        font-size: 12vw;
        color: white;
        font-family:  "space-Groteskb" !important;
        width: 100%;
        height: 100%;
        display: block;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 0.9s ease-in;
        opacity: 1;
        padding: 0% 5%;
        text-align: -webkit-center;
        .Irotating-TaaS{
            transform: rotateZ(-45deg);
            transition: all 0.3s ease-in;
            color: white;
            text-shadow: 0px 7px 6px #0000004D;
          }
    }
      
    
    .TaaS-Btn{
        filter: drop-shadow(1px 3px 3px black);
    }
  }


      
@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .left-AtEvenion h2 {
        font-size: 2.2vw;
    }
    .left-AtEvenion h1 {
        font-size: 8vw;
    }
    .left-AtEvenion p {
        font-size: 1.6vw;
    }

    .TaaS-BtnContainer a{
        font-size: 8vw;
        color: white;
        font-family:  "space-Groteskb" !important;
        width: 100%;
        height: 100%;
        display: block;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 0.9s ease-in;
        opacity: 1;
        padding: 0% 5%;
        .Irotating-TaaS{
            transform: rotateZ(-45deg);
            transition: all 0.3s ease-in;
            color: white;
            text-shadow: 0px 7px 6px #0000004D;
          }
    }
}
