




// New fonts added 
@font-face {
  font-family: "space-Groteskb";
  src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Bold.ttf") format(truetype);
}


@font-face {
  font-family: "space-Groteskr";
  src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Regular.ttf") format(truetype);
}


@font-face {
  font-family: "space-Groteskl";
  src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Light.ttf") format(truetype);
}


@font-face {
  font-family: "space-Groteskm";
  src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Medium.ttf") format(truetype);
}


@font-face {
  font-family: "space-Grotesksb";
  src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-SemiBold.ttf") format(truetype);
}


.font-bold{
  font-family: 'space-Groteskb' !important;
}
.font-regular{
  font-family: 'space-Groteskr' !important;
}
.font-light{
  font-family: 'space-Groteskl' !important;
}
.font-medium{
  font-family: 'space-Groteskm' !important;
}
.font-semibold{
  font-family: 'space-Grotesksb' !important;
}



* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.section-1 {
  background-color: #5A558D;
  padding: 1rem 1.5rem;
}

.section1-heading {
  font-family: "Montserrat-Bold";
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.section1-heading span {
  color: #FFB60A;
}

.menu-img {
  text-align: center;
  margin: 0 auto;
}

.section1-para {
  color: #fff;
  text-align: center;
  line-height: 34px;
}

/* .section1-para{display: none;} */
/* .section1-right-side img { width: 100%;} 
.menu-img img{width: 100%;} */
/* img {
  max-width: 100%;
  height: auto;
} */

.main-blogs {
  padding: 1rem 1.5rem;
  background-color: #FBFBFB;
}

.blog-banner {
  text-align: center;
  margin: 0 auto;
}

.blog-banner-left {
  flex: 1;
}

.blog-banner-right {
  flex: 1;
  order: 1;
}

.blog-head {
  font-family: "Montserrat-Regular";
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  padding: 1rem 0;
  color: #707070;
}

.blog-head-center {
  display: block;
  text-align: center;
  padding: 1rem 0;
}

.head__visible {
  display: none;
}

.blog-para {
  font-family: "Montserrat-Regular";
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  padding: 1rem 0;
  color: #6A696B
}

.about-us_sub_heading {
  font-family: "Poppins-Medium";
  font-size: 42px;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0em;
  /* text-align: left; */
}

.sub-heading {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffff;
}

.sub-sub-heading {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: right;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: right;

}

.blog-learn {
  font-family: "Montserrat-Regular";
  font-size: 16px;
  font-weight: normal;
  color: #FF6A3D;
  text-align: right;
  padding: 40px 80px;
}

.ssd_static_banner {
  background-color: #219EBC;
}

.aboutus_ban_color_1 {
  background-color: #fff9eb;
}

.aboutus_ban_color_2 {
  background-color: #EEF9FC;
}

.section1-right-side {
  text-align: center;
}

/* Tablet menu */
@media all and (min-width: 700px) {
  .section-1 {
    padding: .5rem 1rem;
  }

  .section1-heading {
    font-size: 36px;
    text-align: center;
  }

  .section1-right-side {
    text-align: center;
    max-width: 70%;
    margin: 0px auto;
  }

  .section1-para {
    font-family: "Montserrat-Regular";
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
    line-height: 30px;
    padding-top: 10px;
    display: block;
    text-align: left;
  }

  .main-blogs {
    padding: 20px 30px;
  }

  .blog-banner {
    text-align: center;
    margin: 0 auto;
  }

  .blog-banner-right {
    order: 2;
  }

  .blog-banner-left {
    order: 1;
  }

  .section1-right-side {
    text-align: center;
  }
}

/* Desktop menu */
@media all and (min-width: 960px) {

  .section-1 {
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    height: 450px;
  }

  .section1-left-side {
    flex: 1;
  }

  .section1-right-side {
    flex: 1;
    text-align: right;
  }

  .section1-heading {
    font-family: "Montserrat-Bold";
    font-size: 3rem;
    text-align: left;
  }

  .section1-para {
    font-family: "Montserrat-Regular";
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
    line-height: 30px;
    padding-top: 10px;
    display: block;
    text-align: left;
  }

  .main-blogs {
    /* background-color: #FBFBFB; */
    padding: 60px 60px 0px 60px;
    display: block;
  }

  .blog-banner {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding-bottom: 2.5rem;
  }

  .blog-banner-left {
    flex: 1;
    text-align: left;
    order: 2;
  }

  .blog-banner-right {
    flex: 1;
    order: 1;
    text-align: left;
  }

  .align__style {
    text-align: center !important;
  }

  .blog-para {
    color: #707070;
    /* line-height: 1.5rem; */
  }

  .blog-head-center {
    display: none;
  }

  .head__visible {
    display: block;
  }

  .static_banner_container {
    padding: 3rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* grid-gap: 4rem; */
  }

  .static_banner_container .remove-border {
    border: none;
  }

}

/* Desktiop View end */


/* New Version */
.about_us_content {
  /* background-image: url('../../fonts/images/about_us_back.png');
  background-repeat: no-repeat;

  background-size: cover;
  background-position: bottom; */
  padding: 72px 0px 32px 0px;
}

.about_us_para p {
  font-family: "Poppins-Medium";
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  line-height: 40px;
}

.about_us_para p span {
  font-family: "Poppins-Medium";
  font-size: 18px;
  color: #F05F30;
  font-weight: 500;
  /* line-height: 48px; */
}


.about_approach_container {
  position: relative;
  text-align: center;
  color: white;
  background-color: #000;
  margin-top: -1px;
}

.about_approach_container>img {
  width: 100%;
}

.approach_centered {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
}

.approach_heading {
  margin: 0 !important;
  padding: 0 6rem 0 0;
}

.approach_heading img {
  transform: rotate(180deg);
  margin-right: -4.5rem;
}

.approach_text {
  padding: 3rem 6rem;
  text-align: left;
}

.right_box {
  text-align: right;
  padding-right: 1rem;
}

.our_team_heading {
  font-family: "Poppins-Regular";
  font-size: 48px;
  color: #fff;
}

.our_team_designation {
  font-family: "Poppins-Regular";
  font-size: 36px;
  color: #F05F30;
}

.our_team_para {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: #fff;
}

.about_us_container {
  /* background-image: url('../../fonts/images/our_approach_back_1.png'); */
  background-image: url('../../fonts/images/ourapproach_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  margin-top: 0rem;
  padding: 48px 80px 124px 80px;
}
.approach_div{
  padding-bottom: 84px;
  padding-top: 0px;
}
.content_center{
  align-items: center;
}
.sub_head_desk{
  display: block;
}
.sub_head_mob{
  display: none;
}
.proj_banner_head h2.abt_banner_line_height{
  line-height: 30px;
}

.approach_para{
font-family: 'Poppins-Regular';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 27px;
text-align: justify;
padding: 0px 0px 60px 0px;
/* margin: 0 auto; */
max-width: 60%;
float: right;
}

/* .banner-inner > .about_us_banner { */
  /* width: 35% !important;
  left: 9.5% !important; */
/* } */

.heading_img_abt {
  margin-left: 0;
  margin-right: -3.7rem;
  vertical-align: -webkit-baseline-middle;
}

/* ipad Air */
@media (min-width: 820px) and (max-width: 920px) { 
  .banner-inner > .about_us_banner{
    width: 45% !important;
    left: 9.5% !important;
  }
  .about_us_container{
    padding: 48px 24px 124px 24px;
  }
  .team_container_back{
    padding: 62px 24px;
  }
}


@media all and (max-width: 768px) {
  /* @media all and (max-width: 1920px) { */
    .about-background-style {
      background: #225b99;
    }
    
    .color_white {
      color: #FFF;
    }
    .about_us_content{
      padding: 24px 0px 32px 0px;
    }
    .approach_text {
      padding: 3rem 1rem;
    }
    
    .approach_centered {
      position: absolute;
    top: 10%;
    left: 0%;
    transform: translate(0%, 0%);
  }

  .about_us_container {
    padding: 24px 24px 84px 24px;
    background-position: bottom;
  }
  
  .approach_images {
    height: auto;
    max-width: 100%;
  }
  .about_us_para p {
    font-size: 14px;
    line-height: 30px;
  }
  .about_us_para p span {
    font-size: 14px;
  }
  .approach_para{
    font-size: 14px;
    max-width: 100%;
    text-align: left;
    hyphens: auto;
    padding: 0px 0px 0px 0px;
    margin-bottom: 24px;
  }
  
  .approach_div, .content_center{
    padding: 24px 0px !important
  }
  .approach_div .col-md-9, .content_center .col-md-9{
    padding: 0px !important;
  }
  .sub_head_desk{
    display: none;
  }
  .sub_head_mob{
    display: block;
  }
  
  .image{
    max-width: 31.5%;
    height: auto;
    vertical-align: middle !important;
  }
  
  .image_1{
    max-width: 25%;
    height: auto;
  }
  .heading_img_1{
    margin-left: 0;
    margin-right: -3.5rem;
    vertical-align: middle;
  }
  
  .our_team_img .image_1 {
    max-width: 47.54%;
    height: auto;
  }
  .heading_img_abt {
  margin-left: 0;
  margin-right: -2.8rem;
  vertical-align: middle;
}



}


@media (min-width: 770px) and (max-width: 1200px) { 
  .our_leader_box img{
    max-width: 90%;
  }
}




@media screen and (min-width: 1900px) {
  .about_us_container {
    margin-top: 0rem;
  }
  .banner-inner > .about_us_banner {
    width: 100%;
    left: 50%;
  }
  .about_us_container{
    padding: 48px 120px 124px 120px;
  }
  .approach_para {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    text-align: justify;
    padding: 0px 0px 60px 0px;
    margin: 0 auto;
    max-width: 80%;
  }
  .proj_banner_head h2.abt_banner_line_height {
    line-height: 48px;
  }
  
}

@media screen and (min-width:768px) and (max-width:819px)  { 
  .about_us_container{
    padding: 48px 24px 124px 24px;
  }
}












/* STARTED NEW FROM HERE  */



.main-Class{
  background-image: linear-gradient(to right, #010203, #0b192a);}

a:link {
  text-decoration: none; 
  // color: white;
  color: #0F326C;
}

a:hover{
  color:rgb(187, 187, 187)
}

/* making the first page sticky--> */

.main_container{
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: -1;
}
@media (max-width: 100%) {
  .main_container {
    height: 100vh;
    width: 100vw;
  }
}

/* adding img  */


.main_container1{ 
  top: -1px;
  position: relative;
  left: 0px;
  width: 100%;
  height: auto;

  border-top-right-radius: 12vw;
  border-top-left-radius: 12vw;
  background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  // top: -1px;
}





// newly adding css
.Our-Approach{
  // border: 1px solid red;
}
.Our-Approach-Ex-Container{
  // border: 1px solid black;
  transform: translate(0%, 0%);
  padding-top: 5%;
  padding-bottom: 0%;
  margin-bottom: 0%;
}
.Our-Approach-Ex-Container h1{
  // border: 1px solid blue;
  font-size: 10vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 2%;
}
p.Our-p{
  font-size: 1.4vw;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
}
p.Our-pa{
  font-size: 2vw;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
}
p.Our-paa{
  font-size: 1.4vw;
  text-align: center;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 7%;
  padding-right: 7%;
}


input[name="examples"] {
	appearance: none;
	-webkit-appearance: none;
  width: 25px;
  height: 25px;
	border: 2px solid #FF6600;
	border-radius: 50%;
	margin-right: 10px;
  margin-bottom: 10px;
	background-color: transparent;
	position: relative;
	top: 6px;
}

input[name="examples"]:checked::before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	background-color: #fff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: appear 0.8s;
}

.Our-Toggle{
  padding-top: 1%;
  margin-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
  padding-left: 9%;
  // border: 1px solid red;
}
.Our-Toggle-img{
  border-radius: 50px;
}
.new-class{
  z-index: 2;
  top: 0%;
  height: 20px;
  width: 20px;
  position: relative;
}


.toggle-newww{
  padding-top: 10%;
  padding-bottom: 35%;
  margin-top: 10%;
  margin-bottom: 10%;
}
.both{
  // width:200px;
  // height:200px;
  position:absolute;
}
#onee{
  // border: 1px solid red;
  width: 50%;
  z-index: 1;
  position: absolute;
  opacity: 0.5;
}
#twoo{
  z-index: 2;
  // border: 1px solid blue;
  left: 100px;
  top: 100px;
  position: relative;
  opacity: 1;
}

.Our-Toggle-pa{
  color: rgb(255, 102, 0);
  font-size: 2vw;
}
.Our-Toggle-paa{
  color: rgb(255, 255, 255);
  font-size: 1.2vw;
}




.Our-Stories{
  // border: 1px solid green;
  
}
.Our-Stories-Ex-Container{
  transform: translate(0%, 0%);
  padding-top: 5%;
  padding-bottom: 1%;
  margin-top: 2%;
  margin-bottom: 1%;
  
}
.Our-Stories-Ex-Container h1{
  // border: 1px solid blue;
  font-size: 11vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 0%;
  padding-bottom: 2%;
}
p.Our-Stories-p{
  font-size: 1.4vw;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
  letter-spacing: 1.44px;
}
p.Our-Stories-pa{
  font-size: 2vw;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
}
p.Our-Stories-paa{
  font-size: 1.4vw;
  text-align: center;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 5%;
  padding-left: 5
}
@media  only screen and (min-width: 1024px) {
    .LeaderShip-Container-tablet{
    display: none;
  }
   .Our-Toggle-tablet{
      display: none;
    }
    .LeaderShip-mobile{
      display: none;
    }
 }
@media  only screen and (min-width: 769px) { 
  // .Our-Toggle-tablet{
  //   display: none;
  // }
  .Our-Stories-Icon-tablet{
    display: none;
  }
  // .LeaderShip-Container-tablet{
  //   display: none;
  // }
  .Our-Team-Container-New-tablet{
    display: none;
  }
  .Our-Team-ContentImages-tablet{
    display: none;
  }
  // .Contact-Container-tablet{
  //   display: none;
  // }
}
@media  only screen and (max-width: 480px) { 
  .Our-Toggle-tablet{
  display: none;
}
.Our-Stories-Icon-tablet{
  display: none;
}
.LeaderShip-Container-tablet{
  display: none;
}
.Our-Team-Container-New-tablet{
  display: none;
}
.Our-Team-ContentImages-tablet{
  display: none;
}
.Contact-Container-tablet{
  display: none;
}
.OurTeam-Grid-tablet-Conatiner{
  display: none;
}
.We-Evenion-Container{
  display: none;
}




}
// making the mobile display none-->
@media  only screen and (min-width: 769px) {
  .framer-about-us-main-section-mobile{
  display: none;
}
 }
@media  only screen and (min-width: 481px) { 
  .Our-Stories-Icon-mobile{
    display: none;
  }
//   .LeaderShip-mobile{
//   display: none;
// }
.Photos-mobile{
  display: none;
}
.Photos-mobile1{
  display: none;
}
.Contact-Container-mobile{
  display: none;
}

.Our-Team-Container-New-mobile{
    display: none;
}
.Our-Team-Content-mobile{
  display: none;
}
.We-Evenion-Container-mobile{
  display: none;
}
.LeaderShip-Container-tablet {
  display: none;
}

}

@media  only screen and (min-width: 481px) and (max-width: 768px) { 
  .box-border{
    display: none;
  }
  .framer-about-us-main-section {
    display: none;
  }
  .framer-about-us-main-section-tablet {
    display: none;
  }
  .framer-about-us-main-section {
    display: none;
  }
  .LeaderShip-Container{
    display: none;
  }
  .Our-Team-Container-New{
    display: none;
  }
  .Our-Team-Content{
    display: none;
  }
  .Contact-Container{
    display: none;
  }

}




.Our-Stories-Icon{
  // border: 1px solid yellow;
  padding-top: 1%; 
  margin-top: 1%; 
}
.Our-Stories-Icon-container{
  // border: 1px solid blue;
  width: 100%;
  height: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}
.Our-Stories-IconB{
  border: 1px solid #FF6600;
  text-align: center;
  border-radius: 10%;
  padding: 1%;
  margin: 1%;
  flex-grow: 1;
  flex-basis: 200px;
}

.Our-Stories-IconB p{
  color: white;
  display: grid;
  font-size: 1.2vw;
  text-align: center;
  flex-grow: 1;
  flex-basis: 200px;
}

.Our-Team-Container-New{
  // border: 1px solid red;
  padding-top: 3%;
  padding-bottom: 2%;
  margin-top: 1%;
  margin-bottom: 0%;
  display: flex;
  overflow: hidden;
  background-image: url("../../fonts/svg/team_circle\ \(1\).svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.Our-Team-Content{
  // border: 1px solid green
}
.Our-Team-Content h1{
  font-size: 11vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 2%;
  text-shadow: 0px 5px 9px #000000FC;
}
.Our-Team-Content .Our-Team-pa{
  font-family:  "space-Groteskb" !important;
  font-size: 1.2vw;
  letter-spacing: 0.2rem;
  color: #F1F9F9;
  opacity: 1;
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
}
.Our-Team-Content .Our-Team-paa{
  font-family:  "space-Groteskb" !important;
  font-size: 1.2vw;
  letter-spacing: 0.2rem;
  color: #F1F9F9;
  opacity: 1;
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
}
.Our-Team-Content .Our-Team-paaa{
  font-family:  "space-Groteskb" !important;
  font-size: 1.2vw;
  letter-spacing: 0.2rem;
  color: #F1F9F9;
  opacity: 1;
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
}
.Our-Team-ContentImages{
  position: absolute;
  overflow: hidden;
  left: 60%;
  transition: all 1.7s ease-out;
  padding-top: 5%;
    padding-bottom: 0%;
  }
  
  .Our-Team-ContentImages:hover{
    position: absolute;
left: 50%;
background-position: 100% 0;
background-size: 200% 100%;
transition: all 1.7s ease-in;   
}
.We-Evenion-Container{
  overflow: hidden;
  transform: translate(0%, 0%);
  padding-top: 5% !important;
  padding-bottom: 2% !important;
  margin-top: 5% !important;
  margin-bottom: 0% !important;
}
.We-Evenion-Container h1{
  font-size: 11vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 2%;
  text-shadow: 0px 5px 9px #000000FC;
}
.We-Evenion-pa{
  font-size: 1.8vw;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 0%;
  padding-bottom: 0%;
}



.Img-Wrapper{
  display: flex;
  gap: 1%;
  padding-top: 5%;
  padding-bottom: 2%;
  margin-bottom: 2%;
  margin-top: 5%;
  width: 100%;
  justify-content: space-between;
}

.Evenion-Img2 {
  background-image: url("../../fonts/gallery/18.jpg");
  width: 500px;
  height: 225px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
 
}

.Evenion-Img1 {
  border-radius: 50%;
  width: 250px;
  height: 230px;
  background-image: url("../../fonts/gallery/4.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.Evenion-Img3 {
  background-image: url("../../fonts/gallery/11.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  width: 500px;
  height: 225px;
  
}

.Evenion-Img5 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/5.png");
  object-fit: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 230px;
}

.Evenion-Img4 {
  background-image: url("../../fonts/gallery/16.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 500px;
  height: 225px;
}
.Evenion-Img15 {
  background-image: url("../../fonts/gallery/15.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 500px;
  height: 225px;
}


.Evenion-Img3 img{
object-fit: cover;
border-radius: 100%;
}
.Evenion-Img4 img{
object-fit: cover;
border-radius: 25% 25% 25% 25% / 42% 39% 34% 31%;
}
.Evenion-Img15 img{
object-fit: cover;
border-radius: 25% 25% 25% 25% / 42% 39% 34% 31%;
}
.Evenion-Img5 img{
  object-fit: cover;
  border-radius: 100%
}
.Img-Wrapper2{
  overflow: hidden;
  display: flex;
  gap: 1%;
  padding-top: 2%;
  padding-bottom: 10%;
  margin-bottom: 5%;
  margin-top: 2%;
  justify-content: space-between;
}

.Evenion-Img7 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/7.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 230px;
  // border: 1px solid black;
  
}

.Evenion-Img6 {
  background-image: url("../../fonts/gallery/6.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  width: 500px;
  height: 225px;
  // border: 1px solid black;
  
}


.Evenion-Img9 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/8.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 230px;
  // border: 1px solid black;

}
.Evenion-Img8 {
  // border: 1px solid black;
  background-image: url("../../fonts/gallery/17.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  width: 500px;
  height: 225px;
}

.Contact-Container{
  // border: 1px solid rebeccapurple;
  width: 100%;
  opacity: 1;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.Contact-Container-inside{
  font-size: 10vw;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  opacity: 1;
  background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;
  opacity: 1;
}
.Contact-Container-inside a{      
  width:100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to left,
                    transparent,
                    transparent 50%,
                    #99CC00 50%,
                    #35D2D2);
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all .9s ease-in;    
  opacity: 1; 
  filter: drop-shadow(1px 3px 3px black);
}
.Contact-Container-inside a:hover {       
  background-position: 0 0; 
  box-shadow: 0px 8px 6px #00000047;
  filter: drop-shadow(1px 3px 3px black);
  .Irotating{
    transform: rotateZ(135deg) scale(1.7);
    color: white;
  }
}
.Contact-Button{
  font-size: 10vw;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  opacity: 1;
  background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;
  transition-property: all;
  transition-duration: 12s;
  transition-timing-function: ease;
  // left: 4%;
  padding-left: 10%;
  filter: drop-shadow(1px 3px 3px black);
  .Irotating{
    color: transparent;
    transition: all 1s ease-in-out;
    justify-content: center;
    display: inline-table;
    display: static;
    position: relative;
    transform: scale(0.2);
}
}
.Contact-Button:hover{
  font-size: 10vw;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  opacity: 1;
  background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  // drop-shadow: 10px 8px 6px #ff0055;
  // text-shadow: 0px 7px 6px #0000004D;
  font-family:  "space-Groteskb" !important;  
  transition-property: all;
  transition-duration: 12s;
  transition-timing-function: ease-in;
}
// ending newly addded css





// input[name="example"]{
//     width: 25px;
//     height: 25px;
// }


.photos-container {
    top: 5px;
    position: absolute;
    left: 70%;
    width: 50%;
    transition: all 1.7s ease-out;    
  }

  .photos-container:hover {
    top: 5px;
    position: absolute;
    left: 50%;
    width: 50%;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 1.7s ease-in;   
}



  .color-change{
      filter: gray; 
      -webkit-filter: grayscale(1); 
      -webkit-transition: all .9s ease-in-out;  
  }

.color-change:hover {
    filter: none;
      -webkit-filter: grayscale(0);
      -webkit-transform: scale(1.01);

      // -ms-transform: scale(1.2);
      // -moz-transform: scale(1.2);
      // -webkit-transform: scale(1.2);
      // -o-transform: scale(1.2);
      // transform: scale(1.2);
    }


    .color-change:focus{
      -ms-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }















// -------> ------> -----> ------> -----> -----> ---> --> --> -> HERE STARTS THE FRAMER MOTIONS 
.blurrr{
  -webkit-filter: brightness(50%); 
}
.blur{
  animation: blurr 1s ;
}
@keyframes blurr {
  0%,50% { -webkit-filter: brightness(10%); }
  100%  { -webkit-filter: brightness(50%); }
}
.blur-h{
  animation: blurr-h 1s ;
}
@keyframes blurr-h {
  0%,50% { -webkit-filter: brightness(10%); }
  100%  { -webkit-filter: brightness(100%); }
}

.framer-about-us-main-section{
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.framer-about-us-main-section img{ 
position: relative; 
width: 100%;
top: -1px;
}
.framer-about-us-insideContent{
  position: absolute;
  top: 50%;
  left: 20%;
  // transform: translate(-26%, -70%);
  transform: translate(-26%, -80%);
}

.framer-about-us-insideContent h1{
  // border: 1px solid black; 
  line-height: 100%;
  font-size: 10.7vw;
  opacity: 1;
  background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
  background-clip: border-box;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;
  padding-bottom: 3%;
}

.framer-about-us-insideContent .f-para1{
    // border: 1px solid blue;
    color: #FF6600;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 2vw;
  }
.framer-about-us-insideContent .f-para2{
  // border: 1px solid yellow; 
  letter-spacing: 0.96px;
  color: #FFFFFF;
  opacity: 1;
  font-family: "space-Groteskr" !important;
  font-size: 1.5vw;
}

.btn-container{
    position: relative;
    overflow:hidden;
    padding-top: 1px;
    padding-bottom: 1px;
}
.btn-lii{                         
  display: inline-block;
  position: relative;
  opacity: 1;
  background: white;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important;
  }

.btn-lii a{      
  display: block;
  background-image: linear-gradient(to left,
                      transparent,
                      transparent 50%,
                      #FF6600 50%,
                      #FF6600);
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all .4s ease-in;        
  border-radius: 10px;
  border: 1px solid #FF6600;
  padding-left: 20px;
  padding-right: 30px;
  color: black;
  .rotatee{
    color: white;
    transition: all 0.4s ease-in-out;
    justify-content: center;
    display: inline-table;
    display: static;
    position: relative;
    transform: scale(1.2)rotateZ(180deg);
  }
}
.btn-lii a:hover {       
  background-position: 0 0; 
  box-shadow: 0px 8px 6px #00000047;
  filter: drop-shadow(1px 3px 3px black);
  border-radius: 10px;
  border: 1px solid #FF6600;
  color: black;
  .rotatee{
    transform: scale(1.3)rotateZ(135deg);
    color: white;
    background-position: 0 0; 
  }
}

.btn-liil{
  font-size: 1.3vw;
  font-weight: bolder;
  display: inline-block;
  position: relative;
  opacity: 1;
  background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  .rotatee{
    color: white;
    transition: all 0.4s ease-in-out;
    justify-content: center;
    display: inline-table;
    display: static;
    position: relative;
    transform: scale(0.9);
  }
}

.btn-liil:hover{
  font-size: 1.3vw;
  font-weight: bolder;
  display: inline-block;
  position: relative;
  opacity: 1;
  // background: transparent linear-gradient(91deg, #000 0%, #000 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important; 
  padding-top: 15px;
  padding-bottom: 15px;
  .rotatee{
    transform: scale(1.3)rotateZ(135deg);
    color: white;
    }
}

.toggle-new{
  border: 1px solid red;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}
.toggle-new-img-container{
  border: 1px solid pink;
}
.toggle-new-content{
border: 1px solid blue;
color: white;
}




  .toggle-new-img1{
    border-radius: 10px;
  }
  .toggle-new-img1 img {
    width: 100%;
    border-radius: 10px;
    top: 15%;
    position: relative;
    left: 55%;
    z-index: 1;
  }

  .toggle-new-img2{
    border-radius: 10px;
    position: relative;
    right: 15%;
  }
  .toggle-new-img2 img {
    width: 85%;
    left: 20%;
    position: relative;
    border-radius: 10px;
    opacity: 0.5;
  }

.toggle-new-img11 img {
    width: 85%;
    border-radius: 10px;
    top: 0%;
    position: relative;
    left: 40%;
    opacity: 0.5;
}

.toggle-new-img22 img {
    width: 100%;
    right: 47%;
    position: relative;
    border-radius: 10px;
    top: 15%;
}

.set-imgg img {
  width: 80%;
}
.Our-Toggle-imgg {
  border: 1px solid blue;
  border-radius: 50px;
  position: relative;
  left: 0%;
  bottom: 90px;
  opacity: 0.5;
}


.Our-Toggle-imggg {
  border: 1px solid red;
  border-radius: 50px;
  position: relative;
  right: 95%;
  z-index: 1;
}
.set-imggg img{
  width: 100%;
}


.set-imggg1 img {
    width: 80%;
}
.Our-Toggle-imggg1 {
  border: 1px solid red;
  border-radius: 50px;
  position: relative;
  right: 105%;
  z-index: 1;
  bottom: 90px;
  opacity: 0.5;
}


.set-imgg2 img {
    width: 100%;
}
.Our-Toggle-imgg2 {
  border: 1px solid blue;
  border-radius: 50px;
  position: relative;
  left: 10%;
  bottom: 1px;
  z-index: 3;
}




// -------> ------> -----> ------> -----> -----> ---> --> --> -> HERE ENDS THE FRAMER MOTIONS 


// css for the mobile phone

@media  only screen and (min-width: 576px)  and (max-width: 1023px) {
  .stylee {
    display: flex;
    justify-content: center;
    gap: 5%;
    padding: 6% !important;
}
  .Our-Toggle-Container-img-mobile img {
    width: 70% !important;
    left: 15% !important;
  }
}
@media  only screen and (max-width: 768px) {
  .Our-Toggle-tablet{
    display: none;
  }
.stylee{
  display: flex;
  justify-content: center;
  gap: 5%;
  padding: 5% 0% 5% 0%;
}
.Our-Toggle{
  padding-top: 5%;
  margin-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
  padding-left: 4%;
}
.Our-Toggle-pa {
  color: rgb(255, 102, 0);
  font-size: 18px;
  text-align: center;
  line-height: 150%;
  font-family:"space-Groteskr" !important;
}
.Our-Toggle-paa {
  color: rgb(255, 255, 255);
  font-size: 16px;
  text-align: center;
  line-height: 165%;
  font-family:"space-Groteskr" !important;
  padding: 0% 1% 0% 1%;
}
.Our-Toggle-Container-img-mobile { 
  border-radius: 50px;
}
.Our-Toggle-Container-img-mobile img {
  max-width: 100%;
  height: auto;
  text-align: center;
  width: 80%;
  left: 10%;
  position: relative;
  border-radius: 20px !important;
}
} 
@media  only screen and (max-width: 480px) { 
.box-border{
    display: none;
  }
.framer-about-us-main-section {
    display: none;
  }
.framer-about-us-main-section-tablet{
    display: none;
  }
.framer-about-us-main-section-mobile{
position: relative;
// top: -1px;
}
.framer-about-us-insideContent-mobile{
  position: absolute;
  top: 60%;
  left: 20%;
  transform: translate(-10%, -45%);
}
.blurrr{
  -webkit-filter: brightness(70%); 
}
.blur{
  animation: blurr 1s ;
}
@keyframes blurr {
  0%,50% { -webkit-filter: brightness(10%); }
  100%  { -webkit-filter: brightness(70%); }
}
.blur-h{
  animation: blurr-h 1s ;
}
@keyframes blurr-h {
  0%,50% { -webkit-filter: brightness(10%); }
  100%  { -webkit-filter: brightness(100%); }
}
.framer-about-us-insideContent-mobile h1{
  line-height: 100%;
  font-size: 13.5Vw;
  opacity: 1;
  background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;
}
.framer-about-us-insideContent-mobile .f-para1{
  color: #FF6600;
  opacity: 1;
  letter-spacing: 0px;
  font-family: "space-Grotesksb" !important;
  font-size: 18px;
}
.framer-about-us-insideContent-mobile .f-para2{
  letter-spacing: 0.96px;
  color: #FFFFFF;
  opacity: 1;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
}
.framer-about-us-main-section-mobile img{
  width: 100vw;
  height: 100vh;
  background-size: cover;
  object-fit: cover;
  object-position: 75% 60%;
}

.btn-container-mobile{
  position: relative;
  overflow: hidden;
  padding-top: 2%;
}
.btn-lii-mobile {
  padding-top: 1px;
  padding-bottom: 1px;
}
.btn-lii-mobile a{
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 9px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: black;
  background: #FF6600;
  border: 1px solid black;
}
.btn-liil-mobile{
  font-size: 3.3vw;
  font-family:  "space-Groteskr" !important;
  color: black;
  font-weight: bold;
  background: transparent linear-gradient(91deg, #0c0c0c 0%, #000000 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-lii-mobile a .rotatee-mobile{
  color: black;
  display: static;
  transform: rotate(135deg);
}
.main_container1{
  // top: -6px;
  position: relative;
  left: 0px;
  width: 100%;
  height: auto;
  background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-top-right-radius: 0vw;
  border-top-left-radius: 0vw;
  top: -1px;
}
.Our-Approach-mobile{
  // border: 1px solid red;
}
.Our-Approach-Ex-Container h1 {
  font-size: 13vw;
  line-height: 130%;
  padding: 1% 0% 1% 0%;
  margin-bottom: 3%;
}
p.Our-p {
  font-size: 16px;
  line-height: 200%;
  padding: 2% 1% 2% 1%;
  margin-bottom: 2%;
  letter-spacing: 0.56px;
}
p.Our-pa {
  font-size: 18px;
  padding: 0% 1% 0% 1%;
  margin-bottom: 2%;
  // letter-spacing: 0px;
}
p.Our-paa {
  line-height: 170%;
  font-size: 16px;
  padding: 2% 1% 2% 1%;
  margin-bottom: 2%;
  letter-spacing: 0.56px;
}

.Our-Stories-Ex-Container {
  transform: translate(0%, 0%);
  padding-top: 25% !important;
  padding-bottom: 1% !important;
  margin-top: 12% !important;
  margin-bottom: 0% !important;
}














.Our-Stories{
  // border: 1px solid blue;
}
.Our-Stories-Ex-Container h1 {
  font-size: 13vw;
  padding: 1% 0% 1% 0%;
  margin-bottom: 3%;
}
p.Our-Stories-p {
  font-size: 16px;
  line-height: 200%;
  padding: 2% 1% 2% 1%;
  margin-bottom: 2%;
  letter-spacing: 0.56px;
}
p.Our-Stories-pa {
  font-size: 18px;
  letter-spacing: 0.72px;
  margin-bottom: 2%;
  padding: 1% 0% 1% 0%;
}
p.Our-Stories-paa {
  font-size: 16px;
  line-height: 205%;
  letter-spacing: 0.06px;
  padding: 2% 1% 2% 1%;
  margin-bottom: 2%;
}

.Our-Stories-Icon{
  display: none;
}
.LeaderShip-Container{
  display: none;
}
.Our-Stories-Icon-mobile{
// border: 1px solid red
}
.Our-Stories-Icon-InsideContainer-mobile{
  gap: 1%;
  justify-content: center;
  padding: 0px 21px;
  margin: 0%;
  justify-items: center;
}
.boxx {
  width: 40vw;
  // height: 20vh;
  height: auto;
  border: 1px solid #FF6600;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}
.boxx img{
  padding-top: 15px;
  max-width: 100%;
  height: auto;
  width: 30%;
}
.boxxx{
width: 40vw;
height: auto;
border: 1px solid #FF6600;
border-radius: 20px;
margin-bottom: 25px;
margin-top: 6px;
margin-left: 10px;
margin-right: 10px;
text-align: center;
}
.boxxx img{
  padding-top: 8px;
  max-width: 100%;
  height: auto;
  width: 42%;
}
.boxx p {
  font-family:  "space-Groteskr" !important;
  color: white;
  display: grid;
  font-size: 4vw;
  text-align: center;
  padding-top: 10px;
}
.boxxx p {
  color: white;
  padding-top: 25px;
  display: grid;
  font-size: 4.2vw;
  text-align: center;
  font-family:  "space-Groteskr" !important;
}
.LeaderShip-mobile{
  justify-content: center;
  overflow: hidden;
  color: white;
  text-align: center;
  transform: translate(0%, 0%);
  padding-top: 25% !important;
  padding-bottom: 1% !important;
  margin-top: 12% !important;
  margin-bottom: 0% !important;
}
.LeaderShip-container-mobile{
  width: 85%;
  height: auto;
  display: inline-table;
}

.LeaderShip-mobile h1 {
  font-size: 13vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding: 1% 0% 1% 0%;
  margin-bottom: 6%;
}
.founder-container-mobile {
  justify-content: center;
  background: #0E2D61;
  justify-items: center;
  border-radius: 20px;
  box-shadow: 0px 11px 30px #00000096;
}
.linked-in {
  position: relative;
  width: 20%;
  left: 67%;
  bottom: 22px;
}
.founder-img-mobile{
  position: relative;
  padding-top: 20px;
  // padding-bottom: 35px;
}
.founder-img-mobile img{
  width: 60%;
}
.founder-name-mobile{
  color: #FFFFFF;
  font-family:  "space-Grotesksb" !important;
  font-size: 18px;
  position: relative;
  letter-spacing: 1.2px;
  top: 7%;
}
.founder-role-mobile {
  color: #F05F30;
  letter-spacing: 1.2px;
  text-transform: capitalize;
  position: relative;
  top: 6%;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family:  "space-Groteskr" !important;
}
.founder-text-mobile{
  font-family:  "space-Groteskr" !important;
  font-size: 3.5vw;
  overflow: hidden;
  position: relative;
  color: white;
  line-height: 166%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 70px;
  font-size: 16px;
  letter-spacing: 0.42px;
}

.Our-Team-Content-mobile{
  padding: 1% 0% 1% 0%;

}
.Our-Team-Content-mobile h1 {
  font-size: 13vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding: 1% 0% 1% 0%;
  margin-bottom: 3%;
}


.Our-Team-Content-mobile .Our-Team-pa-mobile {
  font-family:  "space-Groteskr" !important;
  color: #F1F9F9;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  padding: 1% 3% 1% 3%;
  margin-bottom: 2%;
}

.Our-Team-Container-New-mobile{
          // border: 1px solid green;
          background-image: url("../../fonts/svg/team_circle\ \(1\).svg");
          background-size: contain;
          background-repeat: no-repeat;
  padding-top: 3%;
  padding-bottom: 2%;
  margin-top: 1%;
  margin-bottom: 0%;
  display: flex;
  left: 3%;
  overflow: hidden;
  transform: translate(0%, 0%);
  padding-top: 25% !important;
  padding-bottom: 1% !important;
  margin-top: 12% !important;
  margin-bottom: 0% !important;
}

.Our-Team-Content-mobile .Our-Team-paa-mobile {
  font-family:  "space-Groteskr" !important;
  color: #F1F9F9;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  padding: 1% 3% 1% 3%;
  margin-bottom: 2%;
}
.Our-Team-Content-mobile .Our-Team-paaa-mobile {
  font-family:  "space-Groteskr" !important;
  color: #F1F9F9;
  text-align: center;
  font-size: 16px;
  line-height: 200%;
  padding: 1% 3% 1% 3%;
  margin-bottom: 2%;
}
.Photos-mobile{
// border: 1px solid red;
}
.Photos-mobile1{
// border: 1px solid blue;
padding-top: 0px;
}
.photos-Container-mobile{
// border: 1px solid blue;
justify-content: center;
display:flex;
text-align: center;
}
.photos-ContainerA-mobile {
  justify-content: space-evenly;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 30px;
  margin-left: 30px;
  position: relative;
  gap: 10px;
}
.photos-InsideContainerA-mobile{
  position: relative;
  // padding-top: 10px;
  // padding-bottom: 10px;
  text-align: center;
}
.photos-ContainerB-mobile {
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
  justify-content: space-evenly;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.photos-InsideContainerB-mobile{
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.photos-ContainerC-mobile{
  justify-content: space-evenly;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 30px;
  margin-left: 30px;
  position: relative;
  gap: 10px;
}
.photos-InsideContainerC-mobile{
  position: relative;
  // padding-top: 10px;
  // padding-bottom: 10px;
  text-align: center;
}
.photos-ContainerD-mobile{
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
  justify-content: space-evenly;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.photos-InsideContainerD-mobile{
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.photos-ContainerE-mobile{
  justify-content: space-evenly;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 30px;
  margin-left: 30px;
  position: relative;
  gap: 10px;
}
.photos-InsideContainerE-mobile{
  position: relative;
  // padding-top: 10px;
  // padding-bottom: 10px;
  text-align: center;
}
.photos-ContainerF-mobile{
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
  justify-content: space-evenly;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.photos-InsideContainerF-mobile{
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.photos-ContainerG-mobile{
  display: flex;
  // padding-right: 74px;
  // padding-left: 74px;
  margin-left: 15px;
  margin-right: 15px;
  margin-left: 46px;
  margin-right: 30px;
  justify-content: center;
}
.photos-InsideContainerG-mobile{
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  // padding-left: 10px;
  // padding-right: 10px;
}
.photos-InsideContainerA-mobile img{
  width: 100%;
}
.photos-InsideContainerB-mobile img{
  width: 100%;
}
.photos-InsideContainerC-mobile img{
  width: 100%;
}
.photos-InsideContainerD-mobile img{
  width: 100%;
}
.photos-InsideContainerE-mobile img{
  width: 100%;
}
.photos-InsideContainerF-mobile img{
  width: 100%;
}
.photos-InsideContainerG-mobile img{
  width: 100%;
}
.We-Evenion-Container-mobile {
  overflow: hidden;
  transform: translate(0%, 0%);
  padding-top: 12% !important;
  padding-bottom: 1% !important;
  margin-top: 12% !important;
  margin-bottom: 0% !important;
}
.We-Evenion-Container-mobile h1 {
  font-size: 13vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.9882352941);
  padding: 1% 0% 1% 0%;
  margin-bottom: 3%;
}
.We-Evenion-pa-mobile {
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  line-height: 200%;
  padding: 1% 0% 1% 0%;
  margin-bottom: 0%;
  letter-spacing: 0.56px;
  font-size: 16px;

}
.Our-Team-ContentImages{
  display: none;
}
.colorC{
  mix-blend-mode: luminosity;
}
.Evenion-Img2 {
  width: 185px;
  height: 75px;
  background-image: url("../../fonts/gallery/18.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
}
.Evenion-Img1 {
  border-radius: 50%;
  width: 100px;
  height: 80px;
  background-image: url("../../fonts/gallery/4.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Evenion-Img3 {
  width: 185px;
  height: 75px;
  background-image: url("../../fonts/gallery/11.jpg");
  object-fit: fill;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
}
.Evenion-Img5 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/5.png");
  object-fit: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 80px;
}

.Evenion-Img7 {
  border-radius: 50%;
  width: 100px;
  height: 80px;
  background-image: url("../../fonts/gallery/7.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Evenion-Img6 {
  width: 185px;
  height: 75px;
  background-image: url("../../fonts/gallery/6.png");
  object-fit: fill;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
}
.Evenion-Img9 {
  border-radius: 50%;
  width: 100px;
  height: 80px;
  background-image: url("../../fonts/gallery/8.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Evenion-Img4 {
  background-image: url("../../fonts/gallery/16.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 185px;
  height: 75px;
}
.Evenion-Img15 {
  background-image: url("../../fonts/gallery/15.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 185px;
  height: 75px;
}


.Evenion-Img8 {
  background-image: url("../../fonts/gallery/17.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 185px;
  height: 75px;
}
.Contact-Container{
  display: none;
}

.Contact-Container-mobile{
  //  border: 1px solid rebeccapurple;
   width: 100%;
   opacity: 1;
   text-align: center;
   position: relative;
   overflow: hidden;
   background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;;
  }
  .Contact-Container-inside-mobile{
   font-size: 10vw;
   width: 100%;
   height: 100%;
   display: inline-block;
   position: relative;
   opacity: 1;
   background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;
   background-clip: border-box;
   -webkit-background-clip: text;
   background-clip: text;
   -webkit-text-fill-color: transparent;
   font-family:  "space-Groteskb" !important;
   opacity: 1;
  }
  .Contact-Container-inside-mobile a{      
   width:100%;
   height: 100%;
   display: block;
   background-image: linear-gradient(to left,
                     transparent,
                     transparent 50%,
                     #99CC00 50%,
                     #35D2D2);
   background-position: 100% 0;
   background-size: 200% 100%;
   transition: all .9s ease-in;    
   opacity: 1; 
   padding: 5% 0%;
  }
  .Contact-Button-mobile{
    font-size: 10vw;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    opacity: 1;
    background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;  
    transition-property: all;
    transition-duration: 12s;
    transition-timing-function: ease-in;
  .Irotating-mobile{
    color: white;
    justify-content: center;
    display: inline-table;
    display: static;
    position: relative;
    transform: rotateZ(135deg) scale(1.7);
  }
  }

}



//tablet css
@media  only screen and (min-width: 481px) and (max-width: 768px){
    .framer-about-us-main-section-tablet{
    position: sticky;
    position: -webkit-sticky;
    // top: -1;
  }
  .framer-about-us-main-section-tablet img{ 
  position: "relative"; 
  width: "100%" ;
  height: 60vh;
  // top: -1px;
  }

  .framer-about-us-insideContent-tablet{
    // border: 1px solid red;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-30%, -35%);
    }
    
    .framer-about-us-main-section-mobile img{
      width: 100vw;
      height: 100vh;
      background-size: cover;
      object-fit: cover;
      object-position: 75% 60%;
    }
    .framer-about-us-main-section-mobile{
      position: relative;
      // top: -1px;
      }
    .framer-about-us-insideContent-tablet h1{
      // border: 1px solid black; 
      line-height: 100%;
      font-size: 11.7vw;
      opacity: 1;
      background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
      background-clip: border-box;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family:  "space-Groteskb" !important;
    }
    
    .framer-about-us-insideContent-tablet .f-para1{
        // border: 1px solid blue;
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 2.2vw;
      }
    .framer-about-us-insideContent-tablet .f-para2{
      // border: 1px solid yellow; 
      letter-spacing: 0.96px;
      color: #FFFFFF;
      opacity: 1;
      font-family:  "space-Groteskr" !important;
      font-size: 1.5vw;
    }
.btn-container-tablet{
    position: relative;
    overflow: hidden;
    padding-top: 2%;
  }
  .btn-lii-tablet a {
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    background: #FF6600;
}
  .btn-liil-tablet{
    font-size: 2.5vw;
    font-family:  "space-Groteskr" !important;
    color: black;
    background: transparent linear-gradient(91deg, #0c0c0c 0%, #000000 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .btn-lii-tablet a .rotatee-tablet{
    color: black;
    display: static;
    transform: rotate(180deg) scale(1.2);
  }
.main_container1{
  top: -2px;
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
}
.Our-Approach-tablet{
// border: 1px solid red;
}
.Our-Approach-Ex-Container-tablet{
  // border: 1px solid blue;
  transform: translate(0%, 0%);
  padding-top: 3%;
  padding-bottom: 5%;
  margin-bottom: 1%;
}
.Our-Approach-Ex-Container-tablet h1{
  font-size: 14.5vw;
  line-height: 130%;
  padding: 1% 0% 1% 0%;
  margin-bottom: 3%;
}
p.Our-p{
  font-size: 16px;
  line-height: 200%;
  padding: 2% 1% 2% 1%;
  margin-bottom: 2%;
  letter-spacing: 0.56px;
}
p.Our-pa{
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  font-size: 18px;
  padding: 0% 1% 0% 1%;
  margin-bottom: 2%;
}
p.Our-paa{
  font-size: 18px;
  text-align: center;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
}
.Our-Toggle-tablet{
  // border: 1px solid blue;
  padding-top: 1%;
  margin-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
  padding-left: 9%;
}
.Our-Toggle-img-tablet{
  border-radius: 20px;
}
.Our-Toggle-img-tablet img {
  max-width: 100%;
  height: auto;
  width: 90%;
}
.Our-Toggle-pa-tablet {
  color: rgb(255, 102, 0);
  font-size: 2.3vw;
}
.Our-Toggle-paa-tablet {
  color: rgb(255, 255, 255);
  font-size: 1.4vw;
}
.Our-Stories-tablet{
  // border: 1px solid green;
}
.Our-Stories-Ex-Container-tablet{
  transform: translate(0%, 0%);
  padding-top: 12% !important;
  padding-bottom: 1% !important;
  margin-top: 12% !important;
  margin-bottom: 0% !important;

}
.Our-Stories-Ex-Container-tablet h1 {
  font-size: 14.5vw;
  padding: 1% 0% 1% 0%;
  margin-bottom: 3%;
}
p.Our-Stories-p {
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
  line-height: 200%;
  padding: 2% 1% 2% 1%;
  margin-bottom: 2%;
  letter-spacing: 0.56px;
}
p.Our-Stories-pa {
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  font-size: 18px;
  letter-spacing: 0.72px;
  margin-bottom: 2%;
  padding: 1% 0% 1% 0%;
}
p.Our-Stories-paa {
  font-size: 18px;
  text-align: center;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 0%;
  padding-bottom: 0%;
  font-family:  "space-Groteskr" !important;
}
.Our-Stories-Icon-tablet{
  // border: 1px solid blue;
}
.Our-Stories-Icon-InsideContainer-tablet {
  gap: 5%;
  justify-content: center;
  padding: 20px;
  margin: 0%;
  justify-items: center;
}
.boxx-tablet {
  width: 40vw;
  height: 26vh;
  border: 1px solid #FF6600;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 5px;
  text-align: center;
}
.boxx-tablet img{
  padding-top: 5%;
}
.boxx-tablet p {
  color: white;
  display: grid;
  font-size: 4vw;
  text-align: center;
  padding-top: 2%;
  font-family:  "space-Groteskr" !important;
}
.Our-Stories-Icon{
  display: none;
}
  .LeaderShip-Container-tablet{
  // border: 1px solid red;
  padding-top: 3%;
  padding-bottom: 2%;
  margin-top: 2%;
  margin-bottom: 0%;
  overflow: hidden;
}
.linked-in {
  position: absolute;
  top: 75%;
  width: 30%;
  left: 75%;
}
.LeaderShip-Container-tablet h1{
  // border: 1px solid blue;
  font-size: 11vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 0%;
}
.LeaderShip-Container-inside-tablet{
  // border: 1px solid violet;
  padding-top: 0%;
  padding-bottom: 10%;
  margin-top: 2%;
  margin-bottom: 0%;
}
.Founder-Container-tablet {
  background-color: #0E2D61;
  border-radius: 15px;
  position: relative;
  display: flex;
  display: inline-grid;
  width: 95%;
  height: 30vh;
  padding-bottom: 10px;
  margin: 25px;
}
.Founder-img-Container-tablet {
  left: 2%;
  top: 20%;
  width: 25%;
  position: absolute;
  cursor: pointer;
  display: block;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
}
.Founder-Content-Container-tablet {
  left: 29%;
  width: 68%;
  height: 17vh;
  position: absolute;
  background: #0E2D61;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  top: 10%;
  // border: 1px solid red;
}
.Founder-Name-tablet {
  color: #FFFFFF;
  font-family:  "space-Grotesksb" !important;
  font-size: 2.2vw;
  text-align: left;
  position: relative;
  letter-spacing: 1px;
  top: 7%;
}
.Founder-Role-tablet {
  color: #F05F30;
  /* letter-spacing: 4.2px; */
  text-transform: capitalize;
  position: relative;
  text-align: left;
  top: 6%;
  font-size: 2vw;
  font-family:  "space-Groteskr" !important;

}
.Founder-Stories-tablet{
  // border: 1px solid rgb(223, 155, 18);
  color: #FFFFFF;
  left: 0;
  width: 100%;
  font-family:  "space-Groteskr" !important;
  letter-spacing: 0.72px;
  font-size: 1.2vw;
  top: 6%;
  height: 56%;
  position: relative;
  color: white;
  text-align: left;
}
.CO-Founder-Container-tablet{
  background-color: #0E2D61;
  border-radius: 15px;
  position: relative;
  display: flex;
  display: inline-grid;
  width: 95%;
  height: 30vh;
  padding-bottom: 10px;
  margin: 25px;
}
.CO-Founder-img-Container-tablet{
  left: 2%;
  top: 20%;
  width: 25%;
  position: absolute;
  cursor: pointer;
  display: block;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
}
.CO-Founder-Content-Container-tablet{
  left: 29%;
  width: 68%;
  height: 17vh;
  position: absolute;
  background: #0E2D61;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  top: 10%;
  // border: 1px solid red;
}
.CO-Founder-Name-tablet{
  // border: 1px solid green;
  color: #FFFFFF;
  font-family:  "space-Grotesksb" !important;
  font-size: 2.2vw;
  text-align: left;
  position: relative;
  letter-spacing: 1.2px;
  top: 7%;
}
.CO-Founder-Role-tablet{
  color: #F05F30;
  text-transform: capitalize;
  position: relative;
  text-align: left;
  top: 6%;
  font-size: 2vw;
  font-family:  "space-Groteskr" !important;
}
.CO-Founder-Stories-tablet{
  color: #FFFFFF;
  left: 0;
  width: 100%;
  font-family:  "space-Groteskr" !important;
  letter-spacing: 0.72px;
  font-size: 1.2vw;
  top: 6%;
  height: 56%;
  position: relative;
  color: white;
  text-align: left;
}

.We-Evenion-Container h1 {
  font-size: 14vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 2%;
  text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.9882352941);
}
.We-Evenion-pa {
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  line-height: 200%;
  padding: 1% 0% 1% 0%;
  margin-bottom: 0%;
  letter-spacing: 0.56px;
  font-size: 16px;
}
.Evenion-Img2 {
width: 500px;
height: 177px;
background-image: url("../../fonts/gallery/18.jpg");
object-fit: cover;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
// border: 1px solid black;
}
.Evenion-Img1 {
  border-radius: 50%;
  width: 250px;
  height: 177px;
  background-image: url("../../fonts/gallery/4.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // border: 1px solid black;
}
.Evenion-Img3 {
  background-image: url("../../fonts/gallery/11.jpg");
object-fit: cover;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
width: 500px;
height: 177px;
// border: 1px solid black;
}
.Evenion-Img5 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/5.png");
  object-fit: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img7 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/7.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img6 {
  background-image: url("../../fonts/gallery/6.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  width: 500px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img9 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/8.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img4 {
  background-image: url("../../fonts/gallery/16.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 500px;
  height: 177px;
}
.Evenion-Img15 {
  background-image: url("../../fonts/gallery/15.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 500px;
  height: 177px;
}
.Evenion-Img8 {
  // border: 1px solid blue;
  background-image: url("../../fonts/gallery/17.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  width: 500px;
  height: 177px;
  // border: 1px solid black;
}
.Contact-Container-tablet{
//  border: 1px solid rebeccapurple;
 width: 100%;
 opacity: 1;
 text-align: center;
 position: relative;
 overflow: hidden;
 background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;;
}
.Contact-Container-inside-tablet{
 font-size: 10vw;
 width: 100%;
 height: 100%;
 display: inline-block;
 position: relative;
 opacity: 1;
 background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;
 background-clip: border-box;
 -webkit-background-clip: text;
 background-clip: text;
 -webkit-text-fill-color: transparent;
 font-family:  "space-Groteskb" !important;
 opacity: 1;
}
.Contact-Container-inside-tablet a{      
 width:100%;
 height: 100%;
 display: block;
 background-image: linear-gradient(to left,
                   transparent,
                   transparent 50%,
                   #99CC00 50%,
                   #35D2D2);
 background-position: 100% 0;
 background-size: 200% 100%;
 transition: all .9s ease-in;    
 opacity: 1; 
 padding-top: 8%;
 padding-bottom: 8%;
}
.Contact-Button-tablet{
  font-size: 10vw;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  opacity: 1;
  background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;  
  transition-property: all;
  transition-duration: 12s;
  transition-timing-function: ease-in;
.Irotating-tablet{
  color: white;
  justify-content: center;
  display: inline-table;
  display: static;
  position: relative;
  transform: rotateZ(135deg) scale(1.7);
}
}
.framer-about-us-main-section-mobile img {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  object-fit: cover;
  object-position: 75% 60%;
}
.framer-about-us-insideContent-mobile {
  position: absolute;
  top: 60%;
  left: 20%;
  transform: translate(-10%, -40%);
}
.framer-about-us-insideContent-mobile h1 {
  line-height: 100%;
  font-size: 13.5Vw;
  opacity: 1;
  background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;
}
.framer-about-us-insideContent-mobile .f-para1 {
  color: #FF6600;
  opacity: 1;
  letter-spacing: 0px;
  font-family:  "space-Grotesksb" !important;
  font-size: 18px;
}
.framer-about-us-insideContent-mobile .f-para2 {
  letter-spacing: 0.96px;
  color: #FFFFFF;
  opacity: 1;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
}
.btn-stacke-mobile {
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
}
.btn-inner-mobile {
  color: #ffffff;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-left: 3%;
  padding-right: 2.5%;
  background: #FF6600;
  border-radius: 4px;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
}




.LeaderShip-mobile{
  overflow: hidden;
  color: white;
  text-align: center;
  transform: translate(0%, 0%);
  padding-top: 12% !important;
  padding-bottom: 1% !important;
  margin-top: 12% !important;
  margin-bottom: 0% !important;
}
.LeaderShip-container-mobile{
  width: 85%;
  height: auto;
  display: inline-table;
}

.LeaderShip-mobile h1 {
  font-size: 15vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding: 1% 0% 1% 0%;
  margin-bottom: 6%;
}
.founder-container-mobile {
  justify-content: center;
  background: #0E2D61;
  justify-items: center;
  border-radius: 20px;
  box-shadow: 0px 11px 30px #00000096;
}
.linked-in {
  position: relative;
  width: 20%;
  left: 67%;
  bottom: 35px;
}
.founder-img-mobile{
  position: relative;
  padding-top: 20px;
  // padding-bottom: 35px;
}
.founder-img-mobile img{
  width: 60%;
}
.founder-name-mobile{
  color: #FFFFFF;
  font-family:  "space-Grotesksb" !important;
  font-size: 18px;
  position: relative;
  letter-spacing: 1.2px;
  top: 7%;
}
.founder-role-mobile {
  color: #F05F30;
  letter-spacing: 1.2px;
  text-transform: capitalize;
  position: relative;
  top: 6%;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family:  "space-Groteskr" !important;
}
.founder-text-mobile{
  font-family:  "space-Groteskr" !important;
  overflow: hidden;
  position: relative;
  color: white;
  line-height: 160%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 70px;
  font-size: 16px;
  letter-spacing: 0.42px;
}



}







// photossssssssssssssssssssss



// for radio button

input[type="radio"] {
  -webkit-appearance: none;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: transparent;
  position: relative;
  top: 6px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  cursor: pointer;
  
}

input[type="radio"]:checked::before {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // animation: appear 0.8s;
    width: 25px;
    height: 25px;
    border: 2px solid #FF6600;
    cursor: pointer;
}

@keyframes appear {
	0% {
		transform: translate(-50%, -50%) scale(0);
		background-color: #fff;
	}
	45% {
		transform: translate(-50%, -50%) scale(0.4);
		background-color: #64aedf;
	}
	50% {
		transform: translate(-50%, -50%) scale(0.6);
		background-color: #ad97b3;
	}
	55% {
		transform: translate(-50%, -50%) scale(0.4);
	}
	100% {
		transform: translate(-50%, -50%) scale(0.7);
		background-color: #ca8489;
	}
}
// 




// our-box
@media  only screen and (min-width: 1024px) { 

.box-border{
  display: flex;
  gap: 40px;
  // left: 8%;
  justify-content: center;
  position: relative;
}
.box-border-inside {
  border: 1px solid #FF6600;  
  border-radius: 30px;
  width: 15vw;
  text-align: center;
  padding-top: 20px;
  color: white;
}
.box-border-inside p {
  color: white;
  display: grid;
  font-size: 1.4vw;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 20px;
}
.box-border-inside img {
  max-width: 100%;
  height: auto;
  width: 30%;
}
.box-border-inside1 {
  border: 1px solid #FF6600;  
  border-radius: 30px;
  width: 15vw;
  text-align: center;
  padding-top: 20px;
  color: white;
}
.box-border-inside1 p {
  color: white;
  display: grid;
  font-size: 1.4vw;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 10px;
}
.box-border-inside1 img {
  max-width: 100%;
  height: auto;
  width: 40%;
}
}























@media  only screen and (min-width: 768px) and (max-width: 1023px) {
  .LeaderShip-Container{
    display: none;
  }
  .Contact-Container{
    display: none;
  }

  // .We-Evenion-Container{
  //   display: none;
  // } 
  // .We-Evenion-Container-mobile{
  //   display: none;
  // }
  .framer-about-us-main-section-tablet{
    position: sticky;
    position: -webkit-sticky;
    // top: -1;
  }
  .framer-about-us-main-section-tablet img{ 
  // position: "relative"; 
  // width: "100%" ;
  // height: 60vh;
  // top: -1px;
  height: auto;
  position: relative;
  width: 100%;
  }

  .framer-about-us-insideContent-tablet{
    // border: 1px solid red;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-30%, -35%);
    }
    
    .framer-about-us-insideContent-tablet h1{
      // border: 1px solid black; 
      line-height: 100%;
      font-size: 11.7vw;
      opacity: 1;
      background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
      background-clip: border-box;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family:  "space-Groteskb" !important;
    }
    
    .framer-about-us-insideContent-tablet .f-para1{
        // border: 1px solid blue;
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 2.2vw;
      }
    .framer-about-us-insideContent-tablet .f-para2{
      // border: 1px solid yellow; 
      letter-spacing: 0.96px;
      color: #FFFFFF;
      opacity: 1;
      font-family: "space-Groteskr" !important;
      font-size: 1.5vw;
    }
.btn-container-tablet{
    position: relative;
    overflow: hidden;
    padding-top: 2%;
  }
  .btn-lii-tablet a {
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    background: #FF6600;
}
  .btn-liil-tablet{
    font-size: 2.5vw;
    font-family:  "space-Groteskr" !important;
    color: white;
    // background: transparent linear-gradient(91deg, #0c0c0c 0%, #000000 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .btn-liil {
    font-size: 2.3vw;
    font-weight: bolder;
    display: inline-block;
    position: relative;
    opacity: 1;
    background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskr" !important;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
  .btn-lii-tablet a .rotatee-tablet{
    color: white;
    display: static;
    transform: rotate(135deg) scale(1.2);
  }
.main_container1{
  top: -2px;
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    border-top-right-radius: 0vw;
    border-top-left-radius: 0vw;
}
.Our-Approach-tablet{
// border: 1px solid red;
}
.Our-Approach-Ex-Container-tablet{
  // border: 1px solid blue;
  transform: translate(0%, 0%);
  padding-top: 3%;
  padding-bottom: 0%;
  margin-bottom: 0%;
}
.Our-Approach-Ex-Container-tablet h1{
  // border: 1px solid blue;
  font-size: 12vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 2%;
  padding-bottom: 2%;
}
p.Our-p{
  font-size: 18px;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
}
p.Our-pa{
  font-size: 20px;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
}
p.Our-paa{
  font-size: 18px;
  text-align: center;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  // border: 1px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
}
.Our-Toggle{
  display: none;
}
.Our-Toggle-tablet{
  // border: 1px solid red;
  padding-top: 1%;
  margin-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 5%;
  padding-left: 9%;
}
.Our-Toggle-img-tablet{
  border-radius: 50px;
}
.Our-Toggle-img-tablet img {
  max-width: 100%;
  height: auto;
  width: 90%;
}
.Our-Toggle-pa-tablet {
  color: rgb(255, 102, 0);
  font-size: 2.3vw;
}
.Our-Toggle-paa-tablet {
  color: rgb(255, 255, 255);
  font-size: 1.4vw;
}
.Our-Stories-tablet{
  // border: 1px solid green;
}
.Our-Stories-Ex-Container-tablet{
  transform: translate(0%, 0%);
  padding-top: 5%;
  padding-bottom: 1%;
  margin-top: 5%;
  margin-bottom: 1%;

}
.Our-Stories-Ex-Container-tablet h1 {
  font-size: 14vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 0%;
  padding-bottom: 0%;
}
p.Our-Stories-p {
  font-size: 18px;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 0%;
  padding-bottom: 0%;
  letter-spacing: 0.9px;
}
p.Our-Stories-pa {
  font-size: 20px;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 0%;
  padding-bottom: 0%;
}
p.Our-Stories-paa {
  font-size: 18px;
  text-align: center;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 0%;
  padding-bottom: 0%;
}
.Our-Stories-Icon-tablet{
  // border: 1px solid blue;
}
.Our-Stories-Icon-InsideContainer-tablet {
  display : none
}
.boxx-tablet {
  width: 40vw;
  height: 26vh;
  border: 1px solid #FF6600;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 5px;
  text-align: center;
}
.boxx-tablet img{
  padding-top: 10px;
}
.boxx-tablet p {
  color: white;
  display: grid;
  font-size: 4vw;
  text-align: center;
}
.Our-Stories-Icon{
  display: none;
}
  .LeaderShip-Container-tablet{
  // border: 1px solid red;
  padding-top: 3%;
  padding-bottom: 2%;
  margin-top: 2%;
  margin-bottom: 0%;
  overflow: hidden;
}
.linked-in {
  position: absolute;
  top: 75%;
  width: 30%;
  left: 75%;
}
.LeaderShip-Container-tablet h1{
  // border: 1px solid blue;
  font-size: 11vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 0%;
}
.LeaderShip-Container-inside-tablet{
  // border: 1px solid violet;
  padding-top: 0%;
  padding-bottom: 5%;
  margin-top: 2%;
  margin-bottom: 0%;
}
.Founder-Container-tablet {
  background-color: #0E2D61;
  border-radius: 15px;
  position: relative;
  display: flex;
  display: inline-grid;
  width: 95%;
  height: 35vw;
  padding-bottom: 10px;
  margin: 25px;
  box-sizing: content-box;

}
.Founder-img-Container-tablet {
  left: 2%;
  top: 20%;
  width: 25%;
  position: absolute;
  cursor: pointer;
  display: block;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
}
.Founder-Content-Container-tablet {
  left: 29%;
  width: 68%;
  height: 17vh;
  position: absolute;
  background: #0E2D61;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  top: 10%;
  // border: 1px solid red;
}
.Founder-Name-tablet {
  color: #FFFFFF;
  font-family:  "space-Grotesksb" !important;
  font-size: 2.2vw;
  text-align: left;
  position: relative;
  letter-spacing: 1px;
  top: 7%;
}
.Founder-Role-tablet {
  color: #F05F30;
  /* letter-spacing: 4.2px; */
  text-transform: capitalize;
  position: relative;
  text-align: left;
  top: 6%;
  font-size: 2vw;
  font-family:  "space-Groteskr" !important;

}
.Founder-Stories-tablet{
  // border: 1px solid rgb(223, 155, 18);
  color: #FFFFFF;
  left: 0;
  width: 100%;
  font-family:  "space-Groteskr" !important;
  letter-spacing: 0.72px;
  font-size: 1.2vw;
  top: 6%;
  height: 56%;
  position: relative;
  color: white;
  text-align: left;
}
.CO-Founder-Container-tablet{
  background-color: #0E2D61;
  border-radius: 15px;
  position: relative;
  display: flex;
  display: inline-grid;
  width: 95%;
  height: 35vw;
  padding-bottom: 10px;
  margin: 25px;
  box-sizing: content-box;
}
.CO-Founder-img-Container-tablet{
  left: 2%;
  top: 20%;
  width: 25%;
  position: absolute;
  cursor: pointer;
  display: block;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
}
.CO-Founder-Content-Container-tablet{
  left: 29%;
  width: 68%;
  height: 17vh;
  position: absolute;
  background: #0E2D61;
  transition-property: all;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  top: 10%;
  // border: 1px solid red;
}
.CO-Founder-Name-tablet{
  // border: 1px solid green;
  color: #FFFFFF;
  font-family:  "space-Grotesksb" !important;
  font-size: 2.2vw;
  text-align: left;
  position: relative;
  letter-spacing: 1.2px;
  top: 7%;
}
.CO-Founder-Role-tablet{
  color: #F05F30;
  text-transform: capitalize;
  position: relative;
  text-align: left;
  top: 6%;
  font-size: 2vw;
  font-family:  "space-Groteskr" !important;

}
.CO-Founder-Stories-tablet{
  color: #FFFFFF;
  left: 0;
  width: 100%;
  font-family:  "space-Groteskr" !important;
  letter-spacing: 0.72px;
  font-size: 1.2vw;
  top: 6%;
  height: 56%;
  position: relative;
  color: white;
  text-align: left;
}
.We-Evenion-Container h1 {
  font-size: 14vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 2%;
  text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.9882352941);
}
.We-Evenion-pa {
  font-size: 18px;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding-top: 1%;
  padding-bottom: 1%;
}
.Evenion-Img2 {
width: 500px;
height: 177px;
background-image: url("../../fonts/gallery/18.jpg");
object-fit: cover;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
// border: 1px solid black;
}
.Evenion-Img1 {
  border-radius: 50%;
  width: 250px;
  height: 177px;
  background-image: url("../../fonts/gallery/4.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // border: 1px solid black;
}
.Evenion-Img3 {
  background-image: url("../../fonts/gallery/11.jpg");
object-fit: cover;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
width: 500px;
height: 177px;
// border: 1px solid black;
}
.Evenion-Img5 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/5.png");
  object-fit: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img7 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/7.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img6 {
  background-image: url("../../fonts/gallery/6.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  width: 500px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img9 {
  border-radius: 50%;
  background-image: url("../../fonts/gallery/8.png");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 177px;
  // border: 1px solid black;
}
.Evenion-Img4 {
  background-image: url("../../fonts/gallery/16.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 500px;
  height: 177px;
}
.Evenion-Img15 {
  background-image: url("../../fonts/gallery/15.jpg");
  object-fit: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  // border: 1px solid black;
  width: 500px;
  height: 177px;
}

.Evenion-Img8 {
  // border: 1px solid blue;
  background-image: url("../../fonts/gallery/17.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25% 25% 25% 25%/42% 39% 34% 31%;
  width: 500px;
  height: 177px;
  // border: 1px solid black;
}
.Contact-Container-tablet{
//  border: 1px solid rebeccapurple;
 width: 100%;
 opacity: 1;
 text-align: center;
 position: relative;
 overflow: hidden;
 background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;;
}
.Contact-Container-inside-tablet{
 font-size: 10vw;
 width: 100%;
 height: 100%;
 display: inline-block;
 position: relative;
 opacity: 1;
 background: transparent linear-gradient(91deg, #00CCC5 0%, #99CC00 100%) 0% 0% no-repeat padding-box;
 background-clip: border-box;
 -webkit-background-clip: text;
 background-clip: text;
 -webkit-text-fill-color: transparent;
 font-family:  "space-Groteskb" !important;
 opacity: 1;
}
.Contact-Container-inside-tablet a{      
width:100%;
height: 100%;
display: block;
background-image: linear-gradient(to left,
                  transparent,
                  transparent 50%,
                  #99CC00 50%,
                  #35D2D2);
background-position: 100% 0;
background-size: 200% 100%;
transition: all .9s ease-in;    
opacity: 1; 
}
.Contact-Button-tablet{
  font-size: 10vw;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  opacity: 1;
  background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;  
  transition-property: all;
  transition-duration: 12s;
  transition-timing-function: ease-in;
.Irotating-tablet{
  color: white;
  justify-content: center;
  display: inline-table;
  display: static;
  position: relative;
  transform: rotateZ(135deg) scale(1.7);
}
}

.LeaderShip-mobile{
  justify-content: center;
  overflow: hidden;
  color: white;
  text-align: center;
  margin-top: 15%;
}
.LeaderShip-container-mobile{
  width: 65%;
  height: auto;
  display: inline-table;
}

.LeaderShip-mobile h1 {
  font-size: 15vw;
  text-align: center;
  color: white;
  font-family:  "space-Groteskb" !important;
  padding-top: 3%;
  padding-bottom: 2%;
}
.founder-container-mobile {
  justify-content: center;
  background: #0E2D61;
  justify-items: center;
  border-radius: 20px;
  box-shadow: 0px 11px 30px #00000096;
}
.linked-in {
  position: relative;
  width: 20%;
  left: 67%;
  bottom: 50px;
}
.founder-img-mobile{
  position: relative;
  padding-top: 20px;
  // padding-bottom: 35px;
}
.founder-img-mobile img{
  width: 60%;
}
.founder-name-mobile{
  color: #FFFFFF;
  font-family:  "space-Grotesksb" !important;
  font-size: 18px;
  position: relative;
  letter-spacing: 1.2px;
  top: 7%;
}
.founder-role-mobile {
  color: #F05F30;
  letter-spacing: 1.2px;
  text-transform: capitalize;
  position: relative;
  top: 6%;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family:  "space-Groteskr" !important;
}
.founder-text-mobile{
  font-family:  "space-Groteskr" !important;
  font-size: 3.5vw;
  overflow: hidden;
  position: relative;
  color: white;
  line-height: 166%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 70px;
  font-size: 18px;
}

}

