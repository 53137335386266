@font-face {
  font-family: "Poppins-Medium";
  font-display: swap;
  src: local("Poppins"),
    url("./fonts/Poppins-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins-SemiBold";
  font-display: swap;
  src: local("Poppins"),
    url("./fonts/Poppins-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins-Regular";
  font-display: swap;
  src: local("Poppins"),
    url("./fonts/Poppins-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins-Bold";
  font-display: swap;
  src: local("Poppins"),
    url("./fonts/Poppins-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins-Italic";
  font-display: swap;
  src: local("Poppins"),
    url("./fonts/Poppins-Italic.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins-Light";
  font-display: swap;
  src: local("Poppins"),
    url("./fonts/Poppins-Light.woff2") format("woff2");
}




body {
  margin: 0;
  /* font-family: "Inter-Regular", sans-serif !important; */
  font-family: "Poppins-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}