* {
    padding: 0;
    margin: 0;
}
.services_main_banner {
    padding: 0px;
}
.background-style-service-ssd {
    background-color: #219ebc;
}
.ssd_main_banner {
    padding: 0 3rem;
}
.ssd_main_banner_block1,
.ssd_main_banner_block2 {
    padding-bottom: 2rem;
}
.ssd_banner_image {
    text-align: center;
}
.ssd_banner_image img {
    max-width: 100%;
    height: auto;
}
.banner_head {
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #f9f9f9;
    margin-bottom: 1rem;
}
.banner_head_span {
    color: #4169e1;
}
.banner_para {
    font-family: poppins, sans-serif;
    font-size: 18px;
    font-weight: lighter;
    text-align: center;
    color: #f9f9f9;
}
.ssd_blocks_headings {
    font-family: "Inter", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #1a2238;
    padding: 0 6rem;
    text-align: center;
}
.text__color {
    color: #ffb60a !important;
}
.needs-content-ssd-service {
    background-color: #f9f9f9;
    border-radius: 0px 0px 10px 10px;
    height: 260px;
    padding: 0px 12px;
}
.needs-box-fix-ssd {
    color: #f9f9f9;
    box-shadow: rgb(149 157 165 / 20%) 6px 6px 20px;
    margin: 10px 0px;
    border-radius: 10px;
    border: none;
}
.para_color {
    font-weight: bold;
    color: #002236;
}
.text_color_yellow {
    color: #ffb60a;
}
.draw_line hr {
    height: 2px;
    border: 0;
    background: #ffb60a;
    margin: 0;
}
.draw_line_mobile {
    height: 2px;
    border: 0;
    background: #ffb60a;
    margin: 0;
}
.service_head {
    display: block;
}
.service_main_head {
    display: none;
}
@media all and (min-width: 960px) {
    .ssd_main_banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6rem;
    }
    .ssd_main_banner_block1,
    .ssd_main_banner_block2 {
        flex: 0 0 50%;
        padding: 2rem 0;
    }
    .banner_para {
        text-align: left;
    }
    .needs-box-fix-ssd {
        height: 430px;
    }
    .needs-content-ssd-service {
        height: 230px;
    }
    .service_head {
        display: none;
    }
    .service_main_head {
        display: block;
    }
}
@media all and (min-width: 700px) {
    .ssd_main_banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6rem;
    }
    .ssd_main_banner_block1,
    .ssd_main_banner_block2 {
        flex: 0 0 50%;
        padding: 2rem 0;
    }
    .banner_para {
        text-align: left;
    }
}
@media only screen and (max-width: 768px) {
    .ssd_main_banner {
        font-size: 20px;
        padding: 0px;
    }
    .common-needs-box {
        margin: 10px 20px !important;
    }
    .banner_head {
        font-size: 36px !important;
        text-align: center !important;
        padding: 18px 0px !important;
    }
    .offering-box-img {
        height: 160px;
        padding: 10px 16px;
    }
    .needs-content-ssd-service {
        height: auto;
    }
    .needs-box-fix-ssd {
        height: auto;
    }
    .service_head {
        display: block;
    }
    .service_main_head {
        display: none;
    }
    .how_it_prof_head,
    .how_it_head {
        text-align: left;
    }
    .how_it_head img {
        text-align: center;
    }
    .section_sub_heading {
        font-size: 15px;
    }
    .digi_common_box .common_box {
        background-position: top;
    }
    .digi_common_3 {
        background-position: top !important;
    }
    .digi_common_box_content_3 {
        top: 46% !important;
    }
    .digi_common_box_content_2 {
        top: 37% !important;
    }
    .digi_common_box_content_1 {
        top: 45% !important;
    }
}
@media only screen and (max-width: 300px) {
    .banner_head {
        font-size: 30px !important;
    }
    .product_banner_color_1 {
        padding: 0;
    }
    footer {
        background-color: #f9f9f9;
        padding: 50px 10px 0px 10px;
    }
    .footer-section .footer-mobile .contact-links a {
        font-size: 12px;
    }
    .footer-section .footer-mobile .contact-address {
        padding: 0;
    }
    .contact_us_static_banner .content_para a {
        font-size: 14px;
    }
}
.white_color {
    color: #fff;
}
.digital_content {
    transform: skew(0deg, 0deg);
}
.models_content {
    align-items: center;
    justify-content: flex-start;
}
.models_content_end {
    align-items: center;
    justify-content: flex-end;
}
.digi_common_1 {
    background-image: url("../../fonts/images/digi_c1.png");
}
.digi_common_2 {
    background-image: url("../../fonts/images/digi_c2.1.png");
}
.digi_common_3 {
    background-image: url("../../fonts/images/digi_c3.png");
}
.digi_common_box_content_1 {
    position: absolute;
    top: 53%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 75%;
}
.digi_common_box_content_2 {
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 75%;
}
.digi_common_box_content_3 {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}
.clients_section_digi {
    margin-top: -2rem;
}
.clients_section_digi .clients-baclground-style {
    padding-bottom: 0px;
}
.digital-banner .prof_sub_heading {
    padding-bottom: 0px !important;
}
.animate::before {
    content: "Innovation";
    animation: animate infinite 3s;
}
@keyframes animate {
    0% {
        content: "Innovation";
    }
    50% {
        content: "Experience";
    }
    75% {
        content: "Possibilty";
    }
}
@media only screen and (max-width: 768px) {
    .mob_digital_banner_inner {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: 100%;
        padding: 0 24px;
        text-align: center;
    }
    .mob_digital_banner_inner h1 {
        font-family: "Poppins-Medium";
        font-size: 24px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        margin-bottom: 1rem;
    }
    .digital-banner .banner-inner-mob {
        height: auto;
    }
    .digital-banner .prof_sub_heading {
        margin-top: 19rem;
        margin-bottom: 40px;
    }
}
@media only screen and (min-width: 1900px) {
    .clients_section_digi {
        margin-top: -2.5rem;
    }
}
