.TaasBenefits{
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
    overflow: hidden;
}
.taasBenefits-Content{
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
}
.TaaSBox-main{
    padding: 0% 0% 5% 0%;
}


.taasBenefits-Content {
    padding: 5% 5% 5% 5%;
    // background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
}
.taasBenefits-Content h1{
    padding: 0% 0% 0% 0%;
    font-size: 12vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
}
.taasBenefits-Content .para1{
    font-size: 1.4vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding: 1% 10% 1% 10%;
}
.taasBenefits-Content .para2{
    font-size: 2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TaaSbox img {
    max-width: 100%;
    height: auto;
    padding: 5% 0% 0% 3%;
    width: 25%;
}

.TaaSBox-main{
    // border: 1px solid red;
}
.Taasbox-inside1{
    // border: 1px solid blue;
    display: flex;
    gap: 3%;
    padding: 2% 5% 2% 5%;
}
.Taasbox-inside1 .TaaSbox{
    border: 2px solid #FF6600;
    border-radius: 20px;
    opacity: 1;   
    width: 28vw;
    // background: linear-gradient(#032e4c, #032e4b 50%, #0f326c 50%, #0f326c);
    background:  linear-gradient(transparent, transparent 50%, #0f326c 0%, #0f326c);
    background-size: 100% 200%;
    transition: all .4s ease-in;  
    cursor: pointer;
}
.Taasbox-inside1 .TaaSbox:hover{
    background-position: 0% 100%;
    border: 2px solid transparent;
}
.Taasbox-inside1 h1{
    font-family:  "space-Grotesksb" !important;
    font-size: 1.4vw;
    color: #ffff;
    text-align: left;
    letter-spacing: 0px;
    margin: 3% 5% 2% 5%;
    text-transform: uppercase;
}
.Taasbox-inside1 p{
    font-size: 1.3vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 5% 5%; 
    line-height: 200%;
}

.Taasbox-inside2{
    // border: 1px solid blue;
    display: flex;
    gap: 3%;
    padding: 0% 5% 2% 5%;
}
.Taasbox-inside2 .TaaSbox{
    border: 2px solid #FF6600;
    border-radius: 20px;
    opacity: 1;   
    width: 28vw;
    // background: linear-gradient(#032e4c, #032e4b 50%, #0f326c 50%, #0f326c);
    background:  linear-gradient(transparent, transparent 50%, #0f326c 0%, #0f326c);
    background-size: 100% 200%;
    transition: all .4s ease-in;  
    cursor: pointer;
}
.Taasbox-inside2 .TaaSbox:hover{
    background-position: 0% 100%;
    border: 2px solid transparent;
}
.Taasbox-inside2 h1{
    font-family:  "space-Grotesksb" !important;
    font-size: 1.4vw;
    color: #ffff;
    text-align: left;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 3% 5% 2% 5%;
}
.Taasbox-inside2 p{
    font-size: 1.3vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 5% 5%; 
    line-height: 200%;
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .taasBenefits-Content {
        padding: 10% 5% 5% 5%;
    }
    .taasBenefits-Content h1 {
        font-size: 13vw;
    }
    .taasBenefits-Content .para1 {
        font-size: 1.6vw;
    }
    .taasBenefits-Content .para2 {
        font-size: 2.2vw;
    }
    .TaaSBox-main {
        padding: 0% 0% 10% 0%;
    }
    .Taasbox-inside1 .TaaSbox {
        width: 30vw;
    }
    .Taasbox-inside2 .TaaSbox {
        width: 30vw;
    }
    .Taasbox-inside1 {
        display: flex;
        gap: 0%;
        padding: 2% 0% 2% 0%;
        justify-content: space-around;
    }
    .TaaSbox img {
        max-width: 100%;
        height: auto;
        padding: 5% 0% 5% 5%;
        width: 35%;
    }
    .Taasbox-inside1 h1 {
        font-size: 1.6vw;
        letter-spacing: 0.96px;
    }
    .Taasbox-inside1 p {
        font-size: 1.5vw;
        letter-spacing: 0.44px;
    }
    .Taasbox-inside2 h1 {
        font-size: 1.6vw;
        letter-spacing: 0.96px;
    }
    .Taasbox-inside2 p {
        font-size: 1.5vw;
        letter-spacing: 0.44px;
    }
    .Taasbox-inside2 {
        display: flex;
        gap: 3%;
        padding: 0% 2% 2% 2%;
        justify-content: start;
    }
}


@media  only screen and (max-width: 480px) {
    .TaasBenefits{
        display: none;
        
    }
    .TaasBenefits-mobile{
        background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
        padding: 0% 0% 10% 0%;
    }
    .taasBenefits-Content-mobile{
        padding: 5% 2% 5% 2%;
    }
    .taasBenefits-Content-mobile h1{
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 8%;
        padding-bottom: 0%;
    }
    .taasBenefits-Content-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .taasBenefits-Content-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 0% 2% 0%;
        padding: 2% 0% 2% 0%;
    }

    .taasBenefits-Content-mobile{
        padding: 5% 2% 5% 2%;
    }
    .taasBenefits-Content-mobile2{
        text-align: -webkit-center;
    }
    .Taasbox-inside-mobile{
        // border: 1px solid red;
        padding: 10% 0% 0% 0%;
    }
    .Taasbox-inside-mobile .TaaSbox{
        background: #0F326C 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        opacity: 1;
        width: 80vw;
        height: auto;
        text-align: -webkit-left;
        padding: 0% 2% 0% 2%;
        overflow: hidden;
        margin: 0% 0% 10% 0%;
    }
    .Taasbox-inside-mobile .TaaSbox h1 {
            font-family:  "space-Grotesksb" !important;
            font-size: 18px;
            color: white;
            text-align: left;
            letter-spacing: 0px;
            margin: 3% 5% 2% 5%;
            text-transform: uppercase;
    }
    .Taasbox-inside-mobile .TaaSbox p {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 5% 5%;
        line-height: 200%;
    }
    .Taasbox-inside-mobile .TaaSbox img {
        max-width: 100%;
        height: auto;
        padding: 5% 0% 0% 3%;
        width: 30%;
    }

}


@media  only screen and (min-width: 768px){
    .TaasBenefits-mobile{
        display: none;
    }
}
  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .TaasBenefits{
        display: none;
        
    }
    .TaasBenefits-mobile{
        background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
        padding: 0% 0% 10% 0%;
    }
    .taasBenefits-Content-mobile{
        padding: 5% 2% 5% 2%;
    }
    .taasBenefits-Content-mobile h1{
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 8%;
        padding-bottom: 0%;
    }
    .taasBenefits-Content-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .taasBenefits-Content-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 0% 2% 0%;
        padding: 2% 0% 2% 0%;
    }

    .taasBenefits-Content-mobile{
        padding: 5% 2% 5% 2%;
    }
    .taasBenefits-Content-mobile2{
        text-align: -webkit-center;
    }
    .Taasbox-inside-mobile{
        // border: 1px solid red;
        padding: 10% 0% 0% 0%;
    }
    .Taasbox-inside-mobile .TaaSbox{
        background: #0F326C 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        opacity: 1;
        width: 80vw;
        height: auto;
        text-align: -webkit-left;
        padding: 0% 2% 0% 2%;
        overflow: hidden;
        margin: 0% 0% 10% 0%;
    }
    .Taasbox-inside-mobile .TaaSbox h1 {
            font-family:  "space-Grotesksb" !important;
            font-size: 18px;
            color: white;
            text-align: left;
            letter-spacing: 0px;
            margin: 3% 5% 2% 5%;
            text-transform: uppercase;
    }
    .Taasbox-inside-mobile .TaaSbox p {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 5% 5%;
        line-height: 200%;
    }
    .Taasbox-inside-mobile .TaaSbox img {
        max-width: 100%;
        height: auto;
        padding: 5% 0% 0% 3%;
        width: 30%;
    }
  }