
/* ExpandingAccordion.css */
.expanding-accordion-DataSensex {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Gap between accordion cards */
    // border: 1px solid red;
      padding-top: 5%;
      padding-bottom: 5%;
      padding-left: 5%;
      padding-right: 5%;
      width: 65%;
  }
  
  .card-DataSensex {
    // border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    // transition: all 0.9s cubic-bezier(0.4, 0, 1, 1);
    transition: all 0.5s ease-in-out;
    background-color: #040B2B;    
    padding: 1%;
  }
  
  .expanded-DataSensex {
    height: auto;
    // background:   transparent linear-gradient(180deg, #14356d 0%, #3A4CA5 100%) 0% 0% no-repeat padding-box;
      // padding: 5%;
    text-align: -webkit-left;
  }
  
  .card-header-DataSensex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    border-bottom: none;
    background: transparent;
  }
  
  .card-header-DataSensex .card-title-DataSensex {
    margin: 0;
    font-size: 0.9vw;
    font-family:  "space-Grotesksb" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
  }
  
  .card-content-DataSensex {
    display: none;
    padding: 10px;
  }
  
  .expanded-DataSensex .card-content-DataSensex {
    display: block;
    margin-left: 8px;
    font-size: 0.9vw;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.88px;
    color: #FFFFFF;
    opacity: 1;
  }
  

  .Rotate-Close{
    transform: rotateZ(135deg) scale(1.5);
    color: #FF6600;
  }
  .Rotate-Open{
    transform: rotateZ(90deg) Scale(1.5);
      color: #FF6600;
  }

  .img-Conatiner-toggle{
    // border: 1px solid blue;
    width: 40%;
    align-self: center;
  }
 .img-Conatiner-toggle img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

  .Toggle-State{
    display: flex;
  }
















// {/* Mobile */}
.expanding-accordion-DataSensex-Mobile {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Gap between accordion cards */
  // border: 1px solid red;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    width: 65%;
}

.card-DataSensex-Mobile {
  // border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  background-color: #040B2B;
  padding: 1%;
}

.expanded-DataSensex-Mobile {
  height: auto;
  // background:   transparent linear-gradient(180deg, #14356d 0%, #3A4CA5 100%) 0% 0% no-repeat padding-box;
    // padding: 5%;
  text-align: -webkit-left;
}
.card-header-DataSensex-Mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border-bottom: none;
  background: transparent;
}
.card-header-DataSensex-Mobile .card-title-DataSensex-Mobile {
  margin: 0;
  font-size: 0.9vw;
  font-family:  "space-Grotesksb" !important;
  letter-spacing: 0.96px;
  color: #FFFFFF;
  opacity: 1;
}
.card-content-DataSensex-Mobile {
  display: none;
  padding: 10px;
}
.expanded-DataSensex-Mobile .card-content-DataSensex-Mobile {
  display: block;
  margin-left: 8px;
  font-size: 0.9vw;
  font-family:  "space-Groteskr" !important;
  letter-spacing: 0.88px;
  color: #FFFFFF;
  opacity: 1;
}
.Rotate-Close{
  transform: rotateZ(135deg) scale(1) !important;
  color: #FF6600;
}
.Rotate-Open{
  transform: rotateZ(90deg) Scale(1) !important;
    color: #FF6600;
}

.img-Conatiner-toggle-Mobile{
  // border: 1px solid blue;
  width: 40%;
  align-self: center;
}
.img-Conatiner-toggle-Mobile img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.Toggle-State-Mobile{
  display: flex;
}















