* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
}

.blog-content {
  background-color: #000;
  /* padding: 3rem 6rem; */
}
.main_container_blogs{
  position: relative;
  left: 0px;
  width: 100%;
  height: auto;
  background:transparent linear-gradient(359deg, #00122E 0%, #000 14.20%, #013143 34.30%, #00072D 66.60%, #182878 79.20%, #010101 100%);
  opacity: 1;
  transition: background-color .1s cubic-bezier(0.72, 0.28, 0.75, 0.72),color .1s cubic-bezier(.075,.82,.165,1);
  overflow:hidden;
}
.search_container{
  padding: 8% 8%;
  margin-top: 5%;
  text-align: end;
}
.search_container-mobile{
  padding-left: 8%;
  padding-bottom: 8%;
}
.blogs_head{
  font-size: 90px;
  opacity: 1;
  background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;
}
.main_head{
  padding: 5% 0%;
  padding-left: 9%;
}
.main_head-mob{
  padding: 2% 7%;
  margin-top: 12%;
}
.main_sub1{
  font-family:  "space-Grotesksb" !important;
  color: #FF6600;
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 5%;
}
.main_sub1-mob{
  font-family:  "space-Grotesksb" !important;
  color: #FF6600;
  font-size: 20px;
  margin-bottom: 5px;
}
.main_sub2{
  font-family:  "space-Groteskr" !important;
  color: #fff;
  font-size: 22px;
}
.main_sub2-mob{
  font-family:  "space-Groteskr" !important;
  color: #fff;
  font-size: 18px;
}
.blogs_head-mobile{
  font-size: 11.2vw;
  opacity: 1;
  background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;
}
.blogs-section-1 {
  padding: 0 3rem;
}
.blogsMobile{
  background: linear-gradient(90deg, #0C1F4A 0%, #0B1D45 13.35%, #0C1C40 24.84%, #0B193C 36.33%, #0C1737 59.92%, #0A1330 77.77%, #080D21 100%);
  margin-top: -1px;
  padding: 30px 24px;
  text-align: center;
  .f-para1-mobile{
    color: #FF6600;
opacity: 1;
letter-spacing: 0px;
font-family:  "space-Grotesksb" !important;
font-size: 14px;
}
.blogsHead{
  line-height: 100%;
  font-size: 20vw;
  opacity: 1;
  background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskb" !important;

}
}
.blog-banner-background {
  background-color: #FBFBFB !important;
}

.blogs-section1-heading {
  font-family:  "space-Groteskr" !important;
  font-size: 36px;
  font-weight: bold;
  color: #29375B;
  text-align: center;
}

.blog_featured_post_title {
  cursor: pointer;
}

.like-section-blogpage {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

@media all and (max-width: 768px) {
  .like-section-blogpage {
    font-size: 12px;
  }
}

.blogs-section1-heading span {
  color: #3B4CA8;
}

.blogs-section1-right-side {
  text-align: center;
  margin: 0;
}

.menu-img {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.blogs-section1-para {
  font-family:  "space-Groteskr" !important;
  font-size: 18px;
  font-weight: 200;
  line-height: normal;
  padding-top: 10px;
  text-align: center;
}

.waves {
  height: 0;
  top: 0;
  position: relative;
  transform: rotate(180deg);
}

/* .section1-right-side img { width: 100%;} 
.menu-img img{width: 100%;} */
img {
  max-width: 100%;
  height: auto;
}


/* Blog Section Banner - Desktop View */
.main-blogs-blog_page {
  background-color: #1A2238;
  padding: 0 3rem;
}

.main-blogs-blog_page_no_back {
  background-color: #FBFBFB;
}

.main-blogs-blog_page {
  background-color: #1A2238;
  padding: 3rem 1rem;
}

.main-blogs-blog_page_no_back {
  background-color: #FBFBFB;
}

.blog-banner-position-color div>div .banner2-back-color {
  background-color: #fff;
}

.blog-banner-position-color div>div .banner2-caption {
  color: #fff;
}

.blog-banner-position-color div .banner-col {
  max-width: 340px;
  margin: 1.5rem auto;
}

.banner-box {
  max-width: 340px;
  height: 400px;
  padding: 0.5rem;
}

.banner-box img {
  width: 340px;
  height: auto;
  margin-top: -1.5rem;
}

.blog-banner-position-color .row>div:nth-child(2) {
  padding-top: 0em;
}


.blogs-head {
  font-family:  "space-Grotesksb" !important;
  font-size: 32px;
  font-weight: bold;
  color: #9DAAF2;
  text-align: center;
}

.blogs-para {
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
  font-weight: normal;
  color: #FFFFFF;
  text-align: center;
  padding: 1.5rem 0;
}

.blogs-learn {
  font-size: 16px;
  font-weight: normal;
  color: #FF6A3D;
  text-align: center;
  padding: 1.5rem 0;
}

.banner-col {
  max-width: 340px;
  height: auto;
  margin: 1rem auto;
}

.banner-box {
  max-width: 340px;
  height: 400px;
  background-color: #fff;
}

.banner-box-caption {
  font-family: "Montserrat-Bold";
  font-size: 16px;
  font-weight: 800;
  color: #F9F9F9;
  padding: 1rem 0;
  cursor: pointer;
}

.banner-box-learn {
  font-family:  "space-Grotesksb" !important;
  font-size: 16px;
  font-weight: normal;
  color: #FF6A3D;
  text-align: right;
  cursor: pointer;
}

.banner2-main {
  padding: 1rem 3rem;
  background-color: #FBFBFB;
  flex-wrap: nowrap;
  gap: 45px;
}

.banner2-back-color {
  max-width: 340px;
  background-color: #FF6A3D;
}

.banner2-caption {
  color: #2B2A2C;
}

.banner2-learn {
  color: #1A2238;
}

/* .blogs_carousel .slick-slide {
  height: auto;
} */

/* Blog Section Banner END - Desktop View */

/* Desktop */
@media all and (min-width: 1900px) {
  /* .blogs_carousel .slick-slide {
    height: auto;
  } */
  .align-items-like-section {
    display: flex;
    align-items: center;
  }

  .blog_banner_head h2 {
    font-family:  "space-Groteskr" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-transform: capitalize;
    color: #F05F30;
    margin-bottom: 0;
  }

  .blogs_updated_date_read1 {
    flex: 1;
    text-align: left;
    color: #0F326C;
    font-family:  "space-Groteskr" !important;
    line-height: 38px;
    font-size: 20px
  } 
  .blog_banner_head h2 {
    font-size: 32px !important;
    line-height: 48px !important;
  }
  
}

@media all and (min-width: 960px) {

  /* Blog Section Banner - Desktop View */
  .blogs-section-1 {
    display: flex;
    align-items: center;
    background-color: #FBFBFB;
    padding: 0 60px;
    height: 450px;
  }

  .blogs-section1-left-side {
    flex: 1;
  }

  .blogs-section1-right-side {
    flex: 1;
    text-align: center;
    z-index: 1;
  }

  .blogs-section1-heading {
    font-family: 'Intersans-serif';
    font-size: 57px;
    font-weight: bold;
    color: #29375B;
    text-align: left;
  }

  .blogs-section1-heading span {
    color: #3B4CA8;
  }

  .blogs-section1-para {
    font-family:  "space-Groteskr" !important;
    font-size: 18px;
    font-weight: 200;
    line-height: normal;
    padding-top: 10px;
    display: block;
  }

  .waves {
    height: 0;
    top: 0;
    position: relative;
    transform: rotate(180deg);
  }

  .main-blogs-blog_page {
    background-color: #1A2238;
    padding: 4rem 3rem 1rem 3rem;
  }

  .main-blogs-blog_page_no_back {
    background-color: #FBFBFB;
  }

  .blog-banner-position-color div>div .banner2-back-color {
    background-color: #fff;
  }

  .blog-banner-position-color div>div .banner2-caption {
    color: #fff;
  }

  .blog-banner-position-color div .banner-col {
    max-width: 340px;
    height: auto;
    margin: 0 auto;
  }



  .blogs-banner {
    display: flex;
    align-items: flex-start;
  }

  .blogs-banner-left {
    flex: 1;
  }

  .blogs-banner-right {
    flex: 1
  }

  .blogs-head {
    font-family:  "space-Groteskr" !important;
    font-size: 32px;
    font-weight: bold;
    color: #9DAAF2;
    text-align: left;
  }

  .blogs-para {
    font-family:  "space-Groteskr" !important;
    font-size: 16px;
    font-weight: normal;
    color: #3d3935;
    text-align: left;
  }

  .blogs-learn {
    font-family:  "space-Groteskr" !important;
    font-size: 16px;
    font-weight: normal;
    color: #FF6A3D;
    text-align: right;
    padding: 40px 80px;
  }

  .blogs-banner-position {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .banner-col {
    height: auto;
  }

  .blog-banner-position-color .row>div:nth-child(2) {
    padding-top: 5em;
  }

  .banner-box {
    max-width: 340px;
    height: 400px;
    padding: 0.5rem;
  }

  .banner-box img {
    width: 340px;
    height: 400px;
    margin-top: -1.5rem;
  }


  .banner-box-caption {
    font-family: "Montserrat-Bold";
    font-size: 16px;
    font-weight: bold;
    color: #3d3935;
  }

  .banner-box-learn {
    font-family:  "space-Groteskr" !important;
    font-size: 16px;
    font-weight: normal;
    color: #FF6A3D;
    text-align: right;
  }

  .banner2-main {
    padding: 50px 60px;
    background-color: #FBFBFB;
  }

  .banner2-back-color {
    background-color: #FF6A3D;
  }

  .banner2-caption {
    color: #2B2A2C;
  }

  .banner2-learn {
    color: #1A2238;
  }

  .banner2-main>div:nth-child(2) {
    padding-top: 0em;
  }

  /* Blog Section Banner END - Desktop View */

  /* Desktiop View end */
}

.blog_banner_head h1 {
  text-transform: uppercase;
}

.blog_banner_head h2 {
  font-family:  "space-Groteskr" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 48px;
  text-transform: capitalize;
  color: #F05F30;
  margin-bottom: 0;
}

.blog_head {
  font-family:  "space-Grotesksb" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #F05F30;
  text-align: left;
}

.blog_head1 {
  font-family:  "space-Groteskr" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  /* padding: 1rem 0; */
}

.blogs-background {
  background: #000;
  padding-bottom: 5rem;
}

.blog-preHead {
  background: #FFFFFF;
  color: black;
  margin-right: -30px;
}

.blogs_view {
  background-image: url('../../fonts/images/bg_blog.svg');
  /* margin-top: -4rem !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding: 48px 80px;
}

.blogs_updated_date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.blogs_updated_date_read {
  flex: 1;
  text-align: left;
  font-family:  "space-Grotesksb" !important;
  font-size: 16px
}

.blogs_updated_date_read2 {
  flex: 1;
  text-align: left;
  font-family:  "space-Groteskr" !important;
  font-size: 16px
}


.blogs_updated_date_read1 {
  flex: 1;
  text-align: left;
  font-size: 16px;
  /* color: #FFFFFF; */
  font-family: "space-Grotesksb" !important;
  line-height: 39px;
}

@media all and (max-width: 768px) {
  .read-right {
    float: right;
  }
  .arrow-carousal{
    margin-top: -3% !important;
    align-self: center !important;
  }
}

@media screen and (max-width:480px) {
  .arrow-carousal{
    margin-top: -5% !important;
  }
  .filter-categories ul {
    gap: 2% !important;
  }
  .navitem {
    font-size: 16px !important;
  }
  .box-content{
    padding-bottom: 20% !important;
  }
  .main-container-box .box-container .box{
    height: 100% !important;
  }
  
}

.blogs_updated_date_read1.blogs_updated_date_read_more {
  color: #0f326c
}


@media all and (max-width: 768px) {
  .blogs_updated_date_read1 {
    flex: 1;
    text-align: left;
    font-size: 14px;
    color: #000000;
    font-family: "space-Grotesksb" !important;
    line-height: 38px;
  }

  .blog_head1.blog_head1_short_desc {
    color: white;
  }

  .blog_head1.blogs_updated_date1 {
    color: white;
  }
}

.blogs_updated_date1 {
  padding-top: 48px;
  display: flex;
}

.blog_head1_short_desc {
  padding-top: 30px;
}

.date-font {
  /* font-family: italic !important; */
  font-size: 16px;
  font-family:  "space-Groteskr" !important;
}

.blogs_updated_date_icons {
  flex: 0 0 50%;
  text-align: right;
  display: none;
}

.blogs_updated_date_icons1 {
  flex: 0 0 50%;
  text-align: right;
}


.blogs_updated_date_read span {
  margin-left: 1rem;
}

.models_left {
  height: 470px;
  background: #fff;
  order: 2;
  max-width: 40%;
  flex: 0 0 40%;
  border-radius: 10px;
  right: 3rem;
  z-index: 999;

}

.models_right {
  background: #fff;
  padding: 0;
  max-width: 60%;
  flex: 0 0 60%;

}

@media (min-width: 1201px) {
  // .blogs_carousel .slick-next {
  //   right: -70px;

  // }

  // .blogs_carousel .slick-prev {
  //   left: -50px;
  // }

}


.models_right img {
  width: 100%;
  height: 421px;
}

.models_content_blog {
  align-items: center;
  justify-content: space-around;
  padding-top: 32px;
}

.blogs_img_post {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}

.blogs_img_post img {
  width: 100%;
}

.blog_title {
  cursor: pointer;
}

.blogs_post_content {
  padding: 0;
}
.error_text-email {
  font-size: 14px;
  margin-top: 3%;
    margin-left: 2%;
  color: red;
  padding: 0 0rem !important;
  font-family:  "space-Groteskr" !important;
}
.blog_main {
  padding-bottom: 2rem !important;
}

.color_black {
  color: #000 !important;
}

.vertical_center {
  position: relative;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
  /* flex: 0 0 40%; */
  max-width: 80%;
}

.blog_featured_post_title {
  padding: 20px 0px;
}

.blog_featured_short_desc {
  padding: 0px 0px 20px 0px;
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.blogs-margin {
  margin: 0 auto;
  background-color: #F3F3F5;
}

.blogs {
  font-family:  "space-Groteskr" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center !important;
  color: #F05F30;
  margin: 0 auto;

}

.enjoy {
  font-family:  "space-Groteskr" !important;
  font-size: 18px;
  color: #F05F30;
  text-align: center;
}

.share {
  font-family:  "space-Groteskr" !important;
  font-size: 18px;
  text-align: center;
}

.blog_contactbtn {
  text-align: center !important;
}

.contactbtn-blog a {
  margin: 0 auto;
  font-family:  "space-Grotesksb" !important;
  font-size: 18px;
  text-align: center;
  color: white !important;
  background-color: #F05F30;
  box-sizing: border-box;
  filter: drop-shadow(0px 16px 60px rgba(0, 0, 0, 0.15));
  border-radius: 20px;
  line-height: 45px;
  /* height: 45px; */
  padding: 6px 65px;
  white-space: nowrap;
  text-decoration: none;
}

.blogs_title {
  padding: 48px 80px;
}

.blog_line {
  max-width: 100% !important;
}

// .blogs_carousel .slick-arrow {
//   display: block !important;
// }

// .blogs_carousel .slick-slide img:hover {
//   transform: translateY(0rem);
// }


/* ipad Air */
@media (min-width: 820px) and (max-width: 920px) {
  .blogs_view {
    padding: 48px 24px;
  }
  .insta_view {
    padding: 48px 24px;
  }
  .models_content_blog {
    justify-content: space-around;
  }
}

/* Tablet */
@media all and (max-width: 768px) {
  .blog-background-style {
    background: #F3F3F5;
  }

  .blogs_post_content {
    order: 2;
  }

  // .blogs_carousel_mob .slick-slide {
  //   height: auto;
  // }

  // .blogs_carousel_mob .slick-slide {
  //   padding-right: 0;
  // }

  .blogs_carousel_mob {
    display: block;
    /* padding: 0px 24px; */
    margin-bottom: 2rem;
  }

  .blogs_carousel_mob .models_content_blog {
    padding-bottom: 0 !important;
  }

  .blogs_carousel_mob .how_it_head {
    padding: 12px;
  }


  .blogs_title {
    padding: 2rem 1.5rem;
  }

  .blogs-background {
    padding-bottom: 3rem;
  }

  .blogs_view {
    padding: 48px 24px 0px 24px;
  }
  .insta_view {
    padding: 48px 24px 0px 24px;
  }

  .models_content_blog {
    width: 100%;
    margin: 0 auto !important;
    padding-bottom: 1rem !important;
    padding-top: 16px;
  }

  .models_left {
    order: 2;
    height: 320px;
  }

  .blogs_post_content_featured {
    // order: 2;
    background-color: #fff;
  }

  .mob_banner_content {
    padding: 2rem 0;
  }

  .mob_blog_head {
    font-family: "space-Grotesksb" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #F05F30;
    padding: 2rem 0;
  }

  .mob_blog_sub {
    font-family: "space-Grotesksb" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #F05F30;
    padding: 0.5rem 0;
  }

  .mob_blog_para {
    font-family: "space-Grotesksb" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000;
    padding: 0.5rem 0;
  }

  .banner_blog_btn a {
    padding: 0 120px;
    margin-top: 1rem;
    max-width: 350px;
  }

  .blog_head {
    font-size: 18px;
    line-height: 27px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .how_it_head.how_it_head_mob {
    padding: 1rem;
  }

  .blog_head1 {
    font-size: 14px;
    line-height: 21px;
  }

  .blog_head1 {
    padding-top: 0px;
    padding-bottom: 18px;
    color: #000000;
  }

  .blogs_updated_date_icons {
    flex: 0 0 35%;
  }

  .blogs_carousel_mob .blogs_updated_date_icons {
    flex: 0 0 50%;
  }


  .blogs_updated_date_read span {
    margin-left: 1rem;
  }

  .blogs_updated_date_read {
    font-size: 12px;
    font-family:  "space-Groteskr" !important;
  }

}

.insta_view {
  background: #000;
  padding-bottom: 5rem;
  padding-right: 150px;
}
.instagram{
  transition: 0.5s all ease;
  display: flex;
  align-items: center;
  /* text-align: center; */
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 0.5rem;
  background-color: rgba(60, 60, 60, 0.25);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  justify-content: center;
  min-height: 400px;
}
.divider{
  height: 2px !important;
  width: 100% !important;
  border-radius: 2px !important;
}
.insta_slider{
  /* width:950px !important; */
  position: relative;
}
.heading_rotate_img{
  margin-left: 50px;
  margin-right: -2.5rem;
}
.insta_heading_rotate_img{
  margin-left: 50px;
  margin-right: -2.5rem;
  transform: rotate(220deg);
}
.heading_img{
  margin-left: -20px;
  /* margin-right: -4.5rem; */
  vertical-align: middle;
}
.insta_heading_img{
  margin-left: -10px;
  margin-right: -4.5rem;
  vertical-align: middle;
}
.container-new{
  height: 490px;
  /* margin-top: 24px !important; */
}
.insta_items{
  width: auto !important;
}
// .insta_items .slick-slide{ 
//   padding-right: 0;
// }


@media all and (max-width: 420px) {
  .banner_blog_btn a {
    padding: 0 90px;
    margin-top: 1rem;
    max-width: 300px;
  }
}


@media all and (min-width: 1200px) {
  .blogs-background {
    background: #000;
    padding: 0px 80px 86px 80px;
  }

}

.readmore-img {
  position: relative;
  bottom: 6px;
  float: left;
}

@media all and (max-width: 768px) {
  .readmore-img {
    bottom: 0px;
  }
}

@media screen and (min-width:768px) and (max-width:819px)  {  
  .blogs_view{
    padding: 48px 24px;
  }
  .insta_view{
    padding: 48px 24px;
  }

.blog-background-style {
  background: #F3F3F5;
}
// .blogs_carousel_mob .slick-slide {
//   height: auto;
// }

// .blogs_carousel_mob .slick-slide {
//   padding-right: 0;
// }

.blogs_carousel_mob {
  display: block;
  /* padding: 0px 24px; */
  margin-bottom: 2rem;
}



.blogs_carousel_mob .how_it_head {
  padding: 12px;
}


.blogs_title {
  padding: 2rem 1.5rem;
}

.blogs-background {
  padding-bottom: 3rem;
}

.blogs_view {
  padding: 48px 24px 0px 24px;
}
.insta_view {
  padding: 48px 24px 0px 24px;
}

.models_content_blog {
  width: 100%;
  margin: 0 auto !important;
  padding-bottom: 1rem !important;
  padding-top: 16px;
}

.models_left {
  order: 2;
  height: 320px;
}

.blogs_post_content_featured {
  // order: 2;
  background-color: #fff;
  margin: 0 auto;
  max-width: 100%;
}

.mob_banner_content {
  padding: 2rem 0;
}

.mob_blog_head {
  font-family: "space-Grotesksb" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #F05F30;
  padding: 2rem 0;
}

.mob_blog_sub {
  font-family: "space-Grotesksb" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #F05F30;
  padding: 0.5rem 0;
}

.mob_blog_para {
  font-family: "space-Grotesksb" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000;
  padding: 0.5rem 0;
}

.banner_blog_btn a {
  padding: 0 120px;
  margin-top: 1rem;
  max-width: 350px;
}

.blog_head {
  font-size: 18px;
  line-height: 27px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.how_it_head.how_it_head_mob {
  padding: 1rem;
}

.blog_head1 {
  font-size: 14px;
  line-height: 21px;
}

.blog_head1 {
  padding-top: 0px;
  padding-bottom: 18px;
  color: #fff;
}

.blogs_updated_date_icons {
  flex: 0 0 35%;
}

.blogs_carousel_mob .blogs_updated_date_icons {
  flex: 0 0 50%;
}


.blogs_updated_date_read span {
  margin-left: 1rem;
}

.blogs_updated_date_read {
  font-size: 12px;
  font-family:  "space-Groteskr" !important;
}
.models_content_blog {
  width: 100%;
  margin: 0 auto !important;
  padding-top: 16px;
  display: inline-block;
}

.blogs_img_post_feature{
  max-width: 100%;
  margin: 0 auto;
}

.models_content_blog_lines{
  display: flex;

}
.blog_banner{
  padding-top: 0px !important;
  padding-bottom: 24px;
}

}

// .slick-slide{
//   z-index: 0;
// }

// .slick-slide.slick-active.slick-current {
//   z-index: 9999;
//   height: auto;
//   /* width: 971px !important; */
// }
.slide-wrapper{
  // padding-top: 20px;
  // text-align:center;
  // width: 200px;
  // display:inline-block;
  // margin: 0px 100px;
  // color: red;
  padding-right: 10%;
  padding-left: 10%;
  .slick-arrow{
    display: flex;
  }
}
h2{
  font-family:sans-serif;
  color:#fff;
}
.carousel{
  width:100%;
}
.section_heading{
  /* display: none; */
  font-family:  "space-Grotesksb" !important;
  text-align: left;
  font-size: 40px;
  font-weight: 500;
  color: #FFF;
  line-height: 72px;
  margin-bottom: 3rem;
  margin-top: 1rem;
}
.insta_heading{
  /* display: none; */
  font-family:  "space-Grotesksb" !important;
  text-align: left;
  font-size: 40px;
  font-weight: 500;
  color: #FFF;
  line-height: 72px;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.allblogs .featured, .smartcode, .casestudy, .productmanagement, .evenionofferings, .designux, .devops, .managementdesk {
  margin-bottom: 3rem;
}
.scrolled{
  // position: sticky;
  /* width: 90%; */
  align-items: center;
  top: 70px;
  left: 0;
  z-index: 99;
  /* animation: moveDown 0.5s ease-in-out; */
  /* background-color: red; */
}
#all.active{
  /* padding-top: 120px; */
padding-top: 107px;
}

 .navlist{
  // position: sticky; 
  text-align: center;
  padding-left: 6%;
  padding-right: 6%;
    /* background-color: #FFFFFF; */
  /* border: 1px solid #F05F30; */
  border-radius: 2.5rem;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}
.navlist ul {
  list-style-type: none;
  text-align: center;
  padding: 1px 15px;  
}

.navlist li {
  display: inline-block;
}

.navitem {
  display: inline-block;
  padding: 10px;
  color: #d2ccc9 !important;
  font-size: 20px;
}
 .navitem:hover {
  // border-bottom: 3px solid #F05F30;
  color: #fff !important;
  text-decoration: none;
}

.active{
  // border-bottom: 3px solid #F05F30;
  color: #F05F30 !important;
    background: rgb(255 255 255 / 20%);
    border-radius: 5px;
}
.active .navitem{
  background-color: rgba(255,255,255,0.3);
  border-radius: 5px;
    // color: #d2ccc9;
}
.active :hover{
  color: #F05F30 !important;
  border-bottom: 0px;
}
.text-carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.text-carousel {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carousel-item {
  min-width: 300px; /* Adjust the width as needed */
  padding: 10px;
  border: 1px solid #ccc;
}

.active {
  font-weight: bold;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.scroll-button:first-child {
  left: 0;
}

.scroll-button:last-child {
  right: 0;
}

@media screen and (max-width: 768px) {
  ul.navlist {
    display: -webkit-box;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #f05f30;
    padding-left: 0;
    width: -webkit-fill-available;
    margin: 0 -1.5rem 2rem;
    height: 50px;
    z-index: 1;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-color: auto;
}
.scrolled {
    // position: -webkit-sticky;
    // position: sticky;
    align-items: center;
    top: 70px;
    left: 0;
    z-index: 99;
}
.btn-loadmoremob .loadmore_icon {
  margin-top: 0px !important;
}
  ul .navlist{
    /* display: -webkit-inline-box; */
    display: -webkit-box !important;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #f05f30;
    padding-left: 0;
    width: -webkit-fill-available;
    margin: 0 -1.5rem 2rem;
    height: 50px;
    z-index: 1;
    overflow: hidden;
    overflow-x: scroll;
  }
  .scrolled{
    // position: -webkit-sticky;
    // position: sticky;
    align-items: center;
    top: 70px;
    left: 0;
    z-index: 99;
  }
   .section_heading{
    display: none;
  } 
  /* .all.featured, .smartcode, .casestudy, .productmanagement, .evenionofferings, .designux, .devops, .managementdesk {
    padding-top: 0rem;
  } */
  li .navitem{
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all .7s;
    line-height: 14px;
  }
  ul {
    list-style-type: none;
}
  .navitem {
    display: -webkit-box;
    color: #d2cccb;
    text-align: center;
    font-size: 18px;
    font-family:  "space-Groteskr" !important;
    position: relative;
    line-height: 0;
  }
  .TabContent {
    font-size: 2rem;
    text-align: center;
  }
}

 .main-container-box {
  transition: 0.5s all ease;  
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 1.3rem;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: .5rem;
  padding-left: 6%;
  padding-right: 6%;
  // background-color: rgba(60, 60, 60, 0.25);
  // backdrop-filter: blur(10px);
  // -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  justify-content: center;
  min-height: 400px;
}
.box-content{
  width: 100%;
}

 .main-container-box .box-container{
  background: none;
  /* display: flex; */
  display: contents;
  flex-wrap: wrap;
  gap: 1rem; 
  padding: 1rem;
  margin: 0%;
  /* min-height: 400px; */
}
.designer-ux{
  display: flex;
  justify-content: flex-start;
}

 .main-container-box .box-container .box{
  transition: 0.5s all ease;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(16, 24, 29);
  // backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: .5rem;
  flex: 0 1 calc(100%/3 - 15px);
  box-sizing: border-box;
  height: 485px;
  margin: 0%;
}
 .box-container{
  background: none;
  /* display: flex; */
  display: inline-block;
  /* flex-wrap: wrap; */
  gap: 1rem; 
  padding: 1rem;
  margin: 0%;
}
::marker{
  display: none !important;
}
 .box{
  transition: 0.5s all ease;
  display: -webkit-box;
  display: inline-block;
  /* flex-wrap: wrap; */
  background-color:rgba(60, 60, 60, 0.25);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: .5rem;
  /* flex: 0 1 calc(100%/3 - 15px); */
  box-sizing: border-box;
  padding-bottom: -1rem;
  padding-left: 0;
  padding-right: 0;
  /* width:33%; */
}

 .blogimg{
  /* height:40%; */
  height: 180px;
  /* object-fit: fill; */
  width:100%;
  border-radius: .5rem;
  background-color: #000;
  cursor: pointer;
}

 .box-details h3{
  font-family:  "space-Grotesksb" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #F05F30;
  text-align: left;
  padding: 1rem;
  // height: 75px;
  cursor: pointer;
  // margin-bottom: 15px;
  padding-bottom: 0px;
}

 .box h4{
  font-family:  "space-Groteskr" !important;
  font-style: normal;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  justify-content: center;
  margin-bottom: 1.5rem;
}

 .box .date-area{
  font-style: normal;
  color: #909C9D;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  padding-left: 1rem;
  text-align: left;
  margin-bottom: 1rem;
}
 .box .readtime{
  font-style: normal;
  color: #909C9D;
  // float: right;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  // padding-right: 1rem;
}
 /* .box .hashtag-area{
  font-style: normal;
  color: #909C9D;
  font-weight: 400;
  font-size: 16px;
  padding-left: 1rem;
  text-align: left;
  margin-bottom: 1rem;
} */
  .box .hashtag{
  font-style: normal;
  color: #909C9D;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}
 .box:hover{
  .blogimg{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1.03);
    transition: 1s ease;
  }
  // border: 1px solid red;
}
 .main-container-box .box-container .box:hover{
  .blogimg{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1.03);
    transition: 1s ease;
  }
  // border: 1px solid red;
}
 .main-container-box .box-container .box .blogimg{
      /* height: 40%; */
      height: 215px;
      object-fit: cover;
      width: 93%;
      margin-top: 3%;
      // border: 1px solid orangered;
      border-radius: 0.7rem;
      background-color: #000;
      cursor: pointer;
}
.blogRow{
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
  // border: 1px solid grey;
  /* margin-top: 3%; */
  position: absolute;
  bottom: 0;
  width: 100%;
  color:grey;
  display: flex;
  // margin: 0px -12px -12px;
  border-top: 1px solid rgb(31, 48, 58);
}
 .main-container-box .box-container .box .box-details h3{
  font-family:  "space-Grotesksb" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 22px; */
  color: #fff;
  text-align: left;
  padding: 1rem;
  cursor: pointer;
}
 .main-container-box .box-container .box h4{
  font-family:  "space-Groteskr" !important;
  font-style: normal;
  color: white;
  font-weight: 400;
  font-size: 16px;
  // line-height: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  justify-content: center;
  margin-bottom: 1.5rem;
}
 .main-container-box .box-container .box .date-area{
  font-style: normal;
  color: #909C9D;
  font-weight: 400;
  font-size: 16px;
  // line-height: 27px;
  padding-left: 1rem;
  text-align: left;
  margin-bottom: 1rem;
}
.main-container-box .box-container .box .readtime{
  font-style: normal;
  color: #909C9D;
  float: right;
  font-weight: 400;
  // font-size: 16px;
  line-height: 27px;
  padding-right: 1rem;
}
.main-container-box .box-container .box .hashtag-area{
  font-style: normal;
  color: #909C9D;
  font-weight: 400;
  font-size: 16px;
  padding-left: 1rem;
  text-align: left;
  padding-bottom: 1rem;
}
 .main-container-box .box-container .box .hashtag{
  font-family:  "space-Groteskr" !important;
  font-style: normal;
  color: #909C9D;
  font-weight: 300;
  font-size: 15px;
  text-align: left;
}

 .btn_loadmore_container{
  border:1px #FF6600 solid ;
  width: 140px;
  height: 45px;
  border-radius: 5px;
  /* margin-top: 1rem;   */
  cursor: pointer;
  margin: 1rem auto;
  background-color: transparent;
  color: white;
  font-family:  "space-Groteskr" !important;
  font-weight: lighter;
  background-image: linear-gradient(to left,
  transparent,
  transparent 50%,
  #FF6600 50%,
  #FF6600);
  background-position: top bottom;
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all .9s ease-in; 
}
.btn_loadmore_container:hover{
  border:1px #FF6600 solid ;
  width: 140px;
  height: 45px;
  border-radius: 5px;
  background-position: 0 0; 
  background-color: transparent;
  box-shadow: 0px 8px 6px #ffffff26;
}
 .btn_loadmore_container .loadmore_icon{
  margin-left: 10px;
  width: 9px;
}
.btn-loadmoremob{
  margin-top: 0.3rem;
}
 .btn_loadmore{
  margin-top: 0.5rem;
  font-size: 14px;
  color: #FFF;
  background-color: transparent;
} 
.btn-loadmoremob .loadmore_icon{
  margin-left: 10px;
  width: 10px;
  margin-top: 13px;
}
 .loadmore_icon:active{
  animation: effect_dylan 0.5s ease-out;

}
.subscribe-container{
  display: flex;
  justify-content: center;
  padding-bottom: 8%;
  margin-top: 5%;
}
.subscribe_input{
  border: none;
  outline: none;
  font-size: 14px;
  background: none;
  color: white;
  height: 45px;
  width: 70%;
  font-size: 16px;
  padding: 2%;
  border-bottom: 1.5px solid #FF6600;
  font-family:  "space-Groteskr" !important;
  margin-top: 8%;
  margin-right: 3%;
}
.subscribe_text{
  color: rgb(255, 255, 255);
  max-width: 210px;
  line-height: 20px;
  // user-select: none;
  font-family:  "space-Groteskr" !important;
  font-size: 24px;
}
.subscribe_button{
  padding: 11px 21px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #FF6600;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  font-family:  "space-Groteskr" !important;
  font-weight: lighter;
  background-color:#FF6600 ;
}

@keyframes effect_dylan {
  50% {
    transform: scale(1.5, 1.5);
    opacity: 1;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0, 0);
    opacity: 0;
  }
}


@media screen and (max-width: 600px) {
   .main-container-box .box-container{
    background: none;
    /* flex-wrap: wrap; */
    gap: 1rem; 
    padding: 0rem 2rem;
    display: inline-block;
    flex: auto;
  }
  // .main-container-box .box-container .box{
  //   height: auto !important;
  // }
  .main-container-box .box-container .box{
    display: inline-block;
    margin-top: 2rem;
    /* height: 460px; */
    width:100%;
  }
 .main-container-box {
    padding: 0rem 0rem 1rem 0rem;
  }
  .subscribe-container{
    margin-bottom: 15%;
    text-align: center;
    padding: 0% 5%;
    margin-top: 25%;
  }
  .subscribe_input{
    width: 85%;
  }
  .subscribe_text{
    font-size: 20px;
  }
  .subscribe_button{
    margin-top: 8%;
  }
  /* .section_heading{
    font-size: 18px;
  } */
}

@media screen and (min-width: 601px) and (max-width:920px) {
  .main-container-box .box-container{
   background: none;
   /* flex-wrap: wrap; */
   gap: 2rem; 
   padding: 0rem 3rem;
   display: inline-block;
   flex: auto;
 }
 // .main-container-box .box-container .box{
 //   height: auto !important;
 // }
 .main-container-box .box-container .box{
  display: inline-block;
  margin-top: 1rem;
   height: 545px;
  width: 45%;
  margin: 2%;
  gap: 4rem !important;
  vertical-align: top;
  max-width: 45% !important;
 }
.main-container-box {
   padding: 0rem 0rem 1rem 0rem;
 }
 /* .section_heading{
   font-size: 18px;
 } */
}


.tabitem{
  line-height: 0;
  display: inline-block;
  color: #0F326C;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-bottom: 3px solid transparent;
  font-family:  "space-Groteskr" !important;
  position: relative;
  font-style: normal;
  font-weight: 500;

}

.all{
  padding-top: 4rem;
}

// @media screen and (min-width: 1900px) {
//   .main-container-box .box-container .box .blogimg{
//     height: 360px !important;
//   }

//   .main-container-box .box-container .box {
//     height: 620px !important;
//   }
// }

@media screen and (max-width:1365px) and (min-width:921px) {
  .main-container-box .box-container .box {
    height: 510px !important;
  }  
}


@media all and (min-width: 920px){
  .instagram{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .insta_items{
    max-width: 100%;
  }
  .instagram .container-new{
    max-width: 100%;
  }
  // .insta_items .slick-slide{
  //   margin: 0 auto;
  //   align-items: center;
  // }
  .insta_mob_slider{
    display: none;
  }
}
@media all and (min-width: 320px) and (max-width: 580px){
  .instagram{
    height: 700px;
  }
  .insta_items{
    max-width: 100%;
    justify-content: center;
    margin-top: -170px;
  }
  .instagram .container-new{
    margin-top: 10px !important;
    max-width: 100%;
    justify-items: center;
  }

  // .insta_items .slick-slide{
  //   margin: 0 auto;
  //   align-items: center;
  // }
  .insta_slider{
    display: none;
  }
  .insta_mob_slider{
    margin-top: -20px;
  }
  .insta_heading_rotate_img{
    margin-left: 0px;
    margin-right: -2.5rem;
    transform: rotate(212deg);
    height: 75px;
  }
  .insta_heading_img{
    margin-left: 0;
    margin-right: -4.5rem;
    font-size: 30px;
    vertical-align: -webkit-baseline-middl
  }
  .insta_heading{
  font-family:  "space-Grotesksb" !important;
  text-align: left;
  font-size: 30px;
  font-weight: 450;
  color: #FFF;
  line-height: 60px;
  margin-bottom: 3rem;
  margin-top: -2rem;

  }
}

@media all and (max-width:1023px) {
  .btn_loadmore_container{
    border:1px #FF6600 solid ;
  width: 140px;
  height: 45px;
  border-radius: 5px;
  /* margin-top: 1rem;   */
  cursor: pointer;
  margin: 1rem auto;
  background-color: transparent;
  color: white;
  font-family:  "space-Groteskr" !important;
  font-weight: lighter;
  // background-color: #FF6600;
  }
  
}
@media all and (min-width: 590px) and (max-width: 880px){
  .instagram{
    height: auto;
  }
  .insta_items{
    max-width: 100%;
  }
  .instagram .container-new{
    max-width: 100%;
  }
  // .insta_items .slick-slide{
  //   margin: 0 auto;
  //   align-items: center;
  // }
  .insta_mob_slider{
    display: none;
  }
  .insta_heading_rotate_img{
    margin-left: 13px;
    margin-right: -2.5rem;
    transform: rotate(212deg);
    height: 80px;
  }
  .insta_heading_img{
    margin-left: 0;
    margin-right: -4.5rem;
    font-size: 30px;
    vertical-align: -webkit-baseline-middl
  }
  .insta_heading{
  font-family:  "space-Grotesksb" !important;
  text-align: left;
  font-size: 30px;
  font-weight: 450;
  color: #FFF;
  line-height: 60px;
  margin-bottom: 3rem;
  margin-top: -2rem;

  }
}
.search_found{
  color: #fff;
  font-family:  "space-Groteskr" !important;
  margin-left: -14%;
  margin-top: 2%;
}
.gif-search{
  margin-top: -10%;
}

@media screen and (max-width:1230px) and (min-width:768px) {
  .search_found{
    font-family:  "space-Groteskr" !important;
    margin-left: -20%;
    margin-top: 1%;
    font-size: 3vw;
  }
  .gif-search{
    margin-top: -14%;
    width: 55%;
    margin-left: -10%;
  }
}

@media all  and (max-width:920px) {
  .search_found {
    font-family:  "space-Groteskr" !important;
    margin-left: 0%;
    /* margin-top: 1%; */
    font-size: 22px;
    text-align: center;
}
.gif-search {
  margin-top: -14%;
  width: 60%;
  margin-left: 115px;
}
  .back2blogs{
    margin-top: 5% !important;
    margin-bottom: 15%;
    text-align: -webkit-center;
  }
  .back2blogs div {
    text-align: center;
    .rotatee{
}
  }
}

@media screen and (max-width:520px) {
  .gif-search {
    margin-top: -30% !important;
    width: 100% !important;
    margin: 0px;
  }
}


.filter-categories:not(.allow-overflow) {
  overflow-x: auto;
  overflow-y: hidden;
}
.filter-categories {
  position: relative;
  font-family:  "space-Groteskr" !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  padding-left: 6%;
  padding-right: 6%;
  width: 100%;
  flex-direction: row;
  display: flex;
  margin-top: 2%;
}
.filter-categories:not(.allow-overflow) ul {
  overflow-x: auto;
  overflow-y: hidden;
}
::-webkit-scrollbar{
  display: none;
}
.filter-categories ul {
  display: flex;
  gap: 2.9%;
  // padding: 0 2px;
  white-space: nowrap;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scrollbar-width: 0px;
  width: 100%;
}

@media screen and (min-width:1990px) {
  .filter-categories ul {
    gap:4.5%;
    margin-top: 0.5%;
  }
}
ol, ul {
  list-style: none;
}

// .filter-categories .category {
//     display: inline-block;
//     // padding-right: 65px;
// }
.filter-categories .scroll-forward a {
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), color-stop(50%, white));
  background-image: linear-gradient(to right, rgba(255,255,255,0) 0%, white 50%);
}

.filter-categories .scroll a {
  position: absolute;
  z-index: 1;
  width: 40px;
  padding: 10px 0;
  color: #d2ccc9;
}

.arrow-carousal{
  cursor: pointer;
  margin-top: 1%;
}

.back2blogs{                         
  font-size: 16px;
  line-height: 36px;
  /* text-align: center; */
  border-radius: 5px;
  font-weight: 600;
  color: #fff !important;
  margin-top: 2% ;
  }

.back2blogs div{      
  display: block;
  background-image: linear-gradient(to left, transparent, transparent 50%, #F05F30 50%, #F05F30);
  background-position: 100% 0;
  background-size: 201% 100%;
  width: 170px;
  padding: 10px 0px;
  cursor: pointer;
  color: #fff !important;
  border: 1px solid #F05F30;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all .6s ease-in;
  /* color: black; */
  height: 55px;
  text-align: center;
  margin-left: 1%;
  .rotatee{
    transform: scale(1)rotateZ(180deg);
    transition: all .6s ease-in;
    color: #fff !important;
}

}
.back2blogs div:hover {       
  background-position: 0 0; 
  box-shadow: 0px 8px 6px #00000047;
  border-radius: 5px;
  border: 1px solid #F05F30;
  color: #fff;
  .back2blogs-text{
      background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
      background-position: 0 0; 
  }
  .rotatee{
    transform: scale(1.2)rotateZ(135deg);
    color: white !important;
    background-position: 0 0; 
}
}
.back2blogs-text{
  /* font-size: 1.2vw; */
display: inline-block;
position: relative;
opacity: 1;
background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
background-clip: border-box;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-family:  "space-Groteskr" !important;
border-radius: 5px;
}
.back2blogs-text:hover{
  display: inline-block;
  position: relative;
  opacity: 1;
  color: #fff;
  background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important; 
}