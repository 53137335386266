*{
    padding: 0;
    margin: 0;
}
.services_main_banner{
    padding: 0px
}
.services-content {
    height: 115px;
}
.needs-content-service {
    background-color: #F9F9F9;
    border-radius: 0px 0px 10px 10px;
    height: 415px;
    padding: 0px 12px;
    
  }
.common_box{
    min-height: 510px;
    max-width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
}
.common_1{
    background-image: url('../../fonts/images/proj_common_1.png');
}
.common_2{
    background-image: url('../../fonts/images/proj_common_3.png');
}
.common_3{
    background-image: url('../../fonts/images/proj_common_2.png');
}
.common_box_content{
    position: absolute;
    top: 60%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 75%;
}
.how_it_works_color{
        /* background-color: #2565AE; */
        /* background-image: linear-gradient(to right, rgba(15,50,108,89%), rgba(1,1,1,100%)); */
        background-image: url('../../fonts/images/how_it_works_background_1.png');
        transform-origin: 0% 100%;
        margin-top: -7rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        transform: skew(0deg, -1deg);
    }
.common_box_content_1{
    position: absolute;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}
.project-services-background-style {
    /* background-color: #8CD6BC ; */
}
.ssd_main_banner{
    padding: 0 3rem;
}
.ssd_main_banner_block1, .ssd_main_banner_block2{
    padding-bottom: 2rem;
}
.ssd_banner_image{text-align: center;}
.ssd_banner_image img{max-width: 100%; height: auto;}
.banner_head{
    font-family: "Montserrat-Bold";
    font-size: 3rem;
    font-weight: bold;
    color: #F9F9F9;
    margin-bottom: 1rem;
}
.banner_head_span{
    color: #4169E1;
}
.banner_para{
    font-family: "Montserrat-Regular";
    font-size: 18px;
    font-weight: lighter;
    text-align: center;
    color: #F9F9F9;
    font-size: 1rem;
    font-weight: 300;
    line-height: 30px;
    padding-top: 10px;
    display: block;
    text-align: left;
}
.project_blocks_headings{
    font-family: "Montserrat-Regular";
    font-weight: 400;
    line-height: 55px;  
    font-size: 36px;
    color: #1A2238;
    padding: 0 6rem;
    text-align: center;
}
.common-image-heading{
    font-family: "Montserrat-Regular";
    font-weight: 800;
    font-size: 25px;
    color: #2B2A2C;
    line-height: 30px;
}

.center__align{
    text-align: center;
}
.text_para_align{
    text-align: left;
    text-indent: -2.7rem;
    margin-left: 2.5rem;
}
  .engage_logo_line{
      max-width: 40%;
      padding-bottom: 24px;
      line-height: 0;
  }
  .steps_content{
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
.steps_number{
    font-family: 'Times New Roman';
    font-style: normal;
    font-weight: 700;
    font-size: 15rem;
    line-height: 180px;
    color: #F05F30;
    text-shadow: -17px 18px 20px rgba(0, 0, 0, 0.52);
}

.services_img_1{
    position: relative;
}
.services_img_caption{
    position: absolute;
  top: 25%;
  left: 49%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.services_contact_caption{
    position: absolute;
    top: 70%;
    left: 70%;
    transform: translate(-50%, -50%);
}
.caption_heading{
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    line-height: 27px;
}
.caption_heading_1{
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}
.caption_ques{
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    background: linear-gradient(180deg, #100686 0%, #3966A2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.caption_link a{
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #100686;
    line-height: 27px;
    text-decoration: none;
}
.caption_link a:hover{
    color: #FFF;
}

.banner_btn_1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner_btn_1 a{
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    border: 3px solid #FFFFFF;
    border-radius: 20px;
    line-height: 40px;
    height: 45px;
    padding: 0 22px;
    max-width: 145px;
    white-space: nowrap;
    font-size: 16px;
    display: block;
    cursor: pointer;
    box-sizing: border-box;
    filter: drop-shadow(0px 16px 60px rgba(0, 0, 0, 0.15));
}
.banner_btn_1 a:hover{
    text-decoration: none;
}
.our_btn_2{
    position: absolute;
    top: 61%;
    left: 54%
}
.add_value_head{
    padding: 0px 0px 44px 0px;
}

/* Desktop View */
@media all and (min-width: 960px) {  
.ssd_main_banner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6rem;
    height: 450px;
}
.ssd_main_banner_block1, .ssd_main_banner_block2{
    flex: 0 0 50%;
    padding: 2rem 0;
}
.banner_para{
    text-align: left;
}
.text_para_align{
    text-indent: -2.6rem;
    margin-left: 2.4rem;
}
.banner img{
    height: 542px;
}

}
/* Desktop View END */
/* Tablet menu */
@media all and (min-width: 700px) {
    .ssd_main_banner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6rem;
        height: 450px;
    }
    .ssd_main_banner_block1, .ssd_main_banner_block2{
        flex: 0 0 50%;
        padding: 2rem 0;
    }
    .banner_para{
        text-align: left;
    }
    .team-extension-content {
        height: auto;
    }
    .needs-box-fix{
        height: auto;
    }   
  }
  /* Tablet END */
  @media only screen and (max-width: 991px) { 
    .needs-content-service {
        height: auto;
    }
  }


  @media only screen and (max-width: 650px) {
    .ssd_main_banner {
        font-size: 20px;
        padding: 0px;
    }
    .project_blocks_headings {
        font-size: 20px;
        padding: 0px;
    }
    
}

@media screen and (min-width: 760px) { 
  .banner_btn_1 a{
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      background-color: transparent;
      border: 2px solid #FFFFFF;
      border-radius: 20px;
      line-height: 38px;
      height: 38px;
      padding: 0 22px;
      max-width: 130px;
      white-space: nowrap;
      font-size: 16px;
      display: block;
      cursor: pointer;
      box-sizing: border-box;
      filter: drop-shadow(0px 16px 60px rgba(0, 0, 0, 0.15));
  }
  .digital_banner_contact_caption {
    position: absolute;
    text-align: right;
    bottom: 17%;
    right: 9%;
    transform: translate(-50%, -50%);
}
  
  } 


@media only screen and (max-width: 768px) {
    .needs-box-fix{
        margin: 10px 15px;
        width: auto;
        height: 435px;
    }
    .team-extension {
        height: auto;
    }
    .needs-content-service{
        height: auto;
    }
    .team-extension-content {
        height: auto;
    }
    .how_it_works .sub_heading{
        text-align: center;
    }
    .how_it_works .ssd_para {
        text-align: center;
    }
 }

 

 /* new */
.banner-inner{
    display: block;
    position: relative;
    height: auto;
}
.banner-inner-mob{
    position: relative;
    height: auto;
    display: none;
}
.banner-inner > .home_page_caption_inner{
    position: absolute;
    width: 40%;
    left: 10%;
    top: 50%;
    transform: translate(-10%, -50%);
    color: #fff;
}

.home_page_caption_inner h1{
    font-family: "Poppins-Medium";
    font-size: 40px;
    margin-bottom: 16px;
    line-height: 48px;
}
.home_page_caption_inner p{
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
}
.banner-inner img{
    width: 100%;
    height: auto;
}

 .services_container{
    /* background-image: url('../../fonts/images/services_background_1.png'); */
    background-image: url('../../fonts/images/services_offering.png');
    margin-top: -5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 72px 80px 64px 80px;
 }
 .service_rotate{
    transform: skew(0deg, 0deg);
 }
 .services_head{
    font-family: "Poppins-Medium";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    color: #fff;     
    line-height: 48px;
 }
 .services_para{
    font-family: "Poppins-Regular";
    font-size: 16px;
    color: #fff;
    padding: 0px 0px 60px 0px;
    text-align: justify;
 }
 .services_para_1{
    font-family: "Poppins-Regular";
    font-size: 16px;
    color: #fff;
    padding: 0px 0px 16px 0px;
    text-align: justify;
    line-height: 27px;
 }
 .services_img_para {
    font-family: "Poppins-Regular";
    font-size: 16px;
    color: #fff;
    padding-top: 1rem;
    line-height: 27px;
}
.align_right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.text-align-right{
    text-align: right;
}
.services_img_1 img{
    width: 100%;
}
.just_start{
    justify-content: flex-start;
}
.just_end{
    justify-content: flex-end;
}
.services_top{
    padding-top: 64px;
}
.services_content, .managed-service{
    padding-top: 60px;
}
.focus_service{
    padding-top: 56px;
}
.banner_proj_service{
    padding: 56px 0 0 0;
}

/* why us container */
.why_container{
    /* background: linear-gradient(0deg,#48a7bc,#cce2fc 50%,#48a7bc); 
    background-image: url('../../fonts/images/why_us_background.png'); */
    background-image: url('../../fonts/images/why_us.png');
    margin-top: -6rem;
    background-position: top;
    background-size: cover;
    padding: 84px 80px;
}
.why_container_content {
    padding: 0px 0px 32px 0px;
}
.why_us_heading{
    padding: 0 0 44px 0;
}
    /* Animate */
    .why_animate::before {
        content: "Innovative Strategy";
        animation: animate_2 infinite 3s;
        padding-left: 10px;
        color: #F05F30;
      }
      
      @keyframes animate_2 {
        0% {
            content: "Innovative Strategy";
        }
      
        50% {
            content: "Transparent Communication";
        }
      
        75% {
            content: "New-age design thinking";
        }
      }
      .how_it_animate::before {
        content: "Market Research";
        animation: animate_3 infinite 3s;
        color: #F05F30;
      }
      
      @keyframes animate_3 {
        0% {
            content: "Market Research";
        }
      
        25% {
            content: "Product Development";
        }
      
        50% {
            content: "Feedback";
        }
        75% {
            content: "Deployment";
        }
      }

.why_us_blocks{
    align-items: flex-start;
    justify-content: space-between;
}
.why_us_blocks_left{
    flex: 0 0 38%;
}
.why_us_blocks_right{
    flex: 0 0 62%;
    max-width: 61%;
}
.why_container_img{
    position: relative;
}
.why_container_img img{
    width: 100%;
    height: 100%;
}
.why_container_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.why_container_text{
    font-family: "Poppins-SemiBold";
    font-size: 30px;
    color: #fff;
    width: 100%;
    text-align: center;
}
.why_caption_back{
    font-family: "Poppins-SemiBold";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    color: #fff;
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
}
.why_caption_back_para{
    font-family: "Poppins-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    width: 100%;
    text-align: center;
    line-height: 27px;
    padding: 0px 24px;
}
.proj_why_desktop{
    display: flex;
}
.proj_why_mob{
    display: none;
}

.flip-card {
    background-color: transparent;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  .flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
  }

  .best_fit_shadow{
    box-shadow: 5px 8px 7px rgba(0, 0, 0, 0.76);
    border-radius: 15px;
    padding-bottom: 0px !important;
  }
  .best_fit_shadow > img{
    border-radius: 15px;
  }
  .pding_top{
      margin-top: 1rem;
  }

  .image__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(37, 69, 120, 0.3);
	color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: opacity 0.25s;
    border-radius: 15px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.76);
}
.image__overlay{
    opacity: 0;
}

.image__overlay:hover {
  opacity: 1;
}

.image__overlay_1{
    height: 100%;
}


.why_container_img:hover .why_caption{
    opacity: 0;
}
.proj_underline{
    margin-top: -0.2rem;
}
.proj_head_line{
    text-align: right;
}
.how_it_content_proj_container{
    margin: 0px 0px 64px 0px;
}
.how_it_content_proj {
    align-items: center;
    justify-content: flex-start;
    padding: 0px 80px 0px 80px;
}
.how_it_content_proj_step_2{
    align-items: center;
    justify-content: flex-end;
    padding: 60px 80px 60px 80px;
}

.add_value_img{display: none;}
.value_img_container{
    display: block;
}
.proj_banner_head h2{
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 48px;
    text-transform: capitalize;
    color: #F05F30;
    margin-bottom: 0;
  }
  .implement_img{
      text-align: center;
  }

  .aligned_implement{
      align-items: center;
      padding: 48px 0px 32px 0px;
  }

@media only screen and (max-width: 768px) { 
    .banner-inner{
        display: none;
    }
    .banner-inner-mob{
        display: block;
    }
    .mob_banner{
        width: 100% !important;
    }
    /* .mob_home_page_caption_inner{
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -10%);
        width: 100%;
        padding: 0 1.5rem;
    } */
    .mob_home_page_caption_inner h1{
        font-family: "Poppins-Medium";
        font-size: 24px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        margin-bottom: 1rem;
    }
    .mob_home_page_caption_inner p{
        font-family: "Poppins-Regular";
        font-size: 14px;
        color: #fff;
        text-align: center;
    }
    .contactbtn {
        padding-top: 0rem !important;
        text-align: center;
    }
    .services_container{
        padding: 84px 24px;
        margin-top: -6rem;
    }
    .services_head{
        font-size: 20px;
    }

    .section_main_heading{
        font-size: 5vw;
        text-align: center;
    }
    .implement_box{
        margin: 0;
        text-align: center;
    }

    .why_us_blocks {
        display: block;
    }
    .proj_why_desktop{
        display: none;
    }
    .proj_why_mob{
        display: block;
    }
    .why_us_blocks_right {
        max-width: 100%;
        padding-top: 1rem !important;
    }
    .why_container{
        padding-bottom: 3rem;
    }
    .just_start{
        padding: 0 1rem;
    }
    .just_end{
        padding: 0 1rem;
    }
    .why_mob_back{
        position: relative;
    }
    .why_mob_caption{
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    padding: 0rem 1rem;
    }
    .caption_head{
        font-family: "Poppins-Regular";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        color: #fff;
        line-height: 28px;
        padding-bottom: 0.5rem;
    }
    .caption_para{
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: #fff;
        line-height: 28px;
    }
    .common_1, .common_2, .common_3 {
        background-position: top;
    }
    .common_box_content{
        top: 56%;
    }
    .common_box_content_1{
        top: 57%;
    }
    .common-needs-banner{
        margin-top: -1rem;
    }
    .needs-container-padding{
        padding: 48px 24px 0px 24px;
        max-width: 100%;
    }
    .how_it_work_content .pb-3{
        padding: 0 1.5rem;
    }
    .engage_mob_box_3{
        margin-top: 3rem;
    }
    .value_img_container{
        display: none;
    }
    .add_value_img{
        display: block;
        text-align: center;
        padding: 8px 0px;
    }
    
}


@media only screen and (max-width: 590px) { 
    .model_img_container > img {
        height: 520px;
    }
    .img_eng_1 > img {
        height: 570px;
    }
    .services_container{
        margin-top: -7rem;
    }
    .why_container{
        margin-top: -7rem;
    }
    .our_packages{
        margin-top: -6.5rem;
    }
    .engage_mob_box_3 {
        margin-top: 6rem;
    }
}

@media only screen and (max-width: 520px) { 
    .why_mob_back img{
        width: 100%;
        height: 250px;
    }
    .img_eng_2 {
        height: 490px;
    }
    .img_eng_1 {
        height: 490px;
    }
}

@media only screen and (max-width: 420px) { 
    .model_img_container > img {
        height: 420px;
    }
}

@media only screen and (max-width: 340px) { 
    .model_img_container > img {
        height: 600px;
    }
}

@media only screen and (max-width: 768px) { 
.project-banner .banner-inner-mob{
    height: auto;
    position: relative;
}
.project-banner-inner{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.project-banner-inner h1 {
    font-family: "Poppins-Medium";
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    text-align: center;
}
.project-banner-inner-1{
    background: #0C1922;
    padding: 24px 24px;
}
.project-banner-inner-1 p{
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    line-height: 21px;  
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 24px;
}
.services_para{
    font-size: 14px;
    text-align: justify;
    padding: 0px 0px 16px 0px;
    line-height: 27px;
}
.services_para_1{
    font-size: 14px;
}
.aligned_implement{
    padding: 44px 0px 0px 0px;
}
.implement_img{
    padding: 32px 0px 16px 0px;
}
.implement_box .implement_box_mob{
    padding: 16px 0px !important;
}
.services_img_para{
    font-size: 14px;
    padding-top: 0px;
}
.managed-service{
    padding-top: 32px;
}
.banner_proj_service{
    padding: 0;
}

.why_container{
    padding: 72px 24px 92px 24px;
    margin-top: -8rem;
}
.why_container_content{
    padding: 0;
} 
.how_it_content_proj {
    align-items: center;
    justify-content: center;
    padding: 0px 24px 0px 24px;
}
.how_it_content_proj_step_2{
    align-items: center;
    justify-content: center;
    padding: 24px 24px 24px 24px;
} 
.how_it_works_content_right_1{
    right: 0;
}
.how_it_works_content_right{
    margin-left: 0;
}
.engage_logo_line{
    padding-bottom: 8px;
}
.how_line{
    margin: 0 auto;
}
.steps_number{
    font-size: 12rem;
}

}


@media (min-width: 770px) and (max-width: 1200px) { 
    .caption_heading{
        font-size: 14px;
        line-height: 21px;
    }
    .caption_heading_1{
        font-size: 14px;
        line-height: 21px;
    }
    .caption_link{
        line-height: 21px;
    }
    .caption_link a{
        font-size: 14px;
        line-height: 21px;
    }
    .banner_btn_1 a{
        font-family: 'Poppins-Medium';
        line-height: 28px;
        height: 34px;
        padding: 0 9px;
        max-width: 105px;
        font-size: 14px;
    }   
    .services_img_caption{
        top: 30%;
    }

}

@media (min-width: 770px) and (max-width: 1200px) { 
    .banner-inner > .home_page_caption_inner {
        position: absolute;
        width: 50%;
        left: 10%;
        top: 50%;
        transform: translate(-10%, -50%);
        color: #fff;
    }
}

/* ipad Air */
@media (min-width: 820px) and (max-width: 920px) { 
    .services_container {
      padding: 72px 24px 112px 24px;
      margin-top: -6rem;
    }
    .why_container{
        padding: 84px 24px;
        margin-top: -6rem;
    }
    .how_it_content_proj{
        padding: 0px 24px 0px 24px;
        justify-content: center;
    }
    .how_it_content_proj_step_2{
        padding: 60px 24px 60px 24px;
    }
    .banner-inner > .home_page_caption_inner {
        position: absolute;
        width: 65%;
        left: 10%;
        top: 50%;
        transform: translate(-10%, -50%);
        color: #fff;
    }
    .home_page_caption_inner h1 {
        font-family: "Poppins-Medium";
        font-size: 36px;
        margin-bottom: 16px;
    }
    .proj_banner_head h2 {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        text-transform: capitalize;
        color: #F05F30;
        margin-bottom: 0;
    }
    .caption_heading {
        font-size: 16px;
        line-height: 21px;
    }
    .caption_heading_1 {
        font-size: 18px;
        line-height: 21px;
    }
    .caption_link a {
        font-size: 16px;
        line-height: 21px;
    }
    .services_contact_caption {
        position: absolute;
        top: 66%;
        left: 75%;
        transform: translate(-50%, -50%);
    }

  }

  @media only screen and (min-width: 1900px) { 
    .services_container{
        padding: 114px 120px 96px 120px;
        margin-top: -6rem;
    }
    .why_container{
        margin-top: -7.5rem;
        padding: 124px 120px;
    }
    .services_para_1, .services_para{
        font-size: 20px;
        line-height: 36px;
        padding: 0px 0px 32px 0px;
    }

    .banner-inner > .home_page_caption_inner {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        padding: 0px 120px;
    }
    .home_page_caption_inner h1 {
        font-family: "Poppins-Medium";
        font-size: 48px;
        margin-bottom: 20px;
        line-height: 72px;
    }
    .home_page_caption_inner p {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        max-width: 49%;
    }
    .proj_banner_head h2{
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        text-transform: capitalize;
        color: #F05F30;
        margin-bottom: 0;
      }
    .caption_link a{
        font-size: 20px;
    }
    .caption_heading{
        font-size: 20px;
    }
    .caption_heading_1{
        font-size: 26px;
    }
    .banner_btn_1 a{
        font-size: 20px;
        line-height: 40px;
        height: 42px;
        padding: 0 22px;
        max-width: 165px;
    }
    .services_img_para{
        font-size: 20px;
        line-height: 36px;
    }
    .managed_div{
        padding: 60px 0px 0px 0px;
    }
    .how_it_content_proj{
        padding: 0px 120px 0px 120px;
    }
    .how_it_content_proj_step_2{
        padding: 100px 120px 100px 120px;
    }
    .aligned_implement{
        padding: 48px 0px 108px 0px;
    }
    .services_top {
        padding-top: 150px;
    }
    .services_top_banner {
        padding: 96px 0 0 0;
    }
    .why_caption_back_para {
        font-family: "Poppins-Regular";
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        color: #fff;
        width: 100%;
        text-align: center;
        line-height: 36px;
    }
    .focus_service {
        padding-top: 180px;
      }
      .engage_logo_line{
        max-width: 40%;
        padding-bottom: 16px;
        line-height: 0;
    }
    .pding_top {
        margin-top: 1.5rem;
    }
  }



@media only screen 
 and (min-device-width: 768px) 
 and (max-device-width: 1024px) 
 and (-webkit-min-device-pixel-ratio: 1) { 
    .needs-container-padding{
        padding: 44px 24px 48px 24px;
    }
    .focus_service{
        flex: 0 0 45%;
        max-width: 45%;
    }
    .service_offering_banner{
        flex: 0 0 55%;
        max-width: 54%;
    }
    
 }

 @media only screen and (max-width: 420px) {
    .why_container {
        padding: 72px 24px 92px 24px;
        margin-top: -9rem;
    }
    .services_container{
        padding: 112px 24px;
    }
 }


 @media screen and (min-width:768px) and (max-width:819px)  { 
    .project-services-background-style .banner-inner {
        display: none;
    }
    .digital-banner .banner-inner, .project-banner .banner-inner{
        display: none;
    }
    .project .mob_banner_head {
        background: linear-gradient(270deg, #042435 0%, #003A55 48.23%, #052B40 100%);
    }
    .services_container{
        margin-top: -6rem;
        padding: 72px 24px 64px 24px;
    }
    .why_container{
        padding: 84px 24px;
    }
    .how_it_content_proj{
        padding: 0px 24px 0px 24px;
    }
    .why_caption_back {
        font-family: "Poppins-SemiBold";
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        color: #fff;
        width: 100%;
        text-align: center;
        padding-bottom: 16px;
    }
    .digital_banner_contact_caption {
        position: absolute;
        text-align: right;
        bottom: 11%;
        right: 8%;
        transform: translate(-50%, -50%);
    }
    .enage_mob_box_product2 .img_eng_3 {
        margin-top: 248px;
    }
    .how_it_content_proj_step_2{
        padding: 60px 24px 60px 24px;
    }
 }