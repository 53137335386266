.send-btn-contact{
    border: 1px solid #FF6600;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3.5%;
    padding-right: 5%;
    font-size: 1.3vw;
    font-family:  "space-Groteskr" !important;
    font-weight: lighter;
    background-image: linear-gradient(to left,
    transparent,
    transparent 50%,
    #FF6600 50%,
    #FF6600);
    background-position: right bottom;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all .4s ease-in;        
    .rotate-arrow{
        transform: rotate(180deg) scale(1.3);
        color: #ffff;
        background-position: right bottom;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all .4s cubic-bezier(0.82,-0.2, 0.6, 0.71);  
    }
}

.send-btn-contact:hover{
    background-position: 0 0; 
    border-radius: 10px;
    border: 1px solid #FF6600;
    background-color: transparent;
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3.5%;
    padding-right: 5%;
    font-size: 1.3vw;
    font-family:  "space-Groteskr" !important;
    font-weight: lighter;
    .rotate-arrow{
        transform: rotate(135deg)scale(1.3);
        color: white;
        background-position: 0 0; 
    }
}




.outer-product {
    margin: 50px;
  }
  
  .button-product {
    border: 1px solid black;
    border-radius: 3px;
    width: 100px;
    height: 30px;
    display: block;
    background: linear-gradient(to right, black 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
  }
  
  .button-product:hover {
    background-position: left bottom;
  }
  
  .text-product {
    text-align: center;
    font-size: 16px;
    line-height: 30px; 
    color: black;
    transition: all .6s ease-out;
    display: block;
  }
  
  .text-product:hover {
    color: white;  
  }

  .ContactUS-Form{
    // background-image: url("../../fonts/Stakerank/Group\ 454.svg");
    // background-repeat: no-repeat;
    // background-position: bottom;
  }



  .Contact-login-box {
    position: relative;
    width: 40vw;
    background: transparent;
}

.Contact-login-box {
  position: relative;
  // width: 90%;
  // left: 10%;
  width: 100%;
  background: transparent;
  // top: 27%;
}

.Contact-login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #FF6600;
  text-align: center;
}

.Contact-login-box .Contact-user-box {
  position: relative;
  background: transparent;
  background-color: transparent;
}
.Contact-login-box .Contact-user-box input:focus {
  border-bottom: 1px solid #FF6600;
  border-radius: 0px;
}
.Contact-login-box .Contact-user-boxx textarea:focus {
  border-bottom: 1px solid #FF6600;
  border-radius: 0px;
}

.Contact-login-box .Contact-user-box input {
  width: 83%;
  padding: 1% 0% 1.5% 1%;
  font-size: 1.5vw;
  font-family:  "space-Groteskr" !important;
  color: #ffff;
  margin-bottom: 4%;
  border: none;
  border-bottom: 1px solid #ffffff;
  outline: none;
  background: transparent;
  background-color: transparent;
  font-weight: lighter;
  border-radius: 0px;
}
.Contact-login-box .Contact-user-boxx textarea {
  width: 83%;
  padding: 1% 0% 1.5% 1%;
  font-size: 1.5vw;
  font-family:  "space-Groteskr" !important;
  border-radius: 5px;
  color: #ffff;
  margin-bottom: 4%;
  border: none;
  height: 10vw;
  border-bottom: 1px solid #ffffff;
  border-radius: 0px;
  outline: none;
  background: transparent;
  background-color: transparent;
  font-weight: lighter;
  // border-radius: 15px;
}
.Contact-login-box .Contact-user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #ffff;
  pointer-events: none;
  background: transparent;
  background-color: transparent;
  transition: .5s;
}

.Contact-login-box .Contact-user-box input:focus ~ label{
  top: -20px;
  left: 0;
  color: #FF6600;
  font-size: 12px;
  background: transparent;
  background-color: transparent;
}
.Contact-login-box .Contact-user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #FF6600;
  font-size: 12px;
}


// @media  only screen and (min-width: 481px){
  @media  only screen and (min-width: 769px){
  .Contact_mainHead-inside1-mobile{
    display: none;
  }
  .send-btn-contact-mobile{
    display: none;
  }
  .Contact-Location-mobile{
    display: none;
  }
  .image-container-mobile{
    display: none;
  }
}
// @media  only screen and (max-width: 480px){
@media  only screen and (max-width: 768px){
  .Contact_mainHead-inside1{
    display: none;
  }
  .Contact-Location{
    display: none;
  }
  .send-btn-contact{
    display: none;
  }
  .image-container{
    display: none;
  }

  .Contact_mains{
    background: transparent linear-gradient(359deg, #00122E 0%, #012429 14.2%, #013143 34.3%, #00072D 66.6%, #182878 79.2%, #010101 100%);
    width: 100%;
    height: auto;
}
.Contact_mainHead{
    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 10%;
}
  .Contact_mainHead {
    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 10%;
    /* display: flex; */
}
.Contact_mainHead-inside1-mobile {
    position: relative;
    transform: translate(0%, 25%);
    // border: 1px solid red;
    text-align-last: center;
}
.Contact_mainHead-inside2-mobile {
    // border: 1px solid red;
    width: 40%;
    position: relative;
    left: 10%;
    margin-top: 8%;
}
.Contact_mainHead-inside1-mobile h1{
    line-height: 100%;
    font-size: 18vw;
    text-align: -webkit-center;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: ' space-Groteskb';
    padding-bottom: 2%;
}

.Contact_mainHead-inside1-mobile .Contactpara1-mobile{
    color: #FF6600;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 18px;
    text-align: -webkit-center;
    margin-bottom: 1.5%;
}

.Contact_mainHead-inside1-mobile .Contactpara2-mobile{
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 3.2vw;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    font-weight: lighter;
    margin-left: 3%;
    margin-right: 3%;
    text-align: -webkit-center;
}
.Contact_mainHead-inside2 {
  position: relative;
  margin-top: 20%;
  // border: 1px solid red;
}
.Contact-login-box {
  position: relative;
  // width: 100%;
  background: transparent;
  // text-align: -webkit-center;
}
.Contact-login-box .Contact-user-box  {
  position: relative;
  background: transparent;
  background-color: transparent;
  text-align: center;
}
.Contact-login-box .Contact-user-boxx  {
  position: relative;
  background: transparent;
  background-color: transparent;
  text-align: center;
}
.Contact-login-box .Contact-user-boxx textarea {
  width: 70%;
  font-size: 3.5vw;
  font-family:  "space-Groteskr" !important;
  color: white;
  margin-bottom: 5%;
  border: none;
  height: 20vw;
  border-bottom: 1px solid #ffffff !important;
  border-radius: 0px;  
  outline: none;
  background: transparent;
  background-color: transparent;
  font-weight: lighter;
  padding-top: 0%;
  padding-left: 1%;
}
.Contact-login-box .Contact-user-box input {
  width: 70%;
  padding-top: 2%;
  font-size: 3.5vw;
  font-family:  "space-Groteskr" !important;
  color: white;
  margin-bottom: 5%;
  border: none;
  border-bottom: 1px solid #ffffff;
  border-radius: 0px;
  outline: none;
  background: transparent;
  background-color: transparent;
  font-weight: lighter;
  padding-bottom: 0.5%;
  padding-left: 1%;
}
.input-color::placeholder {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font-size: 2.5vw;
  font-family:  "space-Groteskr" !important;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: transparent;
  opacity: 1;
  padding: 2% 0% 0% 0%;
  opacity: 0.8;
}
.input-color{
    background-color: transparent;
    background: transparent;
}
.send-btn-contact-mobile{
  border-radius: 5px;
  border: 1px solid #000000;
  background-color: transparent;
  color: #ffffff;
  background-color: #FF6600;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 3%;
  padding-right: 7%;
  font-size: 3vw;
  font-family:  "space-Groteskr" !important;
  font-weight: lighter;
  margin-left: 15%;
  .rotate-arrow{
    transform: rotate(135deg)scale(1.3);
    color: white;
    background-position: 0 0; 
}
}
.Contact-Location-mobile{
  // border: 1px solid pink;
  margin-top: 10%;
  margin-bottom: 15%;
}
.Contact-Location-mobile h1{
  font-size: 15vw;
  color: white;
  font-family:  "space-Groteskb" !important;
  // border: 1px solid red;
  width: 60%;
  padding-left: 8%;
}
.Contact-Location-mobile-inside1{
  display: flex;
}
.world_img {
  width: 50%;
  // border: 1px solid blue;
}
.world_img img {
  max-width: 100%;
  height: auto;
  width: 100%;
  top: 30%;
  position: relative;
  right: 15%;
}

.Contact-Location-mobile-inside2{
  // border: 1px solid red;
  width: 80vw;
  left: 10vw;
  position: relative;
  margin-top: 5%;
  margin-bottom: 5%;

}


.inside_loc_insideBox1{
  // border: 1px solid blue
  margin-bottom: 10%;
}
.inside_loc_insideBox1 h1{
  color: #ffffff;
  opacity: 1;
  letter-spacing: 0px;
  font-family:  "space-Grotesksb" !important;
  font-size: 20px;
  letter-spacing: 0.76px;
  padding-left: 0%;
}
.inside_loc_insideBox1 p{
  opacity: 1;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  color: #FFFFFF;
  padding-left: 0%;
  font-weight: lighter;
  /* padding-right: 40%; */
  // border: 1px solid white;
  margin-right: 10%;
  letter-spacing: 1px;


}

.inside_loc_insideBox2{
  // border: 1px solid blue
  margin-bottom: 10%;
  
}
.inside_loc_insideBox2 h1{
  color: #ffffff;
  opacity: 1;
  letter-spacing: 0px;
  font-family:  "space-Grotesksb" !important;
  font-size: 20px;
  padding-left: 0%;
  letter-spacing: 0.76px;
}
.inside_loc_insideBox2 p{
  opacity: 1;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  color: #FFFFFF;
  padding-left: 0%;
  font-weight: lighter;
  /* padding-right: 46%; */
  margin-right: 10%;
  // border: 1px solid red;
  letter-spacing: 1px;

}


.inside_loc_insideBox3{
  // border: 1px solid blue
  margin-bottom: 10%;
}
.inside_loc_insideBox3 h1{
  color: #ffffff;
  opacity: 1;
  letter-spacing: 0px;
  font-family:  "space-Grotesksb" !important;
  font-size: 20px;
  padding-left: 0%;
  letter-spacing: 0.76px;
}
.inside_loc_insideBox3 p{
  opacity: 1;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  color: #FFFFFF;
  padding-left: 0%;
  font-weight: lighter;
  /* padding-right: 40%; */
  // border: 1px solid green;
  margin-right: 10%;
  letter-spacing: 1px;

}


.inside_loc_insideBox4{
  // border: 1px solid blue
  margin-bottom: 10%;
}
.inside_loc_insideBox4 h1{
  color: #ffffff;
  opacity: 1;
  letter-spacing: 0px;
  font-family:  "space-Grotesksb" !important;
  font-size: 20px;
  padding-left: 0%;
  letter-spacing: 0.76px;
}
.inside_loc_insideBox4 p{
  opacity: 1;
  font-family:  "space-Groteskr" !important;
  font-size: 16px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  color: #FFFFFF;
  padding-left: 0%;
  font-weight: lighter;
  /* padding-right: 30%; */
  // border: 1px solid red;
  margin-right: 10%;
  letter-spacing: 1px;
}

.image-container-mobile {
  height: 70vw;
  left: 4%;
  position: relative;
  width: 90vw;

  // border: 1px solid red;
}
.image-container-mobile img {
  cursor: pointer;
  height: 60vw;
  max-width: 100vw;
  width: 92vw;
}
.contentimg-mobile img {
  cursor: pointer;
  height: auto;
  left: 5%;
  max-width: 100%;
  position: relative;
  width: 25%;

}

.image-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}
.contentimg-mobile {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -75%);
  color: white;
  font-size: 1.2vw;
  width: 100%;
  cursor: pointer;
  padding-top: 10%;
  // border: 1px solid yellow;
}

.contact-Readmore-mobile { 
  left: 5%;
  position: relative;
  padding-top: 2%;
  padding-bottom: 3%;
}
.contact-Readmore-mobile a{
  border: 1px solid #FF6600;
  padding: 5% 6% 3% 3.5%;
  font-family:  "space-Groteskr" !important;
  font-size: 1.2vw;
  text-align: left;
  color: #FFFFFF;
  font-weight: lighter;
  border-radius: 5px;
  background-image: linear-gradient(to left,
  transparent,
  transparent 50%,
  #FF6600 50%,
  #FF6600);
  background-position: right bottom;
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all .4s ease-in; 
  .rotateSilicon-mobile{
    transform: scale(1) rotateZ(135deg);
  }    
}
.contact-Readmore a:hover{
  background-position: 0 0; 
}
.silicon-para1-mobile{ 
  color: #fff;
  font-family: 'space-Groteskr';
  font-size: 5vw;
  font-weight: lighter;
  left: 5%;
  opacity: 1;
  padding-top: 3%;
  position: relative;
  width: 90%;
  margin-bottom: 2%;

}

.btn-contact-mobile {
  -webkit-text-fill-color: transparent;
  background-clip: initial;
  -webkit-background-clip: text;
  background-clip: text;
  background-color: #fff;
  color: #fff;
  font-family: 'space-Groteskr';
  font-size: 4.5vw;
  font-weight: 700;
}
}

