
// .your-container-view {
//     position: relative;
//     overflow: hidden;
//     width: 80vw;
//     background-color: #00CCC5;
//     border-radius: 10px;
//     margin: 15% 0% 0% 0%;
//   }
  
//   .your-container-view img {
//     width: 100%; 
//     height: auto;
//   }
  
//   .your-container-view h1 {
//     margin-top: 10px; 
//   }
  
// .your-container-view .para-view{
//   font-size: 18px;
//   text-align: left;
//   font-family: "space-Groteskr" !important;
//   letter-spacing: 0.96px;
//   padding: 0% 5% 0% 5%;
// }
// .your-container-view .head-view{
//   letter-spacing: 1.44px;
//   color: #011639;
//   opacity: 1;
//   text-align: left;
//   padding: 2% 5% 2% 5%;
//   font-family:"space-Grotesksb" !important;
//   font-size: 20px;
//   display: flex;
//   justify-content: space-between;
// }
//   .your-container-view p {
//     display: none;
//   }
  
//   .your-container-view.in-view p {
//     display: block;
//   }











.your-container-view {
  position: relative;
  overflow: hidden;
  width: 80vw;
  background-color: #00ccc5;
  border-radius: 10px;
  margin: 15% 0% 0% 0%;
}

.your-container-view img {
  width: 100%;
  height: auto;
}
  .your-container-view h1 {
    margin-top: 10px; 
  }
.your-container-view .head-view{
  letter-spacing: 1.44px;
  color: #011639;
  opacity: 1;
  text-align: left;
  padding: 2% 5% 2% 5%;
  font-family:"space-Grotesksb" !important;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.your-container-view .para-view {
  font-size: 18px;
  text-align: left;
  font-family: "space-Groteskr" !important;
  letter-spacing: 0.96px;
  padding: 0% 5% 0% 5%;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out, max-height 1s ease-in-out;
}

.your-container-view.in-view .para-view {
  opacity: 1;
  max-height: 500px; // Adjust this value based on your content height
}
