// @media  only screen and (max-width: 767px) {
//     .OurTeam-Grid-tablet-Conatiner{
//         display: none;
//     }
// }



@media  only screen and (min-width: 480px) and (max-width: 768px) {
    .OurTeam-Grid-tablet-Conatiner{
        // border: 1px solid green;
        background-image: url("../../fonts/svg/team_circle\ \(1\).svg");
        background-size: contain;
        background-repeat: no-repeat;
        left: 3%;
        overflow: hidden;
        transform: translate(0%, 0%);
        padding-top: 12% !important;
        padding-bottom: 1% !important;
        margin-top: 12% !important;
        margin-bottom: 0% !important;
    }
    .OurTeam-Grid-tablet{
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
    }
    .ourTeam-content-tablet{
        // width: 50vw;
        width: 100vw;
        padding: 1% 0% 1% 0%;
    }
    


    .demo-grid-main1{
        // border: 1px solid red;
        justify-content: center;
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        align-self: center;
        position: relative;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 1.7s ease;
    }
    .row1-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 270px;
        padding-bottom: 110px;
    }
    .row7-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 270px;
        padding-bottom: 110px;
    }
    .row5-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 150px;
        padding-bottom: 90px;
    }
    .row3-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 150px;
        padding-bottom: 90px;
    }
    .row2-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 185px;
        padding-bottom: 60px;
    }
    .row6-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 185px;
        padding-bottom: 60px;
    }
    .row4-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 185px;
        padding-bottom: 60px;
    }
    .row1-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row5-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row1-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row7-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row3-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row2-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row6-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row4-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
















    .photos-Container-grid-tablet{
        // border: 1px solid red;
        width: 100vw;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 1.7s ease;
    }
    .row1-tablet {
        justify-content: space-evenly;
        display: flex;
      }
      .row2-tablet {
        justify-content: space-evenly;
        display: flex;
      }
      .row3-tablet {
        justify-content: space-evenly;
        display: flex;
      }
      .row4-tablet {
        justify-content: space-evenly;
        display: flex;
      }
      .row5-tablet {
        justify-content: space-evenly;
        display: flex;
      }
      .row6-tablet {
        justify-content: space-evenly;
        display: flex;
      }
      .row7-tablet {
        justify-content: space-evenly;
        display: flex;
      }
    .row1-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row2-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row3-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row4-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row5-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;;

    }
    .row6-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row7-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    
    .ourH1-tablet{
        font-size: 14vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 2%;
        // text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.9882352941);
    }
    .ourp1-tablet{
        letter-spacing: 1px;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        color: #F1F9F9;
        text-align: center;
        font-size: 16px;
        line-height: 1.5;
        padding: 1% 3% 1% 3%;
        margin-bottom: 2%;
    }
    .ourp2-tablet{
        letter-spacing: 1px;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        color: #F1F9F9;
        text-align: center;
        font-size: 16px;
        line-height: 1.5;
        padding: 1% 3% 1% 3%;
        margin-bottom: 2%;
    }
    .ourp3-tablet{
        font-family:  "space-Groteskr" !important;
        letter-spacing: 1px;
        color: #F1F9F9;
        opacity: 1;
        text-align: center;
        font-size: 16px;
        line-height: 200%;
        padding: 1% 3% 1% 3%;
        margin-bottom: 2%;
    }
    
    .colorChange{
        filter: gray; 
        -webkit-filter: grayscale(1); 
        -webkit-transition: all .9s ease-in-out;  
    }
    .colorChange:hover {
        filter: none;
        -webkit-filter: grayscale(0);
    }


}
@media  only screen and (min-width: 768px) and (max-width: 1024px) {
    .OurTeam-Grid-tablet-Conatiner{
        // border: 1px solid green;
        background-image: url("../../fonts/svg/team_circle\ \(1\).svg");
        background-size: contain;
        background-repeat: no-repeat;
    }
    .OurTeam-Grid-tablet{
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
    }
    .ourTeam-content-tablet{
        // width: 50vw;
        width: 100vw;
    }
    

    .demo-grid-main1{
        // border: 1px solid blue;
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        align-self: center;
        position: relative;
        justify-content: center;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 1.7s ease;
    }
    .row1-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 210px;
        padding-bottom: 135px;
    }
    .row7-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 210px;
        padding-bottom: 135px;
    }
    .row5-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 90px;
    }
    .row3-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 90px;
    }
    .row2-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 90px;
        padding-bottom: 10px;
    }
    .row6-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 90px;
        padding-bottom: 10px;
    }
    .row4-demo{
        display: grid;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 90px;
        padding-bottom: 10px;
    }
    .row1-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row5-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row1-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row7-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row3-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row2-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row6-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .row4-demo img{
        padding-top: 10px;
        padding-bottom: 10px;
    }

















    .photos-Container-grid-tablet{
        // border: 1px solid blue;
        width: 100vw;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 1.7s ease;
    }
    .row1-tablet {
        justify-content: space-evenly;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        // padding-right: 25px;
        // padding-left: 0px;
        // margin-right: 40px;
        // margin-left: 40px;
        // padding-bottom: 10px;
        // padding-top: 10px;
        // width: 100%;
      }
      .row2-tablet {
        justify-content: space-evenly;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        // padding-right: 25px;
        // padding-left: 0px;
        // margin-right: 40px;
        // margin-left: 40px;
        // padding-bottom: 10px;
        // padding-top: 10px;
        // width: 100%;
      }
      .row3-tablet {
        justify-content: space-evenly;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        // padding-right: 25px;
        // padding-left: 0px;
        // margin-right: 40px;
        // margin-left: 40px;
        // padding-bottom: 10px;
        // padding-top: 10px;
        // width: 100%;
      }
      .row4-tablet {
        justify-content: space-evenly;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        // padding-right: 25px;
        // padding-left: 0px;
        // margin-right: 40px;
        // margin-left: 40px;
        // padding-bottom: 10px;
        // padding-top: 10px;
        // width: 100%;
      }
      .row5-tablet {
        justify-content: space-evenly;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        // padding-right: 25px;
        // padding-left: 0px;
        // margin-right: 40px;
        // margin-left: 40px;
        // padding-bottom: 10px;
        // padding-top: 10px;
        // width: 100%;
      }
      .row6-tablet {
        justify-content: space-evenly;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        // padding-right: 25px;
        // padding-left: 0px;
        // margin-right: 40px;
        // margin-left: 40px;
        // padding-bottom: 10px;
        // padding-top: 10px;
        // width: 100%;
      }
      .row7-tablet {
        justify-content: space-evenly;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        // padding-right: 25px;
        // padding-left: 0px;
        // margin-right: 40px;
        // margin-left: 40px;
        // padding-bottom: 10px;
        // padding-top: 10px;
        // width: 100%;
      }
    .row1-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row2-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row3-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row4-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row5-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row6-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .row7-tablet img {
        max-width: 100%;
        height: auto;
        width: 14%;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    
    .ourH1-tablet{
        font-size: 11vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 2%;
        // text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.9882352941);
    }
    .ourp1-tablet{
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1px;
        color: #F1F9F9;
        opacity: 1;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
    }
    .ourp2-tablet{
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1px;
        color: #F1F9F9;
        opacity: 1;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
    }
    .ourp3-tablet{
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1px;
        color: #F1F9F9;
        opacity: 1;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
    }
    
    .colorChange{
        filter: gray; 
        -webkit-filter: grayscale(1); 
        -webkit-transition: all .9s ease-in-out;  
    }
    .colorChange:hover {
        filter: none;
        -webkit-filter: grayscale(0);
    }
}
@media  only screen and (max-width: 1024px) {
    .OurTeam-Grid{
        display: none;
    }


 }
@media  only screen and (min-width: 1024px) {
    .Contact-Container-tablet{
        display: none;
    }

 }
@media  only screen and (min-width: 1025px) { 
.OurTeam-Grid-tablet{
    display: none;
}
.photos-Container-grid-tablet{
    display: none;
}
.Contact-Container-tablet{
    display: none;
}
.OurTeam-Grid{
    padding-top: 13%;
    padding-bottom: 12%;
    display: flex;
    width: 100%;
    // border: 1px solid blue;
    overflow: hidden;
    background-image: url("../../fonts/svg/team_circle\ \(1\).svg");
    background-size: contain;
    background-repeat: no-repeat;
}
.ourTeam-content{
    width: 50vw;
}

.photos-Container-grid{
    // border: 1px solid red;
    width: 50vw;
    left: 13%;
    align-self: center;
    position: relative;
    padding-left: 10px;
    padding-right: 20px;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 1.7s ease;

  }
.photos-Container-grid:hover{
    // border: 1px solid blue;
    width: 50vw;
    left: 0%;
    position: relative;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 1.7s ease;
  }
  .row1 {
    justify-content: space-evenly;
    display: flex;
    padding-right: 25px;
    padding-left: 0px;
    margin-right: 40px;
    margin-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
  .row2 {
    justify-content: space-evenly;
    display: flex;
    padding-right: 25px;
    padding-left: 0px;
    margin-right: 40px;
    margin-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
  .row3 {
    justify-content: space-evenly;
    display: flex;
    padding-right: 25px;
    padding-left: 0px;
    margin-right: 40px;
    margin-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
  .row4 {
    justify-content: space-evenly;
    display: flex;
    padding-right: 25px;
    padding-left: 0px;
    margin-right: 40px;
    margin-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
  .row5 {
    justify-content: space-evenly;
    display: flex;
    padding-right: 25px;
    padding-left: 0px;
    margin-right: 40px;
    margin-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
  .row6 {
    justify-content: space-evenly;
    display: flex;
    padding-right: 25px;
    padding-left: 0px;
    margin-right: 40px;
    margin-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
  .row7 {
    justify-content: space-evenly;
    display: flex;
    padding-right: 25px;
    padding-left: 0px;
    margin-right: 40px;
    margin-left: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
.row1 img {
    max-width: 100%;
    height: auto;
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
}
.row2 img {
    max-width: 100%;
    height: auto;
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
}
.row3 img {
    max-width: 100%;
    height: auto;
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
}
.row4 img {
    max-width: 100%;
    height: auto;
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
}
.row5 img {
    max-width: 100%;
    height: auto;
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
}
.row6 img {
    max-width: 100%;
    height: auto;
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
}
.row7 img {
    max-width: 100%;
    height: auto;
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
}


.ourH1{
    font-size: 11vw;
    text-align: left;
    color: white;
    font-family:  "space-Groteskb" !important;
    padding-top: 3%;
    padding-bottom: 2%;
    // text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.9882352941);
    padding-left: 10%;
}

.ourp1{
    font-family:  "space-Groteskr" !important;
    font-size: 1.2vw;
    letter-spacing: 1px;
    color: #F1F9F9;
    opacity: 1;
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
}
.ourp2{
    font-family:  "space-Groteskr" !important;
    font-size: 1.2vw;
    letter-spacing: 1px;
    color: #F1F9F9;
    opacity: 1;
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
}
.ourp3{
    font-family:  "space-Groteskr" !important;
    font-size: 1.2vw;
    letter-spacing: 1px;
    color: #F1F9F9;
    opacity: 1;
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
}

.colorChange{
    filter: gray; 
    -webkit-filter: grayscale(1); 
    -webkit-transition: all .9s ease-in-out;  
}
.colorChange:hover {
    filter: none;
    -webkit-filter: grayscale(0);
}






// demo for ma'am
.demo-grid-main1{
    display: none;
}
.demo-grid-main{
    // border: 1px solid red;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    left: 0%;
    align-self: center;
    position: relative;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 1.7s ease;
}

.row1-demo{
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 210px;
    padding-bottom: 135px;
}
.row7-demo{
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 210px;
    padding-bottom: 135px;
}
.row5-demo{
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 90px;
}
.row3-demo{
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 90px;
}
.row2-demo{
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 90px;
    padding-bottom: 10px;
}
.row6-demo{
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 90px;
    padding-bottom: 10px;
}
.row4-demo{
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 90px;
    padding-bottom: 10px;
}
.row1-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}
.row5-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}
.row1-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}
.row7-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}
.row3-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}
.row2-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}
.row6-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}
.row4-demo img{
    padding-top: 10px;
    padding-bottom: 10px;
}




}




@media  only screen and (max-width: 1024px) { 
    .Our-Team-Container-New-tablet{
        display: none;
    }
    .Our-Team-ContentImages-tablet{
        display: none;
    }
    // .Our-Team-Container-New-mobile{
    //     display: none;
    // }
    .Our-Team-ContentImages{
        display: none;
    }
    // .Our-Team-Content-mobile{
    //     display: none;
    // }


}
@media  only screen and (min-width: 1025px) { 
.Our-Team-ContentImages{
    display: none;
}
// .Our-Team-Content-mobile{
//     display: none;
// }
.Our-Team-Container-New-tablet{
    // border: 1px solid red;
}
.Our-Team-Content-tablet h1 {
    font-size: 14vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
    padding-top: 3%;
    padding-bottom: 2%;
    // text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.9882352941);
  }
  .Our-Team-Content-tablet .Our-Team-pa-tablet {
    font-family:  "space-Groteskr" !important;
    font-size: 2.2vw;
    /* letter-spacing: 0.9px; */
    color: #F1F9F9;
    opacity: 1;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
    line-height: 30px;
  }
  .Our-Team-Content-tablet .Our-Team-paa-tablet {
    font-family:  "space-Groteskr" !important;
    font-size: 2.2vw;
    letter-spacing: 0.8px;
    color: #F1F9F9;
    opacity: 1;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
    line-height: 30px;
  }
  .Our-Team-Content-tablet .Our-Team-paaa-tablet {
    font-family:  "space-Groteskr" !important;
    font-size: 3.2vw;
    /* letter-spacing: 0.2rem; */
    color: #F1F9F9;
    opacity: 1;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
  }
  .Our-Team-ContentImages-tablet{
    padding-top: 5%;
    padding-bottom: 0%;
    // height: 100%;
  }
}



@media only screen and (min-width: 1025px) and (max-width: 1225px){
.Evenion-Img8{
    width: 500px;
    height: 150px;
}
.Evenion-Img1{
    width: 250px;
    height: 150px;
}
.Evenion-Img3{
    width: 500px;
    height: 150px;
}
.Evenion-Img5{
    width: 250px;
    height: 150px;
}
.Evenion-Img4{
    width: 500px;
    height: 150px;
}
.Evenion-Img6{
    width: 500px;
    height: 150px;
}
.Evenion-Img7{
    width: 250px;
    height: 150px;
}
.Evenion-Img15{
    width: 500px;
    height: 150px;
}
.Evenion-Img9{
    width: 250px;
    height: 150px;
}
.Evenion-Img2{
    width: 500px;
    height: 150px;
}
}
@media only screen and (min-width: 1226px) and (max-width: 1326px){
    .Evenion-Img8{
        width: 500px;
        height: 165px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 165px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 165px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 165px;
    }
    .Evenion-Img4{
        width: 500px;
        height: 165px;
    }
    .Evenion-Img6{
        width: 500px;
        height: 165px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 165px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 165px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 165px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 165px;
    }
    }
    
@media only screen and (min-width: 1327px) and (max-width: 1426px){
    .Evenion-Img8{
        width: 500px;
        height: 175px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 175px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 175px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 175px;
    }
    .Evenion-Img4{
        width: 500px;
        height: 175px;
    }
    .Evenion-Img6{
        width: 500px;
        height: 175px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 175px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 175px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 175px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 175px;
    }
    }
    
@media only screen and (min-width: 1427px) and (max-width: 1526px){
    .Evenion-Img8{
        width: 500px;
        height: 185px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 185px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 185px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 185px;
    }
    .Evenion-Img4{
        width: 500px;
        height: 185px;
    }
    .Evenion-Img6{
        width: 500px;
        height: 185px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 185px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 185px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 185px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 185px;
    }
    }
    
@media only screen and (min-width: 1527px) and (max-width: 1626px){
    .Evenion-Img8 {
        width: 500px;
        height: 195px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 195px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 195px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 195px;
    }
    .Evenion-Img4{
        width: 500px;
        height: 195px;
    }
    .Evenion-Img6{
        width: 500px;
        height: 195px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 195px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 195px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 195px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 195px;
    }
}


@media only screen and (min-width: 1627px) and (max-width: 1726px){
    .Evenion-Img8 {
        width: 500px;
        height: 205px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 205px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 205px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 205px;
    }
    .Evenion-Img4{
        width: 500px;
        height: 205px;
    }
    .Evenion-Img6{
        width: 500px;
        height: 205px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 205px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 205px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 205px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 205px;
    }
}

@media only screen and (min-width: 1727px) and (max-width: 1826px){
    .Evenion-Img8 {
        width: 500px;
        height: 215px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 215px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 215px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 215px;
    }
    .Evenion-Img4{
        width: 500px;
        height: 215px;
    }
    .Evenion-Img6{
        width: 500px;
        height: 215px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 215px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 215px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 215px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 215px;
    }
}

@media only screen and (min-width: 924px) and (max-width: 1024px){
    .Evenion-Img8 {
        width: 500px;
        height: 135px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 135px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 135px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 135px;
    }
    .Evenion-Img4{
        width: 500px;
        height: 135px;
    }
    .Evenion-Img6{
        width: 500px;
        height: 135px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 135px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 135px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 135px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 135px;
    }
}

@media only screen and (min-width: 824px) and (max-width: 923px){
    .Evenion-Img8 {
        width: 400px;
        height: 115px;
    }
    .Evenion-Img1{
        width: 200px;
        height: 115px;
    }
    .Evenion-Img3{
        width: 400px;
        height: 115px;
    }
    .Evenion-Img5{
        width: 200px;
        height: 115px;
    }
    .Evenion-Img4{
        width: 400px;
        height: 115px;
    }
    .Evenion-Img6{
        width: 400px;
        height: 115px;
    }
    .Evenion-Img7{
        width: 200px;
        height: 115px;
    }
    .Evenion-Img15{
        width: 400px;
        height: 115px;
    }
    .Evenion-Img9{
        width: 200px;
        height: 115px;
    }
    .Evenion-Img2{
        width: 400px;
        height: 115px;
    }
}

@media only screen and (min-width: 724px) and (max-width: 823px){
    .Evenion-Img8 {
        width: 380px;
        height: 100px;
    }
    .Evenion-Img1{
        width: 200px;
        height: 100px;
    }
    .Evenion-Img3{
        width: 380px;
        height: 100px;
    }
    .Evenion-Img5{
        width: 200px;
        height: 100px;
    }
    .Evenion-Img4{
        width: 380px;
        height: 100px;
    }
    .Evenion-Img6{
        width: 380px;
        height: 100px;
    }
    .Evenion-Img7{
        width: 200px;
        height: 100px;
    }
    .Evenion-Img15{
        width: 380px;
        height: 100px;
    }
    .Evenion-Img9{
        width: 200px;
        height: 100px;
    }
    .Evenion-Img2{
        width: 380px;
        height: 100px;
    }
}

@media only screen and (min-width: 624px) and (max-width: 723px){
    .Evenion-Img8 {
        width: 500px;
        height: 110px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 110px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 110px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 110px;
    }
    .Evenion-Img4{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img6{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 110px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 110px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 110px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 110px;
    }
}

@media only screen and (min-width: 524px) and (max-width: 623px){
    .Evenion-Img8 {
        width: 500px;
        height: 100px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 100px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img4{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img6{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 100px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 100px;
    }
}

// @media only screen and (min-width: 424px) and (max-width: 523px){
@media only screen and (max-width: 523px){
    .Evenion-Img8 {
        width: 500px;
        height: 100px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 100px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img4{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img6{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 100px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 100px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 100px;
    }
}



@media only screen and (max-width: 480px){
    .Evenion-Img8 {
        width: 500px;
        height: 85px;
    }
    .Evenion-Img1{
        width: 250px;
        height: 85px;
    }
    .Evenion-Img3{
        width: 500px;
        height: 85px;
    }
    .Evenion-Img5{
        width: 250px;
        height: 85px;
    }
    .Evenion-Img4{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img6{
        width: 0px;
        height: 0px;
    }
    .Evenion-Img7{
        width: 250px;
        height: 85px;
    }
    .Evenion-Img15{
        width: 500px;
        height: 85px;
    }
    .Evenion-Img9{
        width: 250px;
        height: 85px;
    }
    .Evenion-Img2{
        width: 500px;
        height: 85px;
    }
}