.send-btn{
    border: 1px solid #FF6600;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 1.5%;
    padding-right: 3%;
    font-size: 1.3vw;
    font-family:  "space-Groteskr" !important;
    font-weight: lighter;
    background-image: linear-gradient(to left,
    transparent,
    transparent 50%,
    #FF6600 50%,
    #FF6600);
    background-position: right bottom;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all 0.4s ease;  
    .rotate-arrow{
        transform: rotate(180deg) scale(1.3);
        color: #ffff;
        background-position: right bottom;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all 0.4s ease;
    }
}

.send-btn:hover{
    background-position: 0 0; 
    border-radius: 10px;
    border: 1px solid #FF6600;
    background-color: transparent;
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 1.5%;
    padding-right: 3%;
    font-size: 1.3vw;
    font-family:  "space-Groteskr" !important;
    font-weight: lighter;
    .rotate-arrow{
        transform: rotate(135deg)scale(1.3);
        color: white;
        background-position: 0 0; 
    }
}




.outer-product {
    margin: 50px;
  }
  
  .button-product {
    border: 1px solid black;
    border-radius: 3px;
    width: 100px;
    height: 30px;
    display: block;
  
    background: linear-gradient(to right, black 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
  }
  
  .button-product:hover {
    background-position: left bottom;
  }
  
  .text-product {
    text-align: center;
    font-size: 16px;
    line-height: 30px; 
    color: black;
    transition: all .6s ease-out;
    display: block;
  }
  
  .text-product:hover {
    color: white;  
  }

  .stackeRank-Form{

  }


  
  
  
  @media  only screen and (min-width: 768px) {
    .DataSensex-Form-left{
      // border: 1px solid red;
      width: 30vw;
      left: 7vw;
      position: relative;
      margin-top: 5%;
      padding-top: 5%;
      // border: 1px solid blue;
    }
    .DataSenSex-H{
      font-family:  "space-Groteskr" !important;
      font-size: 3.5vw;
      color: #FFFFFF;
      position: relative;
      // padding-left: 5%;
      padding-right: 5%;
      padding-top: 25%;
    }
    .flexClass{
      display: flex;
      padding-bottom: 8%;
    }
  .flexClass img {
    max-width: 100%;
    height: auto;
    width: 30%;
  }
}





.DataSensex-Form-left img{
  // padding-bottom: 10%;
  // padding-top: 7%;
  // width: 80%;
}