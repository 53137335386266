.Main-Pratice{
    // background: transparent linear-gradient(180deg, #003D6D 0%, #001F37 100%) 0% 0% no-repeat padding-box;
    opacity: 1; 
    overflow: hidden;
}
.Pratice-content{
    padding: 5% 5% 5% 5%;
}
.Pratice-content h1{
    padding: 0% 0% 0% 0%;
    font-size: 11vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
}
.Pratice-content .para1{
    font-size: 1.4vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding: 1% 10% 1% 10%;
}
.Pratice-content .para2{
    font-size: 2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;

}

.Pratice-box{
    display: flex;
    padding: 5% 5% 0% 5%;
    justify-content: center;
}
.Pratice-box .box1{
    width: 45vw;
    padding: 0% 5% 5% 5%;
}
.Pratice-box .box2{
    width: 45vw;
    padding: 15% 5%;
}

.pointer{
    cursor: pointer;
}
.box1 .inside-b1{
    background: transparent linear-gradient(180deg, #0F326C00 0%, #0F326C3D 67%, #0F326C 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    padding: 6% 5%;
    border: 6px solid transparent;
    margin: 0% 0% 25% 0%;
    transition: all .3s ease-in;
} 
.box1:hover .inside-b1:hover{
    background: transparent linear-gradient(180deg, #0F326C00 0%, #3E75D1 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 6% 5%;
    margin: 0% 0% 25% 0%;
    border: 6px solid #71A0EE;
    border-radius: 20px;
} 
.box1 .inside-b2{
    background: transparent linear-gradient(180deg, #F05F3000 0%, #F05F304D 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    padding: 6% 5%;
    border: 6px solid transparent;
    transition: all .3s ease-in;
} 
.box1:hover .inside-b2:hover{
    border-radius: 20px;
    opacity: 1;
    padding: 6% 5%;
    background: transparent linear-gradient(180deg, #F05F3000 0%, #F05F30A8 73%, #F05F30 100%) 0% 0% no-repeat padding-box;
    border: 6px solid #FF6600;
    margin: 0% 0% 25% 0%;
} 
.box2 .inside-b1{
    background: transparent linear-gradient(180deg, #F05F3000 0%, #F05F304D 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    padding: 6% 5%;
    border: 6px solid transparent;
    margin: 0% 0% 25% 0%;
    transition: all .3s ease-in;
} 
.box2:hover .inside-b1:hover{
    border-radius: 20px;
    opacity: 1;
    padding: 6% 5%;
    background: transparent linear-gradient(180deg, #F05F3000 0%, #F05F30A8 73%, #F05F30 100%) 0% 0% no-repeat padding-box;
    border: 6px solid #FF6600;
    margin: 0% 0% 25% 0%;
} 

.box2 .inside-b2{
    background: transparent linear-gradient(180deg, #0F326C00 0%, #0F326C3D 67%, #0F326C 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    padding: 6% 5%;
    margin: 0% 0% 0% 0%;
    border: 6px solid transparent;
    transition: all .3s ease-in;
} 
.box2:hover .inside-b2:hover{
    background: transparent linear-gradient(180deg, #0F326C00 0%, #3E75D1 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 6% 5%;
    margin: 0% 0% 0% 0%;
    border: 6px solid #71A0EE;
    border-radius: 20px;
} 

.inside-b1 h1{
    margin: 0;
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
    letter-spacing: 1.445x;
}
.inside-b2 h1{
    margin: 0;
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
    line-height: 135%;
}
.inside-b1 p{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    color: #FFFFFF;

    font-weight: lighter;
    line-height: 200%;
    padding: 5% 0% 0% 0%;

}
.inside-b2 p{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    color: #FFFFFF;
    font-weight: lighter;
    line-height: 200%;
    padding: 5% 0% 0% 0%;

}
.para-end{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    color: #FFFFFF;
    font-weight: lighter;
    line-height: 200%;
    padding: 0% 10% 5% 10%;
    margin-top: 0%;
    margin-bottom: 0%;
}


@media  only screen and (max-width: 480px) {
    .Main-Pratice{
        display: none;
    }
    .Main-Pratice-mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 5% 0%;
        overflow: hidden;
    }

    .Pratice-content-mobile h1{
        font-size: 9.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 2%;
    }
    .Pratice-content-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 5% 5% 5%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .Pratice-content-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }

    .Pratice-box-mobile{
        padding: 5% 0%;
        text-align: -webkit-center;
    }

    .Pratice-box-mobile .PracBox1{
        margin: 15% 0%;
        width: 85vw;
        background: transparent linear-gradient(180deg, #0F326C00 0%, #3E75D1B3 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        overflow: hidden;
    }
    .Pratice-box-mobile .PracBox1 h1{
        font-size: 22px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
        padding: 10% 8% 2% 8%;
        line-height: 200%;
    }
    .Pratice-box-mobile .PracBox1 p{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.64px;
        line-height: 200%;
        padding: 2% 8% 2% 8%;
    }
    .Pratice-box-mobile .PracBox2{
        margin: 15% 0%;
        width: 85vw;
        background: transparent linear-gradient(180deg, #F05F3000 0%, #F05F304D 73%, #F05F30 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        overflow: hidden;
    }
    .Pratice-box-mobile .PracBox2 h1{
        font-size: 22px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
        padding: 10% 8% 2% 8%;
        line-height: 200%;
    }
    .Pratice-box-mobile .PracBox2 p{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.64px;
        line-height: 200%;
        padding: 2% 8% 2% 8%;
    }
    .para-end-mobile{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.64px;
        line-height: 200%;
        padding: 2% 8% 2% 8%;
    }
}


@media  only screen and (min-width: 481px) and (max-width: 767px){
    .Main-Pratice{
        display: none;
    }
    .Main-Pratice-mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 5% 0%;
        overflow: hidden;
    }

    .Pratice-content-mobile h1{
        font-size: 9.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 2%;
    }
    .Pratice-content-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 5% 5% 5%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .Pratice-content-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }

    .Pratice-box-mobile{
        padding: 5% 0%;
        text-align: -webkit-center;
    }

    .Pratice-box-mobile .PracBox1{
        margin: 15% 0%;
        width: 80vw;
        background: transparent linear-gradient(180deg, #0F326C00 0%, #3E75D1B3 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        overflow: hidden;
    }
    .Pratice-box-mobile .PracBox1 h1{
        font-size: 22px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
        padding: 10% 8% 2% 8%;
        line-height: 200%;
    }
    .Pratice-box-mobile .PracBox1 p{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.64px;
        line-height: 200%;
        padding: 2% 8% 2% 8%;
    }
    .Pratice-box-mobile .PracBox2{
        margin: 15% 0%;
        width: 80vw;
        background: transparent linear-gradient(180deg, #F05F3000 0%, #F05F304D 73%, #F05F30 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        overflow: hidden;
    }
    .Pratice-box-mobile .PracBox2 h1{
        font-size: 22px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
        padding: 10% 8% 2% 8%;
        line-height: 200%;
    }
    .Pratice-box-mobile .PracBox2 p{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.64px;
        line-height: 200%;
        padding: 2% 8% 2% 8%;
    }
    .para-end-mobile{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.64px;
        line-height: 200%;
        padding: 2% 8% 2% 8%;
    }
}
@media  only screen and (min-width: 768px){
    .Main-Pratice-mobile{
        display: none;
    }
}
@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .Pratice-content h1 {
        font-size: 12vw;
    }
    .Pratice-content .para1 {
        font-size: 1.6vw;
        letter-spacing: 1.44px;
    }
    .Pratice-content .para2 {
        font-size: 2.5vw;
        letter-spacing: 0.44px;
    }
    .inside-b1 p {
        font-size: 1.5vw;
        letter-spacing: 0.96px;    
    }
    .inside-b1 h1 {
        font-size: 2.2vw;
    }
    .inside-b2 h1 {
        font-size: 2.2vw;
    }
    .Pratice-box .box1 {
        width: 45vw;
        padding: 0% 1% 5% 1%;
    }
    .Pratice-box .box2 {
        width: 45vw;
        padding: 15% 1%;
    }
    .inside-b2 p {
        font-size: 1.5vw;
        letter-spacing: 0.96px;    
    }
    .para-end{
        font-size: 1.4vw;
    }
}