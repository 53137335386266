.main-digiTrans{
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;
}

.head-DigiTran{
    padding: 5% 0% 0% 0%;
}

.head-DigiTran .head1{
    line-height: 100%;
    font-size: 4.5vw;
    opacity: 1;
    color: #FF6600;
    font-family: "space-Groteskb" !important;
    margin: 2% 0% 2% 0%;
    text-align-last: center;
}

.head-DigiTran .para1{
    font-size: 1.3vw;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
}
.head-DigiTran .para2{
    font-size: 1.3vw;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 20%;
    margin-right: 20%;
}

.Container-DigiTran{
    padding: 0% 0% 5% 0%;
    text-align: -webkit-center;
}

.DigiBox{
    .class2{
        display: none;
    }
    margin: 8% 0% 0% 0%;
    height: 22vw;
    width: 65%;
    display: flex;
    background-color: #00CCC5;
    border-radius: 25px;
    cursor: pointer;

    transition-property: all;
    transition-duration: 1s;
    transition-delay: 0.15s;
    transition-timing-function: ease;
    .img-bg{
        width: 40%;
        position: relative;
        align-self: center;       
        height: 22vw;
    }
    .img-bg img{
        max-width: 100%;
        height: auto;
        width: 100%;
        border-radius: 20px;
        height: 22vw;
    }
    .content-bg{
        width: 60%;
    }
    .content-bg .numClass{
        font-size: 32px;
        font-family:  "space-Groteskb" !important;
    }
    .saasClass{
        font-size: 2vw;
    }
    .content-bg h1{
        letter-spacing: 2.4px;
        color: #011639;
        opacity: 1;
        text-align: -webkit-left;
        padding: 15% 0% 5% 5%;
        font-family: "space-Grotesksb" !important;
        font-size: 3vw;
    }
    .content-bg p{
        display: none;
    }
}




.DigiBox:hover{
    width: 85%;
    cursor: pointer;
    .class1{
        display: none;
    }
    .img-bg{
        width: 40%;
    }
    .img-bg img{
        max-width: 100%;
        height: auto;
        width: 100%;
        border-radius: 20px;
        height: 22vw;

    }
    .class2{
        display: block;
    }
    .content-bg{
        width: 60%;
    }
    .content-bg h1{
        letter-spacing: 2.4px;
        color: #011639;
        opacity: 1;
        text-align: -webkit-left;
        padding: 2% 5% 2% 5%;
        font-family: "space-Grotesksb" !important;
        font-size: 3vw;
    }
    .content-bg p{
        display: block;
        font-size: 1.2vw;
        text-align: left;
        font-family: "space-Groteskr" !important;
        letter-spacing: 0.96px;
        padding: 0% 5% 0% 5%;
    }
}






@media  only screen and (max-width: 480px){
    .main-digiTrans{
        display: none;
    }

    .Main-DigiTrans-Mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
    }

    .Mobile-digiC{
        padding: 15% 0% 5% 0% ;
    }
    .Mobile-digiC .para1{
        font-size: 16px;
        text-align: center;
        font-family:"space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
    }
    .Mobile-digiC .para2{
        font-size: 20px;
        text-align: center;
        font-family: "space-Grotesksb" !important;
        letter-spacing: 1.44px;
        color: #FFFFFF;
        opacity: 1;
        margin-left: 12%;
        margin-right: 12%;
    }
    .Mobile-digiC h1{
        line-height: 45px;
        font-size: 40px;
        opacity: 1;
        color: #FF6600;
        font-family:  "space-Groteskb" !important;
        margin: 5% 0% 5% 0%;
        text-align-last: center;
        letter-spacing: 1.96px;
    }
    .Mobile-DigiBox{
        padding: 10% 0% 10% 0%;
        text-align: -webkit-center;
    }
}


@media  only screen and (min-width: 1024px){
    .DigiTab-Container{
        display: none;
    }
  }
@media  only screen and (min-width: 768px){
    .Main-DigiTrans-Mobile{
        display: none;
    }
  }

@media  only screen and (min-width: 481px) and (max-width: 767px){
    .main-digiTrans{
        display: none;
    }

    .Main-DigiTrans-Mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
    }

    .Mobile-digiC{
        padding: 15% 0% 5% 0% ;
    }
    .Mobile-digiC .para1{
        font-size: 16px;
        text-align: center;
        font-family: "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
    }
    .Mobile-digiC .para2{
        font-size: 20px;
        text-align: center;
        font-family: "space-Grotesksb" !important;
        letter-spacing: 1.44px;
        color: #FFFFFF;
        opacity: 1;
        margin-left: 12%;
        margin-right: 12%;
    }
    .Mobile-digiC h1{
        line-height: 45px;
        font-size: 40px;
        opacity: 1;
        color: #FF6600;
        font-family:  "space-Groteskb" !important;
        margin: 5% 0% 5% 0%;
        text-align-last: center;
        letter-spacing: 1.96px;
    }
    .Mobile-DigiBox{
        padding: 10% 0% 10% 0%;
        text-align: -webkit-center;
    }

}



@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .Container-DigiTran{
        display: none;
    }
    .head-DigiTran .para1 {
        font-size: 16px !important;
    }
    .head-DigiTran .head1 {
        font-size: 45px !important;
    }
    .head-DigiTran .para2 {
        font-size: 18px !important;
        margin: 0% 5% !important;
    }
    .DigiTab-Container{
        padding: 0% 0% 5% 0%;
        text-align: -webkit-center;
    }
    .DigiTab{
        margin: 8% 0% 0% 0%;
        height: 32vh;
        width: 85%;
        display: flex;
        background-color: #00CCC5;
        border-radius: 25px;
        cursor: pointer;
        transition-property: all;
        transition-duration: 1s;
        transition-delay: 0.15s;
        transition-timing-function: ease;
    }
    .DigiTab img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .DigiTab .content-bg h1 {
        letter-spacing: 2.4px;
        color: #011639;
        opacity: 1;
        text-align: -webkit-left;
        padding: 2% 5% 2% 5%;
        font-family:  "space-Grotesksb" !important;
        font-size: 20px;
    }
    .DigiTab .content-bg p {
        display: block;
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        padding: 0% 5% 0% 5%;
    }
    .DigiTab .img-bg {
        width: 40%;
    }
    .DigiTab .content-bg {
        width: 60%;
    }
    
}