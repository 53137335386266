.Main_Digital {
    background-image: linear-gradient(to right, #01143f, #016b9b);
}

.Digital_banner{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
.Digital_banner img{ 
    position: relative; 
    width: 100%;
    // height: 100vh;
}
.inner_Digital {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%, -55%);
}
.inner_Digital1{
    width: 80%;
}
.inner_Digital h1{
    line-height: 100%;
    font-size: 6.5vw;
    opacity: 1;
    color: #ffff;
    font-family:"space-Groteskb" !important;
    margin-bottom: 3%;
}
.inner_Digital h2{
    line-height: 100%;
    font-size: 3.5vw;
    opacity: 1;
    color: white;
    font-family:"space-Groteskb" !important;
    margin-bottom: 3%;
}
.inner_Digital .Pre-para1{
    color: #FF6600;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 2.2vw;
    margin-bottom: 3%;
}
.inner_Digital .Pre-para2{
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family: "space-Groteskr" !important;
    font-size: 1.5vw;
    margin-bottom: 3%;
    width: 80%;
}

.Slide-Digital{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;    opacity: 1;
    top: 0px;
  }    


  .Bg-OurStacks-BA{
    background: transparent linear-gradient(180deg, #003D6D 0%, #001F37 100%) 0% 0% no-repeat padding-box;    opacity: 1;
  }


  .BG-Main{
    background: transparent linear-gradient(180deg, #003D6D 0%, #001F37 100%) 0% 0% no-repeat padding-box;
  }
  @media  only screen and (max-width: 480px) {
    .Digital_banner{
        display: none;
    }
    .Digital_banner_mobile{
        position: relative;
        height: 100vh;
        // border: 1px solid red;
    }

    .Digital_banner_mobile img{
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        // object-position: 75% 60%;
    }
    
    .inner_Digital_mobile {
        position: absolute;
        top: 50%;
        left: 8%;
        transform: translate(0%, -26%);
        width: 80vw;
    }
    .inner_Digital_mobile h1{
        line-height: 100%;
        font-size: 10vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskb" !important;
        font-weight: 600;
        text-align: left;
        padding-bottom: 2%;
        line-height: 135%;
    }
    .inner_Digital_mobile .Pre-para1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
    }
    .inner_Digital_mobile h2 {
        line-height: 100%;
        font-size: 18px;
        opacity: 1;
        color: white;
        font-family:  "space-Groteskb" !important;
        margin-bottom: 3%;
    }
    .inner_Digital_mobile .Pre-para2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .Slide-Digital {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        border-top-right-radius:0vw;
        border-top-left-radius: 0vw;
        background: transparent linear-gradient(359deg, #031024 0.39%, #000 14.46%, #03222d 34.38%, #000312 66.4%, #090e26 78.89%, #1b1919 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }

}
@media  only screen and (min-width: 481px) and (max-width: 767px){
    .Digital_banner{
        display: none;
    }
    .Digital_banner_mobile{
        position: relative;
        height: 100vh;
        // border: 1px solid red;
    }

    .Digital_banner_mobile img{
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        // object-position: 75% 60%;
    }
    
    .inner_Digital_mobile {
        position: absolute;
        top: 50%;
        left: 8%;
        transform: translate(0%, -26%);
        width: 80vw;
    }
    .inner_Digital_mobile h1{
        line-height: 100%;
        font-size: 10vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskb" !important;
        font-weight: 600;
        text-align: left;
        padding-bottom: 2%;
        line-height: 135%;
    }
    .inner_Digital_mobile .Pre-para1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
    }
    .inner_Digital_mobile h2 {
        line-height: 100%;
        font-size: 18px;
        opacity: 1;
        color: white;
        font-family:  "space-Groteskb" !important;
        margin-bottom: 3%;
    }
    .inner_Digital_mobile .Pre-para2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .Slide-Digital {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        border-top-right-radius: 0vw;
        border-top-left-radius: 0vw;
        background: transparent linear-gradient(359deg, #031024 0.39%, #000 14.46%, #03222d 34.38%, #000312 66.4%, #090e26 78.89%, #1b1919 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    
    .inner_Digital {
        position: absolute;
        top: 55%;
        left: 5%;
        transform: translate(0%, -55%);
    }
    .inner_Digital h1 {
        font-size: 7vw;
    }
    .inner_Digital .Pre-para2 {
        font-size: 1.6vw;
    }
    .inner_Digital .Pre-para1 {
        font-size: 2.4vw;
    }
    .inner_Digital h2 {
        font-size: 3.6vw;
    }
    .Slide-Digital {
        border-top-right-radius: 0vw;
        border-top-left-radius: 0vw;
    }
}


@media  only screen and (max-width: 480px) {
    .Taas_banner{
        display: none;
    }
    .Taas_banner_mobile{
        position: relative;
        height: 100vh;
        // border: 1px solid red;
    }

    .Taas_banner_mobile img{
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        // object-position: 75% 60%;
    }
    
    .inner_Taas_mobile {
        position: absolute;
        top: 50%;
        left: 8%;
        transform: translate(0%, -26%);
        width: 80vw;
    }
    .inner_Taas_mobile h1{
        line-height: 100%;
        font-size: 14vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskb" !important;
        font-weight: 600;
        text-align: left;
    }
    .inner_Taas_mobile .Pre-para1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
    }
    .inner_Taas_mobile h2 {
        line-height: 100%;
        font-size: 18px;
        opacity: 1;
        color: white;
        font-family:  "space-Groteskb" !important;
        margin-bottom: 3%;
    }
    .inner_Taas_mobile .Pre-para2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .Slide-Taas {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        border-top-right-radius: 12vw;
        border-top-left-radius: 12vw;
        background: transparent linear-gradient(359deg, #031024 0.39%, #000 14.46%, #03222d 34.38%, #000312 66.4%, #090e26 78.89%, #1b1919 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }

}



@media  only screen and (min-width: 768px){
    .Digital_banner_mobile{
        display: none;
    }
}
  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .Taas_banner{
        display: none;
    }
    .Taas_banner_mobile{
        position: relative;
        height: 100vh;
        // border: 1px solid red;
    }

    .Taas_banner_mobile img{
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        // object-position: 75% 60%;
    }
    
    .inner_Taas_mobile {
        position: absolute;
        top: 50%;
        left: 8%;
        transform: translate(0%, -26%);
        width: 80vw;
    }
    .inner_Taas_mobile h1{
        line-height: 100%;
        font-size: 14vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:"space-Groteskb" !important;
        font-weight: 600;
        // line-height: 80px;
        text-align: left;
    }
    .inner_Taas_mobile .Pre-para1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
    }
    .inner_Taas_mobile h2 {
        line-height: 100%;
        font-size: 18px;
        opacity: 1;
        color: white;
        font-family:  "space-Groteskb" !important;
        margin-bottom: 3%;
    }
    .inner_Taas_mobile .Pre-para2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .Slide-Taas {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        border-top-right-radius: 12vw;
        border-top-left-radius: 12vw;
        background: transparent linear-gradient(359deg, #031024 0.39%, #000 14.46%, #051c24 34.38%, #000312 66.4%, #090e26 78.89%, #1b1919 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }
  }