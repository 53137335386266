.Main_Taas_services {
    background-image: linear-gradient(to right, #03186c, #0c0d22);
}

.Taas_banner{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
.Taas_banner img{ 
    position: relative; 
    width: 100%;
}
.inner_Taas {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%, -55%);
}
.inner_Taas1{
    width: 60%;
}
.inner_Taas h1{
    line-height: 100%;
    font-size: 8vw;
    opacity: 1;
    background: linear-gradient(108deg, #FF6600 0%, #35D2D2 40%);
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "space-Groteskb" !important;
    margin-bottom: 2%;
}
.inner_Taas h2{
    line-height: 100%;
    font-size: 3.5vw;
    opacity: 1;
    color: white;
    font-family: "space-Groteskb" !important;
    margin-bottom: 3%;
}
.inner_Taas .Pre-para1{
    color: #FF6600;
    opacity: 1;
    letter-spacing: 0px;
    font-family: "space-Grotesksb" !important;
    font-size: 2.2vw;
    margin-bottom: 2%;
}
.inner_Taas .Pre-para2{
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family: "space-Groteskr" !important;
    font-size: 1.5vw;
    margin-bottom: 2%;
}

.Slide-Taas{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    background: transparent linear-gradient(359deg, #00122E 0.39%, #000 14.46%, #013143 34.38%, #00072D 66.4%, #182878 78.89%, #010101 99.5%) 0% 0% no-repeat padding-box;
    opacity: 1;
    top: 0px;
  }    




    
@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .inner_Taas {
        position: absolute;
        top: 55%;
        left: 5%;
        transform: translate(0%, -55%);
    }
    .inner_Taas h1 {
        font-size: 9vw;
    }
    .inner_Taas .Pre-para2 {
        font-size: 1.6vw;
    }
    .inner_Taas .Pre-para1 {
        font-size: 2.4vw;
    }
    .inner_Taas h2 {
        font-size: 3.6vw;
    }
    .inner_Taas1{
        width: 55%;
    }
}


@media  only screen and (max-width: 480px) {
    .Taas_banner{
        display: none;
    }
    .Taas_banner_mobile{
        position: relative;
        height: 100vh;
    }

    .Taas_banner_mobile img{
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
    }
    
    .inner_Taas_mobile {
        position: absolute;
        top: 50%;
        left: 8%;
        transform: translate(0%, -26%);
        width: 80vw;
    }
    .inner_Taas_mobile h1{
        line-height: 100%;
        font-size: 14vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "space-Groteskb" !important;
        font-weight: 600;
        text-align: left;
    }
    .inner_Taas_mobile .Pre-para1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family: "space-Grotesksb" !important;
        font-size: 18px;
    }
    .inner_Taas_mobile h2 {
        line-height: 100%;
        font-size: 18px;
        opacity: 1;
        color: white;
        font-family:  "space-Groteskb" !important;
        margin-bottom: 3%;
    }
    .inner_Taas_mobile .Pre-para2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .Slide-Taas {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        border-top-right-radius: 12vw;
        border-top-left-radius: 12vw;
        background: transparent linear-gradient(359deg, #031024 0.39%, #000 14.46%, #03222d 34.38%, #000312 66.4%, #090e26 78.89%, #1b1919 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }

}



@media  only screen and (min-width: 768px){
    .Taas_banner_mobile{
        display: none;
    }
}
  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .Taas_banner{
        display: none;
    }
    .Taas_banner_mobile{
        position: relative;
        height: 100vh;
    }

    .Taas_banner_mobile img{
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
    }
    
    .inner_Taas_mobile {
        position: absolute;
        top: 50%;
        left: 8%;
        transform: translate(0%, -26%);
        width: 80vw;
    }
    .inner_Taas_mobile h1{
        line-height: 100%;
        font-size: 14vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "space-Groteskb" !important;
        font-weight: 600;
        text-align: left;
    }
    .inner_Taas_mobile .Pre-para1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family: "space-Grotesksb" !important;
        font-size: 18px;
    }
    .inner_Taas_mobile h2 {
        line-height: 100%;
        font-size: 18px;
        opacity: 1;
        color: white;
        font-family:  "space-Groteskb" !important;
        margin-bottom: 3%;
    }
    .inner_Taas_mobile .Pre-para2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .Slide-Taas {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        border-top-right-radius: 12vw;
        border-top-left-radius: 12vw;
        background: transparent linear-gradient(359deg, #031024 0.39%, #000 14.46%, #051c24 34.38%, #000312 66.4%, #090e26 78.89%, #1b1919 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }
  }