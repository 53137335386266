.Main_DataSenSex{
    background-color: #00092f;
}

.DataSenSex_Main{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    // background-color: #00082E;
    // border: 1px solid red;
}

.DataSenSex_Main img{ 
    position: relative;
    width: 100%;
    display: flex;
    // height: 60vh;
}
.DataSenSex_Main_Inner{
    // border: 1px solid red;
    position: absolute;
    top: 45%;
    left: 20%;
    transform: translate(-25%, -65%);
}
.DataSenSex_Main_Inner img{
    max-width: 100%;
    height: auto;
    width: 20%;
    padding-bottom: 2%;
}
.DataSenSex_Inner h1{
    font-family:  "space-Groteskb" !important;
    font-size: 4vw;
    color: #fff;
    letter-spacing: 1.96px;
}
.DataSenSex_Inner .Datapara1{
    font-family: "space-Grotesksb" !important;
    font-size: 1.8vw;
    text-align: left;
    letter-spacing: 0px;
    color: #FF6600;
    opacity: 1;
}
.DataSenSex_Inner .Datapara2{
    // border: 1px solid yellow; 
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 1.5vw;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    letter-spacing: 1.44px;   
    color: #FFFFFF;
    font-weight: lighter;
}

.DataSenSex_Main_Inner-right{
    // border: 1px solid blue;
    position: absolute;
    top: 45%;
    left: 70%;
    transform: translate(-25%, -65%);
}


.Main_DataSenSex2{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    opacity: 1;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    // background: transparent linear-gradient(0deg, #010A32 0%, #00082E 28%, #193A88 50%, #032151 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(360deg, #000000 0%, #0c0e65 79%, #000000 100%) 0% 0% no-repeat padding-box
    // border: 1px solid green;
}

.Data-video{
    width: 100%;
    height: 28vw !important;
    object-fit: fill;
    padding-bottom: 1%;
}
.Stackerank-video{
    width: 100%;
    height: 28vw !important;
    object-fit: fill;
    padding-bottom: 1%;
}


@media only screen and (max-width: 480px){
    .DataSenSex_Main{
        display: none !important;
    }
    .Toggle-State{
        display: none !important;
    }
    .DataSensex-Form-left {
        display: none;
    }
    .stackeRank-p {
        display: none;
    }

    .Main_DataSenSex2 {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        opacity: 1;
        // background: transparent linear-gradient(0deg, #010A32 0%, #00082E 28%, #193A88 50%, #032151 100%) 0% 0% no-repeat padding-box;
        // background: transparent linear-gradient(360deg, #000000 0%, #0c0e65 79%, #000000 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(0deg,#010516,#0B286B 50%,#021A3F) 0 0 no-repeat padding-box;
        border-top-right-radius: 0vw;
        border-top-left-radius: 0vw;
    }
    .DataSenSex_Main_mobile_sec{
        // border: 1px solid red;
    }
    .DataSenSex_Main_mobile_sec img{
            width: 100vw;
    height: 100vh;
    background-size: cover;
    object-fit: cover;
        // border: 1px solid red;
    }
    .DataSenSex_Main-mobile img{
        max-width: 100%;
        width: 100vw;
        height: 100vh;
    }
    .Data-video-DataSensex-Mobile{
        width: 100%;
        height: auto;
        object-fit: fill;
        padding: 1%;
        // border: 1px solid red;
    }
    .banner-up-DataSensex{
        position: absolute;
        top: 15%;
        right: 20%;
        width: 60%;
        padding-left: 1%;
        padding-right: 1%;
    }
    .DataSenSex_Main_mobile_Content1{
        // border: 1px solid red;
        padding-top: 3%;
        padding-bottom: 5%;
        margin-top: 1%;
        margin-bottom: 5%;
        position: absolute;
        top: 55%;
        left: 10%;
    }

    .DataSenSex_Main_mobile_Content1 img{
        max-width: 100%;
        height: auto;
        width: 20%;
    }
    .DataSenSex_Main_mobile_Content1 h1 {
        font-family:  "space-Groteskb" !important;
        font-size: 10vw;
        color: #fff;
        letter-spacing: 1.96px;
        padding-top: 2%;
        margin-bottom: 0%;
    }
    
    .DataSenSex_Main_mobile_Content1 .Datapara1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        margin-bottom: 1%;
    }
    .DataSenSex_Main_mobile_Content1 .Datapara2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;   
    }
    .WhyDataSenSex-inside1 h1 {
        font-size: 12.5vw;
        padding-left: 5%;
        padding-right: 5%;
        line-height: 130%;
    }
    .WhyDataSenSex-inside1 .WhyData-para1 {
        font-size: 18px;
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 2%;
        letter-spacing: 1.44px;
        line-height: 170%;
    }
    
    .WhyDataSensex-Container1 {
        width: 90%;
    }
    .Content-Container1 .Content-para1 {
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 20px;
        font-family:  "space-Grotesksb" !important;
    }
    .Content-Container1 .Content-para2 {
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 16px;
        font-family:  "space-Groteskr" !important;
        // border: 1px solid red;
    }    
    .img-Container1 {
        width: 50%;
    }
    .img-Container1 img {
        width: 100%;
    }
    .WhyDataSensex-Container2 {
        width: 90%;
    }
    .img-Container2 {
        width: 50%;
    }
    .Content-Container2 {
        width: 60%;
        position: relative;
        align-self: center;
        left: 10%;
    }
    .Content-Container2 .Content-para1 {
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 20px;
        font-family:  "space-Grotesksb" !important;
    }
    .Content-Container2 .Content-para2 {
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 16px;
        font-family:  "space-Groteskr" !important;
    }

.WhyDataSensex-Container3 {
    width: 90%;
    margin-top: 15%;
    margin-bottom:20%;
    display: flex;
    position: relative;
}
.img-Container3 img {
    max-width: 100%;
    height: auto;
    width: 55%;
}
.img-Container3 {
    width: 50%;
}
.Content-Container3 .Content-para1 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;
    font-family:  "space-Grotesksb" !important;
}
.Content-Container3 .Content-para2 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    font-family:  "space-Groteskr" !important;
}
.img-Container4 {
    width: 50%;
}
.Content-Container4 {
    width: 60%;
    position: relative;
    left: 5%;
    align-self: center;
}
.Content-Container4 .Content-para1 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;
    font-family:  "space-Grotesksb" !important;
}
.Content-Container4 .Content-para2 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    font-family:  "space-Groteskr" !important;
}
.img-Container4 img {
    max-width: 100%;
    height: auto;
    width: 70%;
}
.UseCases-Main1 h1 {
    font-size: 13vw !important;
}
.UseCases-Main1 p {
    font-size: 16px  !important;
    margin-left: 8% !important;
    margin-right: 8% !important;
    padding-top: 3% !important;
    padding-bottom: 7% !important;
}
.expanding-accordion-DataSensex-Mobile {
    display: flex;
    flex-direction: column;
    gap: 15px !important;
    padding-top: 0% !important;
    padding-bottom: 5%;
    padding-left: 4% !important;
    padding-right: 4% !important;
    width: 100% !important;
}
.card-header-DataSensex-Mobile .card-title-DataSensex-Mobile {
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 22px;
}
.expanded-DataSensex-Mobile .card-content-DataSensex-Mobile {
    font-size: 16px !important;
    letter-spacing: 0.58px !important;
    // text-align: center !important;
}
.card-header-DataSensex-Mobile {
    padding: 8px !important;
}
 .inside img {
    max-width: 100%;
    height: auto;
    width: 70%;
}

.DataSensex-Form-left-Mobile {
    margin-top: 1%;
    padding-top: 15%;
    position: relative;
    display: grid;
    justify-items: center;
}

.DataSenSex-H-Mobile {
    font-family:  "space-Groteskr" !important;
    font-size: 9vw;
    color: #FFFFFF;
    position: relative;
    padding-top: 5%;
}
    .flexClass-Mobile {
        display: contents;
        padding-bottom: 0%;
        margin-bottom: 2%;
    }
    
.flexClass-Mobile img {
  max-width: 100%;
  height: auto;
  width: 25%;
}
.UseCases-Main1 {
    padding-top: 25% !important;
    padding-bottom: 0% !important;
    margin-top: 8% !important;
}



}

@media only screen and (min-width: 768px){
    .DataSenSex_Main_mobile_sec{
        display: none !important;
    }
    .Toggle-State-Mobile{
        display: none !important;
    }
    .DataSensex-Form-left-Mobile{
        display: none;
    }
}

@media  only screen and (min-width: 481px) and (max-width: 767px){
    .Toggle-State{
        display: none !important;
    }
    .DataSensex-Form-left {
        display: none;
    }

    .DataSenSex_Main{
        display: none;
    }

    .Main_DataSenSex2 {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        opacity: 1;
        background: transparent linear-gradient(0deg, #010A32 0%, #00082E 28%, #193A88 50%, #032151 100%) 0% 0% no-repeat padding-box;
        border-top-right-radius: 0vw;
        border-top-left-radius: 0vw;
    }
    .DataSenSex_Main_mobile_sec{
        // border: 1px solid red;
    }
    .DataSenSex_Main_mobile_sec img{
            width: 100vw;
    height: 100vh;
    background-size: cover;
    object-fit: cover;
        // border: 1px solid red;
    }
    .DataSenSex_Main-mobile img{
        max-width: 100%;
        width: 100vw;
        height: 100vh;
    }
    .Data-video-DataSensex-Mobile{
        width: 100%;
        height: auto;
        object-fit: fill;
        padding-bottom: 1%;
        // border: 1px solid red;
        padding-left: 1%;
        padding-right: 1%;
    }
    .banner-up-DataSensex{
        position: absolute;
        top: 17%;
        right: 25%;
        width: 50%;
        padding-left: 1%;
        padding-right: 1%;
    }
    .DataSenSex_Main_mobile_Content1{
        // border: 1px solid red;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-top: 5%;
        margin-bottom: 5%;
        position: absolute;
        top: 55%;
        left: 10%;
    }

    .DataSenSex_Main_mobile_Content1 img{
        max-width: 100%;
        height: auto;
        width: 16%;
    }
    .DataSenSex_Main_mobile_Content1 h1 {
        font-family:  "space-Groteskb" !important;
        font-size: 8.2vw;
        color: #fff;
        letter-spacing: 1.96px;
        padding-top: 2%;
        margin-bottom: 0%;
    }
    
    .DataSenSex_Main_mobile_Content1 .Datapara1 {
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        margin-bottom: 1%
    }
    .DataSenSex_Main_mobile_Content1 .Datapara2 {
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
    .WhyDataSenSex-inside1 h1 {
        font-size: 11.5vw;
        padding-right: 4%;
        padding-left: 4%;
        line-height: 130%;
    }
    .WhyDataSenSex-inside1 .WhyData-para1 {
        font-size: 18px;
        padding-top: 2%;
        padding-left: 2%;
        padding-right: 2%;
        letter-spacing: 1.44px;
        line-height: 170%;

    }
    .WhyDataSensex-Container1 {
        width: 90%;
    }
    .img-Container1 {
        width: 50%;
    }
    .img-Container1 img {
        width: 85%;
    }
    .Content-Container1 .Content-para1 {
        font-size: 20px;
    }
    .Content-Container1 .Content-para2 {
        font-size: 16px;
    }


    .WhyDataSensex-Container2 {
        width: 90%;
    }
    .img-Container2 {
        width: 50%;
    }
    .Content-Container2 {
        left: 10%;
    }
    .Content-Container2 .Content-para1 {
        font-size: 20px;
    }
    .Content-Container2 .Content-para2 {
        font-size: 16px;
    }


    .WhyDataSensex-Container3 {
        width: 90%;
    }
    .img-Container3 {
        width: 50%;
    }
    .img-Container3 img {
        width: 55%;
    }
    .Content-Container3 .Content-para1 {
        font-size: 20px;
    }
    .Content-Container3 .Content-para2 {
        font-size: 16px;
    }

    .WhyDataSensex-Container4 {
        width: 90%;
    }
    .img-Container4 {
        width: 50%;
    }
    .img-Container4 img {
        width: 60%;
    }
    .Content-Container4 {
        left: 10%;
    }
    .Content-Container4 .Content-para1 {
        font-size: 20px;
    }
    .Content-Container4 .Content-para2 {
        font-size: 16px;
    }

    .UseCases-Main1 h1 {
        font-size: 15vw  !important;
    }
    .UseCases-Main1 p {
        font-size: 16px !important;
        color: white !important;
        text-align: center !important;
        font-family: "space-Groteskr" !important;
        margin-left: 8% !important;
        margin-right: 8% !important;
        padding-top: 2% !important;
    }


    .expanding-accordion-DataSensex-Mobile {
        display: flex;
        flex-direction: column;
        gap: 15px !important;
        padding-top: 5%;
        padding-bottom: 5%;
        padding-left: 4% !important;
        padding-right: 4% !important;
        width: 100% !important;
    }
    .card-header-DataSensex-Mobile .card-title-DataSensex-Mobile {
        font-size: 16px !important;
    }
    .expanded-DataSensex-Mobile .card-content-DataSensex-Mobile {
        font-size: 16px !important;
        letter-spacing: 0.58px !important;
        // text-align: center !important;
    }
    .card-header-DataSensex-Mobile {
        padding: 8px !important;
    }
     .inside img {
        max-width: 100%;
        height: auto;
        width: 70%;
    }

 
    .DataSensex-Form-left-Mobile {
        margin-top: 1%;
        padding-top: 5%;
        position: relative;
        display: grid;
        justify-items: center;
    }

    .DataSenSex-H-Mobile {
        font-family:  "space-Groteskr" !important;
        font-size: 9vw;
        color: #FFFFFF;
        position: relative;
        padding-top: 5%;
    }
        .flexClass-Mobile {
            display: contents;
            padding-bottom: 0%;
            margin-bottom: 2%;
        }
        
    .flexClass-Mobile img {
      max-width: 100%;
      height: auto;
      width: 25%;
    }




}

@media  only screen and (min-width: 768px) and (max-width: 1023px){
    .DataSenSex_Main_Inner {
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translate(-24%, -50%);
    }
    .DataSenSex_Main_Inner img {
        padding-bottom: 5%;
    }
    .DataSenSex_Inner h1 {
        font-size: 5.5vw;
    }
    .DataSenSex_Inner .Datapara1 {
        font-size: 2.1vw;
        margin-bottom: 5px;
    }
    .DataSenSex_Inner .Datapara2 {
        font-size: 2vw;
        margin-bottom: 10px;
    }
    .Data-video {
        height: 40vw !important;
    }
    .DataSenSex_Main_Inner-right {
        position: absolute;
        top: 60%;
        left: 70%;
        transform: translate(-20%, -65%);
    }
    .WhyDataSenSex-inside1 h1 {
        font-size: 10vw;
        padding-top: 8%;
    }
    .WhyDataSenSex-inside1 .WhyData-para1 {
        letter-spacing: 0.96px;
        font-size: 2.5vw;
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .WhyDataSensex-Container1 {
        width: 90%;
    }
    .img-Container1 {
        width: 50%;
    }
    .Content-Container1 .Content-para1 {
        font-size: 3.5vw;
    }
    .Content-Container1 .Content-para2 {
        font-size: 2vw;
    }


    .WhyDataSensex-Container2 {
        width: 90%;
    }
    .img-Container2 {
        width: 50%;
    }
    .Content-Container2 {
        width: 60%;
        position: relative;
        align-self: center;
        left: 10%;
    }
    .Content-Container2 .Content-para1 {
        font-size: 3.5vw;
    }
    .Content-Container2 .Content-para2 {
        font-size: 2vw;
    }


    .WhyDataSensex-Container3 {
        width: 90%;
    }
    .img-Container3 {
        width: 50%;
    }
    .Content-Container3 .Content-para1 {
        font-size: 3.5vw;
    }
    .Content-Container3 .Content-para2 {
        font-size: 2vw;
    }


    .img-Container4 {
        width: 50%;
    }
    .img-Container4 img {
        max-width: 100%;
        height: auto;
        width: 60%;
    }
    .Content-Container4 {
        width: 60%;
        position: relative;
        align-self: center;
        left: 10%;
    }
    .Content-Container4 .Content-para1 {
        font-size: 3.5vw;
    }
    .Content-Container4 .Content-para2 {
        font-size: 2vw;
    }
    .WhyDataSensex-Container4 {
        width: 90%;
    }
    .UseCases-Main1 h1 {
        font-size: 12vw !important;
    }
    .UseCases-Main1 p {
        font-size: 2vw !important;
        margin-left: 6% !important;
        margin-right: 6% !important;
        padding-top: 3% !important;
    }
    .expanding-accordion-DataSensex {
        padding-left: 3% !important;
        padding-right: 1% !important;
        width: 75% !important;
    }
    .card-DataSensex {
        padding: 0% !important;
    }

    .card-header-DataSensex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2% 2% 1% 4% !important;
        cursor: pointer;
        border-bottom: none;
        background: transparent;
    }
    .card-header-DataSensex .card-title-DataSensex {
        font-size: 1.3vw !important;
        letter-spacing: 1px !important;
    }
    .expanded-DataSensex .card-content-DataSensex {
        font-size: 1.3vw !important;
        letter-spacing: 1px !important;
    }
    .card-content-DataSensex {
        display: none;
        padding: 1% 2% 1% 1% !important;
      }
      .img-Conatiner-toggle img {
        width: 90% !important;
    }
    .Rotate-Close{
        transform: rotateZ(135deg) scale(1) !important;
        color: #FF6600;
      }
      .Rotate-Open{
        transform: rotateZ(90deg) Scale(1) !important;
          color: #FF6600;
      }







}

@media  only screen and (min-width: 1024px) and (max-width: 1450px){
    .card-header-DataSensex {
        padding: 8px !important;
    }
    .card-header-DataSensex .card-title-DataSensex {
        font-size: 1.1vw !important;
        letter-spacing: 0.86px !important;
    }

    .expanded-DataSensex .card-content-DataSensex {
        font-size: 1.1vw !important;
    }
    .card-DataSensex {
        padding: 1px !important;
    }
    .Rotate-Close{
        transform: rotateZ(135deg) scale(1.1) !important;
        color: #FF6600;
      }
      .Rotate-Open{
        transform: rotateZ(90deg) Scale(1.1) !important;
          color: #FF6600;
      }


}


@media only screen and (min-width: 690px) and (max-width: 767px){
    .banner-up-DataSensex {
        position: absolute;
        top: 17% !important;
        right: 30% !important;
        width: 40%  !important;
        padding-left: 1%;
        padding-right: 1%;
    }
    .DataSenSex_Main_mobile_Content1 h1 {
        font-size: 7vw;
    }
    .DataSenSex_Main_mobile_Content1 img {
        max-width: 100%;
        height: auto;
        width: 15%;
    }
    .DataSenSex_Main_mobile_Content1 .Datapara1 {
        font-size: 3vw;
    }
    .DataSenSex_Main_mobile_Content1 .Datapara2 {
        font-size: 3vw;
    }
    .DataSenSex_Main_mobile_Content1 {
        padding-top: 5%;
        padding-bottom: 5%;
        margin-top: 5%;
        margin-bottom: 5%;
        position: absolute;
        top: 52%;
        left: 10%;
    }

}
@media only screen and (min-width: 581px) and (max-width: 689px){
    .banner-up-DataSensex {
        position: absolute;
        top: 17% !important;
        right: 25% !important;
        width: 50% !important;
        padding-left: 1%;
        padding-right: 1%;
    }
}
@media only screen and (min-width: 481px) and (max-width: 580px){
    .banner-up-DataSensex {
        position: absolute;
        top: 18% !important;
        right: 22.5% !important;
        width: 55% !important;
        padding-left: 1%;
        padding-right: 1%;
    }
.Data-video-DataSensex-Mobile {
    width: 100%;
    height: auto;
    object-fit: fill;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1%;
}
.DataSenSex_Main_mobile_Content1 img {
    max-width: 100%;
    height: auto;
    width: 18%;
}
.DataSenSex_Main_mobile_Content1 h1 {
    font-family:  "space-Groteskb" !important;
    font-size: 8.5vw;
    color: #fff;
    letter-spacing: 1.96px;
    padding-top: 2%;
    margin-bottom: 0%;
}

}