.Our_offerings{
    background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
}
.Our_ffoerings1{
    padding: 5% 5% 5% 5%;

}
.Our_ffoerings1 h1 {
    font-size: 10vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
    padding-top: 3%;
    padding-bottom: 0%;
}

.Our_ffoerings1 .para1 {
    font-size: 1.4vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.Our_ffoerings1 .para2 {
    font-size: 2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}

.Our_Offoerings_box{
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0% 0% 10% 0%;
    gap: 2%;
    position: relative;
    overflow: hidden;
}
.waveee {
    display: block;
    position: relative;
    height: 40px;
    width: 100%;
    background: #033251 0% 0% no-repeat padding-box;
    transform: scale(1, 1);
}

.waveee:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: white;
    right: -25%;
    top: 20px
}

.waveee:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background: #033251 0% 0% no-repeat padding-box;
    left: -25%;
    top: -240px;
    clip-path: ellipse(100% 15% at -15% 100%);
}


.Our_Offoerings-Insidecontent-box {
    background: #0F326C 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    opacity: 1;
    width: 30vw;
    height: auto;
    text-align: -webkit-left;
    padding: 0% 2% 0% 2%;
}

.Our_Offoerings-Insidecontent-box img {
    max-width: 100%;
    height: auto;
    width: 9vw;
    padding: 8% 0%;
}

.Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para1 {
    font-family:  "space-Grotesksb" !important;
    font-size: 1.7vw;
    color: #FFFFFF;
    text-align: left;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 2%;
}


.Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para21 {
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 10% 0% 5% 0%;
}

.Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para23 {
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 10% 0% 5% 0%;
}

.Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para22 {
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 0% 0% 5% 0%;
}

@media  only screen and (max-width: 480px) {
    .Our_offerings{
        display: none;
    }
    .Our_offerings_mobile{
        background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        border-top-right-radius: 12vw;
        border-top-left-radius: 12vw;
    }
    .Our_ffoerings1_mobile{
        padding: 5% 5% 5% 5%;
    
    }
    .Our_ffoerings1_mobile h1 {
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    
    .Our_ffoerings1_mobile .para1 {
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .Our_ffoerings1_mobile .para2 {
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }

    .Our_Offoerings_box_mobile {
        padding: 0% 0% 15% 0%;
        overflow: hidden;
        text-align: -webkit-center;
        gap: 3%;
        position: relative;
        display: grid;
        justify-items: center;
        // margin: 0% 0% 20% 0%;
    }
        
    .Our_Offoerings-Insidecontent-box_mobile {
        background: #0F326C 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        opacity: 1;
        width: 80vw;
        height: auto;
        text-align: -webkit-left;
        padding: 0% 2% 0% 2%;
        overflow: hidden;

    }

    .Our_Offoerings-Insidecontent-box_mobile img {
        max-width: 100%;
        height: auto;
        width: 25vw;
        padding: 8% 0% 8% 0%;
    }

    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para1 {
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        color: #FFFFFF;
        text-align: left;
        letter-spacing: 0px;
        text-transform: uppercase;
        opacity: 1;
        margin: 0% 0% 0% 5%;
    }


    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para21 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 5%;
        margin-right: 5%;
        line-height: 175%;
        padding: 5% 0% 10% 0%;
    }

    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para23 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 5%;
        margin-right: 5%;
        line-height: 175%;
        padding: 5% 0% 10% 0%;
    }

    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para22 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 0% 5% 15% 5%;
        line-height: 175%;
        padding: 5% 0% 25% 0%;

    }
}

@media  only screen and (min-width: 481px) and (max-width: 767px){
    .Our_offerings{
        display: none;
    }
    .Our_offerings_mobile{
        background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        border-top-right-radius: 12vw;
        border-top-left-radius: 12vw;
    }
    .Our_ffoerings1_mobile{
        padding: 5% 5% 5% 5%;
    
    }
    .Our_ffoerings1_mobile h1 {
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 0%;
    }
    
    .Our_ffoerings1_mobile .para1 {
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .Our_ffoerings1_mobile .para2 {
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }

    .Our_Offoerings_box_mobile {
        padding: 0% 0% 15% 0%;
        overflow: hidden;
        text-align: -webkit-center;
        gap: 3%;
        position: relative;
        display: grid;
        justify-items: center;
        // margin: 0% 0% 20% 0%;
    }
        
    .Our_Offoerings-Insidecontent-box_mobile {
        background: #0F326C 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        opacity: 1;
        width: 80vw;
        height: auto;
        text-align: -webkit-left;
        padding: 0% 2% 0% 2%;
        overflow: hidden;

    }

    .Our_Offoerings-Insidecontent-box_mobile img {
        max-width: 100%;
        height: auto;
        width: 25vw;
        padding: 8% 0% 8% 0%;
    }

    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para1 {
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        color: #FFFFFF;
        text-align: left;
        letter-spacing: 0px;
        text-transform: uppercase;
        opacity: 1;
        margin: 0% 0% 0% 5%;
    }


    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para21 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 5%;
        margin-right: 5%;
        line-height: 175%;
        padding: 5% 0% 10% 0%;
    }

    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para23 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 5%;
        margin-right: 5%;
        line-height: 175%;
        padding: 5% 0% 10% 0%;
    }

    .Our_Offoerings-Insidecontent-box_mobile .Our_Offoerings-content-para22 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 0% 5% 15% 5%;
        line-height: 175%;
        padding: 5% 0% 25% 0%;

    }
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .Our_offerings_mobile_mobile{
        display: none;
    }
    .Our_ffoerings1 h1 {
        font-size: 12vw;
    }
    .Our_ffoerings1 .para1 {
        font-size: 1.8vw;
    }
    .Our_ffoerings1 .para2 {
        font-size: 2.2vw;
    }
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para1 {
        font-size: 1.9vw;
        margin-bottom: 5%;
    }
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para23 {
        font-size: 1.5vw;
    }
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para21 {
        font-size: 1.5vw;
    }
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para22 {
        font-size: 1.5vw;
    }
}

@media  only screen and (min-width: 1024px) {
    .Our_offerings_mobile_mobile{
        display: none;
    }
}

@media  only screen and (min-width: 1024px) and (max-width: 1399px) {
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para21 {
        padding: 10% 0% 5% 0%;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 1.2px;
        font-size: 1.1vw;
        line-height: 25px;
    }
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para23 {
        padding: 10% 0% 5% 0%;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 1.2px;
        font-size: 1.1vw;
        line-height: 25px;
    }
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para22 {
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding: 1% 0% 1% 0%;
        letter-spacing: 1.2px;
        font-size: 1.1vw;
        line-height: 25px;
    }
    .Our_Offoerings-Insidecontent-box .Our_Offoerings-content-para1 {
        font-size: 1.6vw;
    }
    .Our_ffoerings1 .para1 {
        font-size: 1.4vw;
    }
}