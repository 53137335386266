.Industries{
    padding: 10% 0% 0% 0%;
    // border: 1px solid red;
}
.Industries h1{
    padding: 0% 0% 0% 0%;
    font-size: 12vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
}
.Industries_inner{
    display: flex;
    justify-content: space-evenly;    
    padding: 5% 0%;
}

.indus-box1{
   }

.gradient-border-mask {
    display: flow-root;
    position: relative;
    padding: 0 2%;
    width: 30vw;
    height: auto;
  }
  
  .gradient-border-mask::before {
    content: "";
    position: absolute;
    width: 30vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  
.indus-box1 img {
    max-width: 100%;
    height: auto;
    margin-left: -20px;
    padding: 5% 0%;
}
.indus-box2 img {
    max-width: 100%;
    height: auto;
    padding: 5% 0%;
}
.indus-box3 img {
    max-width: 100%;
    height: auto;
    padding: 5% 0%;
}
.indus-box2{
    }
.indus-box3{}


.Industries_inner .Head_indus{
    font-family:  "space-Grotesksb" !important;
    font-size: 1.4vw;
    color: #FF6600;
    text-align: left;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    // padding: 6% 0% 0% 0%;
}

.Industries_inner .para_indus1{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 10% 0% 5% 0%;
}

.Industries_inner .para_indus2{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 2% 0% 5% 0%;
}

.Industries_inner .para_indus3{
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 10% 0% 5% 0%;
}


.No-code{
    padding: 10% 0% 0% 0%;
}
.No-code h1{
    font-size: 1.5vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
}

.No-code-inner {
    display: flex;
    justify-content: space-evenly;
    padding: 5% 0%;
}
.No-code-box{
    border: 1px solid #FF6600;
    border-radius: 30px;
    width: 15vw;
    text-align: center;
    padding: 55px;
    color: white;
    height: auto;
}
.No-code-box p{
    color: white;
    display: grid;
    font-size: 1.4vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 20px;
}


@media  only screen and (max-width: 480px) {
    .Industries{
        display: none;
    }
    .No-code{
        display: none;
    }
    .Industries_mobile{
        padding: 10% 0% 0% 0%;
        // border: 1px solid red;
    }
    .Industries_mobile h1{
        padding: 0% 0% 5% 0%;
        font-size: 16vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    .Industries_inner_mobile{
        /* padding: 5% 0%; */
        text-align: -webkit-center;
    }
    
    .indus-box1_mobile{
        margin: 0% 0% 10% 0%;
        text-align-last: left;
    }
    .indus-box1_mobile img {
        max-width: 100%;
        height: auto;
        margin-left: -15px;
        padding: 5% 0%;
        width: 30%;
    }
    .indus-box2_mobile img {
        max-width: 100%;
        height: auto;
        padding: 5% 0%;
        width: 30%;
    }
    .indus-box3_mobile img {
        max-width: 100%;
        height: auto;
        padding: 5% 0%;
        width: 30%;
    }
    .indus-box2_mobile{

        margin: 0% 0% 10% 0%;
        text-align-last: left;
    }
    .indus-box3_mobile{

        margin: 0% 0% 10% 0%;
        text-align-last: left;
    }
    
.gradient-border-mask-mobile-indus {
    display: flow-root;
    position: relative;
    padding: 1.3rem;
    width: 75vw;
    height: auto;
  }
  
  .gradient-border-mask-mobile-indus::before {
    content: "";
    position: absolute;
    width: 75vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
    
    
    .Industries_inner_mobile .Head_indus{
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        text-align: left;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        margin-left: 3%;
        margin-right: 3%;
        // padding: 6% 0% 0% 0%;
    }
    
    .Industries_inner_mobile .para_indus1{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    
    .Industries_inner_mobile .para_indus2{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    
    .Industries_inner_mobile .para_indus3{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    



    .No-code-mobile{
        padding: 10% 0% 0% 0%;
    }
    .No-code-mobile h1{
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.56px;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        font-family:  "space-Groteskr" !important;
    }
    
    .No-code-inner-mobile1 {
        display: flex;
        justify-content: space-evenly;
        padding: 10% 0% 5% 0%;
    }
    .No-code-inner-mobile2 {
        display: flex;
        justify-content: space-evenly;
        padding: 0% 0%;
    }
    .No-code-box-mobile{
        border: 1px solid #FF6600;
        border-radius: 30px;
        width: 40vw;
        text-align: center;
        color: white;
        height: auto;
    }
    .No-code-box-mobile p{
        color: white;
        display: grid;
        font-size: 4.2vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 25% 10%;
    }


}
@media  only screen and (min-width: 768px){
    .No-code-mobile{
        display: none;
    }
    .Industries_mobile{
        display: none;
    }
}
@media  only screen and (min-width: 481px) and (max-width: 767px){
    .Industries{
        display: none;
    }
    .No-code{
        display: none;
    }
    .Industries_mobile{
        padding: 10% 0% 0% 0%;
        // border: 1px solid red;
    }
    .Industries_mobile h1{
        padding: 0% 0% 5% 0%;
        font-size: 16vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    .Industries_inner_mobile{
        /* padding: 5% 0%; */
        text-align: -webkit-center;
    }
    
    .indus-box1_mobile{

        margin: 0% 0% 5% 0%;
        text-align-last: left;
    }
    .indus-box1_mobile img {
        max-width: 100%;
        height: auto;
        margin-left: -15px;
        padding: 5% 0%;
        width: 30%;
    }
    .indus-box2_mobile img {
        max-width: 100%;
        height: auto;
        padding: 5% 0%;
        width: 30%;
    }
    .indus-box3_mobile img {
        max-width: 100%;
        height: auto;
        padding: 5% 0%;
        width: 30%;
    }
    .indus-box2_mobile{

        margin: 0% 0% 5% 0%;
        text-align-last: left;
    }
    .indus-box3_mobile{
      
        margin: 0% 0% 5% 0%;
        text-align-last: left;
    }
    
.gradient-border-mask-mobile-indus {
    display: flow-root;
    position: relative;
    padding: 1.3rem;
    width: 75vw;
    height: auto;
  }
  
  .gradient-border-mask-mobile-indus::before {
    content: "";
    position: absolute;
    width: 75vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
    
    .Industries_inner_mobile .Head_indus{
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        text-align: left;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        margin-left: 3%;
        margin-right: 3%;
        // padding: 6% 0% 0% 0%;
    }
    
    .Industries_inner_mobile .para_indus1{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    
    .Industries_inner_mobile .para_indus2{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    
    .Industries_inner_mobile .para_indus3{
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    



    .No-code-mobile{
        padding: 10% 0% 0% 0%;
    }
    .No-code-mobile h1{
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.56px;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        font-family:  "space-Groteskr" !important;
    }
    
    .No-code-inner-mobile1 {
        display: flex;
        justify-content: space-evenly;
        padding: 10% 0% 5% 0%;
    }
    .No-code-inner-mobile2 {
        display: flex;
        justify-content: space-evenly;
        padding: 0% 0%;
    }
    .No-code-box-mobile{
        border: 1px solid #FF6600;
        border-radius: 30px;
        width: 40vw;
        text-align: center;
        color: white;
        height: auto;
    }
    .No-code-box-mobile p{
        color: white;
        display: grid;
        font-size: 4.2vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 25% 10%;
    }


}
  
@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .Industries h1 {
        padding: 0% 0% 0% 0%;
        font-size: 14vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    .Industries_inner .Head_indus {
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        text-align: left;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        margin-left: 3%;
        margin-right: 3%;
    }
    .Industries_inner .para_indus1 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
        padding: 20% 0% 5% 0%;
    }
    .Industries_inner .para_indus2 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    .Industries_inner .para_indus3 {
        font-size: 16px;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 175%;
    }
    .indus-box2 {
        padding: 0% 1% 0% 1%;
    }
    
    .indus-box1 {
        padding: 0% 1% 0% 1%;
    }
    
    .indus-box3 {
        padding: 0% 1% 0% 1%;
    }
    .No-code h1 {
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.56px;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        font-family:  "space-Groteskr" !important;
    }

    .No-code-box {
        border: 1px solid #FF6600;
        border-radius: 30px;
        width: 20vw;
        text-align: center;
        padding: 2% 1%;
        color: white;
        height: auto;
        margin: 0% 0%;
    }
    .No-code-box p {
        color: white;
        display: grid;
        font-size: 18px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        padding-top: 20px;
    }

    

}


@media  only screen and (min-width: 1024px) and (max-width: 1399px) {
    .Industries_inner .Head_indus {
        font-size: 1.6vw;
    }
    .indus-box1 img {
        max-width: 100%;
        height: auto;
        margin-left: -20px;
        padding: 5% 0%;
        width: 40%;
    }
    .indus-box2 img {
        max-width: 100%;
        height: auto;
        padding: 5% 0%;
        width: 40%;
    }
    .indus-box3 img {
        max-width: 100%;
        height: auto;
        padding: 5% 0%;
        width: 40%;
    }
    
    .Industries_inner .para_indus1{
        font-size: 1.4vw;
    }

    .Industries_inner .para_indus2{
        font-size: 1.4vw;
    }

    .Industries_inner .para_indus3{
        font-size: 1.4vw;
    }

    .No-code h1 {
        font-size: 1.6vw;
    }
    .Industries {
        padding: 15% 0% 0% 0%;
    }

    .No-code-box {
        border: 1px solid #FF6600;
        border-radius: 30px;
        width: 15vw;
        text-align: center;
        padding: 38px;
        color: white;
        height: auto;
    }
    .No-code-box p {
        color: white;
        display: grid;
        font-size: 1.6vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        padding-top: 20px;
    }

}