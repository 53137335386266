
.partnership-container{
    background-color: #000;
    // padding-top: calc(100px - 22px);
}

.partnershipmbile{
    text-align: left;
    margin-left: 2rem;
    padding-top: 5%;
}

.main_container_flowise{
    top: 0px;
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    // border-top-left-radius: 150px;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    background:transparent linear-gradient(359deg, #00122E 0%, #000 14.20%, #013143 34.30%, #00072D 66.60%, #182878 79.20%, #010101 100%);
    opacity: 1;
}
.framer-main-partnership{
    background-color: #000;
    // height: 41.2rem;
    margin-bottom: 20%;
    padding-bottom: 10%;
    height: 45rem;
}
.framer-partnership-insideContent{
    position: absolute;
    top: 34%;
    left: 20%;
    transform: translate(-35%, -30%);
  }
  
  .framer-partnership-insideContent h1{
    // border: 1px solid black; 
    line-height: 100%;
    font-size: 11vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
  }
  
  .framer-partnership-insideContent .f-para1{
      color: #FF6600;
      opacity: 1;
      letter-spacing: 0px;
      font-family:  "space-Grotesksb" !important;
      font-size: 1.8vw;
    }
  .framer-partnership-insideContent .f-para2{
    // border: 1px solid yellow; 
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:"space-Groteskr" !important;
    font-size: 18px;
  }
 .possible_head{
    font-size: 8vw !important;
 }
 .possibl_para{
    font-size: 1.5vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 2%;
    padding-left: 6%;
    padding-bottom: 5%;
    padding-right: 6%;
 }
.possible_box{
    width: auto;
    height: 21rem;
    padding-top: 4%;
    padding-left: 10%;
    margin: 6px;
    border-radius: 10px;
    flex-shrink: 0;
    background-image: linear-gradient(#182878, #000723);
    color: #FFF;
    font-size: 24px;
    letter-spacing: 3px;
    padding-right: 6%;
    margin: 40px;
    opacity: 999;
}
.possible_img{
    width: 20%;
    object-fit: contain;
}
  .possible_head-box{
    font-family: "space-Grotesksb" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    color: #fff;
    padding-bottom: 8px;
    padding-top: 3%;
    letter-spacing: 0px;
  }
  .possible_para-box{
    font-family: "space-Groteskr" !important;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    color: #fff;
    letter-spacing: 0px;
  }
  .possible_para-box-mobile {
    // font-size: 2.8vw;
    font-size: 16px;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    line-height: 27px;
}
.possible_a1-mobile{
    width: auto;
    height: auto;
    padding: 6%;
    /* margin: 5px; */
    border-radius: 10px;
    flex-shrink: 0;
    background-image: linear-gradient(#182878, #000723);
    color: #FFF;
    font-family: " space-Groteskr" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    box-sizing: border-box;
    /* padding: 8px; */
    height: fit-content;
    .possible_head-box{
        align-self: center;
    }
    .culture_img2-mobile{
        width: 55px;
        margin-left: 4%;
    }
    .culture_img4-mobile{
        margin-left: 4%;
        width: 58px;
    }
}
.possible_a1-mobile{
    // padding: 6%; 
    padding-left: 10%;
    padding-right: 10%;
    /* margin: 4%; */
    margin-bottom: 10%;
    .culture_head{
        font-size: 18px;
    }
    .culture_img2-mobile{
        width: 18%;
        object-fit: contain;
        margin-bottom: 3%;
    }
    .culture_img4-mobile{
        width: 19.2%;
        object-fit: contain;
        margin-left: 5%;
    }
}
.benifit_row{
    margin-bottom: 10%;
}
.benifits_img{
    transform: none;
    object-fit: contain;
    width: 85%;
}
.benifit_head{
    margin-left: 10px;
    transform: none;
    color: #fff;
    font-family: "space-Grotesksb" !important;
    font-size: 36px;
    margin-bottom: 5%;
}
.benifit_para{
    transform: none;
    color: #fff;
    text-align: left;
    font-family: "space-Groteskr" !important;
    font-size: 20px;
    margin-left: 10px;
    // line-height: 25px;
}
.benifit_h1{
    margin-bottom: 5%;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .benifits_img{
        width: 100%;
    }
  .benifit_para{
    font-size: 15px;
  }
  .possibl_para{
    font-size: 2vw;
  }
}

  @media screen and (max-width: 480px) {
    .framer-partnership-insideContent-mobile{
        position: absolute;
        width: 75%;
        top: 38%;
        left: 40%;
        transform: translate(-35%, -50%);
        color: #FF6600;
        text-align: center;
        display: contents;
    }
    .possibl_para{
        font-size: 18px;
    }
    .partnershipmbile .f-para1-mobile{
        font-size: 18px;
        font-family: "space-Grotesksb" !important;
        padding: 0% 2%;
    }
    .partnershipmbile .head_mob{
        font-size: 20vw;
    }
.partnership-main-tab{
    width: 50% !important;
    object-fit: cover;
}
.partnership_image{
    width: 100%;
    object-fit: contain;
}

.possible_head{
    font-size: 12vw !important;
    line-height: 125%;
}
   .possible_img-mobile{
    margin-left: 5%;
    margin-bottom: 1%;
    width: 25%;
    object-fit: contain;
}
.benifit_h1{
    font-size: 15vw !important;
    padding-top: 25% !important;
}
.benifit_head{
    // padding-left: 3%;
    // padding-right: 3%;
    font-size: 34px;
}
.benifit_para{
       /* padding-left: 3%; */
    /* padding-right: 3%; */
    font-size: 16px;
    margin-bottom: 25%;
    line-height: 30px;
}
}

    @media all and (max-width: 767px) and (min-width: 481px){
        .possibl_para{
            font-size: 18px;
        }
        .flowise_mob-img{
            width: 75%;
            left: 13%;
            margin-top: 2px;
        }
        .framer-partnership-insideContent-tablet{         
            position: absolute;
            top: 35%;
            left: 40%;
            width: 80%;
            transform: translate(-35%, -30%);
            color: #FF6600;
            font-family:  "space-Groteskr" !important;
            text-align: center;
            display: contents;
        }
        .partnershipmbile .head_mob{
            font-size: 20vw;
        }
        .partnershipmbile .f-para1-mobile{
            font-size: 18px;
            font-family: "space-Grotesksb" !important;
        }
        .partnershipmbile .f-para1-mobile{
            font-size: 18px;
            font-family: "space-Grotesksb" !important;
        }
          .possible_img-mobile{
            margin-left: 2%;
            margin-bottom: 1%;
            width: 18%;
            object-fit: contain;
        }
        .benifit_row{
            padding: 5%;
        }
        .benifits_img{
            width: 80%;
            padding-bottom: 5%;
        }
        .benifit_h1{
            font-size: 14vw !important;
            padding-bottom: 5%;
        }
        }
@media screen and (min-width: 1900px) {
    .possible_box{
        height: 25rem;
    }
    .possible_head-box{
        font-size: 38px;
    }
    .possible_para-box{
        font-size: 24px;
    }
    .benifit_para{
        font-size: 24px;
    }
    .benifit_head{
        font-size: 38px;
    }
}

.partnership-heading-container {
    padding: 150px 80px 64px 80px;
    margin: 0;
}
.heading_img_partnership {
    margin-left: -0.1rem;
    vertical-align: middle;
}
.logo_identifier {
    position: absolute;
    top: -12px;
    left: 12rem;
}
.head-content {
    font-family: "Poppins-Regular";
    font-size: 18px;
    color: #fff;
    padding: 0;
    line-height: 29px;
    font-weight: 400;
    font-synthesis: none;
    text-align: justify;
    margin: 0;
}
.partnership_para {
    font-size: 16px;
}
.partnership_inner_logo {
    width: 200px;
    height: 61px;
}
.partnership_heading {
    font-size: 24px;
}
.partnership_heading_rotate_img {
    transform: rotate(180deg);
    position: absolute;
    top: 99rem;
    right: 12rem;
}
.partnership_container_back {
    background-image: url("../../fonts/images/flowize.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    margin-top: -5rem;
    padding: 62px 80px;
}
.digital_banner_img_caption_partnership {
    position: absolute;
    text-align: center;
    top: 137rem;
    left: 50%;
    transform: translate(-50%, -50%);
}
.digital_banner_contact_caption_partnership {
    position: absolute;
    text-align: right;
    bottom: -110.6rem;
    right: 10rem;
    transform: translate(-50%, -50%);
}
.partner-banner .home_page_caption_inner {
    position: absolute;
    width: 50%;
    left: 12%;
    top: 50%;
    transform: translate(-12%, -50%);
    color: #fff;
}
.home_page_caption_inner.partnership_banner_inner_content {
    width: 41%;
}
.partnership_page {
    max-width: 1140px;
    margin: 0 auto;
}
@media screen and (max-width: 768px) {
    .partnership-heading-container {
        padding: 120px 24px 0px 24px;
    }
    .partner_mob_box {
        padding: 24px 0px;
    }
    .partnership_para {
        font-size: 14px !important;
    }
    .partnership_inner_logo {
        width: 160px;
    }
    .partnership_container_back_mob {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        margin-top: -2rem;
        padding: 0px !important;
    }
    .partnership_container_back {
        padding: 60px 24px;
    }
    .project-banner-inner_partnership {
        top: 9%;
    }
    .caption_link_mob_partnership a {
        font-size: 16px;
    }
    .learn_btn-mobile a {
        border-radius: 5px;
        color: white;
        font-size: 18px;
        border:1px solid #FF6600;
        font-family:"space-Groteskr" !important;
        text-align: center;
        padding: 10px 15px 10px 15px;
    }
    .learn_text-mobile{
        padding-top: -1px;
        background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
}
@media screen and (min-width: 320px) {
    .model_caption_partner {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 16%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 14%;
    }
}
@media screen and (min-width: 350px) {
    .model_caption_partner {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 16%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 14%;
    }
}
@media screen and (min-width: 500px) {
    .model_caption_partner {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 16%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 14%;
    }
}
@media screen and (min-width: 700px) {
    .model_caption_partner {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 60%;
        left: 21%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 60%;
        left: 19%;
    }
}
@media screen and (min-width: 900px) {
    .model_caption_partner {
        position: absolute;
        top: 14%;
        padding: 0;
        max-width: 70%;
        left: 16%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 14%;
        padding: 0;
        max-width: 70%;
        left: 14%;
    }
}
@media screen and (min-width: 1200px) {
    .model_caption_partner {
        position: absolute;
        top: 14%;
        padding: 0;
        max-width: 70%;
        left: 16%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 14%;
        padding: 0;
        max-width: 70%;
        left: 14%;
    }
}
@media screen and (min-width: 1400px) {
    .model_caption_partner {
        position: absolute;
        top: 17%;
        padding: 0;
        max-width: 70%;
        left: 12%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 17%;
        padding: 0;
        max-width: 70%;
        left: 10%;
    }
}
@media screen and (min-width: 1600px) {
    .model_caption_partner {
        position: absolute;
        top: 17%;
        padding: 0;
        max-width: 62%;
        left: 11%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 17%;
        padding: 0;
        max-width: 62%;
        left: 9%;
    }
}
@media screen and (min-width: 1900px) {
    .model_caption_partner {
        position: absolute;
        top: 20%;
        padding: 0;
        max-width: 70%;
        left: 17%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 20%;
        padding: 0;
        max-width: 70%;
        left: 13%;
    }
    .partnership-heading-container {
        padding: 150px 120px 64px 120px;
        margin: 0;
    }
    .partnership-container .digi_common_desktop {
        margin: 0 auto;
    }
    .partnership_para {
        font-size: 20px;
    }
    .partnership_container_back {
        padding: 96px 120px;
    }
    .partner_image_banner img {
        max-width: 100%;
    }
    .partnership_page {
        padding-top: 60px;
    }
    .partnership_page .digital_banner_contact_caption {
        position: absolute;
        text-align: right;
        bottom: 15%;
        right: 8%;
        transform: translate(-50%, -50%);
    }
    .partnership_heading {
        font-size: 32px;
    }
    .partnership_page {
        max-width: 1140px;
        margin: 0 auto;
    }
}
@media (min-width: 820px) and (max-width: 920px) {
    .partnership-heading-container {
        padding: 100px 24px 0px 24px;
    }
    .partnership_container_back {
        padding: 62px 24px;
    }
    .partnership-container .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 768px) and (max-width: 819px) {
    .partnership-heading-container {
        padding: 100px 24px 0px 24px;
        margin: 0;
    }
    .partnership_container_back {
        padding: 62px 24px;
    }
    .model_caption_partner {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 17%;
    }
    .model_caption_partner_1 {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 70%;
        left: 14%;
    }
    .win_box .model_img_container > img {
        height: 590px;
    }
}