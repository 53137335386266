.Main_Professional_services{
    background-image: linear-gradient(to right, #021d4a, #041535);
}

.Professional_banner{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
.Professional_banner img{ 
    position: relative; 
    width: 100%;
    height: 100vh;
}
.inner_Professional {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%, -75%);
}
.inner_Professional h1{
    line-height: 100%;
    font-size: 8vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "space-Groteskb" !important;
}
.inner_Professional .Pre-para1{
            // border: 1px solid blue;
            color: #FF6600;
            opacity: 1;
            letter-spacing: 0px;
            font-family: "space-Grotesksb" !important;
            font-size: 2.2vw;
}
.inner_Professional .Pre-para2{
    // border: 1px solid yellow; 
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:"space-Groteskr" !important;
    font-size: 1.5vw;
}
.Slide-PProfessional{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #01010a 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    top: 0px;
  }    

  @media  only screen and (max-width: 480px) {
    .Professional_banner{
        display: none;
    }
    .proffesional_banner-mobile{
        position: relative;
    }

    .proffesional_banner-mobile img{
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        object-position: 75% 60%;
    }
    .inner_proffesional-mobile{
        position: absolute;
        top: 60%;
        left: 20%;
        transform: translate(-15%, -50%);
    }
    .Slide-PProfessional{
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
        border-top-right-radius: 0%;
        border-top-left-radius: 0%;
        background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }


    .inner_proffesional-mobile h1{
        line-height: 100%;
        font-size: 13.5Vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskb" !important;
    }

    .inner_proffesional-mobile .Pre-para1{
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family: "space-Grotesksb" !important;
        font-size: 18px;
    }
    .inner_proffesional-mobile .Pre-para2{
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 16px;
    }
       
  }


@media  only screen and (min-width: 481px) and (max-width: 767px){
        .Professional_banner{
            display: none;
        }
        .proffesional_banner-mobile{
        
        }
    
        .proffesional_banner-mobile img{
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
        }
        .inner_proffesional-mobile{
            position: absolute;
            top: 60%;
            left: 20%;
            transform: translate(-15%, -30%);
        }
        .Slide-PProfessional{
            position: relative;
            left: 0px;
            width: 100%;
            height: auto;
            border-top-right-radius: 0%;
            border-top-left-radius: 0%;
            background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
            opacity: 1;
            top: 0px;
        }
    
    
        .inner_proffesional-mobile h1{
            line-height: 100%;
            font-size: 13.5Vw;
            opacity: 1;
            background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
            background-clip: border-box;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family:  "space-Groteskb" !important;
        }
    
        .inner_proffesional-mobile .Pre-para1{
            color: #FF6600;
            opacity: 1;
            letter-spacing: 0px;
            font-family: "space-Grotesksb" !important;
            font-size: 18px;
        }
        .inner_proffesional-mobile .Pre-para2{
            letter-spacing: 0.96px;
            color: #FFFFFF;
            opacity: 1;
            font-family:  "space-Groteskr" !important;
            font-size: 16px;
        }
}


@media  only screen and (min-width: 768px){
    .proffesional_banner-mobile{
        display: none;
    }
    .inner_Professional {
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translate(3%, -50%);
    }
    
}


