.EngagmentMain {
  padding: 10% 0%;
  background: #001220 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow: hidden;
}


.Engag-content {}

.Engag-content h1 {
  line-height: 100%;
  font-size: 4.5vw;
  opacity: 1;
  color: #FFFFFF;
  font-family: "space-Groteskb" !important;
  margin: 2% 0% 2% 0%;
  text-align-last: center;
}

.Engag-content p {
  font-size: 1.3vw;
  text-align: center;
  font-family: "space-Groteskr" !important;
  letter-spacing: 0.96px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 20%;
  margin-right: 20%;
}




.Textchange-list-container {
  display: flex;
  font-size: 2vw;
  text-align: center;
  font-family: "space-Groteskr" !important;
  justify-content: space-evenly;
  padding: 3% 0% 0% 0%;
}

.Textchange {
  color: white;
  transition: color 1s ease;
  /* Color transition */
  background-color: transparent;
}

.Textchange.active {
  color: #FF6600;
  background-color: transparent;
}


.Engag-boxs {
  display: flex;
}


.Engag-boxs1 {
  width: 50%;
  position: relative;
  text-align: -webkit-center;
}

.Engag-boxs2 {
  width: 50%;
  position: relative;
  text-align: -webkit-center;
  display: flex;
  justify-content: center;
  align-items: center;
}




.EngagBox {
  background: #263846;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(30px);
  margin: 15% 0% 0% 0%;
  width: 40vw;
  overflow: hidden;

  .Aclass1 {
    display: none;
  }

  .Dclass1 {
    display: block;
  }
}

.EngagBox .head-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5% 0%;
}

.EngagBox .head-box h1 {
  font-family: "space-Grotesksb" !important;
  font-size: 2vw;
  text-shadow: 0px 9px 38px #000000;
  color: #ffffff;
  text-align: left;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}

.paradiv {
  // display: flex;
}

.paradiv .para1 {
  font-size: 1.3vw;
  text-align: left;
  font-family: "space-Groteskr" !important;
  margin: 0% 5% 0% 5%;
  padding: 0% 0% 5% 0%;
  color: #EBF5F5;
}


.EngagBox:hover {
  .Dclass1 {
    display: none;
  }

  .Aclass1 {
    display: block;
  }
}

.EngagBox111{
  overflow: hidden;
}
#newhover {
  .head-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5% 0%;
    overflow: hidden;
  }

  .head-box h1 {
    font-family: "space-Grotesksb" !important;
    font-size: 2vw;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: left;
    letter-spacing: 1.44px;
    text-transform: uppercase;
  }

  .paradiv {
    // display: flex;
  }

  .paradiv .para1 {
    font-size: 1.3vw;
    text-align: left;
    font-family: "space-Groteskr" !important;
    margin: 0% 5% 0% 5%;
    padding: 0% 0% 5% 0%;
    color: #EBF5F5;
  }

  background: #263846;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(30px);
  margin: 15% 0% 0% 0%;
  width: 40vw;

  .Aclass2 {
    display: none;
  }

  .Dclass2 {
    display: block;
  }
}

#newhover:hover {
  .Dclass2 {
    display: none;
  }
  .Aclass2 {
    display: block;
  }
}



@media only screen and (max-width: 480px) {
  .EngagmentMain {
    display: none;
  }

  .Main-Engagment-Mobile {
    background-color: #001220;
    opacity: 1;
    padding: 10% 0% 5% 0%;
  }

  .Mobile-Engag {}

  .Mobile-Engag h1 {
    line-height: 45px;
    font-size: 40px;
    opacity: 1;
    color: #FF6600;
    font-family:  "space-Groteskb" !important;
    margin: 5% 0% 5% 0%;
    text-align-last: center;
    letter-spacing: 1.96px;
  }

  .Mobile-Engag .para1 {
    font-size: 16px;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
  }

  .Textchange-list-container-Mobile {
    font-size: 18px;
    text-align: center;
    font-family: "space-Groteskr" !important;
    padding: 0%;
  }

  .Textchange {
    color: white;
    transition: color 1s ease;
    /* Color transition */
    background-color: transparent;
  }

  .Textchange.active {
    color: #FF6600;
    background-color: transparent;
  }

  .Mobile-EngagBox {
    text-align: -webkit-center;
  }

  .Mobile-EngagBox .Mob-Box {
    background: #263846;
    border-radius: 20px;
    width: 80vw;
    height: auto;
    text-align: -webkit-left;
    margin: 5% 0% 15% 0%;
    overflow: hidden;
  }

  .Mobile-EngagBox .Mob-Box img {
    max-width: 100%;
    height: auto;
    width: 35%;
    margin: 5% 5% 5% 5%;
  }

  .Mobile-EngagBox .Mob-Box h1 {
    font-family: "space-Grotesksb" !important;
    font-size: 20px;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: left;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    margin: 0% 5% 0% 5%;
    padding: 0% 0% 5% 0%;
  }

  .Mobile-EngagBox .Mob-Box .para1 {
    font-size: 18px;
    text-align: left;
    font-family: "space-Groteskr" !important;
    margin: 0% 5% 0% 5%;
    padding: 0% 0% 5% 0%;
    color: #EBF5F5;
  }
}


@media only screen and (min-width: 768px) {
  .Main-Engagment-Mobile {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .EngagmentMain {
    display: none;
  }

  .Main-Engagment-Mobile {
    background-color: #001220;
    opacity: 1;
    padding: 10% 0% 5% 0%;
  }

  .Mobile-Engag {}

  .Mobile-Engag h1 {
    line-height: 45px;
    font-size: 40px;
    opacity: 1;
    color: #FF6600;
    font-family:  "space-Groteskb" !important;
    margin: 5% 0% 5% 0%;
    text-align-last: center;
    letter-spacing: 1.96px;
  }

  .Mobile-Engag .para1 {
    font-size: 16px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
  }

  .Textchange-list-container-Mobile {
    font-size: 18px;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding: 0%;
  }

  .Textchange {
    color: white;
    transition: color 1s ease;
    /* Color transition */
    background-color: transparent;
  }

  .Textchange.active {
    color: #FF6600;
    background-color: transparent;
  }

  .Mobile-EngagBox {
    text-align: -webkit-center;
  }

  .Mobile-EngagBox .Mob-Box {
    background: #263846;
    border-radius: 20px;
    width: 80vw;
    height: auto;
    text-align: -webkit-left;
    margin: 5% 0% 15% 0%;
    overflow: hidden;
  }

  .Mobile-EngagBox .Mob-Box img {
    max-width: 100%;
    height: auto;
    width: 35%;
    margin: 5% 5% 5% 5%;
  }

  .Mobile-EngagBox .Mob-Box h1 {
    font-family:  "space-Grotesksb" !important;
    font-size: 20px;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: left;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    margin: 0% 5% 0% 5%;
    padding: 0% 0% 5% 0%;
  }

  .Mobile-EngagBox .Mob-Box .para1 {
    font-size: 18px;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    margin: 0% 5% 0% 5%;
    padding: 0% 0% 5% 0%;
    color: #EBF5F5;
  }

}




@media only screen and (min-width: 1024px) {
  .Enagag-Tab{
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Engag-boxs {
    display: none;
  }

  .Engag-content h1 {
    font-size: 45px !important;
  }

  .Engag-content p {
    font-size: 16px !important;
  }

  .Textchange-list-container {
    font-size: 20px !important;
  }

  .Enagag-Tab {
    padding: 5% 0% 0% 0%;
    display: flex;
    align-items: center;
  }

  .Enagag-TabBox1 {
    width: 50vw;
    // border: 1px solid red;
    text-align: -webkit-center;
  }

    .Eng-Box {
      background: #263846;
      opacity: 1;
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      margin: 15% 0% 0% 0%;
      width: 90%;
      border-radius: 20px;
    }

    .Eng-Box p {
      font-size: 16px;
      text-align: left;
      font-family:  "space-Groteskr" !important;
      margin: 0% 5% 0% 5%;
      padding: 0% 0% 5% 0%;
      color: #EBF5F5;
    }

    .head-box {
      display: flex;
      align-items: center;
      padding: 5% 0%;
      margin: 0% 5% 0% 5%
    }

    .head-box h1 {
      font-size: 18px;
      font-family:  "space-Grotesksb" !important;
      font-size: 2vw;
      text-shadow: 0px 9px 38px #000000;
      color: #ffffff;
      text-align: left;
      letter-spacing: 1.44px;
      text-transform: uppercase;
    }

    .head-box img {
      max-width: 100%;
      height: auto;
      width: 40%;
      padding: 0% 5%;
    }
  


  .Enagag-TabBox2 {
    width: 50vw;
    border: 1px solid red;
    text-align: -webkit-center;

    .Eng-Box {
      background: #263846;
      opacity: 1;
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      margin: 15% 0% 0% 0%;
      width: 90%;
      border-radius: 20px;
    }

    .Eng-Box p {
      font-size: 16px;
      text-align: left;
      font-family:  "space-Groteskr" !important;
      margin: 0% 5% 0% 5%;
      padding: 0% 0% 5% 0%;
      color: #EBF5F5;
    }

    .head-box {
      display: flex;
      align-items: center;
      padding: 5% 0%;
    }

    .head-box h1 {
      font-size: 18px;
      font-family:  "space-Grotesksb" !important;
      font-size: 2vw;
      text-shadow: 0px 9px 38px #000000;
      color: #ffffff;
      text-align: left;
      letter-spacing: 1.44px;
      text-transform: uppercase;
    }

    .head-box img {
      max-width: 100%;
      height: auto;
      width: 40%;
      padding: 0% 5%;
    }
  }
}