
.our_culture{
  background-image: url('../../fonts/images/career_culture.png');
  transform-origin: 0% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}


.our_culture_content {
  /* padding: 8.5rem 6rem 3rem 6rem; */
  max-width: 100%;
  transform-origin: 100% 0%;
}
.culture_head{
  font-family:  "space-Grotesksb" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 44px;
  color: #fff;
  padding-bottom: 8px;
  padding-top: 3%;
  letter-spacing: 0px;
}
.culture_para{
  font-family:  "space-Groteskr" !important;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  color: #fff;
  letter-spacing: 0px;
}
.culture_first_div{
  padding-top: 44px;
}
.culture_container{
  align-items: center;
}
.culture_right_align{
  justify-content: flex-end;
  padding: 64px 0px 64px 0px;
}


@media all and (max-width: 768px) {  
  .culture_container{
    padding: 16px 0;
  }
  .culture_img_container {
    text-align: center;
  }
  .culture_img_container img{
    max-width: 40%;
    margin: 5px;
  }
  .culture_content {
    padding-bottom: 0;
  }

}

@media all and (min-width: 1900px) {   
  .culture_head{
    font-family:   "space-Grotesksb" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #fff;
    padding-bottom: 8px;
  }
  .culture_para{
    font-family:  "space-Groteskr" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    color: #fff;
  }

}