/* Tablet menu */
@media all and (min-width: 700px) {
  .ssd_main_blocks{
    display: flex;
    align-items: flex-start;  
    padding: 0px 3rem;
    text-align: left;
    margin: 1rem 0;
  }
  .ssd_main_benefits_blocks {
    padding: 3rem;
    text-align: left;
    margin: 0;
}
  .ssd_heading{display: none;}
  .ssd_heading_inner{display: block; margin: 0;}
  
  .ssd_image {
    flex: 0 0 50%;
    margin-bottom: 0;
  }
  .ssd_content{
    flex: 0 0 50%;
    /* padding-left: 8rem; */
  }
  .order-2{order: 2;}
  .ssd_content_left{padding:0;}
  .ssd_image{
    max-width: 450px;
    height: auto;
    vertical-align: middle;
    padding: 0rem 1rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 4rem;
    /* border: 1px solid #F9F9F9;
    background-color: #F9F9F9;
    box-shadow: rgb(149 157 165 / 50%) 6px 6px 10px; */
  }
}
/* Tablet END */


/* Desktop View */
@media all and (min-width: 960px) {  
.ssd_main_blocks{
  display: flex;
  align-items: flex-start;  
  padding: 0px 6rem;
  text-align: left;
  margin: 1rem 0;
}
.ssd_heading{display: none;}
.ssd_heading_inner{display: block; margin: 0;}

.ssd_image {
  flex: 0 0 50%;
  margin-bottom: 0;
}
.ssd_content{
  flex: 0 0 50%;
  /* padding-left: 8rem; */
}
.order-2{order: 2;}
.ssd_content_left{padding:0;}
.ssd_image{
  max-width: 450px;
  height: auto;
  vertical-align: middle;
  padding: 0rem 1rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 4rem;
  /* border: 1px solid #F9F9F9;
  background-color: #F9F9F9;
  box-shadow: rgb(149 157 165 / 50%) 6px 6px 10px; */
}
 }
/* Desktop View END */


/* New Version */
.collaboration_models{
    background-image: url('../../fonts/images/collaboration_models.png');
    margin-top: -5rem !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 64px 80px;
}
.how_it_img_size img{
  max-width: 78%;
}
.how_it_head_img_right{
  text-align: right !important;
}
.digi_col_content{
  padding: 0px 0px 60px 0px;
}
.shadow_effect{
  -webkit-filter: drop-shadow(-17px 18px 30px #222);
  filter: drop-shadow(-17px 18px 30px #222);
}
.heading_padding{
  padding: 0px 0px 44px 0px;
}

 /* ipad Air */
 @media (min-width: 820px) and (max-width: 920px) { 
  .collaboration_models{
      padding: 64px 24px;
  }
  .digi_col_content .col-md-2{
    max-width: 30%;
    flex: 30%;
  }
  .digi_col_content .col-md-9{
    max-width: 70%;
  }


}



@media only screen and (max-width: 768px) {  
  .section_main_heading{
    font-size: 28px !important;
  }
  .collaboration_models{
    padding: 4rem 1rem;
  }
 .digital_content {
   padding: 0;
 }
 .engagement_animate{
   padding-bottom: 2rem;
 }
 .engage_text_1{
   padding-top: 0rem !important;
   padding-bottom: 1rem !important;
 }
 .how_it_head_img {
  text-align: center !important;
  padding-bottom: 16px;
}
.how_it_head{
  font-size: 14px;
}
.model_mob_padding{
  padding: 0;
}


}

@media only screen and (max-width: 440px) {  
  .section_main_heading{
    font-size: 24px !important;
  }


}



@media only screen and (max-width: 390px) {  
  .section_main_heading{
    font-size: 22px !important;
  }
  .collaboration_models{
    padding: 64px 24px;
  }
 .digital_content {
   padding: 0;
 }



}


@media only screen and (max-width: 390px) {  
  .section_main_heading{
    font-size: 24px !important;
  }
}

@media all and (max-width: 340px) { 
  .section_main_heading{
    font-size: 22px !important;
  }
  
  }

  @media only screen and (min-width: 1900px) {  
    .heading_padding{
      padding: 0px 0px 80px 0px;
    }
    .collaboration_models {
      padding: 96px 120px 16px 120px;
  }
  .model_mob_padding .how_it_prof_head_for{
    padding: 20px 0px 0px 0px;
  }
  .digi_col_content{
    padding: 0px 0px 120px 0px;
  }
  .collaboraion_sub_text{
    padding-bottom: 0px;
  }


  }

  @media only screen 
 and (min-device-width: 768px) 
 and (max-device-width: 1024px) 
 and (-webkit-min-device-pixel-ratio: 1) { 
  .models_content{
    align-items: center;
    justify-content: center;
  }
  .models_content_end{
    align-items: center;
    justify-content: center;
  }
  .models_content .col-md-2, .models_content_end .col-md-2{
    flex: 0 0 25%;
    max-width: 25%;
  }
  .models_content .col-md-9, .models_content_end .col-md-9{
    flex: 0 0 75%;
    max-width: 75%;
  }

 }

 @media screen and (min-width:768px) and (max-width:819px)  { 
  .collaboration_models{
    padding: 64px 24px;
  }

}