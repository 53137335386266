.benefits_container{
  background-image: url('../../fonts/images/benefits_of_collaboration.png');
  background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    transform-origin: 100% 0%;
    margin-top: -3rem;
    padding-top: 48px;
}
.benefits_content{
  margin: 0;
  padding: 0px 80px 64px 80px;
}
.benefits_heading{
  padding: 0px 80px 44px 0px;
}
.benefit_color_code{
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #FFF;
}
.benefits_desc{
  display: flex;
  justify-content: flex-end; 
  align-items: center;
}
.benefits_desc_left{
  display: flex;
  justify-content: flex-start; 
  align-items: center;
}
.benefit_align{
  justify-content: flex-end;
  margin: 0;
  width: 100%;
}
.benefit_align_left{
  justify-content: flex-start;
  margin: 0;
  width: 100%;
}

.benefit_img_flex{
  flex: 0 0 22%;
  text-align: center;
}
.implement_box_benefit{
  align-items: flex-start;
}
.services_top_banner{
  padding: 64px 0 0 0;
}
.benefit_banner{
  position: absolute;
  top: 30%;
  left: 49%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.benefit_banner_contact{
  position: absolute;
  top: 70%;
  left: 70%;
  transform: translate(-50%, -50%);
}
.benefit_img_para{
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #fff;
  padding-top: 32px;
}

@media only screen and (max-width: 768px) { 
  .benefits_heading {
    padding: 0px 24px;
  }
  .benefits_content{
    padding: 16px 24px;
  }
  .implement_box_benefit{
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .benefit_img_para{
    font-size: 14px;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 48px;
  }
  .services_top{
    padding-top: 0 !important;
  }
  .services_top_banner{
    padding: 0;
    margin: 0 auto;
  }
  .benefit_banner{
    top: 30%;
  }

}


@media only screen and (max-width: 421px) { 
  .benefits_container{
    margin-top: -5.5rem;
  }


}


@media only screen and (min-width: 1900px) {  
  .benefit_img_para{
    font-size: 20px;
    line-height: 36px;
  }
  .benefits_heading{
    padding: 0px 120px 44px 120px;
  }
  .benefits_content{
    padding: 0px 120px 96px 120px;
  }
  .benefits_container{
    padding-top: 96px;
  }


}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .implement_box_benefit{
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .implement_box_benefit .col-md-4{
      max-width: 50%;
      flex: 0 0 50%;
    }


  }

  /* ipad Air */
  @media (min-width: 820px) and (max-width: 920px) { 
    .benefits_heading{
        padding: 0px 24px 44px 24px;
    }
    .benefits_content{
      padding: 0px 24px 64px 24px;
    }
    .implement_box_benefit .col-md-4{
      text-align: center;
      margin-bottom: 64px;
    }
    .implement_box_benefit .services_top {
      padding-top: 0px;
  }
  
  } 

  @media screen and (min-width:768px) and (max-width:819px)  { 
    .benefits_heading{
      padding: 0px 24px 44px 0px;
    }
    .benefits_content {
      margin: 0;
      padding: 0px 24px 64px 24px;
  }

  }