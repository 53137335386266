
.Main_Precisely{
  background-image: linear-gradient(to right, #07000c, #560894);
}
.precisely_banner{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
  .precisely_banner img{ 
  position: relative; 
  width: 100%;
  }
  .inner_Precise{
    // border: 1px solid red;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-26%, -80%);
  }
  .inner_Precise h1{
    line-height: 100%;
    font-size: 11.7vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
  }
  .inner_Precise .Pre-para1{
            // border: 1px solid blue;
            color: #FF6600;
            opacity: 1;
            letter-spacing: 0px;
            font-family:  "space-Grotesksb" !important;
            font-size: 2.2vw;
  }
  .inner_Precise .Pre-para2{
      // border: 1px solid yellow; 
      letter-spacing: 0.96px;
      color: #FFFFFF;
      opacity: 1;
      font-family: "space-Groteskr" !important;
      font-size: 1.5vw;
  }
  

  .Slide-Precisely{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    // background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #01010a 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    top: 0px;
  }

  .Precisely_content{
    display: flex;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    // background: transparent linear-gradient(360deg, #00122E 0%, #000000 14%, #013143 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5% 5% 5% 5%;
    margin-top: 0%;
    padding-top: 0%;
  }
  .Precisely_content_inside{
    display: flex;
    padding-top: 2%;
    margin-top: 2%;
  }
  .precise-image{
    width: 40%;
    // border: 1px solid red;
  }
  .Precisely_content img {
    max-width: 100%;
    height: auto;
    width: 90%;
    left: 10%;
    position: relative;
  }

  .precise-Content{
    width: 60%;
    // border: 1px solid blue;
    align-self: center;
  }
  .precise-Content .Content-para1{
    font-size: 2vw;
    font-weight: bolder;
    letter-spacing: 0.96px;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
  }
  .precise-Content .Content-para2{
    font-size: 1.2vw;
    letter-spacing: 1.44px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
  }



@media  only screen and (min-width: 768px) and (max-width: 1023px) {
  .inner_Precise {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-26%, -65%);
  }
  .Precisely_content {
    display: flex;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    opacity: 1;
    padding: 5% 2% 5% 2%;
    margin-top: 0%;
    padding-top: 0%;
  }
  .precise-Content .Content-para2 {
    font-size: 2vw;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
      font-family:  "space-Groteskr" !important;
      text-align: left;
    }
    .precise-Content .Content-para1 {
      font-size: 3vw;
      font-weight: bolder;
      letter-spacing: 0.96px;
      color: #FF6600;
      opacity: 1;
      font-family:  "space-Groteskr" !important;
      text-align: left;
    }
    
    .Precisely_content img {
      max-width: 100%;
      height: auto;
      width: 100%;
      left: 5%;
      position: relative;
  }

  .precise-image {
    width: 40%;
    align-self: center;
  }
}


@media  only screen and (max-width: 480px) {
  .precisely_banner{
    display: none;
  }
    .Precisely_content{
        display: none;
      }
      .precisely_banner-mobile{
        
      }

      .precisely_banner-mobile img{
        width: 100vw;
        height: 100vh;
        background-size: cover;
        object-fit: cover;
    }
    .inner_Precise-mobile{
      position: absolute;
      top: 60%;
      left: 20%;
      transform: translate(-10%, -15%);
    }
    .Slide-Precisely{
      position: relative;
      left: 0px;
      width: 100%;
      height: auto;
      border-top-right-radius: 0%;
      border-top-left-radius: 0%;
      background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
      opacity: 1;
      top: 0px;
    }
    
    
    .inner_Precise-mobile h1{
      line-height: 100%;
      font-size: 13.5Vw;
      opacity: 1;
      background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
      background-clip: border-box;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family:  "space-Groteskb" !important;
    }
    
    .inner_Precise-mobile .Pre-para1{
      color: #FF6600;
      opacity: 1;
      letter-spacing: 0px;
      font-family:  "space-Grotesksb" !important;
      font-size: 18px;
    }
    .inner_Precise-mobile .Pre-para2{
      letter-spacing: 0.96px;
      color: #FFFFFF;
      opacity: 1;
      font-family:  "space-Groteskr" !important;
      font-size: 16px;
    }
    .Precisely_content_inside_mobile {
      // padding-top: 5%;
      // margin-top: 5%;
    }
    
    .precise-image-mobile{
      align-self: center;
      display: block;
      position: relative;
      text-align-last: center;
      padding-top: 10%;
    }
    .precise-image-mobile img {
      max-width: 100%;
        height: auto;
        width: 75%;
      }
    .precise-Content-mobile .Content-para1 {
      font-size: 20px;
      font-weight: bolder;
      letter-spacing: 0.44px;
      color: #FF6600;
      opacity: 1;
      font-family:  "space-Groteskr" !important;
      text-align: left;
  }
    .precise-Content-mobile .Content-para2 {
      font-size: 16px;
      letter-spacing: 0.96px;
      color: #FFFFFF;
      opacity: 1;
      font-family:  "space-Groteskr" !important;
      text-align: left;
      line-height: 200%;
      }

      .precise-Content-mobile {
        width: 80%;
        position: relative;
        left: 10%;
        padding: 0% 0% 15% 0%;
      }
    }
    
    
    @media  only screen and (min-width: 481px) and (max-width: 767px){
      .precisely_banner{
        display: none;
      }
      .Precisely_content{
        display: none;
      }
      .precisely_banner-mobile{
        
      }

  .precisely_banner-mobile img{
    width: 100vw;
    height: 100vh;
    background-size: cover;
    object-fit: cover;
  }
  .inner_Precise-mobile{
    position: absolute;
    top: 60%;
    left: 20%;
    transform: translate(-10%, -15%);
  }
  .Slide-Precisely{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    border-top-right-radius: 0%;
    border-top-left-radius: 0%;
    background: transparent linear-gradient(360deg, #041A3E 0%, #000607 14%, #023345 34%, #00072D 67%, #182878 79%, #010101 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    top: 0px;
  }
  
  
  .inner_Precise-mobile h1{
    line-height: 100%;
    font-size: 13.5Vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskb" !important;
  }
  
  .inner_Precise-mobile .Pre-para1{
    color: #FF6600;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 18px;
  }
  .inner_Precise-mobile .Pre-para2{
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 16px;
  }
  .Precisely_content_inside_mobile {
    // padding-top: 5%;
    // margin-top: 5%;
  }
  
  .precise-image-mobile{
    align-self: center;
    display: block;
    position: relative;
    text-align-last: center;
    padding-top: 10%;
  }
  .precise-image-mobile img {
    max-width: 100%;
    height: auto;
    width: 75%;
  }
  .precise-Content-mobile .Content-para1 {
    font-size: 20px;
    font-weight: bolder;
    letter-spacing: 0.44px;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
  }
  .precise-Content-mobile .Content-para2 {
    font-size: 16px;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    line-height: 200%;
  }
  
  .precise-Content-mobile {
    width: 80%;
    position: relative;
    left: 10%;
    padding: 0% 0% 10% 0%;
  }
  }
  

  @media  only screen and (min-width: 768px) {
    .AboutPrecisely-mobile{
      display: none;
    }
  }
  
  @media  only screen and (min-width: 1024px) and (max-width: 1399px) {
    .inner_Precise {
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translate(-26%, -65%);
    }

    .precise-Content .Content-para2 {
      letter-spacing: 1.2px;
      font-size: 1.2vw;
      line-height: 34px;
    }
    .precise-Content .Content-para1 {
      font-size: 2vw;
      letter-spacing: 0.96px;
    }
    .youtube-logo img {
      width: 35% !important;
    }
    .youtube-logo {
      transform: translate(-50%, -50%) !important;
    }
  


  
  
  }