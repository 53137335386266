
  
  .job-lists {
    /* box-shadow: -10px -30px 50px rgba(11, 202, 243, 0.05), 10px 10px 20px rgba(1, 2, 2, 0.5); */
    /* border-radius: 10px; */
    /* border: 1px solid lightgray;
    border-bottom: 1px solid lightgray; */
    /* height: auto; */
    /* margin-bottom: 1rem; */
    padding: 2rem;
    color: #fff;
    /* background: #fff; */
    align-items:baseline;
    flex-direction: row;
    font-family:  "space-Groteskr" !important;
    margin-right: -12%;
}
.apply_title{
  font-size: larger;
  font-weight:400 ;

}
.apply_title:hover{
    text-decoration: underline;
    transform: translateY(-2px);
    cursor: pointer;
}
.job-lists .job_list_view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.postion_location div{
    margin-left: -90%;
}
.job_list_view .posted__date_date{
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
} 
.position__dropdown{
    text-align: center;
}
.requirement_tabs {
    padding: 2rem 0rem;
    max-width: 100%;
    font-family:  "space-Groteskr" !important;
    padding-right: 18%;
    color: #fff;
}
.requirement_tabs_head{
    font-weight: 600;
    font-family:  "space-Grotesksb" !important;
    font-size: 17px;
    color: #fff;
    padding-bottom: 0.5rem;
}
.requirement_tabs_details {
    font-size: 15px;
    font-family:  "space-Groteskr" !important;
    line-height: 28px;
}
.apply_btn{                         
    display: inline-block;
    position: relative;
    opacity: 1;
    background: white;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    border-radius: 5px;
    }
  
  .apply_btn div{      
    display: block;
    background-image: linear-gradient(to left, transparent, transparent 50%, #F05F30 50%, #F05F30);
    background-position: 100% 0;
    background-size: 200% 100%;
    width: 150px;
    font-weight: 600;
    padding: 5px 0px;
    cursor: pointer;
    color: #F05F30;
    border: 1px solid #F05F30;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .9s ease-in;
    padding-left: -7px;
    padding-right: 3px;
    /* color: black; */
    height: 50px;
  }
  .apply_btn div:hover {       
    background-position: 0 0; 
    box-shadow: 0px 8px 6px #00000047;
    border-radius: 5px;
    border: 1px solid #F05F30;
    color: #fff;
    .apply_text{
        background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
        background-position: 0 0; 
    }
  }
.apply_text{
    /* font-size: 1.2vw; */
  display: inline-block;
  position: relative;
  opacity: 1;
  background: transparent linear-gradient(91deg, #F05F30 0%, #F05F30 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important;
  border-radius: 10px;
}
.apply_text:hover{
    display: inline-block;
    position: relative;
    opacity: 1;
    background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:  "space-Groteskr" !important; 
}
.apply__col{
    display: flex;
    align-items: center !important;
    justify-content: center !important; 
}
.apply__col-mobile{
    /* display: flex; */
    /* align-items: left !important; */
    /* justify-content: left !important;  */
}

.text-center_btn{
    margin: 1rem auto;
    margin-top: 14px;
}
.position__dropdown_mobile {display: none;}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .apply_btn-tablet div {
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: white;
    background: #FF6600;
    border: 2px solid #FF6600;
    width: 145px;
    height: 45px;

}
.apply_text{
  background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
}
@media all and (min-width: 481px) and (max-width: 767px) {
    .apply_btn-tablet div {
        border-radius: 5px;
        color: white;
        height: 45px;
        background: #FF6600;
        width: 140px;
    }
    .apply_text{
      background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
      background-clip: border-box;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
}
@media all and (max-width: 480px) {
      .job-lists{
        padding: 0px;
        margin-bottom: 0.5rem;
      }
      .postion_location div {
        margin-left: -25%;
    }
    .apply_btn-mobile div {
        border-radius: 5px;
        color: white;
        font-size: 18px;
        background: #FF6600;
        margin-bottom: 80%;
        font-family:  "space-Groteskr" !important;
        text-align: center;
        padding: 6px 15px 6px 15px;
    }
    .apply_text-mobile{
        padding-top: -1px;
        background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
}
/* @media all and (max-width: 700px) {
    .job-lists {
        height: auto;
        margin-bottom: 1.5rem;
    }
    .position__dropdown{
        display: none !important;
    }
    .position__dropdown_mobile {
        display: block;
        text-align: center;
    }
    .job_list_view{
        display: block !important;
        align-items: center !important;
        padding: 0 !important;
        margin: 0 1rem;
        width: 100%;
    }
    .position__name_title, .location__name_title, .posted__date_title{
        padding-bottom: 0;
        font-size: 18px;
        line-height: 27px;
    }
    .location__name_location, .position__name_reqid{
        font-size: 14px;
    }
    .job_list_view .location__name{
        flex: 0;
        padding: 0;
        margin: 0 auto;
    }
    .job_list_view .posted__date{
        flex: 0 0 50%;
        padding: 0;
    }
    .section1-left-side{
        margin-bottom: 1.5rem;
    }
    .apply__col {
        padding: 1rem 0;
    }

} */


@media all and (min-width: 1900px) {   
    .position__name_title, .location__name_title, .posted__date_title{
        font-family: "Poppins-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: #0F326C;
    }
    .location__name_location, .position__name_reqid {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #0F326C;
    }
    .job_list_view .posted__date_date{
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #0F326C;
    } 
    
@media all and (max-width:480px) {
    .position__name{
        width: 100%;
    }
    .joblist-container-mobile{
        margin: 0;
    }
    .apply_btn-tablet div {
        border-radius: 5px;
        color: white;
        height: 40px;
        background: #FF6600;
        width: 140px;
    }
}
@media all and (max-width: 320px) and (min-width:200px){
.postion_location-mobile {
    margin-left: -70%;
}

}
}