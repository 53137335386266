.Main-togglingPro{
    background-image: url('../../fonts/Home/Mask\ Group\ 245\ \(2\).png');
    object-fit: cover;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}
.Main-togglingPro h1{
    font-size: 4.5vw;
    opacity: 1;
    font-family:  "space-Groteskb" !important;
    color: #FF6600;
    padding: 5% 0% 0% 5%;
}
.togglingPro{
    display: flex;
    padding-top: 10px;
    align-items: center;
}
.rowwPro1{
    position: relative;
    width: 10%;
    left: 4%;
    margin-top: -3%;
    // cursor: pointer;
}
.rowwPro2{
    width: 50%;
    position: relative;
}
.rowwPro3 {
    width: 40%;
    text-align: center;
    right: 8%;
    position: relative;
    margin-top: -2%;
    cursor: pointer;
}


.imagePro1{
position: relative;
top: 8vw;
opacity: 0.5;
transition-property: all;
transition-duration: 0.6s;
transition-timing-function: ease;
}
.imagePro1 img{
width: 50%;
border-radius: 25px;
transition-property: all;
transition-duration: 0.6s;
transition-timing-function: ease;
}
.imagePro2 {
position: relative;
bottom: 11vw;
left: 15%;
transition-property: all;
transition-duration: 0.6s;
transition-timing-function: ease;
}
.imagePro2 img{
width: 60%;
border-radius: 25px;
transition-property: all;
transition-duration: 0.6s;
transition-timing-function: ease;
}


.imagePro22 {
    position: relative;
    bottom:11vw;
    left: 0%;
    opacity: 0.5;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}
.imagePro22 img {
    width: 50%;
    border-radius: 25px;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}
.imagePro11 {
    position: relative;
    // top: 6%;
    // top: 170px;
    top: 8vw;
    left: 15%;
    z-index: 2;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}
.imagePro11 img {
    width: 60%;
    border-radius: 25px;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}




.rowwPro3-inside1{
    cursor: pointer;
}
.rowwPro3-inside2{
    cursor: pointer;
}

.rowwPro3-inside1-pa{
    font-size: 2vw;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;

}
.rowwPro3-inside2-pa{
    font-size: 2vw;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #FF6600;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;

}

.rowwPro3-inside1-paa{
    font-size: 1.2vw;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;
}
.rowwPro3-inside2-paa{
    font-size: 1.4vw;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    text-align: left;
    cursor: pointer;
}
.flexCl{
    display: flex;
}

.learnMore-OurPro {
    background-color: #FF6600;
    color: #FFFFFF;
    font-family:  "space-Groteskr" !important;
    font-size: 1.2vw;
    height: auto !important;
    padding: 1.5% 0% 1.5% 0% !important;
    border: 1px solid red;
    display: block !important;
    width: 10vw;
    border-radius: 10px;
}
.LearnArrow-OurPro{
    transform: rotateZ(135deg) scale(1.3);
    background-position: 0 0;
    color: #FFFFFF;
}


// Mlobile
@media  only screen and (max-width: 480px){
    .togglingPro{
        display: none;
    }
    .Main-togglingPro h1 {
        font-size: 45px !important;
        opacity: 1;
        font-family:  "space-Groteskb" !important;
        color: #FF6600;
        text-align-last: center !important;
        padding: 10% 0% 0% 0% !important;
        text-align: center;
    }

    .PdCl{
        padding: 5% 5% 10% 5% !important;
        margin: 0% !important;
    }
}


@media  only screen and (min-width: 768px){
    .Our-Toggle{
        display: none;
    }
    // .Enagag-Tab{
    //     display: none;
    // }
  }
@media  only screen and (min-width: 1024px){
    .togglingPro{
        display: none;
    }


    .SerMore{
        background-color: #FF6600;
        color: #FFFFFF;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        height: auto !important;
        padding: 3% 2% 3% 2% !important;
        border: 1px solid red;
        display: flex;
        width: 25vw !important;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
      }
      .SerMoreArrow{
        transform: rotateZ(135deg) scale(1.3);
        background-position: 0 0;
        color: #FFFFFF;
      }
  }




  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .togglingPro{
        display: none;
    }
    .Main-togglingPro h1 {
        font-size: 45px !important;
        opacity: 1;
        font-family:  "space-Groteskb" !important;
        color: #FF6600;
        text-align-last: center !important;
        padding: 10% 0% 0% 0% !important;
    }

    .PdCl{
        padding: 5% 5% 10% 5% !important;
        margin: 0% !important;
    }
  }


    
@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .togglingPro{
        display: none;
    }
    
  .SerMore{
    background-color: #FF6600;
    color: #FFFFFF;
    font-family:  "space-Groteskr" !important;
    font-size: 18px;
    height: auto !important;
    padding: 3% 2% 3% 2% !important;
    border: 1px solid red;
    display: flex;
    width: 25vw !important;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
  .SerMoreArrow{
    transform: rotateZ(135deg) scale(1.3);
    background-position: 0 0;
    color: #FFFFFF;
  }
}