.whyEvenion_Analytics{
    padding: 10% 0% 5% 0%;
}
.whyEvenion_Analytics h1{
    font-size: 11vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
}

.Why_evenion_inner{
    display: flex;
    justify-content: space-evenly;
    padding: 5% 0% 8% 0%;
}

.Why_evenion_box1{

}
.Why_evenion_box2{
   
}
.Why_evenion_box3{

}
.Why_evenion_box4{

}

.gradient-border-mask1 {
    display: flow-root;
    position: relative;
    padding: 1.3rem;
    width: 22vw;
    height: auto;
  }
  
  .gradient-border-mask1::before {
    content: "";
    position: absolute;
    width: 22vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

.Why_evenion_inner .Head_indus{
    font-family:  "space-Grotesksb" !important;
    font-size: 1.4vw;
    color: #FF6600;
    text-align: center;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    opacity: 1;
    padding: 20% 0% 0% 0%;
}
.Why_evenion_inner .para-text{
    font-size: 1.2vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;

}
.Why_evenion_inner .para1{
    padding: 8% 0% 0% 0%;
}
.Why_evenion_inner .para2{
    padding: 8% 0% 0% 0%;
}
.Why_evenion_inner .para3{
    padding: 8% 0% 0% 0%;
}
.Why_evenion_inner .para4{
    padding: 8% 0% 0% 0%;
}


@media  only screen and (max-width: 480px) {
    .whyEvenion_Analytics{
        display: none;
    }

    .whyEvenion_Analytics_mobile{
        padding: 25% 0% 25% 0%;
    }
    .whyEvenion_Analytics_mobile h1{
        font-size: 14vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    
    .Why_evenion_inner_mobile1{

        display: grid;
        text-align: -webkit-center;
        justify-content: space-evenly;

    }
    
    .Why_evenion_inner_mobile2{
        display: grid;
        text-align: -webkit-center;
        justify-content: space-evenly;

    }
    
    .Why_evenion_box1_mobile{

    }
    .Why_evenion_box2_mobile{

    }
    .Why_evenion_box3_mobile{

    }
    .Why_evenion_box4_mobile{

    }
    
.gradient-border-mask-mobile {
    display: flow-root;
    position: relative;
    padding: 0% 2%;
    width: 80vw;
    height: auto;
    margin-top: 15%;
    overflow: hidden;
  }
  
  .gradient-border-mask-mobile::before {
    content: "";
    position: absolute;
    width: 80vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
    
    .Why_evenion_inner_mobile1 .Head_indus{
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        text-align: center;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        padding: 8% 2% 3% 2%;
        margin: 0%;
    }
    .Why_evenion_inner_mobile1 .para-text{
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 2%;
        margin-right: 2%;
        line-height: 135%;
    }
    .Why_evenion_inner_mobile2 .Head_indus{
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        text-align: center;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        padding: 8% 2% 3% 2%;
        margin: 0%;
    }
    .Why_evenion_inner_mobile2 .para-text{
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 2%;
        margin-right: 2%;
        line-height: 135%;
    }

    .Why_evenion_inner_mobile1 .para1{
        padding: 4% 0% 0% 0%;
    }
    .Why_evenion_inner_mobile1 .para2{
        padding: 4% 0% 0% 0%;
    }
    .Why_evenion_inner_mobile2 .para3{
        padding: 4% 0% 0% 0%;
    }
    .Why_evenion_inner_mobile2 .para4{
        padding: 4% 0% 0% 0%;
    }
    
}


@media  only screen and (min-width: 768px){
    .whyEvenion_Analytics_mobile{
        display: none;
    }

}
@media  only screen and (min-width: 481px) and (max-width: 767px){

    .whyEvenion_Analytics{
        display: none;
    }

    .whyEvenion_Analytics_mobile{
        padding: 25% 0% 10% 0%;
    }
    .whyEvenion_Analytics_mobile h1{
        font-size: 14vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
    
    .Why_evenion_inner_mobile1{
        display: flex;
        justify-content: space-evenly;
        padding: 5% 0% 8% 0%;
    }
    
    .Why_evenion_inner_mobile2{
        display: flex;
        justify-content: space-evenly;
        padding: 5% 0% 10% 0%;
    }
    
    .Why_evenion_box1_mobile{

    }
    .Why_evenion_box2_mobile{

    }
    .Why_evenion_box3_mobile{

    }
    .Why_evenion_box4_mobile{

    }
    
.gradient-border-mask-mobile {
    display: flow-root;
    position: relative;
    padding: 0% 2%;
    width: 45vw;
    height: auto;
  }
  
  .gradient-border-mask-mobile::before {
    content: "";
    position: absolute;
    width: 45vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
    .Why_evenion_inner_mobile1 .Head_indus{
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        text-align: center;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        padding: 7% 2%;
        margin: 0%;
    }
    .Why_evenion_inner_mobile1 .para-text{
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 2%;
        margin-right: 2%;
        line-height: 135%;
    }
    .Why_evenion_inner_mobile2 .Head_indus{
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        letter-spacing: 0.96px;
        text-align: center;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        padding: 7% 2%;
        margin: 0%;
    }
    .Why_evenion_inner_mobile2 .para-text{
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 2%;
        margin-right: 2%;
        line-height: 135%;
    }

    .Why_evenion_inner_mobile1 .para1{
        padding: 4% 0% 0% 0%;
    }
    .Why_evenion_inner_mobile1 .para2{
        padding: 4% 0% 0% 0%;
    }
    .Why_evenion_inner_mobile2 .para3{
        padding: 4% 0% 0% 0%;
    }
    .Why_evenion_inner_mobile2 .para4{
        padding: 4% 0% 0% 0%;
    }
    
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .whyEvenion_Analytics h1 {
        font-size: 14vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
    }

    .Why_evenion_inner .Head_indus{
        font-family:  "space-Grotesksb" !important;
        font-size: 16px;
        letter-spacing: 0.96px;
        text-align: center;
        text-transform: uppercase;
        color: #FF6600;
        opacity: 1;
        padding: 7% 2%;
        margin: 0%;
    }
    .Why_evenion_inner .para-text{
        font-size: 14px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin-left: 2%;
        margin-right: 2%;
        line-height: 135%;
    
    }
    .Why_evenion_inner .para1{
        padding: 8% 0% 0% 0%;
    }
    .Why_evenion_inner .para2{
        padding: 8% 0% 0% 0%;
    }
    .Why_evenion_inner .para3{
        padding: 8% 0% 0% 0%;
    }
    .Why_evenion_inner .para4{
        padding: 8% 0% 0% 0%;
    }
    .Why_evenion_box1 {
        width: 24vw;
    }
    .Why_evenion_box2 {
        width: 24vw;
    }
    .Why_evenion_box3 {
        width: 24vw;
    }
    .Why_evenion_box4 {
        width: 24vw;
    }
    .whyEvenion_Analytics {
        padding: 10% 0% 5% 0%;
    }
    

}




@media  only screen and (min-width: 1024px) and (max-width: 1399px) {
    .Why_evenion_inner .Head_indus {
        font-size: 1.6vw;
    }
    .Why_evenion_inner .para-text {
        font-size: 1.4vw;
    }
    .Why_evenion_box1 {
        width: 23vw;
    }
    .Why_evenion_box2 {
        width: 23vw;
    }
    .Why_evenion_box3 {
        width: 23vw;
    }
    .Why_evenion_box4 {
        width: 23vw;
    }
    .whyEvenion_Analytics {
        padding: 10% 0% 5% 0%;
    }

    
.gradient-border-mask1 {
    display: flow-root;
    position: relative;
    padding: 0% 1%;
    width: 24vw;
    height: auto;
    overflow: hidden;
  }
  
  .gradient-border-mask1::before {
    content: "";
    position: absolute;
    width: 24vw;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1.5px solid transparent;
    background: linear-gradient(45deg, #00CCC5, #48A7BC, #FF6600) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
    
}