.common-needs-banner-digi {
        transform-origin: 100% 0;
        margin-top: 0px;
        /* background-image: url('../../fonts/images/digi_common_back.png'); */
        background-image: url('../../fonts/images/common_needs.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
  }
 .engage_line_height{
     line-height: 36px;
 } 
.digi-needs-container-padding{
    padding: 0px 0px 112px 0px;
}
 .common-needs-heading-container{
    padding: 48px 80px 44px 80px;
    margin: 0;
 }

.digi_common_desktop{
    display: block;
    padding-top: 48px;
}
.digi_common_mob{
    display: none;
}



 
@media only screen and (max-width: 768px)  {
    .common-needs-heading-container{
        padding: 32px 24px 0px 24px;
    }
    .digi_common_desktop{
        display: none;
    }
    .digi_common_mob{
        display: block;
    }
    .digi-needs-container-padding{
        padding: 16px 24px 96px 24px;
        margin: 0 auto;
    }
    .needs-container-padding .row{
        padding: 0;
        margin: 0;
    }
    .heading-right{
        text-align: right !important;
    }
    .engage_line_height{
        line-height: 27px;
    }
    .img_eng_2_digi{
        height: auto;
    }
    .digi-common-box-design{
        padding: 48px 12px;
    }

}

@media only screen and (max-width: 650px)  {
    .needs-box {
        margin: 38px 0px;
    }
    .needs-heading-center {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #1A2238;
        position: relative;
        text-align: center;
    }
    .center-box {
        display: inline-block !important;
        margin: 0px 18px;
    }
    .section3-box {
        margin: 10px 15px;
        width: auto;
        height: auto;
        /* width: 200px; */
    }
    
.digi_box_1{
    position: absolute;
    top: 18%;
    padding: 0;
    max-width: 75%;
    left: 13.5%;
  }
  
  .digi_box_2 {
    position: absolute;
    top: 18%;
    padding: 0;
    max-width: 75%;
    left: 11%;
  }
  .digi_box_3 {
    position: absolute;
    top: 18%;
    padding: 0;
    max-width: 75%;
    left: 14%;
  }
  .engage_line_height {
    line-height: 36px;
}


}



@media only screen and (max-width: 580px)  {
    .digi_common_box > img {
        height: auto;
    }
    /* .model_caption_proj_1 {
        position: absolute;
        top: 18%;
        padding: 0;
        max-width: 75%;
        left: 13.5%;
    } */

 }

 @media only screen and (max-width: 480px)  {
    .digi_common_box > img {
        height: auto;
    }
    .proj_mob_box_3{
        padding-bottom: 24px;
    }
 }

 @media only screen and (max-width: 420px)  {
    .digi_common_box > img {
        height: auto;
    }
    .proj_mob_box_3{
        padding-bottom: 24px;
    }
 }
 @media only screen and (max-width: 380px)  {
    .digi_common_box > img {
        height: 350px;
    }
 }

 @media only screen and (max-width: 350px)  {
    .digi_common_box > img {
        height: 300px;
    }
    .proj_mob_box_3{
        padding-bottom: 24px;
    }
    .engage_logo{
        padding-bottom: 8px;
    }
    .engage_head{
        line-height: 21px;
    }
    .engage_logo_line{
        padding-bottom: 0;
    }
 }

 @media only screen and (min-width: 1900px)  {
    .common-needs-heading-container{
        padding: 48px 120px 20px 120px;
    }
    .digi-needs-container-padding{
        padding: 0px 0px 150px 0px;
    }
    .model_caption_proj_1 {
        position: absolute;
        top: 12%;
        padding: 0;
        max-width: 75%;
        left: 14%;
    }
    .model_caption_proj_2 {
        position: absolute;
        top: 15%;
        padding: 0;
        max-width: 75%;
        left: 11%;
    }
    .digi_common_desktop {
        display: block;
        max-width: 1400px;
    }
    .engage_line_height {
        line-height: 48px;
    }

 }


 
 @media only screen 
 and (min-device-width: 768px) 
 and (max-device-width: 1023px) 
 and (-webkit-min-device-pixel-ratio: 1) {
   .digi-needs-container-padding {
        padding: 16px 24px 94px 24px;
        margin: 32px auto 0px auto;
   }
   .engage_mob_box{
       max-width: 100%;
       flex: none;
   } 
   .digi_common_box > img{
       width: 100%;
   }
   .model_caption_proj{
    position: absolute;
    top: 15%;
    padding: 0;
    max-width: 75%;
    left: 14%;
  }
  
  .model_caption_proj_1 {
    position: absolute;
    top: 15%;
    padding: 0;
    max-width: 75%;
    left: 14%;
  }
  .model_caption_proj_2 {
    position: absolute;
    top: 20%;
    padding: 0;
    max-width: 75%;
    left: 11%;
  }

 }


 /* ipad Air */
@media (min-width: 820px) and (max-width: 920px) { 
    .digi-needs-container-padding{
        text-align: center;
        margin: 0 auto;
    }
    .common-needs-heading-container{
        padding: 48px 24px 44px 24px;
    }
    .model_caption_proj {
        position: absolute;
        top: 20%;
        padding: 0;
        max-width: 64%;
        left: 19%;
    }
    .model_caption_proj_1 {
        position: absolute;
        top: 20%;
        padding: 0;
        max-width: 64%;
        left: 19%;
    }
    .model_caption_proj_2 {
        position: absolute;
        top: 20%;
        padding: 0;
        max-width: 64%;
        left: 16%;
    }
    .engage_mob_box{
        padding-top: 64px;
    }

    .digital_banner_contact_caption{
        position: absolute;
        text-align: right;
        bottom: 11% !important;
        right: 8% !important;
        transform: translate(-50%, -50%);
    }
    
}

@media screen and (min-width:768px) and (max-width:819px)  { 

    .model_caption_proj {
      position: absolute;
      top: 15%;
      padding: 0;
      max-width: 61%;
      left: 14%;
  }
  .model_caption_proj_1 {
    position: absolute;
    top: 15%;
    padding: 0;
    max-width: 62%;
    left: 14%;
}
.model_caption_proj_2 {
    position: absolute;
    top: 20%;
    padding: 0;
    max-width: 61%;
    left: 11%;
}
.img_eng_3{
    margin-top: 64px;
}
.common-needs-heading-container {
    padding: 48px 24px 44px 24px;
    margin: 0;
}

   }