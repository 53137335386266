.main-BOC{
    background: transparent linear-gradient(180deg, #001D34 0%, #011639 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    padding: 5% 0% 5% 0%;
    overflow: hidden;
}
.BOC-head{
    width: 50%;
    padding: 5% 4% 0% 4%;
}
.BOC-head h1 {
    padding: 0% 0% 0% 0%;
    font-size: 6vw;
    text-align: left;
    color: white;
    font-family:  "space-Groteskb" !important;
    text-shadow: 0px 3px 6px #0000007D;
}
.BOC-head .para1 {
    font-size: 1.4vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 3% 0% 3% 0%;
}
.BOC-head .para2 {
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 1.44px;
    padding: 3% 0% 3% 0%;
}

.BOC-box {
    width: 50%;
    padding: 0% 4% 0% 4%;
    display: inline-flex;
    flex-flow: wrap;
    justify-content: space-evenly;
}

.BOC-Card{
    background: #26405c;
    border-radius: 15px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    width: 46%;
    margin: 2% 2% 2% 2%;
    text-align-last: center;
    overflow: hidden;
}
.BOC-Card img {
    max-width: 100%;
    height: auto;
    width: 30%;
    padding: 10% 0% 10% 0%;
}
.BOC-Card h1 {
    font-size: 1.4vw;
    text-align: -webkit-center;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 2% 0% 8% 0%;
}
@media  only screen and (max-width: 400px) {
    .BOC-box {
        width: 100%;
        padding: 0% 4% 0% 4%;
        display: inline-flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        flex-direction: column !important;
        align-content: center !important;
    }
    .BOC-Card {
        width: 55% !important;
        margin: 4% 2% 4% 2% !important;
    }
    .BOC-Card h1 {
        padding: 4% 4% 8% 4% !important;
    }
    
}
@media  only screen and (max-width: 480px) {
    .main-BOC {
        background: transparent linear-gradient(180deg, #001D34 0%, #011639 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 10% 0% 10% 0%;
        display: block;
        text-align: -webkit-center;
    }
    .BOC-head{
        width: 100%;
        padding: 5% 4% 5% 4%;
    }
    .BOC-head h1 {
        padding: 0% 0% 0% 0%;
        font-size: 11vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        text-shadow: 0px 3px 6px #0000007D;
    }
    .BOC-head .para1 {
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 0%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .BOC-head .para2 {
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }

    .BOC-box {
        width: 95%;
        padding: 0% 4% 0% 4%;
        display: inline-flex;
        flex-flow: wrap;
        justify-content: space-evenly;
    }

    .BOC-Card{
        background: #26405c;
        border-radius: 15px;
        opacity: 1;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        width: 46%;
        margin: 2% 2% 2% 2%;
        text-align-last: center;
        overflow: hidden;
    }
    .BOC-Card img {
        max-width: 100%;
        height: auto;
        width: 45%;
        padding: 10% 0% 8% 0%;
    }
    .BOC-Card h1 {
        font-size: 16px;
        text-align: -webkit-center;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        padding: 2% 3% 8% 3%;
    }
}


@media  only screen and (min-width: 481px) and (max-width: 767px){
    .main-BOC {
        background: transparent linear-gradient(180deg, #001D34 0%, #011639 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 10% 0% 10% 0%;
        display: block;
        text-align: -webkit-center;
    }
    .BOC-head{
        width: 100%;
        padding: 5% 4% 5% 4%;
    }
    .BOC-head h1 {
        padding: 0% 0% 0% 0%;
        font-size: 11vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        text-shadow: 0px 3px 6px #0000007D;
    }
    .BOC-head .para1 {
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 0%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .BOC-head .para2 {
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }

    .BOC-box {
        width: 95%;
        padding: 0% 4% 0% 4%;
        display: inline-flex;
        flex-flow: wrap;
        justify-content: space-evenly;
    }

    .BOC-Card{
        background: #26405c;
        border-radius: 15px;
        opacity: 1;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        width: 46%;
        margin: 2% 2% 2% 2%;
        text-align-last: center;
        overflow: hidden;
    }
    .BOC-Card img {
        max-width: 100%;
        height: auto;
        width: 45%;
        padding: 10% 0% 8% 0%;
    }
    .BOC-Card h1 {
        font-size: 16px;
        text-align: -webkit-center;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        padding: 2% 3% 8% 3%;
    }
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .BOC-box {
        padding: 0% 4% 0% 0%;
    }
    .BOC-head {
        padding: 5% 2% 0% 4%;
    }
    .BOC-head .para1 {
        font-size: 1.5vw;
        letter-spacing: 0.96px;
    }

    .BOC-head .para2 {
        font-size: 2.5vw;
        letter-spacing: 0.44px;
    }
    .BOC-Card img {
        max-width: 100%;
        height: auto;
        width: 50%;
        padding: 10% 0% 8% 0%;
    }
    .BOC-Card h1 {
        font-size: 1.5vw;
    }
}