.Main-slider{
    display: flex;
    background-color: #24333f;
}
.slider-inside-right{
    width: 50vw;
}
.slider-inside-left{
    width: 50vw;
}

.section-center {
    height: 450px;
    // max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    position: absolute;
  }






.btn-flex{
    display: flex;
    gap: 45%;
    padding: 0% 0% 0% 5%;
}



  .slider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background: #24333f;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    padding: 5% 0%;
}
  
  /* CSS for Slider Content */
  .slider-content {
    // flex: 1 1;
    padding: 0% 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50vw;
    // border: 1px solid red;
  }
  
  .slider-content h2 {
    font-size: 24px;
    margin: 0;
    font-size: 2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  
  .slider-content p {
    font-size: 1.2vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 5% 0%;
    line-height: 200%;
    cursor: pointer;
  }
  
  .slider-button {
    cursor: pointer;
    font-size: 16px;
    padding: 8px;
    background-color: transparent;
    color: #fff;
    -webkit-user-select: none;
    user-select: none;
    scale: 1.7;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}


  
  .slider-image {
    // flex: 1;
    width: 50vw;
    text-align: center;
  }
  
  .slider-image img {
    max-width: 100%;
    height: auto;
    width: 80%;
  }
  


  .circless{
    display: block;
    background-color: transparent;
    transition: all 1s ease;
  }
  .circless:hover{
      background-color: #45515c;
      color: #FF6600;
  }
  .circless:hover{
       border-radius: 100%;
       -moz-border-radius: 100%;
       -webkit-border-radius: 100%;
       
  }
  
  .circless{
         -webkit-border-radius: 0 0 0 0;
  }
  
  



  

@media  only screen and (max-width: 480px) {
  .slider-sec{
      display: none;
  }
  

.btn-flex-mobile{
  display: flex;
  gap: 20%;
  padding: 0% 0% 0% 0%;
  justify-content: center;
}


.slider-sec-mobile{
  text-align: -webkit-center;
}
.slider-container-mobile {
  background: #24333f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  padding: 5% 0%;
  text-align: -webkit-center;
  border-radius: 10px;
  width: 90vw;
  margin: 0% 0% 15% 0%;

}

.slider-content-mobile {
  // flex: 1 1;
  padding: 0% 5%;
  display: flex;
  flex-direction: column;
  width: 80vw;
}

.slider-content-mobile h2 {
  font-size: 18px;
  color: white;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  padding: 10% 0% 0% 0%;
  margin: 5% 0% 0% 0%;

}

.slider-content-mobile p {
  font-size: 16px;
  text-align: center;
  font-family:  "space-Groteskr" !important;
  opacity: 1;
  color: #FFFFFF;
  font-weight: lighter;
  margin: 5% 0%;
  line-height: 200%;
  cursor: pointer;
}

.slider-button-mobile {
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  background-color: transparent;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  scale: 1.7;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}



.slider-image-mobile {
  // flex: 1;
  width: 80vw;
  text-align: center;
}

.slider-image-mobile img {
  max-width: 100%;
  height: auto;
  width: 100%;
}



.circless-mobile{
  display: block;
  background-color: transparent;
  transition: all 1s ease;
}
.circless-mobile:hover{
    background-color: #45515c;
    color: #FF6600;
}
.circless-mobile:hover{
     border-radius: 100%;
     -moz-border-radius: 100%;
     -webkit-border-radius: 100%;
     
}

.circless-mobile{
       -webkit-border-radius: 0 0 0 0;
}
  

}



@media  only screen and (min-width: 768px){
  .slider-sec-mobile{
      display: none;
  }
}
@media  only screen and (min-width: 481px) and (max-width: 767px){
  .slider-sec{
    display: none;
}


.btn-flex-mobile{
display: flex;
gap: 20%;
padding: 0% 0% 0% 0%;
justify-content: center;
}


.slider-sec-mobile{
text-align: -webkit-center;
}
.slider-container-mobile {
background: #24333f;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
overflow: hidden;
position: relative;
padding: 5% 0%;
text-align: -webkit-center;
border-radius: 10px;
width: 90vw;
margin: 0% 0% 15% 0%;

}

.slider-content-mobile {
// flex: 1 1;
padding: 0% 5%;
display: flex;
flex-direction: column;
width: 80vw;
}

.slider-content-mobile h2 {
font-size: 18px;
color: white;
text-align: center;
font-family:  "space-Groteskr" !important;
padding: 10% 0% 0% 0%;
margin: 5% 0% 0% 0%;

}

.slider-content-mobile p {
font-size: 16px;
text-align: center;
font-family:  "space-Groteskr" !important;
opacity: 1;
color: #FFFFFF;
font-weight: lighter;
margin: 5% 0%;
line-height: 200%;
cursor: pointer;
}

.slider-button-mobile {
cursor: pointer;
font-size: 16px;
padding: 8px;
background-color: transparent;
color: #fff;
-webkit-user-select: none;
user-select: none;
scale: 1.7;
transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}



.slider-image-mobile {
// flex: 1;
width: 80vw;
text-align: center;
}

.slider-image-mobile img {
max-width: 100%;
height: auto;
width: 100%;
}



.circless-mobile{
display: block;
background-color: transparent;
transition: all 1s ease;
}
.circless-mobile:hover{
  background-color: #45515c;
  color: #FF6600;
}
.circless-mobile:hover{
   border-radius: 100%;
   -moz-border-radius: 100%;
   -webkit-border-radius: 100%;
   
}

.circless-mobile{
     -webkit-border-radius: 0 0 0 0;
}
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
  .slider-content h2 {
    font-size: 2.2vw;
  }
  .slider-content p {
    font-size: 1.6vw;
  }
  .slider-button {
    scale: 1.4;
  }
  .slider-image img {
    max-width: 100%;
    height: auto;
    width: 85%;
  }
  .slider-image img {
    max-width: 100%;
    height: auto;
    width: 85%;
  }
}