.spinner-container {
    justify-content: center;
    align-items: center;
    background: #02122B;
  }
  .loading-spinner h1{
      /* border: 1px solid black;  */
      line-height: 100%;
      font-size: 5vw;
      opacity: 1;
      background:#fff;
      background-clip: border-box;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: ' space-Groteskb';
      padding: 2rem 5rem;
  }
  .loaderGIF{
    object-fit: contain;
    width: 100%;
    /* margin-top: -6%; */
    /* padding: 5%; */
  }
  .loader-percentage-mobile{
    font-family: ' space-Groteskb';
    font-size: 9vw;
    text-align: end;
    padding: 2% 6%;
    color: #02122B;
  }

  .loaderGIF-mobile{
    object-fit: contain;
    width: 100%;
  }
  .gif-404{
    object-fit: contain;
    width: 100%;
    margin: 20% -10%;
  }
  .gif-404-mobile{
    object-fit: contain;
    width: 100%;
    margin: 5% 0%;
  }
  .error-404 h1{
    line-height: 100%;
    font-size: 6vw;
    opacity: 1;
    background: #fff;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: ' space-Groteskb';
    padding: 2rem 5rem;
    margin-top: 10%;
}
.error-title{
  margin-top: 0% !important;
  font-family:  "space-Groteskr" !important;
  font-size: 2vw !important;
  padding-bottom: 20% !important;
  line-height: 40px !important;
}
.zzz-img{
  object-fit: contain;
    width: 15%;
    margin-left: 6%;
    margin-bottom: 3%;
}

@media screen and (max-width:921px) {
  .error-404 h1{
    text-align: center;
    font-size: 16vw;
    padding: 2rem 2rem !important;
  }
  .error-title{
    font-size: 4vw !important;
    padding-bottom: 30% !important;
    line-height: 50px !important;
  }
  .back2home-mob{
    font-size: 3.5vw !important;
    line-height: 36px;
    margin-top: 5% !important;
    text-align: -webkit-center;
  }
  .back2home-mob div {
    display: block;
    width: 60%;
    padding: 10px 0px;
    cursor: pointer;
    color: #fff !important;
    border: 1px solid #F05F30;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .6s ease-in;
    /* color: black; */
    /* height: 70px; */
    background: #F05F30;
    text-align: center;
    .rotatee{
      transform: rotate(-45deg);
      /* font-size: 45px; */
      color: #fff;
  }  
}
.back2home-textmob{
  /* font-size: 1.2vw; */
display: inline-block;
position: relative;
opacity: 1;
background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
background-clip: border-box;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-family:  "space-Groteskr" !important;
border-radius: 5px;
}
}

@media screen and (min-width:519px) and (max-width:767px) {
  .back2home-mob div{
    width: 50% !important;
  }
}
@media screen and (min-width:768px) and (max-width:921px) {
  .back2home-mob div{
    width: 46% !important;
    padding: 23px 0px !important;

  }
}

.loader-container {
     text-align: center;
    margin-top:  50px;
    position: absolute;
    bottom: 0;
    background: #D6D6D6;
    width: 100%;
    height: 30%;
    z-index: 99;
}

.loader-container-mobile{
  background: #fff;
  width: 100%;
  height: 130px !important;
  margin-bottom: 0px;
}

.loader-bar {
  width: 100%;
  height: 15px;
  background: transparent;
  overflow: hidden;
}

.loader-progress {
  height: 60%;
  background:  linear-gradient(90deg, #35D2D2 0%, #57C0AE 47.01%, rgba(255, 102, 0, 0.94) 100%);
  transition: width 0.2s ease-in-out;
}

.loader-percentage {
  font-family: ' space-Groteskb';
  font-size: 5vw;
  text-align: end;
  padding: 0% 5%;
  color: #02122B;
  margin-top: 1%;
}

.back2home{                         
  font-size: 16px;
  line-height: 36px;
  /* text-align: center; */
  border-radius: 5px;
  font-weight: 600;
  color: #fff !important;
  margin-top: 2% ;
  }

.back2home div{      
  display: block;
  background-image: linear-gradient(to left, transparent, transparent 50%, #F05F30 50%, #F05F30);
  background-position: 100% 0;
  background-size: 201% 100%;
  width: 200px;
  padding: 10px 0px;
  cursor: pointer;
  color: #fff !important;
  border: 1px solid #F05F30;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all .6s ease-in;
  /* color: black; */
  height: 55px;
  text-align: center;
  .rotatee{
    transform: scale(1)rotateZ(180deg);
    transition: all .6s ease-in;
    color: #fff !important;
}

}
.back2home div:hover {       
  background-position: 0 0; 
  box-shadow: 0px 8px 6px #00000047;
  border-radius: 5px;
  border: 1px solid #F05F30;
  color: #fff;
  .back2home-text{
      background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
      background-position: 0 0; 
  }
  .rotatee{
    transform: scale(1.2)rotateZ(135deg);
    color: white !important;
    background-position: 0 0; 
}
}
.back2home-text{
  /* font-size: 1.2vw; */
display: inline-block;
position: relative;
opacity: 1;
background: transparent linear-gradient(91deg, #fff 0%, #fff 100%) 0% 0% no-repeat padding-box;
background-clip: border-box;
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-family:  "space-Groteskr" !important;
border-radius: 5px;
}
.back2home-text:hover{
  display: inline-block;
  position: relative;
  opacity: 1;
  color: #fff;
  background: transparent linear-gradient(91deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:  "space-Groteskr" !important; 
}