 .WhyDataSenSex-Main1{
    // border: 1px solid red;
    padding-top: 0%;
    margin-top: 0%;
}
.WhyDataSenSex-inside1 h1{
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    // text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    opacity: 1;
    line-height: 100%;
    font-size: 9vw;
    font-family:  "space-Groteskb" !important;
    padding-top: 10%;
    padding-bottom: 0% !important;
}
.WhyDataSenSex-inside1 .WhyData-para1{
    letter-spacing: 1.44px;
    font-size: 2.2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 2%;
}



.WhyDataSensex-Container-main{
    // border: 1px solid red;
    text-align: -webkit-center;
}
.WhyDataSensex-Container1{
    // border: 1px solid blue;
    width: 80%;
    margin-top: 15%;
    margin-bottom: 5%;
    display: flex;
    position: relative;
}
.WhyDataSensex-Container2{
    // border: 1px solid rgb(34, 203, 45);
    width: 80%;
    margin-top: 15%;
    margin-bottom: 5%;
    display: flex;
    position: relative;

}
.WhyDataSensex-Container3{
    // border: 1px solid rgb(16, 29, 175);
    width: 80%;
    // padding-top: 5%;
    // padding-bottom: 5%;
    margin-top: 15%;
    margin-bottom: 5%;
    display: flex;
    position: relative;

}
.WhyDataSensex-Container4{
    // border: 1px solid rgb(190, 193, 26);
    width: 80%;
    // padding-top: 5%;
    // padding-bottom: 5%;
    margin-top: 15%;
    margin-bottom: 5%;
    display: flex;
    position: relative;

}


.img-Container1{
    width: 40%;
    // border: 1px solid green;
}

.img-Container1 img{
    max-width: 100%;
    height: auto;
    width: 75%;
}
.Content-Container1{
    width: 60%;
    // border: 1px solid blue;
    position: relative;
    align-self: center;
}
.Content-Container1  .Content-para1{
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 2.5vw;
    font-family:  "space-Grotesksb" !important;
}
.Content-Container1  .Content-para2{
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 1.5vw;
    font-family:  "space-Groteskr" !important;
}


.img-Container2{
    width: 40%;
    // border: 1px solid green;
}
.img-Container2 img{
    max-width: 100%;
    height: auto;
    width: 60%;
}
.Content-Container2{
    width: 60%;
    // border: 1px solid blue;
    position: relative;
    align-self: center;
}
.Content-Container2  .Content-para1{
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    opacity: 1;
    font-size: 2.5vw;
    font-family:  "space-Grotesksb" !important;
}
.Content-Container2  .Content-para2{
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    opacity: 1;
    font-size: 1.5vw;
    font-family:  "space-Groteskr" !important;
}



.img-Container3{
    width: 40%;
    // border: 1px solid green;
}
.img-Container3 img{
    max-width: 100%;
    height: auto;
    width: 50%;
}
.Content-Container3{
    width: 60%;
    // border: 1px solid blue;
    position: relative;
    align-self: center;
}
.Content-Container3  .Content-para1{
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    opacity: 1;
    font-size: 2.5vw;
    font-family:  "space-Grotesksb" !important;
}
.Content-Container3  .Content-para2{
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    opacity: 1;
    font-size: 1.5vw;
    font-family:  "space-Groteskr" !important;
}



.img-Container4{
    width: 40%;
    // border: 1px solid green;
}
.img-Container4 img{
    max-width: 100%;
    height: auto;
    width: 50%;
}
.Content-Container4{
    width: 60%;
    // border: 1px solid blue;
    position: relative;
    align-self: center;
}
.Content-Container4  .Content-para1{
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    opacity: 1;
    font-size: 2.5vw;
    font-family:  "space-Grotesksb" !important;
}
.Content-Container4  .Content-para2{
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    opacity: 1;
    font-size: 1.5vw;
    font-family:  "space-Groteskr" !important;
}