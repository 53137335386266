
.Main_Analytics_services{
    background-image: linear-gradient(to right, #021d4a, #041535);
}

.Analytics_banner{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
.Analytics_banner img{ 
    position: relative; 
    width: 100%;
    // height: 100vh;
}
.inner_Analytics {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%, -55%);
}
.inner_Analytics h1{
    line-height: 100%;
    font-size: 8vw;
    opacity: 1;
    background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "space-Groteskb" !important;
    font-weight: 600;
}
.inner_Analytics .Pre-para1{
            // border: 1px solid blue;
            color: #FF6600;
            opacity: 1;
            letter-spacing: 0px;
            font-family: "space-Grotesksb" !important;
            font-size: 2vw;
}
.inner_Analytics .Pre-para2{
    // border: 1px solid yellow; 
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    font-family:"space-Groteskr" !important;
    font-size: 1.5vw;
}

.Slide-Analytics{
    position: relative;
    left: 0px;
    width: 100%;
    height: auto;
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    background: transparent linear-gradient(359deg, #00122E 0.39%, #000 14.46%, #013143 34.38%, #00072D 66.4%, #182878 78.89%, #010101 99.5%) 0% 0% no-repeat padding-box;
    opacity: 1;
    top: 0px;
  }    


  /* For mobile phones: */
  @media  only screen and (max-width: 480px) {
    .Analytics_banner{
        display: none;
    }

    
    .Analytics_banner_mobile{
        position: relative;
        height: 100vh;
        // border: 1px solid red;
    }

    .Analytics_banner_mobile img{
        width: 100vw;
        // height: 100vh;
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        width: 100vw;
        height: 80vh;
        background-size: cover;
        object-fit: cover;
        object-position: 75% 60%;
    }

    .inner_Analytics_mobile{
        position: absolute;
        top: 20%;
        left: 0%;
        transform: translate(0%, -40%);
        width: 100vw;
    }
    .inner_Analytics_mobile h1{
        line-height: 100%;
        font-size: 14vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:"space-Groteskb" !important;
        font-weight: 600;
        // line-height: 80px;
        text-align: center;
    }
    .inner_Analytics_mobile .Pre-para1{
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family: "space-Grotesksb" !important;
        font-size: 18px;
        padding: 0% 2%;
        text-align: center;
    }

    .centee-btn-btn{
        text-align: -webkit-center;
        padding: 1% 0% 1% 0%;
    }
    .centee-btn{
        position: absolute;
        bottom: 18%;
        width: 100vw;
        text-align: -webkit-center;
    }
    .Main_Analytics_services {
        background-image: linear-gradient(to right, #04131a, #04131a);
    }
    .Slide-Analytics {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
         border-top-right-radius: 0%; 
         border-top-left-radius: 0%; 
        background: transparent linear-gradient(359deg, #00122E 0.39%, #000 14.46%, #013143 34.38%, #00072D 66.4%, #182878 78.89%, #010101 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }
  }

  @media  only screen and (min-width: 768px){
    .Analytics_banner_mobile{
        display: none;
    }
}
  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .Analytics_banner{
        display: none;
    }

    
    .Analytics_banner_mobile{
        position: relative;
    }

    .Analytics_banner_mobile img{
        width: 100vw;
        height: 85vh;
        background-size: cover;
        object-fit: cover;
        top: 0px !important;
        position: relative;
        background-size: cover;
        object-fit: cover;
        object-position: 75% 60%;
    }
    .centee-btn-btn{
        text-align: -webkit-center;
        padding: 1% 0% 1% 0%;
    }
    .inner_Analytics_mobile{
        position: absolute;
        top: 25%;
        left: 0%;
        transform: translate(0%, -40%);
        width: 100vw;
    }
    .inner_Analytics_mobile h1{
        line-height: 100%;
        font-size: 14vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:"space-Groteskb" !important;
        font-weight: 600;
        text-align: center;
    }
    .inner_Analytics_mobile .Pre-para1{
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family: "space-Grotesksb" !important;
        font-size: 18px;
        padding: 0% 2%;
        text-align: center;
    }

    .centee-btn{
        position: absolute;
        bottom: 15%;
        width: 100vw;
        text-align: -webkit-center;
    }
    .Main_Analytics_services {
        background-image: linear-gradient(to right, #04131a, #04131a);
    }
    .Slide-Analytics {
        position: relative;
        left: 0px;
        width: 100%;
        height: auto;
         border-top-right-radius: 0%; 
         border-top-left-radius: 0%; 
        background: transparent linear-gradient(359deg, #00122E 0.39%, #000 14.46%, #013143 34.38%, #00072D 66.4%, #182878 78.89%, #010101 99.5%) 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 0px;
    }
  }

  
@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .Main_Analytics_services {
        background-image: linear-gradient(to right, #06171e, #071b22);
    }

    .Industries h1 {
        padding: 0% 0% 0% 0%;
        font-size: 14vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
    }
}

@media  only screen and (min-width: 1024px) and (max-width: 1399px) {
    .Main_Analytics_services {
        background-image: linear-gradient(to right, #06171e, #071b22);
    }
    
}