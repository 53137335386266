.Main-BA{
    padding: 5% 0% 5% 0%;
    overflow: hidden;
}
.Main-BA h1{
    padding: 0% 0% 2% 0%;
    font-size: 4vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
}
.Main-BA p{
    font-size: 1.2vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    color: #FFFFFF;
    font-weight: lighter;
    line-height: 200%;
    padding: 0% 10% 5% 10%;
    margin-top: 0%;
    margin-bottom: 0%;
}


@media  only screen and (max-width: 480px) {
    .Main-BA{
        padding: 10% 2% 10% 2%;
    }
    .Main-BA h1{
        padding: 0% 0% 2% 0%;
        font-size: 11vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    }
    .Main-BA p{
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        color: #FFFFFF;
        font-weight: lighter;
        line-height: 200%;
        padding: 0% 10% 10% 10%;
        margin-top: 0%;
        margin-bottom: 10%;
    }
    
}
@media  only screen and (min-width: 481px) and (max-width: 767px){
    .Main-BA{
        padding: 10% 2% 10% 2%;
    }
    .Main-BA h1{
        padding: 0% 0% 2% 0%;
        font-size: 11vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    }
    .Main-BA p{
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        color: #FFFFFF;
        font-weight: lighter;
        line-height: 200%;
        padding: 0% 10% 10% 10%;
        margin-top: 0%;
        margin-bottom: 10%;
    }
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .Main-BA h1 {
        padding: 0% 0% 2% 0%;
        font-size: 8vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    }
    .Main-BA p{
        font-size: 1.5vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        color: #FFFFFF;
        font-weight: lighter;
        line-height: 200%;
        padding: 0% 10% 5% 10%;
        margin-top: 0%;
        margin-bottom: 0%;
    }
}