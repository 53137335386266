.align {
    text-align: center;
    font-size: 48px;
    font-family: "Poppins-Bold";
    color: #0F326C;
}

.sub_class_sitemap {
    /* list-style: none; */
}

.sub_class_sitemap li {
    margin-left: 24px;
}

.sitemap_container_main {
    margin-top: 12px;
    margin-bottom: 16px;
}

.sitemap_container {
    padding: 0px 80px;
    margin: 0;
}

.list_container {
    margin: 0;
    padding: 0px 24px;
}

.space {
    padding: 17px;
    font-size: Poppins-Medium;
}

.size_1 {
        font-family: "space-Groteskb" !important;
    font-size: 18px;
    color: #0F326C;
}

.size {
        font-family: "space-Groteskb" !important;
    font-size: 18px;
    color: #0F326C;
}

.size:hover {
    color: #F05F30;
}

.card {
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

.children {
        font-family: "space-Groteskm" !important;
    font-size: 18px;
    color: #0F326C;
    cursor: pointer;
    font-weight: 400;
    line-height: 23px !important;
}

.children:hover {
    color: #F05F30;
}

@media only screen and (max-width: 767px) {
    .sitemap_container_main {
        padding: 12px 24px 24px 24px;
    }

    .sitemap_container {
        padding: 0px 24px;
        margin: 0;
    }

    .list_container {
        margin: 0;
        padding: 0px;
    }

    .align {
        font-size: 24px;

    }

    .space {
        font-size: 14px;
    }

    .size,
    .size_1 {
        font-size: 18px;
    }

    .children {
        font-size: 14px;
    }
}

@media only screen and (max-width: 580px) { 
    .size, .size_1 {
        font-size: 16px;
    }
    .align {
        font-size: 22px;
    }

}