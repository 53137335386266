.main-OurStack{
    display: flex;
    padding: 5% 0% 5% 0%;
    overflow: hidden;
}
.OS-head{
    width: 50%;
    padding: 3% 4% 0% 4%;
}
.OS-head h1{
    padding: 0% 0% 0% 0%;
    font-size: 6vw;
    text-align: left;
    color: white;
    font-family:  "space-Groteskb" !important;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
}
.OS-head .para1{
    font-size: 1.4vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    padding: 3% 0% 3% 0%;
    margin: 0 !important;

}
.OS-head .para2{
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    letter-spacing: 1.44px;
    padding: 3% 0% 3% 0%;
}
.OS-lang{
    width: 60%;
    display: inline-flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-content: space-around;
    padding: 0% 5% 0% 5%;
}
.Os-box{
    width: 18%;
    background-color: #264c6a;
    text-align-last: center;
    margin: 2% 1% 2% 1%;
    border-radius: 15px;
}
.Os-box img {
    max-width: 100%;
    height: auto;
    width: 50%;
}


@media  only screen and (max-width: 480px) {
    .main-OurStack{
        display: block;
        padding: 10% 0% 10% 0%;
    }
    .OS-head{
        width: 100%;
        padding: 5% 4% 5% 4%;
    }
    .OS-head h1{
        padding: 0% 0% 0% 0%;
        font-size: 11vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    }
    .OS-head .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 0%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .OS-head .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .OS-lang{
        width: 100%;
        display: inline-flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        align-content: space-around;
        padding: 0% 5% 0% 5%;
    }
    .Os-box{
        width: 20%;
        background-color: #264c6a;
        text-align-last: center;
        margin: 2% 1% 2% 1%;
        border-radius: 15px;
    }
    .Os-box img {
        max-width: 100%;
        height: auto;
        width: 100%;
    }
}

@media  only screen and (min-width: 481px) and (max-width: 767px){
    .main-OurStack{
        display: block;
        padding: 10% 0% 10% 0%;
    }
    .OS-head{
        width: 100%;
        padding: 5% 4% 5% 4%;
    }
    .OS-head h1{
        padding: 0% 0% 0% 0%;
        font-size: 11vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    }
    .OS-head .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 0%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .OS-head .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .OS-lang{
        width: 100%;
        display: inline-flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        align-content: space-around;
        padding: 0% 5% 0% 5%;
    }
    .Os-box{
        width: 20%;
        background-color: #264c6a;
        text-align-last: center;
        margin: 2% 1% 2% 1%;
        border-radius: 15px;
    }
    .Os-box img {
        max-width: 100%;
        height: auto;
        width: 100%;
    }
}
@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .OS-head h1 {
        font-size: 8vw;
    }
    .OS-head .para1 {
        font-size: 1.5vw;
    }
    .OS-head .para2 {
        font-size: 2.5vw;
    }
    .OS-head {
        width: 50%;
        padding: 0% 0% 0% 4%;
    }
    .Os-box img {
        width: 80%;
    }
    .Os-box {
        width: 22%;
        background-color: #264c6a;
        text-align-last: center;
        margin: 2% 1% 2% 1%;
        border-radius: 15px;
    }
    .main-OurStack {
        display: flex;
        padding: 5% 0% 5% 0%;
        align-items: center;
    }
    
}