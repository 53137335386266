
.accordion-container {
    width: 80%;
    margin: 0 auto;
  }

  .accordion-item.open {
     border-color: #FF6600; 
     border-bottom: 2px solid #FF6600;
     .animated{
        color: #FF6600;
      }



  }
  
  .accordion-heading {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .accordion-heading:hover {
    color: #FF6600;

  }
  .accordion-item{
    text-decoration: none;
    border-bottom: 1px solid #cccccc;
    padding: 5% 0% 0% 0%;
    }
  .accordion-item::after{
    content: '';
    display: block;
    width: 0;    
    height: 2px;
    background: #FF6600;
    transition: width 0.3s ease;
    border-bottom: 2px solid #FF6600;
    }
  .accordion-item:hover::after{
    width: 100%; 
    border-bottom: 2px solid #FF6600;
  }
  .accordion-item:hover{
    // width: 100%; 
    border-bottom: 1.5px solid #FF6600;
    .animated{
        color: #FF6600;
      }
  }
  .accordion-heading h2 {
    margin: 0;
    text-decoration: underline;
    text-decoration-color: transparent; /* Initial underline color */
    transition: text-decoration-color 0.3s ease-in-out;
    font-family:  "space-Groteskr" !important;

  }
  

.accordion-paragraph {
    display: none;
    padding: 0%;
    font-size: 1.1vw;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 0% 2%;
    line-height: 200%;
    cursor: pointer;
    letter-spacing: 0.96px;
}
  
  
  .accordion-item.open .accordion-paragraph {
    display: block;
  }
  



  
@media  only screen and (max-width: 480px) {
  .accordion-heading h2 {
    margin: 0;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s ease-in-out;
    font-family:  "space-Groteskr" !important;
    font-size: 18px !important;
}
    .accordion-paragraph {
      display: none;
      padding: 0%;
      font-size: 16px !important;
      text-align: left;
      font-family:  "space-Groteskr" !important;
      opacity: 1;
      color: #FFFFFF;
      font-weight: lighter;
      margin: 0% 2%;
      line-height: 140% !important;
      cursor: pointer;
      letter-spacing: 0.96px;
  }
}



@media  only screen and (min-width: 768px){
  .Main-Types-mobile{
      display: none;
  }
}
@media  only screen and (min-width: 481px) and (max-width: 767px){
  .accordion-heading h2 {
    margin: 0;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s ease-in-out;
    font-family:  "space-Groteskr" !important;
    font-size: 18px !important;
}
    .accordion-paragraph {
      display: none;
      padding: 0%;
      font-size: 16px !important;
      text-align: left;
      font-family:  "space-Groteskr" !important;
      opacity: 1;
      color: #FFFFFF;
      font-weight: lighter;
      margin: 0% 2%;
      line-height: 140% !important;
      cursor: pointer;
      letter-spacing: 0.96px;
  }
}





@media  only screen and (min-width: 768px) and (max-width: 1023px) {
  .accordion-heading h2 {
    margin: 0;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s ease-in-out;
    font-family:  "space-Groteskr" !important;
    font-size: 18px;
  }

  .accordion-paragraph {
    display: none;
    padding: 0%;
    font-size: 16px;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 0% 2%;
    line-height: 140%;
    cursor: pointer;
    letter-spacing: 0.96px;
  }
}