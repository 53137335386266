.UseCases-Main1{
    // border: 1px solid red;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-top: 5%;
    margin-top: 5%;
    // display: flex;
}
.UseCases-Main1 h1 {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    // text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4901960784);
    opacity: 1;
    line-height: 100%;
    font-size: 9vw;
    font-family:  "space-Groteskb" !important;
    // padding-top: 10%;
    padding-top: 5% !important;
}
.UseCases-Main1 p {
    font-size: 1.4vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    margin-left: 16%;
    margin-right: 16%;
    padding-top: 5%;
}