
.jobList_career{
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 6%;
}
.divider_career{
height: 1px;
width:100%;
margin: 10px;
background: #00CCC5;
}
.developmet_career .text1{
    color: #fff;
    opacity: 999;
    width: fit-content;
    height: 32px;
    background:rgba(245, 242, 242, 0.20000000298023224);
    padding: 6px;
    margin: 5px;
}
.globe_career{
    height: 25px;
    width: 25px;
}


.joblist-container .container {
    max-width: 100%;
}

.labels-career-open {
    color: white;
    text-align: left;
    font-size: 22px;
    font-weight: 500;
    font-family: "Poppins-Medium";
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 24px;
}
.labels-career-open .job_list_view{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.labels-career-open .job_title{
    padding-left: 22px;
}
.modal-content .close::after {
    display: none;
}

.modal-content .close::before {
    display: none;
}

.section-get-center {
    height: auto;
    padding: 1.5rem 1.5rem 0rem 1.5rem;
}

.modal_content_apply {
    .modal-content{
        background: linear-gradient(180deg, #182878 0%, #0C143C 100%) !important;
        width: 80%;
        border-radius: 2%;
        margin-left: 8%;
    }
}


@media screen and (max-width:820px) {
    .modal_content_apply {
        .modal-content{
            margin-top: 22%;
        }
    }
}


.modal_apply{
    color: #ffffff;
    border-radius: 5px;
    text-align: left;
    padding: 20px;
    font-family:  "space-Groteskr" !important;
    background: linear-gradient(180deg, #182878 0%, #0C143C 100%) !important;
  }



.modal-dialog {
    top: 10%;
}

.text-box {
    width: 100%;
    font-size: 14px;
    font-family:  "space-Groteskr" !important;
    color: #ffff;
    margin-bottom: 45px;
    border: none;
    border-bottom: 1px solid #ffffff;
    outline: none;
    background: transparent;
    background-color: transparent;
    font-weight: lighter;
}
 .text-box:focus{
    border-bottom: 1px solid #FF6600;
}
/*
.text-box::selection {
    border-bottom: 1px solid #FF6600;
} */

.form-career input {
    font-family:  "space-Groteskr" !important;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    height: 30px;
}


.details-message {
    font-family:  "space-Grotesksb" !important;
    font-weight: 600;
    font-size: 24px;
    color: #f05f30;
    margin-top: 3%;
    padding-left: 5%;
    padding-right: 5%;
}

.section-get-center .form-career {
    text-align: left;
}

.section-get-center .form-career label {
    padding: 0;
}

.section-get-center .form-career input[type="file"]::file-selector-button {
    content: 'Upload Resume';
    border: 1px solid #FF6600;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    background: transparent;
    transition: 1s;
    color: #fff;
}
.modal_close{
    border: 1px solid #F05F30;
    padding: 10px 12px 10px 12px;
    border-radius: 5px;
    background: transparent;
    color: #F05F30;
    font-family:  "space-Groteskr" !important;
    background-image: linear-gradient(to left, transparent, transparent 50%, #F05F30 50%, #F05F30);
    background-position: 100% 0;
    background-size: 200% 100%;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .5s ease-in;
}
.modal_close:hover{
    background-position: 0 0; 
    box-shadow: 0px 8px 6px #00000047;
    border-radius: 5px;
    border: 1px solid #F05F30;
    color: #fff;
}
.error_text {
    font-size: 11px;
    color: red;
    padding: 0 0rem !important;
    margin-top: -18px;
    font-family:  "space-Groteskr" !important;
}

.model_box_close_btn {
    float: right;
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
    top: -4px;
    position: relative;
    color: #fff;
}

.model_box_close_btn:hover {
    color: #f05f30;
}
.apply_btn_apply{
    border: 1px solid #F05F30;
    padding: 10px 12px 10px 12px;
    border-radius: 5px;
    background: transparent;
    color: #fff;
    margin-top: 4%;
    font-family:  "space-Groteskr" !important;
    background-image: linear-gradient(to left, transparent, transparent 50%, #F05F30 50%, #F05F30);
    background-position: 100% 0;
    background-size: 200% 100%;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all .5s ease-in;
}
.apply_btn_apply:hover{
    background-position: 0 0; 
    box-shadow: 0px 8px 6px #00000047;
    border-radius: 5px;
    border: 1px solid #F05F30;
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    background-color: transparent;
    background: transparent;
} */

@media all and (max-width: 1023px) {
    .apply_btn_apply{
        border-radius: 5px;
        border: 1px solid #F05F30;
        color: #fff;
        background-color: #FF6600;
    }
    .modal_close{
        border-radius: 5px;
        border: 1px solid #F05F30;
        color: #fff;
        background-color: #FF6600;
    }
}
@media screen and (max-width: 1184px) {
    .file-upload-button{
        width: 45% !important;
    }
}
@media screen and (max-width:480px) {
    .file-upload-button{
        width: 62% !important;
    }
}


.arrow_career{
    width: 12px;
    height: 12px;
    border-top: 2px solid #F05F30;
    border-right: 2px solid #F05F30;
    cursor: pointer;
    transform: rotate(135deg);
}
@media screen and (min-width:768px) and (max-width:819px)  { 
    .labels-career-open{
      font-size: 20px;
    }

    @media all and (max-width:480px) {
        .jobList_career-mobile {
            max-width: 100%;
            padding-right: 7%;
            padding-left: 7%;
        } 
    }

   }

    .file-upload-button {
        position: relative;
        background: #080833 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2392156863);
        font-size: 14px;
        font-family:  "space-Groteskr" !important;
        color: #FFFFFF;
        display: flex;
        align-items: left;
        justify-content: left;
        /* border: 1px solid #FF6600; */
        padding: 5px 10px 5px 10px;
        border-radius: 1px;
        background: #fff;
        transition: 1s;
        color: #ff6a3d;
        width: 35%;
        margin-bottom: 2%;
        cursor: pointer;
}
.file-upload-button span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #00BFFF;
    cursor: pointer;
    color: #fff;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
}
.file-upload-button input {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }