/* .our_packages {
  background-color: #DEF0F7;
}
.our_packages_contact {
  background-color: #DEF0F7;
} */
/* Scroll bar hide but scrolling */
.our_packages .value_add_box div {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow-y: scroll; 
}
.our_packages .value_add_box div::-webkit-scrollbar {
  display: none; 
}

.our_packages .value_add_box{
  height: auto;
}
.our_packages .para {
  font-size: 16px;
  color: #fff;
}
.our_packages .heading {
  text-align: center;
}
.our_packages .needs-heading-center{
  /* font-family: "Inter", sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #1A2238;
  /* padding: 0 6rem; 
  text-align: center; */
  /* padding: 0 6rem; */
  text-align: center;
}
.our_packages .row{
  margin-left: 0;
  margin-right: 0;
}
.our_packages .ourpackage_btn{
  margin: 0 auto;
  text-align: center;
}
.our_packages .ourpackage_btn a{
  background-color: #f9f9f9;
  border-radius: 12px;
  /* border: 1px solid #ECF0F3; */
  /* box-shadow: rgb(149 157 165 / 20%) 4px 4px 10px; */
  /* line-height: 45px; */
  white-space: nowrap;
  padding: 10px 36px;
  color: #1A2238;
}
.our_packages .ourpackage_btn a:hover{
  text-decoration: none;
  color: #FF6A3D;
}


/* Tablet menu */
@media all and (min-width: 700px) {
  .our_packages .value_add_box {
    /* margin: 10px 0px; */
    width: 100%;
    height: 320px;
}
.our_packages .para {
  font-family: "Montserrat-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #F9F9F9;
  padding: 0;
  text-align: center;
}
.our_packages .heading {
    /* color: white; */
    margin: 1.2rem 0;
    text-align: center;
    padding-bottom: 0;
    font-size: 22px;
}
.our_packages .value_add_box div {
  height: 240px;
  overflow: auto;
}

.our_packages .value_add_box .scroll_height_value_add {
  height: 185px;
  overflow: auto;
}

}
/* Tablet END */


/* Desktop View */
@media all and (min-width: 960px) {  
  .our_packages .value_add_box {
    background-color: #219EBC;
    border: 1px solid #219EBC;
    border-radius: 10px;
    height: 375px;
    /* width: 220px;
    margin: 0px 70px; */
  }
  .our_packages .value_add_box div {
    height: auto;
  }
  .our_packages .value_add_box .scroll_height_value_add {
    height: auto;
  }
  .our_packages .heading {
    /* color: white; */
    margin: 1.5rem;
    text-align: center;
    padding-bottom: 0;
    font-size: 26px;
}
.our_packages .para {
  font-family: "Montserrat-Regular";
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #F9F9F9;
  padding: 1rem;
  text-align: center;
}
 }
/* Desktop View END */


/* New version */
.our_packages{
  /* background-image: url('../../fonts/images/how_we_add_value_bck_1.png'); */
  background-image: url('../../fonts/images/how_we_add_value.png');
  transform-origin: 0% 100%;
  margin-top: -5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  transform: skew(0deg, -1deg);
}
.our_packages_content{
  transform: skew(0deg, 1deg);
  padding: 84px 80px 0px 80px;
}
.our_packages_section { 
  max-width: 100%;
  transform-origin: 100% 0%;
  padding: 0px 0px 0px;
}
.value_content{
  padding-bottom: 56px;
}
.value_head{
  font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    color: #fff;
    line-height: 36px;
}
.value_para{
    font-family: "Poppins-Regular";
    font-size: 16px;
    color: #fff;
    padding: 0;
    line-height: 27px;
    font-weight: 400;
    font-synthesis: none;
    text-align: justify;
    margin: 0;
}


/* ipad Air */
@media (min-width: 820px) and (max-width: 920px) { 
  .our_packages_content{
    padding: 84px 24px 0px 24px;
  }
  .add_value_align .col-md-8{
    max-width: 100%;
  }
  
}

@media only screen and (max-width: 768px) { 
.our_packages_content{
  padding: 84px 24px 0px 24px;
}
.our_packages_section { 
  max-width: 100%;
  padding: 0px;
  margin: 0;
}
.our_packages_section .col-md-8, .our_packages_section .col-md-4 {
  padding: 0;
}
.value_head{
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 8px;
  text-align: center;
}
.value_para{
  font-size: 14px;
}
.value_content{
  padding-bottom: 32px;
}
.value_img_container{
  padding-bottom: 16px;
}
.our_packages .row{
  display: flex;
}
.our_packages_section .col-md-8{
  margin: 0 auto;
}

}


@media only screen 
 and (min-device-width: 768px) 
 and (max-device-width: 1024px) 
 and (-webkit-min-device-pixel-ratio: 1) { 
  .our_packages .row{
    display: flex;
  }
  .our_packages .row .col-md-2{
    max-width: 100%;
    flex: 0 0 25%;
  }
  .value_head{
    text-align: left;
  }

 }

 
@media only screen and (min-width: 1900px) { 
  .our_packages_content{
    padding: 114px 120px 0px 120px;
  }
  .value_head {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #fff;
    line-height: 48px;
}
.value_para {
  font-family: "Poppins-Regular";
  font-size: 20px;
  color: #fff;
  padding: 0;
  line-height: 36px;
  font-weight: 400;
  font-synthesis: none;
  text-align: justify;
  margin: 0;
}

}

@media screen and (min-width:768px) and (max-width:819px)  { 
  .our_packages_content{
    padding: 84px 24px 0px 24px;
  }
} 