.WhatWeOffer-main{
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0% 0% 5% 0%;
}

.WhatWeOffer-content{
    padding: 5% 5% 5% 5%;

}
.WhatWeOffer-content h1{
    padding: 0% 0% 0% 0%;
    font-size: 12vw;
    text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
}
.WhatWeOffer-content .para1{
    font-size: 1.4vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding: 1% 12% 1% 12%;
}
.WhatWeOffer-content .para2{
    font-size: 2vw;
    color: white;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}



.WhatWeOffer-Box h1{
    font-family:  "space-Grotesksb" !important;
    font-size: 1.5vw;
    color: white;
    text-align: center;
    letter-spacing: 0px;
    margin: 3% 5% 2% 5%;

}
.WhatWeOffer-Box p{
    font-size: 1.2vw;
    text-align: center;
    font-family:  "space-Groteskr" !important;
    opacity: 1;
    color: #FFFFFF;
    font-weight: lighter;
    margin: 5% 2%;
    line-height: 200%;
}
.WhatWeOffer-OutsideBox{
    padding: 0% 0% 5% 0%;
}
.InsideBox1{
    display: flex;
    justify-content: center;
    gap: 2%;
    padding: 5% 0% 3% 0%;
}

.InsideBox2{
    display: flex;
    justify-content: center;
    gap: 2%;
    padding: 5% 0% 3% 0%;
}

.InsideBox3{
    display: flex;
    justify-content: center;
    gap: 2%;
    padding: 5% 0% 3% 0%;
}

.InsideBox1 .WhatWeOffer-Box{
    opacity: 1;
    backdrop-filter: blur(30px);
    background-color: #3345539c;
    -webkit-backdrop-filter: blur(30px);
    width: 22vw;
    border-radius: 20px;    
    text-align: -webkit-center;
    // overflow: hidden;

}
.InsideBox2 .WhatWeOffer-Box{
    opacity: 1;
    backdrop-filter: blur(30px);
    background-color: #3345539c;
    -webkit-backdrop-filter: blur(30px);
    width: 22vw;
    border-radius: 20px;    
    text-align: -webkit-center;
    // overflow: hidden;


}
.InsideBox3 .WhatWeOffer-Box{
    opacity: 1;
    backdrop-filter: blur(30px);
    background-color: #3345539c;
    -webkit-backdrop-filter: blur(30px);
    width: 22vw;
    border-radius: 20px;    
    text-align: -webkit-center;
    // overflow: hidden;


}
.icon-circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #334553;
    color: #FF6600;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 32px;
    font-family:  "space-Groteskb" !important;
    padding: 5%;
    position: relative;
    bottom: 50px;
}
.icon-circle img {
    max-width: 100%;
    height: auto;
    width: 70%;
}

@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .WhatWeOffer-main{
        display: none;
    }
    .WhatWeOffer-content {
        padding: 10% 5% 5% 5%;
    }
    .WhatWeOffer-content .para1 {
        font-size: 1.6vw;
        padding: 1% 8% 1% 8%;
        letter-spacing: 0.96px;
    }
    .WhatWeOffer-content .para2 {
        font-size: 2.2vw;
    }

    .WhatWeOffer-OutsideBox{
        // border: 1px solid red;
    }
    .InsideBox1{
        display: flex;
        justify-content: center;
        gap: 2%;
        padding: 5% 0% 3% 0%;
    }
    
    .InsideBox2{
        display: flex;
        justify-content: center;
        gap: 2%;
        padding: 5% 0% 3% 0%;
    }
    
    .InsideBox3{
        display: flex;
        justify-content: center;
        gap: 2%;
        padding: 5% 0% 3% 0%;
    }
    .InsideBox4{
        display: flex;
        justify-content: center;
        gap: 2%;
        padding: 5% 0% 3% 0%;
    }
    .InsideBox5{
        display: flex;
        justify-content: center;
        gap: 2%;
        padding: 5% 0% 3% 0%;
    }
    
    .InsideBox1 .WhatWeOffer-Box{
        opacity: 1;
        backdrop-filter: blur(30px);
        background-color: #3345539c;
        -webkit-backdrop-filter: blur(30px);
        width: 30vw;
        border-radius: 20px;    
        text-align: -webkit-center;
        // overflow: hidden;
    
    }
    .InsideBox2 .WhatWeOffer-Box{
        opacity: 1;
        backdrop-filter: blur(30px);
        background-color: #3345539c;
        -webkit-backdrop-filter: blur(30px);
        width: 30vw;
        border-radius: 20px;    
        text-align: -webkit-center;
        // overflow: hidden;
    
    
    }
    .InsideBox3 .WhatWeOffer-Box{
        opacity: 1;
        backdrop-filter: blur(30px);
        background-color: #3345539c;
        -webkit-backdrop-filter: blur(30px);
        width: 30vw;
        border-radius: 20px;    
        text-align: -webkit-center;
        // overflow: hidden;
    }
    .InsideBox4 .WhatWeOffer-Box{
        opacity: 1;
        backdrop-filter: blur(30px);
        background-color: #3345539c;
        -webkit-backdrop-filter: blur(30px);
        width: 30vw;
        border-radius: 20px;    
        text-align: -webkit-center;
        // overflow: hidden;
    }
    .InsideBox5 .WhatWeOffer-Box{
        opacity: 1;
        backdrop-filter: blur(30px);
        background-color: #3345539c;
        -webkit-backdrop-filter: blur(30px);
        width: 30vw;
        border-radius: 20px;    
        text-align: -webkit-center;
        // overflow: hidden;
    }
    .icon-circle {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        background-color: #334553;
        color: #FF6600;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        font-size: 32px;
        font-family:  "space-Groteskb" !important;
        padding: 5%;
        position: relative;
        bottom: 50px;
    }
    .icon-circle img {
        max-width: 100%;
        height: auto;
        width: 70%;
    }
    .icon-circle img {
        max-width: 100%;
        height: auto;
        width: 60%;
    }
    .WhatWeOffer-Box h1 {
        font-family:  "space-Grotesksb" !important;
        font-size: 1.6vw;
        color: white;
        text-align: center;
        letter-spacing: 0px;
        margin: -10% 5% 2% 5%;
    }
    .WhatWeOffer-Box p {
        font-size: 1.4vw;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 5% 2%;
        line-height: 200%;
    }

    .WhatWeOffer-OutsideBox{
        padding: 0% 0% 5% 0%;
    }
}



@media  only screen and (max-width: 480px) {
    .WhatWeOffer-main{
        display: none;
    }
    .WhatWeOffer-main-tablet{
        display: none;
    }
    .WhatWeOffer-main-mobile{
        background: #001220 0% 0% no-repeat padding-box;
        padding: 0% 0% 10% 0%;
    }
    .WhatWeOffer-content-mobile{
        padding: 5% 2% 5% 2%;
    }
    .WhatWeOffer-content-mobile h1{
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 8%;
        padding-bottom: 0%;
    }
    .WhatWeOffer-content-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .WhatWeOffer-content-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 0% 2% 0%;
        padding: 2% 0% 2% 0%;
    }
    .InsideBox-main-mobile{
        text-align: -webkit-center;
        padding: 15% 0% 5% 0%;

    }
    .WhatWeOffer-Box{
        opacity: 1;
        backdrop-filter: blur(30px);
        background-color: rgba(51, 69, 83, 0.6117647059);
        -webkit-backdrop-filter: blur(30px);
        width: 75vw;
        border-radius: 10px;
        margin: 0% 0% 20% 0%;
        text-align: -webkit-center;
    }
    .WhatWeOffer-Box h1{   
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        color: white;
        text-align: center;
        letter-spacing: 0px;
        margin: -6% 5% 2% 5%;
    }
    .WhatWeOffer-Box P{ 
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 0% 2% 0% 2%;
        line-height: 200%;
        padding: 2% 0% 5% 0%;

    }
    .icon-circle {
        height: 85px;
        width: 85px;
        border-radius: 50%;
        background-color: #334553;
        color: #FF6600;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        font-size: 32px;
        font-family:  "space-Groteskb" !important;
        padding: 5%;
        position: relative;
        bottom: 50px;
    }
    .icon-circle img {
        max-width: 100%;
        height: auto;
        width: 70%;
    }
    

}



@media  only screen and (min-width: 768px){
    .WhatWeOffer-main-mobile{
        display: none;
    }
}
@media  only screen and (min-width: 1024px){
    .WhatWeOffer-main-tablet{
        display: none;
    }
}
  @media  only screen and (min-width: 481px) and (max-width: 767px){
    .WhatWeOffer-main{
        display: none;
    }
    .WhatWeOffer-main-tablet{
        display: none;
    }
    .WhatWeOffer-main-mobile{
        background: #001220 0% 0% no-repeat padding-box;
        padding: 0% 0% 10% 0%;
    }
    .WhatWeOffer-content-mobile{
        padding: 5% 2% 5% 2%;
    }
    .WhatWeOffer-content-mobile h1{
        font-size: 12.5vw;
        text-align: center;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 8%;
        padding-bottom: 0%;
    }
    .WhatWeOffer-content-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .WhatWeOffer-content-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 0% 2% 0%;
        padding: 2% 0% 2% 0%;
    }
    .InsideBox-main-mobile{
        text-align: -webkit-center;
        padding: 15% 0% 5% 0%;

    }
    .WhatWeOffer-Box{
        opacity: 1;
        backdrop-filter: blur(30px);
        background-color: rgba(51, 69, 83, 0.6117647059);
        -webkit-backdrop-filter: blur(30px);
        width: 75vw;
        border-radius: 10px;
        margin: 0% 0% 20% 0%;
        text-align: -webkit-center;
    }
    .WhatWeOffer-Box h1{   
        font-family:  "space-Grotesksb" !important;
        font-size: 18px;
        color: white;
        text-align: center;
        letter-spacing: 0px;
        margin: -6% 5% 2% 5%;
    }
    .WhatWeOffer-Box P{ 
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        opacity: 1;
        color: #FFFFFF;
        font-weight: lighter;
        margin: 0% 2% 0% 2%;
        line-height: 200%;
        padding: 2% 0% 5% 0%;
    }
    .icon-circle {
        height: 85px;
        width: 85px;
        border-radius: 50%;
        background-color: #334553;
        color: #FF6600;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        font-size: 32px;
        font-family:  "space-Groteskb" !important;
        padding: 5%;
        position: relative;
        bottom: 50px;
    }
    .icon-circle img {
        max-width: 100%;
        height: auto;
        width: 70%;
    }
  }