.Contact_mains{
    background: transparent linear-gradient(359deg, #010e22, #000 14.2%,  #00072d  34.3%, #013143 66.6%, #071452 79.2%, #010101);    width: 100%;
    height: auto;
}

.Borderr{
    border-radius: 35px;
    opacity: 0.6;
}
.borderr-Mobile{
    border-radius: 15px;
    opacity: 0.6;
    
}
// @media  only screen and (min-width: 1024px){
@media  only screen and (min-width: 769px){

    .Contact_mainHead{
        width: 100%;
        height: auto;
        position: relative;
        padding-bottom: 10%;
        display: flex;
    }
    .Contact_mainHead-inside {
    }
    .Contact_mainHead-inside1 {
        width: 50%;
        position: relative;
        transform: translate(14%, 30%);
    }
    .Contact_mainHead-inside2 {
        // border: 1px solid red;
        width: 40%;
        position: relative;
        left: 10%;
        margin-top: 3%;
    }
    .Contact_mainHead-inside1 h1{
        line-height: 100%;
        font-size: 10.7vw;
        opacity: 1;
        background: linear-gradient(97deg, #FF6600 0%, #00CCC5 100%);
        background-clip: border-box;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family:  "space-Groteskb" !important;
        padding-bottom: 3%;
    }
    
    .Contact_mainHead-inside1 .Contactpara1{
        color: #FF6600;
        opacity: 1;
        letter-spacing: 0px;
        font-family:  "space-Grotesksb" !important;
        font-size: 1.5vw;
        padding-bottom: 0%;
        margin-bottom: 0.5%;
    }
    .Contact_mainHead-inside1 .Contactpara2{
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        font-size: 1.2vw;
        color: var(--unnamed-color-ffffff);
        text-align: left;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        font-weight: lighter;
    }

}



.Contact-Loc{
    padding-top: 10%;
    padding-top: 10%;
}
.Contact-Location{
    // border: 1px solid red;
    margin-top: 10%;
    margin-bottom: 5%;
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
}
.Contact-Location-right{
    // border: 1px solid blue;
    width: 60%;
    padding-bottom: 3%;
    padding-left: 3%;

}
.Contact-Location-right h1{
    font-size: 11vw;
    // text-align: center;
    color: white;
    font-family:  "space-Groteskb" !important;
    padding-top: 0%;
    padding-bottom: 0%;
}
.Contact-Location-insideBox1{
    // border: 1px solid blue;
    display: flex;
    padding-bottom: 4%;
    width: 50vw;
    padding-top: 5%;
}
.Contact-Location-insideBox3{
    // border: 1px solid blue;
    display: flex;
    padding-bottom: 4%;
    width: 50vw;
    padding-top: 5%;
}
.Contact-Location-insideBox2{
    // border: 1px solid blue;
    display: flex;
    width: 50vw;
}

.insideBox1{
    // border: 1px solid red;
    width: 50%;
}
.insideBox1 h1{
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 1.6vw
}
.insideBox1 p{
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 1.1vw;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    color: #FFFFFF;
    padding-left: 0%;
    font-weight: lighter;
    padding-right: 5%;
}
.insideBox2{
    // border: 1px solid red;
    width: 50%;
}
.insideBox2 h1{
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 1.6vw
}
.insideBox2 p{
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 1.1vw;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    color: #FFFFFF;
    font-weight: lighter;
    padding-right: 11%;
}
.insideBox3{
    // border: 1px solid red;
    width: 50%;

}
.insideBox3 h1{
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 1.6vw
}
.insideBox3 p{
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 1.1vw;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    color: #FFFFFF;
    font-weight: lighter;
    padding-right: 24%;
}
.insideBox4{
    // border: 1px solid red;
    width: 50%;

}
.insideBox4 h1{
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0px;
    font-family:  "space-Grotesksb" !important;
    font-size: 1.6vw
}
.insideBox4 p{
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 1.1vw;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    color: #FFFFFF;
    font-weight: lighter;
    padding-right: 5%;
}

.Contact-Location-left{
    width: 40%;
    // border: 1px solid yellow;
}
.Contact-Location-left img{
    max-width: 100vw;
    height: auto;
    width: 40vw;
    padding-top: 20%;
    right: 15%;
    position: relative;
}

.silicon-card{
    border: 1px solid red;
    text-align-last: center;
    padding-bottom: 10%;
    padding-top: 5%;

}
.silicon-card img {
    max-width: 100%;
    height: auto;
    width: 90%;
}

.silicon-para1{
    opacity: 1;
    font-family:  "space-Groteskr" !important;
    font-size: 2.5vw;
    color: #FFFFFF;
    font-weight: lighter;
    // border: 1px solid red;
    left: 5%;
    width: 70%;
    position: relative;
    padding-top: 3%;

}
.silicon-card-inside{
    border: 1px solid yellow; 
}
.silicon-card-inside img{
    max-width: 100%;
    height: auto;
    width: 10vw;
}
.silicon-card-out{
border: 1px solid green;
padding-top: 10%;
padding-bottom: 10%;
}

.image-container {
    width: 80vw;
    height: 40vw;
    position: relative;
    left: 10%;
}
.image-container img {
    max-width: 100%;
    height: auto;
    width: 100%;
    cursor: pointer;
}
.contentimg img {
    max-width: 100%;
    height: auto;
    width: 10%;
    left: 5%;
    position: relative;
    cursor: pointer;

}

//   .image {
//     width: 100%;
//     height: 100%;
//     object-fit: cover; 
//   }
  .contentimg {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -75%);
    color: white;
    font-size: 1.2vw;
    // border: 1px solid red;
    width: 100%;
    cursor: pointer;
  }
  
  .contact-Readmore { 
    left: 5%;
    position: relative;
    padding-top: 2%;
    padding-bottom: 3%;
  }
  .contact-Readmore a{
    border: 1px solid #FF6600;
    padding: 1% 1% 1% 1%;
    font-family:  "space-Groteskr" !important;
    font-size: 1.2vw;
    text-align: left;
    color: #FFFFFF;
    font-weight: lighter;
    border-radius: 5px;
    background-image: linear-gradient(to left,
    transparent,
    transparent 50%,
    #FF6600 50%,
    #FF6600);
    background-position: right bottom;
    background-position: 100% 0;
    background-size: 200% 100%;
    transition: all .4s ease-in;     
    .rotateSilicon{
        transform: scale(1) rotateZ(180deg);
        transition: all .4s ease-in;  
    }
}
.contact-Readmore a:hover{
    background-position: 0 0; 
    .rotateSilicon{
        transform: rotateZ(135deg) scale(1);
        background-position: 0 0; 
    }

}


@media  only screen and (max-width: 480px){

    .Contact_mainHead-inside1-mobile {
        transform: translate(0%, 20%)!important;
    }
    .Contact_mainHead-inside1-mobile h1 {
        padding-bottom: 0.5% !important;
        font-size: 18vw !important;
    }
    .Contact_mainHead-inside1-mobile .Contactpara2-mobile {
        font-size: 16px !important;
    }
    .Contact-login-box .Contact-user-box input {
        padding-top: 0% !important;
        font-size: 3.5vw !important;
        margin-bottom: 5% !important;
    }
    .Contact-login-box .Contact-user-boxx textarea {
        font-size: 3vw !important;
        margin-bottom: 4% !important;
        height: 18vw !important;
        border-bottom: 1px solid #ffffff !important;
        border-radius: 0px;
    }
    .send-btn-contact-mobile {
        padding-top: 2% !important;
        padding-bottom: 2% !important;
        padding-left: 2.5% !important;
        padding-right: 4.5% !important;
        font-size: 4vw !important;
    }
    .Contact-Location-mobile {
        margin-top: 10% !important;
        margin-bottom: 15% !important;
    }

    .error_text-Contact {
        font-size: 2.6vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -4% !important;
        margin-left: 20% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
    .error_Product {
        font-size: 2.6vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -7% !important;
        margin-left: 4% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
    .contact-Readmore-mobile a{
        border: 1px solid #FF6600;
        padding: 5% 4.5% 3.5% 4% !important;
        font-family:  "space-Groteskr" !important;
        font-size: 1.2vw;
        text-align: left;
        color: #FFFFFF;
        font-weight: lighter;
        border-radius: 5px;
        background-image: linear-gradient(to left,
        transparent,
        transparent 50%,
        #FF6600 50%,
        #FF6600);
        background-position: right bottom;
        background-position: 100% 0;
        background-size: 200% 100%;
        transition: all .4s ease-in; 
        .rotateSilicon-mobile{
          transform: scale(0.9) rotateZ(135deg) !important;
        }    
      }
}




// 
@media  only screen and (min-width: 481px) and (max-width: 768px){
    .error_text-Contact {
        font-size: 2.6vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -4% !important;
        margin-left: 20% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
    .error_Product {
        font-size: 2.6vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -4% !important;
        margin-left: 7% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
}
@media  only screen and (min-width: 769px) and (max-width: 1024px){
    .error_text-Contact {
        font-size: 1.6vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -4% !important;
        margin-left: 4% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
    .error_Product {
        font-size: 1.6vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -5% !important;
        margin-left: 4% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
    .Contact-login-box .Contact-user-box input {
        padding: 8% 1% 0.5% 2% !important;
    }
    .Contact-login-box .Contact-user-boxx textarea {
        padding: 1% 0% 1.5% 1% !important;
        margin-bottom: 6% !important;
        margin-top: 4% !important;
    }
    .Contact-login-box .Contact-user-box input {
        padding: 3% 0% 0.5% 1% !important;
        font-size: 2.2vw !important;
    }
    .input-color::placeholder {
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font-size: 2vw;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0px;
        color: #FFFFFF;
        background: transparent;
        opacity: 1;
        padding: 2% 0% 0% 0%;
        opacity: 0.6;
      }
}
@media  only screen and (min-width: 1025px){
    .error_text-Contact {
        font-size: 1.2vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -2% !important;
        margin-left: 4% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
    .error_Product {
        font-size: 1.2vw !important;
        line-height: 20px !important;
        color: #F03030 !important;
        margin-top: -4% !important;
        margin-left: 4% !important;
        text-align-last: start !important;
        padding-bottom: 2% !important;
    }
    .input-color::placeholder {
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font-size: 1.2vw;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0px;
        color: #FFFFFF;
        background: transparent;
        opacity: 1;
        padding: 0% 0% 0% 0% !important;
        opacity: 0.6;
      }
      .Contact-login-box .Contact-user-boxx textarea {
        width: 83%;
        padding: 1% 0% 1.5% 1%;
        font-size: 1.5vw;
        font-family:  "space-Groteskr" !important;
        border-radius: 5px;
        color: white;
        margin-bottom: 4%;
        border: none;
        height: 8vw !important;
        border-bottom: 1px solid #ffffff;
        outline: none;
        background: transparent;
        background-color: transparent;
        font-weight: lighter;
    }
    .Contact_mainHead-inside2 {
        width: 40%;
        position: relative;
        left: 10%;
        margin-top: 5% ;
    }
    .Contact-login-box .Contact-user-box input {
        width: 83%;
        padding: 1% 0% 0.3% 1% !important;
        font-size: 1.5vw;
        font-family:  "space-Groteskr" !important;
        color: white;
        margin-bottom: 4%;
        border: none;
        border-bottom: 1px solid #ffffff;
        outline: none;
        background: transparent;
        background-color: transparent;
        font-weight: lighter;
    }
}
@media  only screen and (min-width: 1366px) and (max-width: 1566px){
    .Contact_mainHead-inside1 {
        transform: translate(10%, 25%) !important;
    }
    .Contact-login-box .Contact-user-boxx textarea {
        height: 8vw !important;

    }
}