
.Main-BrandAssciation{
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;
}




.brand-content{
    padding: 5% 0% 5% 0%;
}


.brand-content .head{
    text-align: center;
    letter-spacing: 3.2px;
    color: #EBF5F5;
    opacity: 1;
    opacity: 1;
    font-family:  "space-Groteskb" !important;
    font-size: 4.5vw;
}

.brand-content .para1{
    font-size: 1.3vw;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 20%;
    margin-right: 20%;
}

.brand-content .para2{
    font-family: "space-Groteskb" !important;
    font-size: 1.4vw;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1.44px;
    text-transform: uppercase;
}


.imgTable{
    width: 100%;
    padding: 5% 0% 10% 0%;
}
table {
    margin: 0 auto;
    border-collapse: collapse;
    border-style: hidden;
    width: 80%;
    /*Remove all the outside
    borders of the existing table*/
}
table td {
    padding: 0.5rem;
    border: 1px solid #FF6600;
    padding: 2% 0% 2% 0%;
    position: relative;
    text-align: -webkit-center;
}

.imgSM1 img {
    max-width: 100%;
    height: auto;
    width: 25%;

}
table td img {
    max-width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
table td img:hover {
    max-width: 100%;
    height: auto;
    // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    // filter: grayscale(50%);
}
table th {
    // padding: 1.5rem;
    border: 1px solid #FF6600;
    // padding: 2% 0% 2% 0%;
    position: relative;
    text-align: -webkit-center;
    // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    // filter: grayscale(100%);
}




.thImg{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.thImg img{
        max-width: 100%;
        height: auto;
        width: 80%;
        padding: 5%;
}





.imgHover {
    position: relative;
    overflow: hidden;
    width: 10vw;
    height: 60px;
    // border: 1px solid red;
    margin: 10% 0%;
    text-align: -webkit-auto;
  }

  .imgHover img {
    max-width: 100%;
    height: auto;
    width: 100%;
    cursor: pointer;
    padding: 5% 0%;
}
  
  .image {
    position: absolute;
    transition: transform 0.4s ease-in-out;
    // position: absolute;
    // width: 100%; /* Ensure the image takes up 100% of its container */
    // height: 100%; /* Ensure the image takes up 100% of its container */
    // object-fit: cover; /* Maintain the aspect ratio and cover the container */
    // transition: transform 0.3s ease-in-out;
  }
  
  .secondimg {
    transform: translateY(-100%);
  }
  
  .imgHover.hovered .firstimg {
    transform: translateY(100%);
  }
  
  .imgHover.hovered .secondimg {
    transform: translateY(0);
  }

.imgsmall{
    // width: 100%;
}
// th {
//     width: 150px; /* Set a specific width for the table cells */
//     height: 200px; /* Set a specific height for the table cells */
//   }










@media  only screen and (min-width: 1400px){
    .imgTable-Desk{
        display: none;
        }
}
// @media  only screen and (min-width: 1023px){
//     .imgTable-Desk{
//         display: none;
//         }
// }
@media  only screen and (max-width: 480px){
    .Main-BrandAssciation{
        display: none;
    }
.Brand-Mobile{
    background: #001220 0% 0% no-repeat padding-box;
    opacity: 1;  
    padding: 5% 0% 5% 0%;
    overflow: hidden;
}
.brand-content-Mobile{
    padding: 5% 0% 5% 0%;
}
    
.brand-content-Mobile .head{
    text-align: center;
    letter-spacing: 3.2px;
    color: #EBF5F5;
    opacity: 1;
    font-family:  "space-Groteskb" !important;
    font-size: 45px;
}

.brand-content-Mobile .para1{
    font-size: 16px;
    text-align: center;
    font-family: "space-Groteskr" !important;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 20%;
    margin-right: 20%;
}

.brand-content-Mobile .para2{
    font-family: "space-Grotesksb" !important;
    font-size: 18px;
    text-shadow: 0px 9px 38px #000000;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    padding: 0% 6% 0% 6% !important;

}
.Brand-img-img  {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
.Brand-img-img img {
    max-width: 100%;
    height: auto;
    width: 75%;
    padding: 2% 2%;
}
.imgTable-Mobile{
    width: 100%;
    padding: 5% 0% 20% 0%;
}
}



@media  only screen and (min-width: 768px){
    .Brand-Mobile{
        display: none;
    }
  }


@media  only screen and (min-width: 1024px){
    .imgTable-tab{
        display: none;
    }
}

@media  only screen and (min-width: 768px) and (max-width: 1023px){
    .imgTable{
        display: none;
    }
    .imgTable-Desk{
        display: none;
    }
    .imgTable-tab{
        width: 100%;
        padding: 5% 0% 10% 0%;
    }
    table {
        margin: 0 auto;
        border-collapse: collapse;
        border-style: hidden;
        width: 90%;
    }
    .brand-Tablet img {
        max-width: 100%;
        height: auto;
        width: 80%;
        padding: 5% 0%;
    }
}
@media  only screen and (min-width: 481px) and (max-width: 767px){
    .Main-BrandAssciation{
        display: none;
    }
    .Brand-Mobile{
        background: #001220 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 5% 0% 5% 0%;
        overflow: hidden;
    }
    .brand-content-Mobile{
        padding: 5% 0% 5% 0%;
    }
        
    .brand-content-Mobile .head{
        text-align: center;
        letter-spacing: 3.2px;
        color: #EBF5F5;
        opacity: 1;
        font-family:  "space-Groteskb" !important;
        font-size: 45px;
    }
    
    .brand-content-Mobile .para1{
        font-size: 16px;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        margin-left: 20%;
        margin-right: 20%;
    }
    
    .brand-content-Mobile .para2{
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        text-shadow: 0px 9px 38px #000000;
        color: #ffffff;
        text-align: center;
        letter-spacing: 1.44px;
        text-transform: uppercase;
        padding: 0% 6% 0% 6% !important;
    
    }
    .Brand-img-img  {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    .Brand-img-img img {
        max-width: 100%;
        height: auto;
        width: 75%;
        padding: 2% 2%;
    }
    .imgTable-Mobile{
        width: 100%;
        padding: 5% 0% 20% 0%;
    }
    
}

@media  only screen and (min-width: 1024px) and (max-width: 1399px){
    .imgTable{
        display: none;
    }
    table {
        width: 85% !important;
    }
    .imgTable-Desk{
        width: 100%;
        padding: 5% 0% 10% 0%;
    }
}