.blog__landing__container {
  padding: 0px 80px;
  background-color: #000;
  position: relative;
  padding-top: 35px;
}

.landing_banner {
  text-align: left;
}

.blogs-heading {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #FFFFFF;
}

.section-head {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #eceaec;
  padding: 16px 0px;
}

.section-para {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: left;
  color: #eceaec;
  padding-bottom: 16px;
}

.blog-image {
  text-align: center;
}

.recent-head {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 48px;
  color: #FFFFFF;
}

@media screen and (max-width: 768px) {
  .recent-head {
    padding-bottom: 20px;
    padding-top: 30px;
    font-size: 20px
  }
}


.recent_para {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}

.recent_img {
  padding-bottom: 8px;
  cursor: pointer;
}

.recent_img img {
  border: solid 0px #EFEFEF;
}

.recent_img:hover img {
  border: solid 0px #CCC;
  -moz-box-shadow: 1px 1px 8px 0px #ffffff;
  -webkit-box-shadow: 1px 1px 8px 0px #ffffff;
  box-shadow: 1px 1px 8px 0px #ffffff;
}

.recent_para:hover {
  color: #fff;
}

.recent_view {
  padding: 16px 0px;
}

.recent-section-blog-title {
  font-size: 16px;
  color: white;
  font-family: "poppins-Medium";
  line-height: 27px;
}

.author_name_mob {
  font-size: 16px;
  font-family: 'Poppins-Medium';
}




@media screen and (max-width: 768px) {
  .recent-section-blog-title {
    font-size: 14px;
    color: white;

  }
}

.space_landing {
  justify-content: space-between;
}

/* .space_landing .col-md-8, .space_landing .col-md-3{
  padding: 0;
} */
.recent_tabs {
  /* border-left: 1px solid #8B8B8B; */
  /* max-height: 380px; */
  padding: 0px 0px 0px 80px;
  /* overflow-y: scroll; */
}
.recent_tabs {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.recent_tabs::-webkit-scrollbar { 
  display: none;
}

.recent_view_container {
  max-height: 650px;
  overflow: hidden;
}
.recent_tabs::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.recent_tabs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 4px 6px 6px 3px rgb(0 0 0 / 72%);
  background-color: #ffffff;
}

.recent_tabs::-webkit-scrollbar-thumb {
  background-color: #F05F30;
  border: 0px solid #abaaaa;
  border-radius: 15px;
  box-shadow: inset 4px 6px 6px 3px rgb(240, 98, 45);
}



.recent_img img {
  max-width: 100%;
}

.section-para ol,
.section-para li {
  margin-left: 25px;
}

.author-img-height {
  height: 2.5rem;
  border-radius: 50%;
}

.img_space {
  margin-left: -40px;
  vertical-align: middle;
  width: 70px;
}

.height-fix {
  font-size: 14px;
  font-family: 'Poppins-Regular';
  font-weight: 500;
  color: #909C9D;
  padding: 0px 0px 0px 50px;
}

.height-fix-date {
  font-size: 12px;
  font-weight: 400;
}

.height-fix-main {
  padding: 0px 0px 0px 30px;
}


.like-section {
  text-align: end;
  display: flex;
  margin-bottom: 45px;
  padding: 0px;
  /* justify-content: end; */
}

.like-section1 {
  text-align: end;
  display: flex;
  margin-bottom: 45px;
  padding: 0px;
  justify-content: flex-end;
  color: #FFFFFF;
  right: -11px
}


@media screen and (max-width: 768px) {}

.recent_tabs {
  display: block;
}

.recent_tabs_mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .recent_tabs {
    display: none;
  }

  .recent_tabs_mobile {
    display: block;
  }

  .like-section {
    text-align: end;
    display: flex;
    margin-bottom: 20px;
    padding: 0px;
  }

  .like-section1 {
    text-align: end;
    display: flex;
    margin-bottom: 20px;
    padding: 0px;
    justify-content: flex-start;
    font-family: 'Poppins-Regular';
  }

  .space_landing {
    max-width: 100%;
    margin: 0 auto;
  }

}

.like-section span {
  margin-left: 5px;
  color: #FFFFFF;
}

.margin_div_section {
  margin: 0px 10px 0px 0px;
  font-family: "Poppins-Regular";
}

.short_description_landingpage {
  font-size: 18px;
  color: #909C9D;
  padding-bottom: 20px;
  font-family: 'Poppins-Light';
}

.published_dt {
  font-family: "Poppins-Regular";
  font-size: 14px;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.published_dt .col-md-6:nth-child(1) {
  flex: 0 0 60%;
  width: 60%;
}

.published_dt .col-md-6:nth-child(2) {
  flex: 0 0 40%;
  width: 40%;
}

.blog_page_line {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .published_dt {
    font-family: "Poppins-Regular";
    font-size: 11px;
    justify-content: space-between;
    text-align: left;
  }
}

.recent_tabs::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.recent_tabs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 4px 6px 6px 3px rgb(0 0 0 / 72%);
  background-color: #ffffff;
}

.recent_tabs::-webkit-scrollbar-thumb {
  background-color: #F05F30;
  border: 0px solid #abaaaa;
  border-radius: 15px;
  box-shadow: inset 4px 6px 6px 3px rgb(240, 98, 45);
}

.blog-author-section {
  color: white;
  align-items: center;
  padding-bottom: 45px;
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .blog-author-section {
    padding-bottom: 20px;

  }
}


.blog-img-recent-section {
  max-width: 64px;
  height: 64px;
  border-radius: 50%;
}

.description_section {
  /* max-height: 410px; */
  padding: 24px 80px 0px 16px;
  /* overflow-y: scroll; */
}

@media screen and (max-width: 768px) {
  .description_section {
    max-height: initial;
    padding: 0 0px 0px 5px;
    overflow: hidden;
  }
}

.description_section::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.description_section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 4px 6px 6px 3px rgb(0 0 0 / 72%);
  background-color: #ffffff;
}

.description_section::-webkit-scrollbar-thumb {
  background-color: #F05F30;
  border: 0px solid #abaaaa;
  border-radius: 15px;
  box-shadow: inset 4px 6px 6px 3px rgb(240, 98, 45);
}


.landing_sticky {
  /* position: absolute;
    width: 100%;
    height: 65px;
    left: 0px;
    bottom: 0;
    background: white;
    color: red; */
  /* overflow: hidden;
    background-color: #ffffff;
	position: -webkit-sticky;
	position: sticky;
	top: 0; */
  position: absolute;
  width: 822px;
  height: 40px;
  left: 0px;
  top: 418px;
  background: #000000;
  color: white;
  /* height: auto; */
  /* background: white; */
  box-shadow: 0px -3px 9px rgb(77 74 74 / 62%);
  padding: 8px 0px;
}

.sticky-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sticky-element {
  text-align: center;
}

.section-para ul,
.section-para ol {
  text-indent: 0.1rem;
  margin-left: 1.5rem;
}

pre code {
  font-size: 16px;
  color: #fff;
  word-break: normal;
}

code {
  font-size: 18px !important;
  color: #fff !important;
  word-wrap: break-word;
}

.blog-image img {
  width: 100%;
}

.author_name {
  font-family: 'Poppins-Medium';
  font-size: 18px;
}

.recent_view_blog {
  align-items: center;
  justify-content: flex-start;
}
.recent_tabs {
  right: 0;
}


@media screen and (max-width: 768px) {
  .short_description_landingpage {
    font-size: 16px;
  }

  .blog__landing__container {
    padding: 24px;
  }

  .blog__landing__container .space {
    padding: 0;
    margin: 0 auto;
  }

  .blog__landing__container .space .col-md-6,
  .blog__landing__container .space .col-md-4 {
    padding: 0;
  }

  .height-fix {
    line-height: 20px !important;
  }

  .height-fix-1 {
    padding: 0px 0px 0px 12px;
    text-align: center;
  }

  .blogs-heading {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  .section-head,
  .section-para {
    font-size: 16px;
    line-height: 27px;
  }

  .recent_tabs {
    border: none;
    min-height: auto;
    overflow: hidden;
    padding: 0px;
  }

  .recent_view_container {
    min-height: auto;
    overflow: hidden;
  }

  .landing_sticky {
    width: 100%;
    bottom: 0;
    left: 0;
    position: fixed;
    /* top: 0 !important; */
    height: 40px;
  }

  .author-img-height {
    width: 100%;
    border-radius: 50%;
    height: auto;
  }

  .blog-author-section .col-2 {
    max-width: 30%;
    flex: 0 0 30%;
  }

  .recent_view_blog {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    margin: 0;
  }

  .recent_view_blog .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 0px;
    text-align: center;
  }

  .recent_view_blog .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    text-align: left;
    padding: 0;
  }

  .date_reading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .date_reading .col-md-6:nth-child(1) {
    flex: 0 0 50%;
  }

  .date_reading .col-md-6:nth-child(2) {
    flex: 0 0 50%;
  }


}


@media screen and (min-width: 1000px) {
  .description_section {
    /* max-height: 375px; */
  }

  .recent_tabs {
    /* max-height: 375px; */
    padding: 0px 0px 0px 40px;
  }

  .landing_sticky {
    width: 64.7%;
    top: 375px;
  }
}

@media screen and (min-width: 1200px) {
  .description_section {
    /* max-height: 375px; */
  }

  .recent_tabs {
    /* max-height: 375px; */
    padding: 0px 0px 0px 40px;
  }

  .landing_sticky {
    width: 64.7%;
    top: 383px;
  }

  .author-img-height {
    height: auto;
    border-radius: 50%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1300px) {
  .description_section {
    /* max-height: 460px; */
  }

  .recent_tabs {
    /* max-height: 460px; */
    padding: 24px 0px 24px 40px;
    position: absolute;
    right: 0;
  }

  .landing_sticky {
    width: 65.0%;
    top: 457px;
  }
}

@media screen and (min-width: 1400px) {
  .description_section {
    /* max-height: 450px; */
  }

  .recent_tabs {
    /* max-height: 450px; */
  }

  .landing_sticky {
    width: 65.0%;
    top: 457px;
  }
}

@media screen and (min-width: 1500px) {
  .description_section {
    /* max-height: 790px; */
  }

  .recent_tabs {
    /* max-height: 570px; */
  }

  .recent_view_blog {}

  .landing_sticky {
    width: 65.0%;
    top: 558px;
  }
}

@media screen and (min-width: 1900px) {
  .description_section {
    /* max-height: 950px; */
  }

  .blogs-heading {
    font-family:  "space-Grotesksb" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
  }

  .recent_tabs {
    /* max-height: 8200px; */
  }

  .landing_sticky {
    width: 65.25%;
    top: 658px;
  }

  .recent-head {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    color: #FFFFFF;
    padding-bottom: 16px;
  }

  .recent-section-blog-title {
    font-size: 20px;
    color: white;
    font-family: "poppins-Medium";
    line-height: 36px;
  }

  .author_name_mob {
    font-size: 18px;
    font-family: 'Poppins-Medium';
    padding: 0px 0px 0px 35px;
  }

  .img_space {
    margin-left: -38px;
    vertical-align: middle;
    margin-bottom: 6px;
  }

  .short_description_landingpage {
    font-size: 24px;
    color: #909C9D;
    padding-bottom: 20px;
    font-family: 'Poppins-Light';
    line-height: 36px;
  }

  .section-para {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    text-align: left;
    color: #eceaec;
    padding-bottom: 16px;
  }
  .height-fix {
    font-size: 16px;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    color: #909C9D;
    padding: 0px 0px 0px 50px;
  }
  
  .height-fix-date {
    font-size: 14px;
    font-weight: 400;
  }
  .recent_tabs {
    overflow-y: hidden;
  }


}


@media screen and (max-width: 480px) {
  .height-fix {
    font-size: 14px;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    color: #909C9D;
    padding: 0px 0px 0px 30px;
    max-width: 70%;
    flex: 0 0 70%;
  }

  .recent-section-blog-title {
    line-height: 21px;
  }

  .date_reading{
    max-width: 100%;
  }

}

@media screen and (max-width: 355px) {
  .height-fix {
    padding: 0px 0px 0px 15px;
  }

}

@media screen and (max-width: 340px) {
  .height-fix {
    padding: 0px 0px 0px 12px;
  }

}

@media screen and (max-width: 330px) {
  .height-fix {
    padding: 0px 0px 0px 10px;
  }
  .date_reading{
    max-width: 86%;
  }

}

@media screen and (max-width: 342px) { 
  .blog-author-section .date_placement {
    max-width: 36%;
    flex: 0 0 36%;
}
.date_reading{
  max-width: 100%;
} 
}



.date_placement {
  text-align: right;
}

.posted__date_date {
  font-family: "Poppins-Regular";
}

@media screen and (max-width: 768px) {
  .author_name {
    font-size: 12px;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .date_placement {
    text-align: left;
  }
  .blog-author-section .date_placement {
    max-width: 36%;
    flex: 0 0 36%;
}
}

@media screen and (min-width:768px) and (max-width:819px)  {  
  .blog__landing__container{
    padding: 24px 24px;
  }
  .space_landing {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
  .description_section {
    max-height: initial;
    padding: 0 0px 0px 5px;
    overflow: hidden;
    max-width: 100%;
}
  .recent_tabs {
    border: none;
    min-height: auto;
    overflow: hidden;
    padding: 0px;
    max-width: 75%;
}
}

@media screen and (min-width:900px) and (max-width:1024px)  {  
  .blog-author-section .col-md-1{
    max-width: 16%;
    flex: 0 0 16%;
  }
  .author_name{
    max-width: 50%;
    flex: 0 0 50%;
  }
  .date_placement{
    max-width: 23%;
    flex: 0 0 23%;
  }
  .published_dt{
    flex-wrap: nowrap;
  }
}