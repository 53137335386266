.main-EvenionDiff{
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
    overflow: hidden;
    background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box; 
    opacity: 1;
    padding: 5% 0%;
}
.evenionDiff-content{
    border-top-right-radius: 12vw;
    border-top-left-radius: 12vw;
}


.evenionDiff-content {
    padding: 5% 10% 0% 10%;
    // background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
}
.evenionDiff-content h1{
    padding: 0% 0% 0% 0%;
    font-size: 8vw;
    text-align: left;
    color: white;
    font-family:  "space-Groteskb" !important;
}
.evenionDiff-content .para1{
    font-size: 1.4vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding: 1% 0% 1% 0%;
}
.evenionDiff-content .para2{
    font-size: 2vw;
    color: white;
    text-align: left;
    font-family:  "space-Groteskr" !important;
    padding-top: 1%;
    padding-bottom: 1%;
}
.evenionDiff-box{
    padding: 0% 5% 5% 5%;
    text-align: -webkit-center;
}
.evenionDiff-box .box1{
    border: 2px solid #FF6600;
    border-radius: 20px;
    width: 80vw;
    margin: 5% 0%;
    display: flex;
    height: 23vw;
    transition: all .3s ease-in;  
    overflow: hidden;  
    cursor: pointer;
    .content-box{
        width: 60vw;
        padding: 5% 1% 5% 2%;
    }
     .content-box h1{
        margin: 0;
        font-size: 2vw;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
    
    }
     .content-box p{
        font-size: 1.2vw;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        color: #FFFFFF;
        font-weight: lighter;
        line-height: 200%;
    }
    .img-box1{
        width: 40vw;
        padding: 2% 2%;   
        position: relative;
        text-align: -webkit-center; 
        align-self: center;
    }
     .img-box1 img {
        max-width: 100%;
        height: auto;
        width: 80%;
        color: transparent;
        justify-content: center;
        display: inline-table;
        display: static;
        position: relative;
        opacity: 0;
        transform: rotateZ(-45deg);
        transition: all .3s ease-in;    
        filter: drop-shadow(1px 3px 3px black);
    }
    
}




.evenionDiff-box .box1:hover{
    border: 2px solid transparent;
    border-radius: 20px;
    background-color: #001220;
    width: 80vw;
    margin: 5% 0%;
    display: flex;
    height: 23vw;
    overflow: hidden;
    cursor: pointer;
    .content-box{
        width: 60vw;
        padding: 5% 1% 5% 2%;
    }
    
     .content-box h1{
        margin: 0;
        font-size: 2vw;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        padding-top: 1%;
        padding-bottom: 1%;
    
    }
    
     .content-box p{
        font-size: 1.2vw;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        color: #FFFFFF;
        font-weight: lighter;
        line-height: 200%;
    }
     .img-box1{
        width: 40vw;
        padding: 2% 2%;
        position: relative;
        text-align: -webkit-center;
        align-self: center
        ;
    }
    
     .img-box1 img {
        max-width: 100%;
        height: auto;
        width: 100%;
        transform: rotateZ(0deg);
        color: white;
        opacity: 1;
    }

}



@media  only screen and (max-width: 480px) {
    .main-EvenionDiff{
       display: none; 
    }

    .main-EvenionDiff-mobile{
        background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 5% 0%;
    }
    .evenionDiff-content-mobile{
        padding: 5% 7%;
    }
    .evenionDiff-content-mobile h1{
        font-size: 9.5vw;
        text-align: left;
        color: white;
        font-family:  "space-Groteskb" !important;
        padding-top: 3%;
        padding-bottom: 2%;
    }
    .evenionDiff-content-mobile .para1{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        margin: 2% 4% 2% 0%;
        padding: 2% 0% 2% 0%;
        letter-spacing: 0.64px;
        line-height: 200%;
    }
    .evenionDiff-content-mobile .para2{
        color: white;
        text-align: center;
        font-family:  "space-Groteskr" !important;
        font-size: 18px;
        letter-spacing: 1.44px;
        line-height: 170%;
        margin: 2% 4% 2% 4%;
        padding: 2% 0% 2% 0%;
    }
    .evenionDiff-box-mobile{
        text-align: -webkit-center;
    }
    .evenionDiff-box-mobile .boxDigi{
        background: #001220 0% 0% no-repeat padding-box;
        border-radius: 10px;
        width: 85vw;
        margin: 10% 0%;
    }
    .evenionDiff-box-mobile .boxDigi h1{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 0.96px;
        color: #FFFFFF;
        opacity: 1;
        font-family:  "space-Groteskr" !important;
        text-align: left;
        padding: 10% 8% 2% 8%;
        line-height: 200%;
    }
    .evenionDiff-box-mobile .boxDigi p{
        font-size: 16px;
        color: white;
        text-align: left;
        font-family:  "space-Groteskr" !important;
        letter-spacing: 0.64px;
        line-height: 200%;
        padding: 2% 8% 2% 8%;
    }
    .imgDigi {
        padding: 5%;

    }
    .imgDigi img {
        max-width: 100%;
        height: auto;
        width: 100%;
    }
}

@media  only screen and (min-width: 481px) and (max-width: 767px){
    .main-EvenionDiff{
        display: none; 
     }
 
     .main-EvenionDiff-mobile{
         background: transparent linear-gradient(0deg, #043353 0%, #001A2D 60%, #030A1C 100%) 0% 0% no-repeat padding-box;
         opacity: 1;
         padding: 5% 0%;
     }
     .evenionDiff-content-mobile{
         padding: 5% 7%;
     }
     .evenionDiff-content-mobile h1{
         font-size: 9.5vw;
         text-align: left;
         color: white;
         font-family:  "space-Groteskb" !important;
         padding-top: 3%;
         padding-bottom: 2%;
     }
     .evenionDiff-content-mobile .para1{
         font-size: 16px;
         color: white;
         text-align: left;
         font-family:  "space-Groteskr" !important;
         margin: 2% 4% 2% 0%;
         padding: 2% 0% 2% 0%;
         letter-spacing: 0.64px;
         line-height: 200%;
     }
     .evenionDiff-content-mobile .para2{
         color: white;
         text-align: center;
         font-family:  "space-Groteskr" !important;
         font-size: 18px;
         letter-spacing: 1.44px;
         line-height: 170%;
         margin: 2% 4% 2% 4%;
         padding: 2% 0% 2% 0%;
     }
     .evenionDiff-box-mobile{
         text-align: -webkit-center;
     }
     .evenionDiff-box-mobile .boxDigi{
         background: #001220 0% 0% no-repeat padding-box;
         border-radius: 10px;
         width: 85vw;
         margin: 10% 0%;
     }
     .evenionDiff-box-mobile .boxDigi h1{
         font-size: 20px;
         font-weight: bolder;
         letter-spacing: 0.96px;
         color: #FFFFFF;
         opacity: 1;
         font-family:  "space-Groteskr" !important;
         text-align: left;
         padding: 10% 8% 2% 8%;
         line-height: 200%;
     }
     .evenionDiff-box-mobile .boxDigi p{
         font-size: 16px;
         color: white;
         text-align: left;
         font-family:  "space-Groteskr" !important;
         letter-spacing: 0.64px;
         line-height: 200%;
         padding: 2% 8% 2% 8%;
     }
     .imgDigi {
         padding: 5%;
 
     }
     .imgDigi img {
         max-width: 100%;
         height: auto;
         width: 100%;
     }
}

@media  only screen and (min-width: 768px){
    .main-EvenionDiff-mobile{
        display: none;
    }
}
  
@media  only screen and (min-width: 1024px) {
    .evenionDiff-box-tablet{
        display: none;
    }
}


@media  only screen and (min-width: 768px) and (max-width: 1023px) {
    .evenionDiff-box{
        display: none;
    }
    .evenionDiff-content .para1 {
        font-size: 1.6vw;
        letter-spacing: 1px;
    }
    .evenionDiff-content h1 {
        font-size: 8.5vw;
    }
    .evenionDiff-content .para2 {
        font-size: 2.5vw;
        letter-spacing: 0.44px;
    }

    .evenionDiff-box-tablet {
        padding: 0% 5% 5% 5%;
        text-align: -webkit-center;
    }
    .evenionDiff-box-tablet .box1{
        border: 2px solid transparent;
        border-radius: 20px;
        background-color: #001220;
        width: 90vw;
        margin: 5% 0%;
        display: flex;
        overflow: hidden;
        cursor: pointer;
        .content-box{
            width: 60vw;
            padding: 5% 1% 5% 2%;
        }
        
         .content-box h1{
            margin: 0;
            font-size: 2.2vw;
            color: white;
            text-align: left;
            font-family:  "space-Groteskr" !important;
            padding-top: 1%;
            padding-bottom: 1%;
        
        }
        
         .content-box p{
            font-size: 1.5vw;
            text-align: left;
            font-family:  "space-Groteskr" !important;
            color: #FFFFFF;
            font-weight: lighter;
            line-height: 200%;
            letter-spacing: 0.96px;
        }
         .img-box1{
            width: 40vw;
            padding: 2% 2%;
            position: relative;
            text-align: -webkit-center;
            align-self: center
            ;
        }
         .img-box1 img {
            max-width: 100%;
            height: auto;
            width: 100%;
        }
    
    }
}