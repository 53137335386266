.precisely_card{
    background-color: #283474 0% 0% no-repeat padding-box;
    background: #283474 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
}


.inside_card1{
    padding: 5% 0% 5% 0%;
    text-align: center;
}
.inside_card1 img{
    max-width: 100%;
    height: auto;
    width: 70%;
    cursor: pointer;
    filter: drop-shadow(2px 20px 25px black);
}
.youtube-logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.youtube-logo img{
    max-width: 100%;
    height: auto;
    width: 40%;
    cursor: pointer;
}

@media  only screen and (max-width: 480px) {
    .youtube-logo img {
        max-width: 100%;
        height: auto;
        width: 35% !important;
        cursor: pointer;
    }
    .youtube-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .inside_card1 img {
        max-width: 100%;
        height: auto;
        width: 80%;
        cursor: pointer;
        filter: drop-shadow(2px 20px 25px black);
    }
    .inside_card1 {
        padding: 8% 0% 8% 0% !important;
        text-align: center;
    }
    .video-Sizing {
        width: 70vw !important;
        height: 40vw !important;
    }
}


@media  only screen and (min-width: 481px) and (max-width: 767px) { 
    .youtube-logo img {
        max-width: 100%;
        height: auto;
        width: 35% !important;
        cursor: pointer;
    }
    .youtube-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .inside_card1 img {
        max-width: 100%;
        height: auto;
        width: 80%;
        cursor: pointer;
        filter: drop-shadow(2px 20px 25px black);
    }
    .inside_card1 {
        padding: 8% 0% 8% 0% !important;
        text-align: center;
    }
    .video-Sizing {
        width: 70vw !important;
        height: 35vw !important;
    }

}


// @media  only screen and (min-width: 768px) {
// }

.video-Sizing{
    width: 60vw;
    height: 30vw;
    // border: 1px solid red;
}