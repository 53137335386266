.our_packages_new {
  /* background-image: url('../../fonts/images/our_package_back.png'); */
  background-image: url('../../fonts/images/our_packages.svg');
  transform-origin: 100% 0%;
  margin-top: -3rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  /* transform: skew(0deg, -1deg); */
}

.package_title {
  padding: 64px 80px 44px 80px;
}

.ourpackage_box_container {
  max-width: 100%;
  padding: 0 80px;
}

.package_head {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #0F326C;
  padding-bottom: 1rem;
  text-align: center;
}

.package_para {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  color: #0F326C;
  padding-bottom: 1rem;
  text-indent: -1.3rem;
  margin-left: 1.1rem;
}

.package_img {
  position: relative;
  text-align: center;
}

.package_img>img {
  max-width: 100%;
}

.package_content {
  position: absolute;
  top: 12%;
  left: 13.5%;
  width: 72%;
}

.our_btn {
  position: absolute;
  bottom: 10%;
  width: 82%;
  left: 5.8%;
}

.our_btn_1 {
  position: absolute;
  bottom: 10%;
  width: 84.5%;
  left: 12%;
}

.image_middle {
  vertical-align: middle;
}

.package_text {
  text-indent: 0;
}

.white_color {
  color: #FFF;
}

/* .banner_btn a {
  background-color: #0F326C;
  border: 1px solid #0F326C;
} */

.digital_banner_img_caption {
  position: absolute;
  text-align: center;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.digital_banner_contact_caption1 {
  position: absolute;
  text-align: right;
  bottom: 15%;
  right: 8%;
  transform: translate(-50%, -50%);
}

.digital_banner_contact_caption {
  position: absolute;
  text-align: right;
  bottom: 15%;
  right: 15%;
  transform: translate(-50%, -50%);
}

.digi_link a {
  background: linear-gradient(90.22deg, #DA4453 0.11%, #89216B 90.55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-decoration: underline;
}

.digi_link a:hover {
  background: linear-gradient(90.22deg, #f3eeee 0.11%, #f1edf0 90.55%);
  text-decoration: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.our_pacakge_banner_desk {
  display: block;
}

.our_pacakge_banner_mob {
  display: none;
}

.our_package_banner {
  padding-top: 72px;
}

/* ipad Air */
@media (min-width: 820px) and (max-width: 920px) { 
  .package_title {
    padding: 64px 24px 44px 24px;
  }
  .ourpackage_box_container{
    padding: 0px 24px;
  }

}


@media only screen and (max-width: 767px) {
  .ourpackage_box_container {
    padding: 0px 24px;
  }

  .package_title {
    padding: 0 1.5rem;
  }

  .our_packages_content_new {
    padding-top: 64px;
  }

  .package_head {
    font-size: 18px;
  }

  .package_para {
    font-size: 14px;
  }

  .our_pacakge_banner_desk {
    display: none;
  }

  .our_pacakge_banner_mob {
    display: block;
    padding: 16px 0 32px 0px;
  }
  .our_pacakge_banner_mob .caption_link a, .our_pacakge_banner_mob .caption_heading{
    font-size: 14px;
  }
  .our_pacakge_banner_mob .caption_heading_1 {
    font-size: 16px;
  }


  .our_package_banner {
    padding: 0;
    margin: 0 auto;
  }

  .package_space_in_mob {
    padding: 0px 0px 16px 0px;
  }

  .package_img > img{
    width: 650px;
    height: 290px;
  }

  .our_packages_content_new .section_sub_heading{
    hyphens: auto;
    line-height: 27px;
  }
  .about_us_container .heading_img {
    margin-left: -3rem;
  }
  .about_us_container .heading_rotate_img {
    transform: rotate(196deg);
    margin-right: -1.5rem;
  }

}

@media only screen and (max-width: 620px) { 
  .package_img > img{
    width: 80%;
    height: 350px;
  }

}

@media only screen and (max-width: 520px) { 
  .package_img > img{
    width: 100%;
    height: 340px;
  }

}

@media only screen and (max-width: 460px) { 
  .package_img > img{
    width: 100%;
    height: 395px;
  }
  .package_content {
    position: absolute;
    top: 15%;
    left: 9.5%;
    width: 80%;
}

}
@media only screen and (max-width: 360px) { 
  .package_img > img{
    width: 100%;
    height: 400px;
  }
  .package_content {
    position: absolute;
    top: 15%;
    left: 9.5%;
    width: 80%;
}

}

@media only screen and (max-width: 340px) { 
  .package_content {
    position: absolute;
    top: 14%;
    left: 7%;
    width: 85%;
}

}


@media (min-width: 770px) and (max-width: 899px) { 
  .package_img>img {
    height: 440px;
    width: 100%;
  }
  .package_content{
    position: absolute;
    top: 11%;
    left: 9.5%;
    width: 80%;
  }
  .package_head{
    font-size: 18px;
    line-height: 21px;
  }
  .package_para{
    font-size: 16px;
    line-height: 27px;
  }

}


@media screen and (min-width: 760px) { 
  .package_img>img {
    max-width: 100%;
    height: 435px;
  }
  .package_content{
    position: absolute;
    top: 11%;
    left: 5.5%;
    width: 87%;
  }
  .package_head{
    font-size: 18px;
    line-height: 21px;
  }
  .package_para{
    font-size: 14px;
    line-height: 18px;
  }

} 


@media screen and (min-width: 865px) { 
  .package_img>img {
    height: 540px;
    width: 100%;
  }
  .package_content{
    position: absolute;
    top: 11%;
    left: 6.5%;
    width: 85%;
  }
} 

@media screen and (min-width: 900px) { 
  .package_img>img {
    height: 500px;
    width: 100%;
  }
  .package_content{
    position: absolute;
    top: 11%;
    left: 7.5%;
    width: 84%;
  }
  .our_package_banner{
    padding-top: 48px;
  }
  .digital_banner_contact_caption {
    position: absolute;
    text-align: right;
    bottom: 16%;
    right: 11%;
    transform: translate(-50%, -50%);
  }
  .package_head{
    font-size: 18px;
    line-height: 21px;
  }
  .package_para{
    font-family: "Poppins-Regular";
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 12px;
  }

}

@media screen and (min-width: 1000px) { 
  .package_img>img {
    height: 450px;
    width: 100%;
  }
  .package_content{
    position: absolute;
    top: 10%;
    left: 7%;
    width: 82%;
  }
  .package_head{
    font-size: 24px;
    line-height: 36px;
  }
  .package_para{
    font-family: "Poppins-Regular";
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 16px;
    line-height: 27px;
  }
  .digital_banner_contact_caption {
    position: absolute;
    text-align: right;
    bottom: 16%;
    right: 9%;
    transform: translate(-50%, -50%);
}
.our_package_banner{
  padding-top: 72px;
}
}

@media screen and (min-width: 1023px) {
  .our_package_banner {
      padding-top: 48px;
  }
  .ourpackage_box_container {
    padding: 0 80px 64px 80px;
  }
}

@media screen and (min-width: 1100px) { 
  .package_img>img {
    height: 440px;
    width: 100%;
  }
  .package_content{
    position: absolute;
    top: 13%;
    left: 14%;
    width: 70%;
  }
  .our_package_banner{
    padding-top: 72px;
  }
  .ourpackage_box_container {
    padding: 0 80px 64px 80px;
  }
}



@media screen and (min-width: 1300px) { 
  .package_img>img {
    min-height: 410px;
    width: calc(100% - 50px);
    height: 70vh;
  }
  .package_content{
    position: absolute;
    top: 13%;
    left: 14%;
    width: 70%;
  }
  .our_package_banner {
    padding-top: 48px;
}
.our_package_banner .digital_banner_contact_caption {
  position: absolute;
  text-align: right;
  bottom: 17%;
  right: 9%;
  transform: translate(-50%, -50%);
}

}

@media screen and (min-width: 1400px) {
  .package_img > img {
    max-width: 85%;
    height: 380px;
  }
  .package_content {
    position: absolute;
    top: 15%;
    left: 14%;
    width: 70%;
  }
  .digital_banner_contact_caption {
    position: absolute;
    text-align: right;
    bottom: 17%;
    right: 12%;
    transform: translate(-50%, -50%);
  }
  .our_package_banner{
    padding-top: 72px;
  }
}

@media screen and (min-width: 1600px) {
  .package_img > img {
    max-width: 70%;
    height: 380px;
  }
  .package_content {
    position: absolute;
    top: 18%;
    left: 19.5%;
    width: 60%;
  }
  .digital_banner_contact_caption {
    position: absolute;
    text-align: right;
    bottom: 17%;
    right: 12%;
    transform: translate(-50%, -50%);
  }
  .our_package_banner{
    padding-top: 72px;
  }
}




/* 1900px large Desktop */
@media screen and (min-width: 1900px) {
  .package_img > img {
    max-width: 80%;
    height: 580px;
  }
  .package_content {
    position: absolute;
    top: 18%;
    left: 19.5%;
    width: 60%;
  }
  .digital_banner_contact_caption {
    position: absolute;
    text-align: right;
    bottom: 21%;
    right: 12%;
    transform: translate(-50%, -50%);
  }
  .our_package_banner{
    padding-top: 96px;
  }
  .package_title{
    padding: 96px 120px 48px 120px;
  }

  .ourpackage_box_container{
    padding: 0px 120px;
  }
  .package_para{
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    padding-bottom: 24px;
}
.package_head{
  font-size: 32px;
  padding-bottom: 24px;
  line-height: 48px;
}

.our_packages_content_new{
  padding-bottom: 96px;
}
.engage_logo_line {
  max-width: 40%;
  padding-bottom: 36px;
  line-height: 0;
}

}

@media screen and (min-width:768px) and (max-width:819px)  { 
  /* .our_package_banner{
    padding-top: 0;
  } */
  .digital_banner_contact_caption {
    position: absolute;
    text-align: right;
    bottom: 4%;
    right: 0%;
    transform: translate(-50%, -50%);
}
.package_title{
  padding: 64px 24px 44px 24px;
}
.ourpackage_box_container{
  padding: 0px 24px;
}
.our_package_banner {
  padding-top: 48px;
  padding-bottom: 48px;
}
.product-container_stackerank .our_package_banner {
  padding-top: 0px;
  padding-bottom: 0px;
}
}

@media screen and (min-width:820px) and (max-width:920px)  { 
  .our_package_banner {
    padding-top: 48px;
}
.ourpackage_box_container {
  padding: 0px 24px 48px 24px;
}
}