/* *{
  user-select: none;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/** Font Sizes For Main-Heading**/
.section_main_heading {
  font-family: "Poppins-Medium";
  font-size: 40px;
  font-weight: 500;
  color: #FFF;
  line-height: 72px;
}

.section_sub_heading {
  font-family: "Poppins-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #FFF;
  line-height: 27px;
  padding-bottom: 16px;
}

.section_sub_text {
  font-family: "Poppins-Regular";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  color: #FFF;
  text-transform: uppercase;
  line-height: 48px;
  /* padding-bottom: 16px; */
}

.heading-right {
  text-align: right;
}

.heading-left {
  text-align: left !important;
}

.color_white {
  color: #fff;
}

.sub_text_under_line {
  padding-bottom: 8px;
}

.contactbtn a {
  font-family: "Poppins-Bold";
  font-size: 18px;
  color: #FFF;
  background-color: #F05F30;
  border: 1px solid #F05F30;
  box-sizing: border-box;
  filter: drop-shadow(0px 16px 60px rgba(0, 0, 0, 0.15));
  border-radius: 20px;
  line-height: 32px;
  height: 32px;
  padding: 6px 60px;
  white-space: nowrap;
  text-decoration: none;
}

/****Menu Items****/
.menu-items {
  font-family: "Montserrat-Regular";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 17px;
  color: #002236;
}

/****Sub Headings****/
.sub_heading {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-size: 25px;
  line-height: 30px;
  color: #000;
  padding-bottom: 1rem;
}

/******Lines below sub heading*******/
.lines_styles {
  font-family: "Poppins-Regular";
  font-size: 18px;
  font-style: normal;
  line-height: 30px;
  color: #000;
}

/*****Content Color******/
.content_styles {
  font-family: "Poppins-Regular";
  line-height: 30px;
  /* color:#3d3935; */
  color: #000;
  padding-bottom: 1rem;
}

.orange_code {
  color: #FF6600;
}



.odd {
  background-color: #219EBC;
}

.even {
  background-color: #8CD6BC;
}

.ssd-services-section .ssd_image {
  box-shadow: unset !important;
}

.react-multi-carousel-dot button {
    background-color: #F05F30 !important;
    border-width: 0 !important;
    opacity: 0.4 !important;
    margin-right:  10px !important;
    width: 10px !important;
    height: 10px !important;
    border: none !important;
}

.react-multi-carousel-dot--active button {
  background: #F05F30 !important;
  opacity: 1 !important;
}

.aboutus__contact .Contact_btn_align {
  position: relative;
  top: -16px;
  right: 10px;
}


@media screen and (max-width:768px) {
  .aboutus__contact .Contact_btn_align {
    position: relative;
    top: 0px !important;
    right: 0px !important;
  }

  .section_sub_text {
    line-height: 28px;
  }

  .section_sub_heading {
    text-align: right;
    line-height: 21px;
  }

  .mob_banner_head {
    background: linear-gradient(270deg, #042435 0%, #003A55 48.23%, #052B40 100%);
  }

  .mob_banner_head h1 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 32px 0px;
    margin: 0;
  }

  .mob_banner_sub_head {
    background: #0C1922;
    padding: 30px 24px;
  }

  .mob_banner_sub_head h2 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #F05F30;
    margin-bottom: 8px;
  }

  .mob_banner_sub_head p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0px;
  }

  .mob_contactbtn {
    text-align: center;
    padding: 26px 0 0px;
  }

  .mob_contactbtn a {
    font-family: "Poppins-Bold";
    font-size: 18px;
    color: #FFF;
    background-color: #F05F30;
    border: 1px solid #F05F30;
    box-sizing: border-box;
    filter: drop-shadow(0px 16px 60px rgba(0, 0, 0, 0.15));
    border-radius: 20px;
    line-height: 32px;
    height: 32px;
    padding: 6px 60px;
    white-space: nowrap;
    text-decoration: none;
  }

  .mob_banner_sub_head .prof_sub_heading {
    margin: 0 0 8px 0px !important;
    padding: 0 !important;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }


  /* banner color */
  /*Home page */
  .home .mob_banner_head {
    background: linear-gradient(270deg, #042435 0%, #003A55 48.23%, #052B40 100%);
  }

  .home .mob_banner_sub_head {
    background: #0C1922;
    padding: 30px 24px;
    margin: -2px 0px 0px 0px;
  }

  .home .mob_banner_head h1 {
    line-height: 32px;
  }

  /*Digital Service */
  .digital .mob_banner_head {
    background: #081742;
  }

  .digital .mob_banner_sub_head {
    background: #081742;
    padding: 30px 24px;
  }

  /*Project Service */
  .project .mob_banner_head {
    background: linear-gradient(270deg, #042435 0%, #003A55 48.23%, #052B40 100%);
  }

  .project .mob_banner_sub_head {
    background: #0C1922;
    padding: 30px 24px;
  }

  /*Professional Service */
  .prof .mob_banner_head {
    background: linear-gradient(270deg, #1D639D 0%, #1E598A 19.78%, #1F4F77 33.56%, #1F4C71 39.71%, #1F486A 45.13%, #203E58 60.01%, #213243 81.42%, #222B35 100%);
  }

  .prof .mob_banner_sub_head {
    background: linear-gradient(270deg, #1E527D 0%, #21425E 35.2%, #222B35 100%);
    padding: 30px 24px;
  }

  /*About us */
  .about .mob_banner_head {
    background: linear-gradient(90deg, #25619D 0%, #215C97 21.19%, #1B4C82 53.65%, #153F6F 68.31%, #63B1CC 100%);
  }

  .about .mob_banner_sub_head {
    background: linear-gradient(90deg, #276CA6 0%, #275F9D 33.76%, #214C81 53.77%, #1C4172 70.11%, #12243A 100%);
    padding: 30px 24px;
  }

  /*Career */
  .career .mob_banner_head {
    background: #FFFFFF;
  }

  .career .mob_banner_sub_head {
    background: #FFFFFF;
    padding: 30px 24px;
  }

  .career .mob_banner_head h1 {
    color: #F05F30;
  }

  .career .mob_banner_sub_head p {
    color: #153E75;
  }

  /*blog */
  .blog .mob_banner_head {
    background: #030202;
  }

  .blog .mob_banner_sub_head {
    background: linear-gradient(90deg, #0C1F4A 0%, #0B1D45 13.35%, #0C1C40 24.84%, #0B193C 36.33%, #0C1737 59.92%, #0A1330 77.77%, #080D21 100%);
    padding: 30px 24px;
  }

  /*product */
  .product .mob_banner_head {
    background: #0F50A7;
  }

  .product .mob_banner_sub_head {
    background: #0F50A7;
    padding: 30px 24px;
  }

  /*Conduct */
  .contact .mob_banner_head {
    background: linear-gradient(90deg, #0D1F1F 0%, #0F2825 20.31%, #153736 33.29%, #2D5B59 51.69%, #428986 61.98%, #346D6C 70.05%, #1E4E4E 79.11%, #1F4C49 89.5%, #366D6A 100%);
  }

  .contact .mob_banner_sub_head {
    background: linear-gradient(90deg, #021525 0%, #021728 13.39%, #01263D 21.25%, #052F48 29.43%, #0B4665 37.61%, #366B87 47.1%, #4384A0 63.79%, #4A99BA 81.79%, #2A7999 90.95%, #1C6B8D 97.6%);
    padding: 30px 24px;
  }

  /*Partnership */
  .partner .mob_banner_head {
    background: linear-gradient(270deg, #042435 0%, #003A55 48.23%, #052B40 100%);
  }

  .partner .mob_banner_sub_head {
    background: #0C1922;
    padding: 30px 24px;
  }


  /* banner color end */


}


.cc-7doi .cc-1ada{
  background-color: #F05F30 !important;;
}


/* 404 Page */



@media screen and (min-width: 1900px) {
  .section_sub_heading_1{
    padding-top: 0px;
  }
  .section_main_heading {
    font-family: "Poppins-Medium";
    font-size: 48px;
    font-weight: 500;
    color: #FFF;
    line-height: 72px;
  }
  
  .section_sub_heading {
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 400;
    color: #FFF;
    padding-bottom: 16px;
    line-height: 36px;
    padding-bottom: 40px;
    padding-top: 8px;
    font-size: 20px;
  }
  
  .section_sub_text {
    font-family: "Poppins-Regular";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    color: #FFF;
    text-transform: uppercase;
    line-height: 48px;
    /* padding-bottom: 16px; */
  }

}

@media screen and (min-width:768px) and (max-width:819px)  { 
  .home .mob_banner_head {
    background: linear-gradient(270deg, #042435 0%, #003A55 48.23%, #052B40 100%);
}
.about .mob_banner_head {
  background: linear-gradient(90deg, #25619D 0%, #215C97 21.19%, #1B4C82 53.65%, #153F6F 68.31%, #63B1CC 100%);
}
.about .mob_banner_sub_head {
  background: linear-gradient(90deg, #276CA6 0%, #275F9D 33.76%, #214C81 53.77%, #1C4172 70.11%, #12243A 100%);
  padding: 30px 24px;
}
.career .mob_banner_head {
  background: #FFFFFF;
}
.career .mob_banner_head h1 {
  color: #F05F30;
}
.career .mob_banner_sub_head {
  background: #FFFFFF;
  padding: 30px 24px;
}
.blog .mob_banner_head {
  background: #030202;
}
.blog .mob_banner_sub_head {
  background: linear-gradient(90deg, #0C1F4A 0%, #0B1D45 13.35%, #0C1C40 24.84%, #0B193C 36.33%, #0C1737 59.92%, #0A1330 77.77%, #080D21 100%);
  padding: 30px 24px;
}
.contact .mob_banner_head {
  background: linear-gradient(90deg, #0D1F1F 0%, #0F2825 20.31%, #153736 33.29%, #2D5B59 51.69%, #428986 61.98%, #346D6C 70.05%, #1E4E4E 79.11%, #1F4C49 89.5%, #366D6A 100%);
}
.contact .mob_banner_sub_head {
  background: linear-gradient(90deg, #021525 0%, #021728 13.39%, #01263D 21.25%, #052F48 29.43%, #0B4665 37.61%, #366B87 47.1%, #4384A0 63.79%, #4A99BA 81.79%, #2A7999 90.95%, #1C6B8D 97.6%);
  padding: 30px 24px;
}
.partner .mob_banner_head {
  background: linear-gradient(270deg, #042435 0%, #003A55 48.23%, #052B40 100%);
}
.partner .mob_banner_sub_head {
  background: #0C1922;
  padding: 30px 24px;
}
  .mob_banner_head h1 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 32px 0px;
    margin: 0;
  }
  .mob_banner_sub_head h2 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #F05F30;
    margin-bottom: 8px;
}
.mob_banner_sub_head p {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 0px;
}
.career .mob_banner_sub_head p {
  color: #153E75;
}
  .home .mob_banner_head h1 {
    line-height: 32px;
}

  .mob_banner_sub_head {
    background: #0C1922;
    padding: 30px 24px;
  }

  .mob_banner_sub_head h2 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #F05F30;
    margin-bottom: 8px;
  }

  .mob_banner_sub_head p {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0px;
  }

  .mob_contactbtn {
    text-align: center;
    padding: 26px 0 0px;
  }

  .mob_contactbtn a {
    font-family: "Poppins-Bold";
    font-size: 18px;
    color: #FFF;
    background-color: #F05F30;
    border: 1px solid #F05F30;
    box-sizing: border-box;
    filter: drop-shadow(0px 16px 60px rgba(0, 0, 0, 0.15));
    border-radius: 20px;
    line-height: 32px;
    height: 32px;
    padding: 6px 60px;
    white-space: nowrap;
    text-decoration: none;
  }
  .digital .mob_banner_head {
    background: #081742;
}
.digital-banner .prof_sub_heading {
  text-align: center;
}
  .mob_banner_head h1 {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 32px 0px;
    margin: 0;
}
.prof .mob_banner_head {
  background: linear-gradient(270deg, #1D639D 0%, #1E598A 19.78%, #1F4F77 33.56%, #1F4C71 39.71%, #1F486A 45.13%, #203E58 60.01%, #213243 81.42%, #222B35 100%);
}
.product .mob_banner_head {
  background: #0F50A7;
}
.product .mob_banner_sub_head {
  background: #0F50A7;
  padding: 30px 24px;
}

}