.how_it_work_content{
        transform: skew(0deg, 1deg);
        padding: 64px 0px 64px 0px;
}
.how_it_head{
    font-family: "Poppins-Regular";
    font-size: 16px;
    color: #fff;
    padding: 0;     
    line-height: 27px;
    font-weight: 400;
    font-synthesis: none;
    text-align: justify;
    margin: 0;
 }
 .how_it_para{
    font-family: "Poppins-Regular";
    font-size: 18px;
    color: #fff;
    padding: 0;
    text-align: justify;
 }
 .how_it_content{
    align-items: center;
    justify-content: flex-start;
    padding: 48px 80px 0px 80px;
 }
 .how_it_content_step_2{
    align-items: center;
    justify-content: flex-end;
    padding: 72px 80px 72px 80px;
 }
 .how_it_content_img{
     text-align: left;
 }
 .how_it_content_img_right_proj{
    text-align: left;
}
.how_it_works_content_right{
    margin-left: -80px;
    padding: 0;
}
.how_it_works_content_right_1{
    right: 50px;
    padding: 0;
}


.works-image{
    /* max-width: 311px; */
    height: auto;
    vertical-align: middle;
    text-align: center;
    padding: 0rem 1rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    /* border: 1px solid #F9F9F9;
    background-color: #F9F9F9;
    box-shadow: rgb(149 157 165 / 50%) 6px 6px 10px; */
}
.value_add_box {
    background-color: #219EBC;
    border: 1px solid transparent;
    border-radius: 10px;
    height: 290px;
    box-sizing: border-box;
    /* width: 220px;
    margin: 0px 70px; */
}
.para {
    color: white;
    font-family: "Montserrat-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}
.heading {
    color: white;
    text-align: center;
    font-family: "Montserrat-Regular";
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
}
.steps_align{
    font-family: "Montserrat-Regular";
    font-weight: 400;
    font-size: 18px;
}



@media only screen and (max-width: 768px) {
    .how_it_content{
        padding: 48px 24px 0px 24px;
    }
    .how_it_content_step_2 {
        align-items: flex-end;
        justify-content: flex-end;
        padding: 72px 24px 72px 24px;
    }
    .how_it_work_content{
        padding: 64px 0px 64px 0px;
    }
    .how_it_head{
        font-size: 14px;
        line-height: 24px;
    }
    .value_add_box {
        margin: 10px 0px;
        width: 100%;
        height: auto;
    }
    .para {
        font-size: 20px;
        color: #999898;
    }
    .heading {
        color: white;
        font-family: "Montserrat-Regular";
        text-align: center;
        font-size: 34px;
        margin: 1.2rem 0;
        line-height: 40px;
        padding-bottom: 0rem;
    }
    .needs-heading-center {
        font-size: 40px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .how_it_content_img_right_proj{
        text-align: left;
        max-width: 30%;
        flex: 0 0 30%;
    }
    
}

@media only screen and (max-width: 740px) { 
    .how_it_content_img_right_proj{
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 770px) and (max-width: 1119px) { 
    .how_it_content_img_proj{
        flex: 0 0 27.666667%;
        max-width: 27.666667%;
    }
    .how_it_works_content_right_proj{
        flex: 0 0 70.666667%;
        max-width: 70.666667%;
    }
    .how_it_content_img_right_proj{
        flex: 0 0 30.666667%;
        max-width: 30.666667%;
    }
    .how_it_works_content_right_1_proj{
        flex: 0 0 67%;
        max-width: 67%;
    }

}

@media (min-width: 1100px) and (max-width: 1200px) { 
    .how_it_content_img_proj{
        flex: 0 0 21.666667%;
        max-width: 21.666667%;
    }
    
}


@media screen and (min-width: 1240px) {
    .how_it_content_img_proj{
        flex: 0 0 18.666667%;
        max-width: 18.666667%;
    }
    .how_it_works_content_right_proj{
        flex: 0 0 70%;
        max-width: 70%;
    }

    .how_it_content_img_right_proj{
        flex: 0 0 21.666667%;
        max-width: 21.666667%;
    }
    .how_it_works_content_right_1_proj{
        flex: 0 0 62%;
        max-width: 62%;
    }

  }

@media screen and (min-width: 1250px) { 
    .how_it_content_img_right_proj{
        flex: 0 0 21.666667%;
        max-width: 21.666667%;
    }
}
  @media screen and (min-width: 1900px) {
    .how_it_content_img_proj{
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .how_it_works_content_right_proj{
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: -70px;
    }
    .how_it_content_proj_step_2{
        justify-content: center !important;
    }
    .how_it_content_img_right_proj{
        flex: 0 0 14.666667%;
        max-width: 14.666667%;
    }
    .how_it_works_content_right_1_proj{
        flex: 0 0 60%;
        max-width: 60%;
    }
    .how_it_head{
        line-height: 36px;
        font-size: 20px;
      }
      .how_it_work_content{
          padding: 96px 0px 114px 0px;
      }


  }